import React, { useCallback, useMemo, useState, useRef, memo } from 'react';

import InputTypeNumber from 'components/UI/input/text/number';

export const withCustomPrice = (BaseComponent, useLazyHook, type) => {
	return memo(props => {
		const refetchHandlerRef = useRef();
		const onChangePriceHandlerRef = useRef();
		const [showCustomPrice, setShowCustomPrice] = useState(0);

		const [refetch, { data, isFetching, isError }] = useLazyHook();

		const refetchData = useCallback(action => refetchHandlerRef.current(refetch, action), [refetch]);

		const changedData = useMemo(() => {
			if (data && !data.length) refetchData('refetch');

			const items = Array.isArray(data) ? data : typeof data === 'object' && data?.totalCount ? data.apiResponse ?? [] : [];
			return { items, status: items.some(el => el.price === false) };
		}, [data]);

		const checkShowCustomPrice = useMemo(() => {
			if (type && type === 'engraving') return showCustomPrice === 0;
			return changedData.status && changedData.items.length !== 0;
		}, [changedData]);

		const onChangeCustomPrice = useCallback(price => {
			setShowCustomPrice(price);
			onChangePriceHandlerRef.current(price);
		}, []);

		return (
			<BaseComponent
				{...props}
				refetchHandlerRef={refetchHandlerRef}
				onChangeCustomPrice={onChangePriceHandlerRef}
				refetchData={refetchData}
				isFetching={isFetching}
				isError={isError}
				changedData={changedData}
				customPrice={showCustomPrice}
				changeCustomPrice={setShowCustomPrice}
			>
				{checkShowCustomPrice && (
					<InputTypeNumber
						blockClasses='own__form__input'
						label='Введите стоимость'
						defaultValue={showCustomPrice}
						name={`own__form__input__faska}`}
						id={`own__form__input__faska}`}
						onChange={onChangeCustomPrice}
						useDelay={false}
					/>
				)}
			</BaseComponent>
		);
	});
};
