export const generateCatalogWithCanvas = (status = false, side = ['front'], selectedProducts = []) => {
	const obj = {
		status,
		side,
		selectedProducts
	};

	if (side === false) delete obj.side;

	return obj;
};
