import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Pedestal from './Pedestal';
import Tombstone from './Tombstone';
import SecondButton from 'components/UI/button/second';
import SidebarElementTab from 'components/UI/tabs/sidebarElementTab';
import PrimaryButton from 'components/UI/button/primary';

import { useGoToNextStep } from 'hooks/useGoToNextStep';
import { useActions } from 'hooks/useActions';
import { useCart } from 'hooks/useCart';
import useBurialsData from 'hooks/useBurialsData';

import { excel } from 'services/Excel';

import { ReactComponent as TombstoneIcon } from 'images/sidebar/Tombstone.svg';
import { ReactComponent as PedestalIcon } from 'images/sidebar/Pedestal.svg';

import './elements.scss';
import { dispatchEvent } from '../../../utils/events/dispatchEvent';

const sleep = time =>
	new Promise(resolve => {
		setTimeout(() => resolve(), time);
	});

const Elements = () => {
	const actions = useActions();
	const goToNextStep = useGoToNextStep();
	const { burial, currentBurial, firstImprovedBurialIndex, burials } = useBurialsData();
	const areaSize = useSelector(state => state.configurator.present.step1.workZone.zoneValues);
	const cart = useCart(burials);

	const currentStella = burial.stella.items[burial.stella.currentStella];
	const currentTombstone = burial.tombstone.items[burial.tombstone.currentTomb];
	const instalation = useSelector(state => state.configurator.present.step1.installation);
	const tombstoneLength = useMemo(() => burial.tombstone.items.length, [burial.tombstone.items.length]);

	const nextBtnClickHandler = useCallback(() => {
		actions.goToStep(4);
		actions.changeCurrentBurial(firstImprovedBurialIndex);
		actions.clearHistory();
	}, [actions, firstImprovedBurialIndex]);

	const goToNextStepFunc = id => goToNextStep.goToNextStep(nextBtnClickHandler, id);

	const [activeTab, setActiveTab] = useState('ПАМЯТНИК');

	let tablesTabs = useMemo(() => {
		let tabs = [
			{ name: 'ПАМЯТНИК', type: 'stele', icon: <PedestalIcon /> },
			{ name: 'НАДГРОБ.', type: 'tombstone', icon: <TombstoneIcon /> }
		];

		if (tombstoneLength === 0) tabs = tabs.splice(0, 1);

		return tabs;
	}, [tombstoneLength]);

	const openCanvasHandler = useCallback(() => {
		const tab = tablesTabs.find(el => el.name === activeTab);
		const type = tab.type === 'stele' && burial.stella.currentStella != 0 ? 'second_stele' : tab.type;
		const currentEl = type.includes('stele') ? currentStella : currentTombstone;
		actions.toggleCanvasContainer({
			status: true,
			id: currentEl?.id
		});
	}, [
		activeTab,
		burial.id,
		burial.stella.currentStella,
		burial.tombstone.currentTomb,
		currentStella?.id,
		currentTombstone?.id,
		actions,
		tablesTabs
	]);

	const nextHandler = useCallback(async () => {
		if (instalation === 'Установка памятника') {
			goToNextStepFunc(4);
		} else {
			const duration = 1;
			const images = {};
			images.steles = [];
			images.tombstones = [];
			const iframe = document.getElementById('iframe');
			await dispatchEvent(`setCameraTarget`, { target: null, duration });
			await sleep(100);
			images.base = iframe.contentDocument.getElementsByClassName('v3d-canvas')[0].toDataURL() ?? '';
			await sleep(300);
			for (let burialId = 0; burialId < burials.length; burialId++) {
				await dispatchEvent(`setCameraTarget`, { target: 'stella', currentBurial: burialId, duration });
				await sleep(100);
				images.steles.push(iframe.contentDocument.getElementsByClassName('v3d-canvas')[0].toDataURL() ?? '');
				await sleep(300);
			}
			for (let burialId = 0; burialId < burials.length; burialId++) {
				await dispatchEvent(`setCameraTarget`, { target: 'flower', currentBurial: burialId, duration });
				await sleep(100);
				images.tombstones.push(iframe.contentDocument.getElementsByClassName('v3d-canvas')[0].toDataURL() ?? '');
				await sleep(300);
			}
			await dispatchEvent(`setCameraTarget`, { target: null, duration });
			const { url: link } = await excel.getExcel(cart, images, areaSize);
			const linkEl = document.createElement('a');
			linkEl.setAttribute('href', link);
			linkEl.setAttribute('target', '_blank');
			linkEl.click();
			linkEl.remove();
		}
	}, [instalation, cart, areaSize]);

	useEffect(() => {
		let target = null;
		// eslint-disable-next-line default-case
		switch (activeTab) {
			case 'ПАМЯТНИК':
				target = 'stella';
				break;
			case 'НАДГРОБ.':
				target = 'tombstone';
				break;
		}
		dispatchEvent(`setCameraTarget`, { target, currentBurial });
	}, [activeTab, currentBurial]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Элементы</h1>
			<SidebarElementTab
				activeTab={activeTab}
				changeTab={setActiveTab}
				items={tablesTabs}
				burials={burials}
				currentBurial={currentBurial}
			/>
			{activeTab == 'ПАМЯТНИК' && <Pedestal burial={burial} />}
			{activeTab == 'НАДГРОБ.' && <Tombstone burial={burial} currentBurial={currentBurial} />}

			<SecondButton title='Расположение' id='edit__tables_position' onClick={openCanvasHandler} classes='mb24 box__value__edit' />

			<PrimaryButton title={instalation === 'Установка памятника' ? 'Продолжить' : 'Получить XLSX'} onClick={nextHandler} />
		</>
	);
};

export default Elements;
