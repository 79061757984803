import React, { useCallback, useState, useEffect } from 'react';

const ProductListItem = ({ title, sku, size, price, status, onClick, classNames = 'product__card product__card--list', item, ...data }) => {
	const [isActive, setActive] = useState(status);

	useEffect(() => setActive(status), [isActive, status]);

	const onClickCallback = useCallback(() => {
		if (isActive) return;

		onClick({ ...item, status: !isActive });
		setActive(!isActive);
	}, [isActive, item, onClick]);

	return (
		<div className={classNames} onClick={onClickCallback}>
			<div className='product__card__heading'>
				<h1>{size}</h1>
				<h2>{title}</h2>
				<span>Артикул: {sku}</span>
			</div>
			<div className='product__card__details'>
				<h3>{price}</h3>
				<button className={`--svg__add_to_cart-after ${isActive ? 'active' : ''}`}></button>
			</div>
		</div>
	);
};

export default ProductListItem;
