import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';

import RadioStoneInput from 'components/UI/input/radioStone';
import BlockWithArrow from 'components/UI/block/blockWithArrow';

import { preloaderEvents } from 'utils/events/preloader';
import { productsAPI } from 'services/Products';

const TileStone = ({ categoryType, type, module_tax }) => {
	const actions = useActions();
	const categories = useConfiguratorCategories('materials', categoryType);
	const stone = useSelector(state => state.configurator.present.step5.tile[type][0]);

	const onChangeMaterialHandler = useCallback(
		value => {
			if (!value) return;
			const check = (stone?.tile || []).some(prod => prod.id === value.tile?.id);
			if (check) value.tile = [];
			const tileProducts = [...(stone?.tile || []), value?.tile || []].flat();
			actions.changeTileStone({ type: type, value: { ...value, tile: tileProducts } });
		},
		[type, stone]
	);

	useEffect(() => {
		actions.changeTileStone({ type: type, value: categories[0] });
	}, [type]);

	const [refetch, { data, isFetching, isError, isSuccess }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => {
		if (!stone) return;
		refetch(
			{
				args: {
					per_page: 1,
					page: 1,
					material: stone.id,
					module_tax
				}
			},
			true
		);
	}, [stone, module_tax]); // the second arg is preferCacheValue

	useEffect(() => {
		refetchData();
	}, [stone]);

	useEffect(() => {
		if (isFetching) {
			preloaderEvents.preloaderEnable();
		} else {
			preloaderEvents.preloaderDisable();
		}
	}, [isFetching, isError, isSuccess]);

	useEffect(() => {
		if (data && data?.apiResponse?.length) {
			onChangeMaterialHandler({ ...stone, tile: data.apiResponse?.[0] });
		}
	}, [data]);

	return stone ? (
		<BlockWithArrow
			title='Материал плитки'
			blockClasses='sidebar__radio__block__value checkbox__type change__type__stone'
			contentClasses='sidebar__radio__block__value__items'
		>
			{categories &&
				categories.map((item, index) => (
					<RadioStoneInput
						key={index}
						img={item.img}
						name={item.material}
						title={item.title}
						id={item.material + item.title}
						defaultChecked={item.title === stone.title}
						onChange={onChangeMaterialHandler.bind(null, item)}
						size={item.default_plate_size}
						type='radio'
					/>
				))}
		</BlockWithArrow>
	) : null;
};

export default TileStone;
