import React, { useCallback, useMemo } from 'react';

import { useActions } from 'hooks/useActions';
import TypeStone from 'components/UI/bigComponents/TypeStone';

const TypeStoneContent = ({ burial, currentBox, pedestalAmount }) => {
	const actions = useActions();
	const material = burial.material.value;
	const defaultMaterial = burial.boxes.items[currentBox].stone;
	const type = useMemo(() => (pedestalAmount >= 1 ? 'top' : 'bottom'), [pedestalAmount]);

	const onChangeMaterialHandler = useCallback(
		item => {
			actions.changeBoxStone(item.title);
		},
		[burial.id, currentBox, actions, type]
	);

	return (
		<TypeStone
			materialTitle='Вид камня'
			type={material}
			stone={defaultMaterial}
			onChangeMaterialHandler={onChangeMaterialHandler}
			showJustMaterials
		/>
	);
};

export default TypeStoneContent;
