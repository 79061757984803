import React from 'react';
import { getStringLengthWithoutSpaces } from 'utils/getStringLengthWithoutSpaces';

import './textarea.scss';

const TextareaInput = ({ blockClasses = 'textarea', name, id, required, readOnly = false, onChange = () => {}, value = '' }) => {
	return (
		<div className={blockClasses}>
			<textarea name={name} id={id} required={required} readOnly={readOnly} onChange={e => onChange(e, e.target.value)} value={value} />
			<span>{getStringLengthWithoutSpaces(value)}</span>
		</div>
	);
};

export default TextareaInput;
