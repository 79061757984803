import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useGetParametrs from './../../../API/getParametrs';

import CatalogForm from 'components/UI/form/catalogForm';

import { useActions } from 'hooks/useActions';
import { v4 as uuid } from 'uuid';

const ForgedElements = () => {
	const actions = useActions();
	const { status, items } = useSelector(state => state.configurator.present.step5.decoratedElements.forged);

	const getParamsHandler = useGetParametrs();

	const onChangeToggleHandler = useCallback(() => actions.changeDecoratedElementsStatus({ type: 'forged', values: !status }), [status]);

	const onClickProductHandler = useCallback(item => actions.changeDecoratedElementsSelectedProducts({ type: 'forged', values: item }), []);

	return (
		<CatalogForm
			formTitle='Кованные элементы'
			formState={status}
			onToggle={onChangeToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог элементов'
			products={() => getParamsHandler.getModuleProducts('elements')}
			selectedProducts={items}
			plusState
			onPlusHandler={product => {
				actions.changeDecoratedElementsSelectedProducts({
					type: 'forged',
					values: { ...product, sku: product.sku + Array(items.length).fill(' ').join('') },
					clone: true
				});
			}}
		/>
	);
};

export default ForgedElements;
