import { dispatchEvent } from '../dispatchEvent';

export const step1Events = {
	// graves
	amountChange(value) {
		return dispatchEvent('grave_amount_changed', value);
	},
	// changeState(id, state = 'improved') {
	// 	return dispatchEvent('grave_state_changed', {
	// 		state: state,
	// 		id: id + 1
	// 	});
	// },
	// changeDimensions(type, areaValues, id) {
	// 	return dispatchEvent(`grave_${type}_changed`, {
	// 		width: parseFloat(areaValues.width),
	// 		height: parseFloat(areaValues.length),
	// 		id: id + 1
	// 	});
	// },
	// uniteGraves(index) {
	// 	return dispatchEvent('unite_graves_changed', [index + 1, index + 2]);
	// },

	// pickup
	pickUpSelfDelivery() {
		return dispatchEvent('pickup_selected');
	},
	pickUpInstallation() {
		return dispatchEvent('installation_selected');
	},

	// religion
	changeReligion(value) {
		return dispatchEvent('religion_changed', value);
	},

	// work zone
	getMinWorkingAreaDimension() {
		return new Promise((resolve, reject) => {
			dispatchEvent('get_min_working_area_dimension', data => {
				resolve(data);
			});
		});
	},
	workZoneDimensionChange(type, value) {
		return dispatchEvent(`working_zone_dimensions_${type}_changed`, value);
	},
	workAreaChange(value) {
		return dispatchEvent('working_area_changed', value);
	}
};
