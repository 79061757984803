import { setGoToStepMessage } from './setGoToStepMessage';

export const checkGoToStep = (clicked, steps, burials) => {
	const activeStep = [...steps].reverse().find(step => step.isActive).id;
	const willLostSteps = [...steps].filter(el => el.id <= activeStep && el.id > clicked && el.id !== clicked).map(el => el.id);
	const mustBeCheckedSteps = [...steps].filter(step => step.id < clicked).map(el => el.id);
	const improvedBurials = burials.filter(el => el.selected === 'improved');

	const checkItems = items => {
		const check = items.every(el => {
			const products = el.selectedProducts;
			const sizesStatus = el.individualSizes.status;
			return sizesStatus === true || products.length !== 0;
		});

		return check;
	};

	const checkBadBurials = improvedBurials.reduce((currentArr, el, i, arr) => {
		const { type, selectedProducts } = el.catalog;
		const { pedestal, stella, boxes, flowers, tombstone } = el;

		const pedestalCheck = pedestal.items.length !== 0 && checkItems(pedestal.items);
		const stellaCheck = stella.items.length !== 0 && checkItems(stella.items);
		const flowerCheck = flowers.items.every(flower => {
			const check = item => item.status || item.selectedProducts.length !== 0;
			return check(flower.poperehna) && check(flower.prodolna);
		});
		const checkTombstone = checkItems(tombstone.items);

		const item = {
			step1: [el.selected === 'improved'],
			step2: [type === 'go-step' || selectedProducts.length !== 0],
			step3: {
				graveBasic: boxes.items.length !== 0 || pedestalCheck,
				stella: stellaCheck,
				flowers: flowerCheck,
				tombstone: checkTombstone
			}
		};

		const status = Object.values(item).every((el, index) => {
			const check = Object.values(el).every(item => item === true);
			return mustBeCheckedSteps.includes(index + 1) ? check : true;
		});

		return !status ? currentArr.concat({ ...item, id: el.id + 1 }) : currentArr;
	}, []);

	const message = setGoToStepMessage({ checkBadBurials, clicked, activeStep, improvedBurials, willLostSteps });

	return { checkBadBurials, willLostSteps, activeStep, improvedBurials, message };
};
