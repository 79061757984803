import React, { useMemo, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Step1 from 'components/step1';
import Step2 from 'components/step2';
import Step3 from 'components/step3';
import Step4 from 'components/step4';
import Step5 from 'components/step5';
import Step6 from 'components/step6';
import Configurator from 'components/Configurator';
import SidebarStep3 from 'components/step3/Sidebar';
import Preloader from 'components/Preloader';
import Header from 'components/Header/';
import EventsPreloader from 'components/Preloader/EventsPreloader';

import HomeEvents from 'utils/events/home';
import { useWindowEvent } from '@mantine/hooks';
import { useCategories } from 'hooks/useCategories';
import { useActions } from 'hooks/useActions';
import { useApiHook } from 'hooks/apiVerge';

const components = [Step1, Step2, Step3, Step4, Step5, Step6];

const Home = () => {
	const actions = useActions();
	const steps = useSelector(state => state.configurator.present.configuratorSteps.steps);
	const stepIndex = useMemo(
		() =>
			Object.values(steps)
				.reverse()
				.find(el => el.isActive === true).id - 1,
		[steps]
	);
	const loading = useSelector(state => state.configurator.present.step1.loading);
	const homeEvents = HomeEvents();
	const CurrentStepComponent = useMemo(() => components[stepIndex], [stepIndex]);
	const style = useMemo(() => (stepIndex == 2 ? { marginLeft: 12 } : { marginLeft: 0 }), [stepIndex]);

	// graves
	useWindowEvent('change_working_zone', homeEvents.changeWorkingZoneHandler);
	useWindowEvent('change_areaValues', homeEvents.changeAreaValues);
	useWindowEvent('changeBurialPosition', homeEvents.changeBurialPosition);
	useWindowEvent('changeExcludedItemPosition', homeEvents.changeExcludedItemPosition);
	// useWindowEvent('graves_united', homeEvents.changeGravesHandler);
	// useWindowEvent('graves_severed', homeEvents.changeGravesHandler);
	useWindowEvent('meta_data_changed', homeEvents.changeGravesHandler);
	// stella
	useWindowEvent('update_allowed_alignments', homeEvents.update_allowed_alignments);
	useWindowEvent('will_the_second_stele_fit', homeEvents.will_the_second_stele_fit);
	useWindowEvent('set_max_for_parallel', homeEvents.set_max_for_parallel);
	useWindowEvent('set_max_for_line', homeEvents.set_max_for_line);
	useWindowEvent('update_stele_popup', homeEvents.update_stele_popup);
	useWindowEvent('update_one_stele_popup', homeEvents.update_one_stele_popup);
	// boxes / cubes
	useWindowEvent('update_top_cubes_popup', homeEvents.changeBoxPopValuesHandler);
	useWindowEvent('update_bottom_cubes_popup', homeEvents.changeBoxPopValuesHandler);
	useWindowEvent('update_top_cubes_popup_after_length_changing', homeEvents.changeBoxPopValuesHandler);
	useWindowEvent('update_bottom_cubes_popup_after_length_changing', homeEvents.changeBoxPopValuesHandler);

	useEffect(() => actions.fetchFirstComplectForBurial(), []);

	useCategories();
	useApiHook();

	return (
		<>
			<EventsPreloader />
			{loading ? <Preloader /> : <Header />}
			<main className='main'>
				{!loading && (
					<>
						{stepIndex == 2 && <SidebarStep3 />}
						<div className='sidebar'>
							<div className='sidebar__container'>
								<div style={style} className='sidebar__content'>
									<CurrentStepComponent />
								</div>
							</div>
						</div>
					</>
				)}
				<Configurator stepIndex={stepIndex} />
			</main>
		</>
	);
};

export default memo(Home);
