import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import Religion from './Religion';
import WorkZone from './WorkZone';
import HowManyBurials from './HowManyBurials';
import LinkBurialsComponent from './LinkBurialsComponent';
import InstallationMethod from './InstallationMethod';
import BtnGoToNextStep from './BtnGoToNextStep';
import BurialsList from './Burials/index';

import useBurialsData from 'hooks/useBurialsData';

const Step1 = () => {
	const { burialsImproved } = useBurialsData();
	const instalation = useSelector(state => state.configurator.present.step1.installation);

	return (
		<>
			<InstallationMethod />
			<Religion />
			{instalation === 'Установка памятника' ? (
				<>
					<WorkZone />
					<HowManyBurials />
					<BurialsList />
					{/*<LinkBurialsComponent />*/}
				</>
			) : (
				<HowManyBurials />
			)}
			{burialsImproved.length !== 0 && <BtnGoToNextStep id={burialsImproved[0].id} />}
		</>
	);
};

export default memo(Step1);
