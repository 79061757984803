import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';

import CatalogForm from 'components/UI/form/catalogForm';

import { productsAPI } from 'services/Products';

const Fence = () => {
	const actions = useActions();
	const categories = useConfiguratorCategories('modules');
	const module_tax = categories.find(el => el.slug === 'fence')?.id;
	const fenceData = useSelector(state => state.configurator.present.step5.fence);

	const changeDataHandler = useCallback(data => actions.changeFenceData(data), []);
	const onToggleHandler = useCallback(() => changeDataHandler({ status: !fenceData.status, data: [] }), [fenceData.status]);
	const onProductClickHandler = useCallback(
		item => {
			const include = fenceData.selectedProducts.some(el => el.sku === item.sku);

			changeDataHandler({
				status: fenceData.status,
				data: include ? [] : [item]
			});
		},
		[fenceData]
	);

	const [refetch, { data, isFetching, isError }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => refetch({ args: { module_tax, per_page: 100 } }, true), [module_tax]); // the second arg is preferCacheValue

	return (
		<CatalogForm
			formTitle='Оградка'
			formState={fenceData.status}
			onToggle={onToggleHandler}
			onClickProductCard={onProductClickHandler}
			btnTitle='Каталог оградок'
			products={data?.apiResponse ?? []}
			refetch={refetchData}
			hook
			isLoading={isFetching}
			isError={isError}
			selectedProducts={fenceData.selectedProducts}
			forseOpen={fenceData.forseOpen}
			parentClasses='exception__block fence_block'
		/>
	);
};

export default Fence;
