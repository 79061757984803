import { ReactComponent as MetalCeramIcon } from 'images/sidebar/Table.svg';
import { ReactComponent as CerGranIcon } from 'images/sidebar/CerGran.svg';
import { ReactComponent as GlassIcon } from 'images/sidebar/Glass.svg';
import { ReactComponent as GranPlitIcon } from 'images/sidebar/GranPlit.svg';

export const tablesTabs = [
	{ name: 'Метал.Керам', type: 'metal', icon: MetalCeramIcon },
	{ name: 'Фарфор', type: 'ceramic', icon: CerGranIcon },
	{ name: 'Стекло', type: 'glass', icon: GlassIcon },
	{ name: 'гранит.плит', type: 'granit', icon: GranPlitIcon }
];

export const generateTables = () => {
	return {
		metal: {
			currentItem: 0,
			items: []
		},
		ceramic: {
			currentItem: 0,
			items: []
		},
		glass: {
			currentItem: 0,
			items: []
		},
		granit: {
			currentItem: 0,
			items: []
		}
	};
};
