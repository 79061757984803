import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import useArrayFromLenght from 'hooks/useArrayFromLenght';
import { useActions } from 'hooks/useActions';

const Sverlovka = ({ burial, currentStella }) => {
	const actions = useActions();
	const sverlovka = burial.stella.items[currentStella].sverlovka;
	const createArr = useArrayFromLenght(4);

	return (
		<BlockWithArrow title='Сверловка' blockClasses='sidebar__radio__block__value' contentClasses='sidebar__radio__block__value__items'>
			{createArr.map((item, index) => (
				<RadioInput
					key={index}
					title={index + 1}
					defaultChecked={sverlovka == index + 1}
					name='sverlovka'
					id={'sverlovka' + index + 1}
					onChange={e => actions.changeStellaSverlovka(index + 1)}
				/>
			))}
		</BlockWithArrow>
	);
};

export default Sverlovka;
