import { createSlice } from '@reduxjs/toolkit';

const additionalWorks = [
	{
		id: 0,
		status: false,
		text: 'Уборка дёрна',
		addTextLabel: 'Площадь уборки',
		addTextClassName: 'sidebar__radio__block--values__amount',
		amount: 0,
		price: 0
	},
	{
		id: 1,
		status: false,
		text: 'Покос травы',
		addTextLabel: 'Площадь покоса',
		addTextClassName: 'sidebar__radio__block--values__amount',
		amount: 0,
		price: 0
	},
	{
		id: 2,
		status: false,
		text: 'Выравнивание участка',
		addTextLabel: 'Площадь выравнивания',
		amount: 0,
		price: 0
	},
	{
		id: 3,
		status: false,
		text: 'Уклоны',
		addTextLabel: 'Количество уклонов',
		addTextClassName: 'sidebar__radio__block--values__amount',
		amount: 0,
		price: 0
	},
	{
		id: 4,
		status: false,
		text: 'Вкопать крест',
		addTextLabel: 'Количество крестов',
		addTextClassName: 'sidebar__radio__block--values__amount',
		amount: 0,
		price: 0
	},
	{
		id: 5,
		status: false,
		text: 'Доставка бетона',
		addTextLabel: 'Площадь облицовки',
		amount: 0,
		price: 0
	}
];

const initialState = {
	road: {
		type: 'Не требуется',
		length: 5,
		price: 0
	},
	takeaway: {
		type: 'Менее 20 м',
		length: 0,
		price: 0
	},
	additionalWork: {
		status: false,
		items: additionalWorks
	}
};

export const step6Slice = createSlice({
	name: 'step6',
	initialState,
	reducers: {
		changeAdditionalWorkStatus: (state, action) => {
			state.additionalWork = {
				status: action.payload,
				items: additionalWorks
			};
		},
		changeAdditionalWorkItem: (state, action) => {
			const { id, status, amount, price } = action.payload;
			state.additionalWork.items = state.additionalWork.items.map(el => {
				if (el.id === id) return (el = { ...el, status, amount, price });

				return el;
			});
		},
		changeTakeAway: (state, action) => {
			state.takeaway = action.payload;
		},
		changeRoad: (state, action) => {
			state.road = action.payload;
		}
	}
});

export const step6SliceActions = step6Slice.actions;

export default step6Slice.reducer;
