export const calculatePosition = ({ isNotAside, indexType, typeValue, positionItems, boxesLength, max, pedestalItems }) => {
	let result = null;
	if (isNotAside) {
		const mustBeChange = [indexType - 1, indexType + 1].filter(el => el !== 0 && el !== boxesLength);
		const mathDid = typeValue - positionItems[indexType];
		const value = Math.floor(Math.abs(mathDid) / mustBeChange.length);

		result = positionItems.map((el, i) => {
			if (i === indexType) return Math.floor(typeValue);
			if (mustBeChange.includes(i)) {
				const newValue = mathDid > 0 ? el - value : el + value;
				return Math.floor(newValue <= 0 ? 0 : newValue);
			}
			return Math.floor(el);
		});

		const check = result.reduce((acc, el) => {
			return (acc = acc + el);
		}, 0);

		if (check > max) {
			result = result.map((el, i) => {
				if (i === indexType) return Math.floor(typeValue - (check - max));
				return el;
			});
		}

		if (boxesLength === 2) {
			result = positionItems.map((el, i) => {
				if (i > boxesLength) return 0;
				if (i === indexType) return Math.floor(typeValue);
				return Math.floor((max - typeValue) / 2);
			});
		}
	} else {
		const value = (typeValue % 2 == 0 ? typeValue : typeValue - 1) / 2;
		const isNotAsideItems = positionItems.filter((el, i) => i !== 0 && i !== boxesLength && i < boxesLength);

		const isNotAsideItemsSorted = isNotAsideItems
			.map((el, i) => ({
				index: i + 1,
				value: el
			}))
			.sort((a, b) => a.value - b.value)
			.map(el => {
				const value = (max - typeValue) / isNotAsideItems.length;
				return {
					...el,
					value
				};
			});

		result = positionItems.map((el, i) => {
			const isAside = i === 0 || i === boxesLength;
			if (i > boxesLength) return 0;
			if (isAside) return Math.floor(value);
			const obj = isNotAsideItemsSorted.find(el => el.index === i);
			return Math.floor(obj.value);
		});
	}

	if (pedestalItems.length === 0) {
		result = result.map((el, i) => {
			if (i === indexType) return Math.min(typeValue, max);
			if (i !== 0 && i !== boxesLength && i < boxesLength) {
				return el;
			}
			return 0;
		});
	}

	return result;
};
