import { findBurial, changeSelectProductStatus } from './../../step1Slice';

import { createFlower } from 'utils/helpers/step3_generateFlower';
import { dimensions } from 'config/defaultDimensions.config';

const step3Flower = () => {
	const addFlower = (state, action) => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;
		if (!burial.flowers.items.length) {
			burial.tombstone.items = [];
		}
		state.generalIndexFlowers = state.generalIndexFlowers + 1;

		const poperehnaSizes =
			state.defaultComplect[burial.material.value]?.components.find(el => el.terms.includes('poperehna')).size.values ||
			dimensions.flower.poperehnaSizes;
		const prodolnaSizes =
			state.defaultComplect[burial.material.value]?.components.find(el => el.terms.includes('prodolna')).size.values ||
			dimensions.flower.prodolnaSizes;

		burial.flowers.items = [
			...burial.flowers.items.map((el, i, arr) => {
				if (arr.length >= 1) el.selected = 'separate';
				return el;
			}),
			createFlower({
				index: state.generalIndexFlowers,
				selected: burial.flowers.items.length === 0 ? 'standard' : 'separate',
				title,
				value,
				stone,
				flowerId: action.payload,
				poperehnaSizes: poperehnaSizes
					? {
							width: poperehnaSizes.thickness,
							length: poperehnaSizes.length,
							height: poperehnaSizes.height
					  }
					: null,
				prodolnaSizes: prodolnaSizes
					? {
							width: prodolnaSizes.length,
							length: prodolnaSizes.thickness,
							height: prodolnaSizes.height
					  }
					: null
			})
		];
		burial.flowers.currentFlower = burial.flowers.items.length - 1;
	};

	const removeFlower = (state, action) => {
		const burial = findBurial(state);
		const newFlowers = burial.flowers.items.filter((el, index) => burial.flowers.currentFlower != index);
		if (!newFlowers.length) {
			burial.tombstone.items = [];
		}
		burial.flowers.items = newFlowers;
		if (burial.flowers.items.length !== 0) burial.flowers.currentFlower = burial.flowers.items.length - 1;
	};

	const changeFlowerSizesStatus = (state, action) => {
		const burial = findBurial(state);
		if (action.payload.type == 'prodolna') {
			burial.flowers.items[burial.flowers.currentFlower].prodolna.status = action.payload.status;
		} else {
			burial.flowers.items[burial.flowers.currentFlower].poperehna.status = action.payload.status;
		}
	};

	const changeFlowerIndividualSizes = (state, action) => {
		const burial = findBurial(state);
		const { status, sizes, type } = action.payload;

		burial.flowers.items[burial.flowers.currentFlower][type].status = status;
		burial.flowers.items[burial.flowers.currentFlower][type].sizes = sizes;
	};

	const changeFlowerType = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].selected = action.payload.name;
	};

	const changeSelectedFlowerVariant = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].selected = action.payload;
	};

	const changeFlowerSelectedProductsPoperehna = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].poperehna.selectedProducts = changeSelectProductStatus(
			burial.flowers.items[burial.flowers.currentFlower].poperehna.selectedProducts,
			action.payload,
			true
		);
	};

	const changeFlowerSelectedProductsProdolna = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].prodolna.selectedProducts = changeSelectProductStatus(
			burial.flowers.items[burial.flowers.currentFlower].prodolna.selectedProducts,
			action.payload,
			true
		);
	};

	const changeFlowerPolishing = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].polishing = action.payload;
	};

	const changeFlowerOwnState = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].own.isActive = action.payload;
	};

	const changeFlowerOwnPrice = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].own.price = action.payload;
	};

	const changeFlowerOwnImg = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].own.img = action.payload;
	};

	const changeFlowerMaterial = (state, action) => {
		const burial = findBurial(state);
		let burialFlower = burial.flowers.items[burial.flowers.currentFlower];
		burialFlower.material = action.payload;
		burialFlower.material.value == 'granite' ? (burialFlower.stone = 'Габбро Диабаз') : (burialFlower.stone = 'Мрамор');
	};

	const changeFlowerStone = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.items[burial.flowers.currentFlower].stone = action.payload;
	};

	const changecurrentFlower = (state, action) => {
		const burial = findBurial(state);
		burial.flowers.currentFlower = action.payload;
	};

	return {
		addFlower,
		removeFlower,
		changeFlowerSizesStatus,
		changeFlowerIndividualSizes,
		changeFlowerType,
		changeSelectedFlowerVariant,
		changeFlowerSelectedProductsPoperehna,
		changeFlowerSelectedProductsProdolna,
		changeFlowerPolishing,
		changeFlowerOwnState,
		changeFlowerOwnPrice,
		changeFlowerOwnImg,
		changeFlowerMaterial,
		changeFlowerStone,
		changecurrentFlower
	};
};

export default step3Flower;
