import React, { useCallback, useState } from 'react';
import TextInput from './../../input/text';
import { useShallowEffect, useDebouncedValue } from '@mantine/hooks';

const AdvancedPopUpSeach = ({ onSearchHandler = () => {} }) => {
	const [inputValue, setInputValue] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(inputValue, 300);

	const onChange = useCallback(value => setInputValue(value), []);

	useShallowEffect(() => onSearchHandler(debouncedSearchTerm), [debouncedSearchTerm]);

	return (
		<div className='configurator__light-box__header__search'>
			<TextInput defaultValue={inputValue} name='search-form' id='search-form' type='text' onChange={onChange} />
			<a id='search-form--submit' className='--svg__search' onClick={onSearchHandler.bind(this, inputValue)}></a>
		</div>
	);
};

export default AdvancedPopUpSeach;
