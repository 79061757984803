import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { cartTranslation } from 'config/cart.config';
import { tablesInstalationTypes } from 'config/tablesInstalationTypes';
import { getStringLengthWithoutSpaces } from 'utils/getStringLengthWithoutSpaces';
import { flatten } from 'utils/flatten';

export const useCart = burials => {
	const categories = useSelector(state => state.configurator.present.configuratorSteps.categories.materials || []);
	const instalation = useSelector(state => state.configurator.present.step1.installation);

	// step 4
	const dismantling = useSelector(state => state.configurator.present.step4.dismantling);
	const dismantlingWithPreservation = useSelector(state => state.configurator.present.step4.dismantlingWithPreservation);

	// step 5
	const backfillArea = useSelector(state => state.configurator.present.step5.decoratedElements.backfillArea);
	const backfillFlower = useSelector(state => state.configurator.present.step5.decoratedElements.backfillFlower);
	const decoratedElements = useSelector(state => {
		const data = state.configurator.present.step5.decoratedElements;
		return [data.stone, data.metal, data.forged, data.sculptures].filter(el => el.status && el.items.length);
	});

	// tiles
	const tilesData = useSelector(state => {
		const data = state.configurator.present.step5;
		const horizontalTilesCount = data.pavingEditor.rectCountByMaterial;
		const verticalTilesCount = data.pavingEditor.rectCountByMaterialVert;

		const tiles = [];

		function calcArea({ length, width, price, countTiles }) {
			length = parseInt(length) / 1000; // milimetres to metres
			width = parseInt(width) / 1000; // milimetres to metres
			countTiles = parseInt(countTiles);
			price = parseFloat(price);

			return length * width * countTiles * price;
		}

		function combineTiles({ tilesCountByMaterial, type, typeInTitle }) {
			return Object.entries(tilesCountByMaterial).map(([id, countTiles]) => {
				const product = (data.tile[type]?.[0]?.tile || []).find(product => product.materials == id);
				let price = 0;

				if (product) {
					const { length, width } = product.size.values;
					const priceByMetre = product.price.square_metre;
					price = calcArea({ length, width, price: priceByMetre, countTiles });
				}

				return {
					title: `Плитка ${typeInTitle} | ` + (product?.title || ''),
					materialId: id,
					count: countTiles,
					price
				};
			});
		}

		// horizontal
		if (horizontalTilesCount) {
			const horizontalTiles = combineTiles({
				tilesCountByMaterial: horizontalTilesCount,
				type: 'horizontal',
				typeInTitle: 'горизонтальная'
			});

			if (horizontalTiles.length) tiles.push(horizontalTiles);
		}

		// vertical
		if (verticalTilesCount) {
			const verticalTiles = combineTiles({
				tilesCountByMaterial: verticalTilesCount,
				type: 'vertical',
				typeInTitle: 'вертикальная'
			});

			if (verticalTiles.length) tiles.push(verticalTiles);
		}

		return flatten(tiles);
	});

	// fence
	const fenceData = useSelector(state => {
		const data = state.configurator.present.step5;
		const fenceCount = data.pavingEditor.fenceCount;
		const selectedFence = data.fence.selectedProducts?.[0];

		if (selectedFence && fenceCount) {
			return {
				length: fenceCount / 10,
				price: parseInt(parseFloat(selectedFence.price.running_metre) * (fenceCount / 10))
			};
		}

		return { length: 0, price: 0 };
	});

	// step 6
	const road = useSelector(state => state.configurator.present.step6.road);
	const takeaway = useSelector(state => state.configurator.present.step6.takeaway);
	const additionalWork = useSelector(state => state.configurator.present.step6.additionalWork);

	const toNumber = price => {
		if (typeof price === 'number') {
			return price;
		} else if (typeof price === 'string') {
			return parseFloat(price.replace(' ', '')) || 0;
		} else if (typeof price === 'object' && price.unit) {
			return toNumber(price.unit);
		}
		return 0;
	};
	const getAdditionPrice = (item, title) => {
		let additionalPrice = 0;
		let priceItems = [];
		if (item.form?.clienForm?.status) {
			const price = toNumber(item.form.clienForm.price);
			additionalPrice += price;
			priceItems.push({
				price: { unit: price },
				title: title + ' | Форма клиента',
				components: []
			});
		}
		if (item.form?.decorationFaska?.status) {
			item.form.decorationFaska.selectedProducts.forEach(prod => {
				const price = toNumber(prod.price);
				additionalPrice += price;
				const sku = prod?.sku ? ` | SKU: ${prod?.sku}` : '';
				priceItems.push({
					price: { unit: price },
					title: title + ' | ' + (prod?.title ?? 'Декоративная фаска') + sku,
					components: []
				});
			});
		}
		if (item.form?.form?.status) {
			item.form.form.selectedProducts.forEach(prod => {
				const price = toNumber(prod.price);
				additionalPrice += price;
				const sku = prod?.sku ? ` | SKU: ${prod?.sku}` : '';
				priceItems.push({
					price: { unit: price },
					title: title + ' | ' + (prod?.title ?? ' Форма') + sku,
					components: []
				});
			});
		}
		if (item.form?.frames?.status) {
			item.form.frames.selectedProducts.forEach(prod => {
				const price = toNumber(prod.price);
				additionalPrice += price;
				const sku = prod?.sku ? ` | SKU: ${prod?.sku}` : '';
				priceItems.push({
					price: { unit: price },
					title: title + ' | ' + (prod?.title ?? ' Рамка') + sku,
					components: []
				});
			});
		}
		if (item.form?.decoration?.status) {
			item.form.decoration.selectedProducts.forEach(prod => {
				const price = toNumber(prod.price) * (item.form.decoration?.side?.length || 1);
				additionalPrice += price;
				const sku = prod?.sku ? ` | SKU: ${prod?.sku}` : '';
				priceItems.push({
					price: { unit: price },
					title: title + ' | ' + (prod?.title ?? ' Декорация') + sku,
					components: []
				});
			});
		}
		if (item.form?.ownFaska?.status) {
			console.log(item.form.ownFaska);
			const price = toNumber(item.form.ownFaska.price);
			additionalPrice += price;
			priceItems.push({
				price: { unit: price },
				title: title + ' | Фаска клиента',
				components: []
			});
		}
		return priceItems;
	};
	const getCustomPrise = (item, type, material, setDimensions) => {
		//TODO
		let dimensions;
		let title = '';

		if (type === 'Куб') {
			dimensions = {
				length: item.size.depth,
				width: item.size.width,
				height: item.size.height
			};
		} else if (type.startsWith('Поперечная деталь цветника')) {
			dimensions = item.poperehna.sizes;
		} else if (type.startsWith('Продольная деталь цветника')) {
			dimensions = item.prodolna.sizes;
		} else {
			if (item.individualSizes) {
				dimensions = item.individualSizes.values;
			} else {
				console.log(item);
				console.error('individualSizes is missing');
			}
		}

		if (setDimensions && setDimensions.length && setDimensions.width && setDimensions.height) {
			dimensions = setDimensions;
		}

		const cube = (dimensions.length * dimensions.width * dimensions.height) / (1000 * 1000 * 1000);

		const drilling = {
			sku: material?.drilldown?.sku,
			title: material?.drilldown?.title,
			count: toNumber(item.sverlovka) || 0,
			price: (toNumber(material?.drilldown?.price[0]?.value) || 0) * (toNumber(item.sverlovka) || 0)
		};

		return {
			price: { unit: cube * parseFloat(material?.price || 0) || 0 },
			title: type + ' ' + (item.stone ?? title),
			size: {
				values: dimensions,
				raw: dimensions.length + 'x' + dimensions.width + 'x' + dimensions.height
			},
			drilling,
			components: []
		};
	};

	const selectedData = useMemo(() => {
		const burialsData = burials
			.filter(bur => !['occupied', 'empty'].includes(bur.selected))
			.map(bur => {
				const material = categories.filter(category => category?.title === bur?.materialStone)?.[0];
				const checkLocalMaterial = item => {
					if (!item.stone) return null;
					const localMaterial = categories.filter(category => category.title === item.stone)[0];
					if (localMaterial) return localMaterial;
					else return material;
				};
				const burCatalogProducts = bur.catalog.selectedProducts;
				// step 3 necessary if catalog products is select
				const burPedestalItems = bur.pedestal.items.map(item => item.selectedProducts);

				const burPedestalCustomItems = bur.pedestal.items
					.filter(item => item.individualSizes.status === true)
					.map(item => getCustomPrise(item, 'Тумба', checkLocalMaterial(item)));

				const burStellaItems = bur.stella.items.map(item => item.selectedProducts);
				const burStellaCustomItems = bur.stella.items
					.filter(item => item.individualSizes.status === true)
					.map(item => getCustomPrise(item, 'Стела', checkLocalMaterial(item)));

				const burialFlower = bur.flowers.items.map((flower, index) => {
					const { poperehna, prodolna } = flower;
					const pedestalWidth = bur.pedestal.items?.[0]?.individualSizes.values.width ?? 0;

					const poperehnaData =
						poperehna.status === true
							? getCustomPrise(flower, `Поперечная деталь цветника`, checkLocalMaterial(flower))
							: poperehna.selectedProducts?.[0] ?? [];
					const prodolnaData =
						prodolna.status === true
							? getCustomPrise(flower, `Продольная деталь цветника`, checkLocalMaterial(flower))
							: prodolna.selectedProducts?.[0] ?? [];

					switch (flower.selected) {
						case 'general': {
							const diff = parseFloat(poperehna.sizes.length) - parseFloat(pedestalWidth);
							const poperehnaTop = getCustomPrise(flower, `Поперечная деталь цветника №${index + 1}`, checkLocalMaterial(flower), {
								length: parseFloat(diff > 0 ? diff : 0),
								width: parseFloat(poperehna.sizes.width),
								height: parseFloat(poperehna.sizes.height)
							});

							if (diff <= 0) poperehnaTop.price = 0;

							return [poperehnaTop, poperehnaData, prodolnaData, prodolnaData].flat();
						}
						case 'separate':
							return [poperehnaData, poperehnaData, prodolnaData, prodolnaData].flat();
						default:
							return [poperehnaData, prodolnaData, prodolnaData].flat();
					}
				});

				const burBoxCustomItems = bur.boxes.items.map(item => getCustomPrise(item, 'Куб', checkLocalMaterial(item)));

				const burTombstoneItems = bur.tombstone.items.map(item => item.selectedProducts);
				const burTombstoneEpitafia = bur.tombstone.items
					.filter(el => el.engraving.epitafia.status && el.engraving.epitafia.items !== null)
					.map(item => ({
						price: { unit: toNumber(item.engraving.epitafia.price) * getStringLengthWithoutSpaces(item.engraving.epitafia.items?.text) },
						title: 'Надгробная плита | Гравировка | Эпитафия'
					}));

				const burTombstoneElements = bur.tombstone.items
					.filter(el => el.elements.epitafia.status)
					.map(item => ({
						price: { unit: toNumber(item.elements.epitafia.price) * getStringLengthWithoutSpaces(item.elements.epitafia.text) },
						title: 'Надгробная плита | Элементы | Эпитафия'
					}));

				const burTombstoneEngravingElements = bur.tombstone.items
					.filter(el => el.engraving.engraving.status)
					.map(el => el.engraving.engraving.items.map(el => ({ ...el, title: (el?.title ?? '') + ' | ' + (el?.sku ?? '') })));

				const burTombstoneElemsElements = bur.tombstone.items
					.filter(el => el.elements.elems.status)
					.map(el => el.elements.elems.selectedProducts.map(el => ({ ...el, title: (el?.title ?? '') + ' | ' + (el?.sku ?? '') })));

				const burTombstoneCustomItems = bur.tombstone.items
					.filter(item => item.individualSizes.status === true)
					.map(item => getCustomPrise(item, 'Надгробная плита', checkLocalMaterial(item)));

				const metalCeramTable = bur.tables.metal.items
					.filter(el => el.type !== 'own')
					.map(item => {
						if (item.size === null) return null;
						const components = [
							{
								price: { unit: toNumber(item.montaz.price) },
								title: `Установка | ${item.montaz.type}`
							}
						];

						if (item.montaz.type !== tablesInstalationTypes.find(el => el.name === 'niche').title) {
							components.push({
								price: { unit: toNumber(item.fastening.price) },
								title: `Крепления | ${item.fastening.type === 'remote' ? 'Дистанционный' : 'Эконом'}`,
								count: item.fastening.amount
							});
						}

						if (item.background.status) {
							item.background.items.forEach(bg => {
								components.push({
									price: { unit: toNumber(bg.price?.unit ?? 0) },
									title: (bg?.title ?? 'Фон') + (bg?.sku ? ` | ${bg.sku}` : '')
								});
							});
						}

						if (item.restoration.status) {
							components.push({
								price: { unit: toNumber(item.restoration.price) },
								title: 'Реставрация фото',
								size: {
									values: item.size.values,
									raw: item.size.value
								}
							});
						}

						if (item.changeClothes) {
							components.push({
								price: { unit: toNumber(0) },
								title: 'Переодеть'
							});
						}

						const price = components.reduce((acc, cur) => acc + cur.price.unit, 0) + item.price;

						components.push({
							price: { unit: toNumber(item.price) },
							title: 'Табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							}
						});

						return {
							price: { unit: toNumber(price) },
							title: cartTranslation.metal + ' табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							},
							components
						};
					});

				const ceramicTable = bur.tables.ceramic.items
					.filter(el => el.type !== 'own')
					.map(item => {
						if (item.size === null) return null;

						const components = [
							{
								price: { unit: toNumber(item.montaz.price) },
								title: `Установка | ${item.montaz.type}`
							}
						];

						if (item.montaz.type !== tablesInstalationTypes.find(el => el.name === 'niche').title) {
							components.push({
								price: { unit: toNumber(item.fastening.price) },
								title: `Крепления | ${item.fastening.type === 'remote' ? 'Дистанционный' : 'Эконом'}`,
								count: item.fastening.amount
							});
						}

						if (item.restoration.status) {
							components.push({
								price: { unit: toNumber(item.restoration.price) },
								title: 'Реставрация фото',
								size: {
									values: item.size.values,
									raw: item.size.value
								}
							});
						}

						if (item.background.status) {
							item.background.items.forEach(bg => {
								components.push({
									price: { unit: toNumber(bg.price?.unit ?? 0) },
									title: (bg?.title ?? 'Фон') + (bg?.sku ? ` | ${bg.sku}` : '')
								});
							});
						}

						const price = components.reduce((acc, cur) => acc + cur.price.unit, 0) + item.price;

						components.push({
							price: { unit: toNumber(item.price) },
							title: 'Табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							}
						});

						return {
							price: { unit: toNumber(price) },
							title: cartTranslation.ceramic + ' табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							},
							components
						};
					});

				const glassTable = bur.tables.glass.items
					.filter(el => el.type !== 'own')
					.map(item => {
						if (item.size === null) return null;
						const components = [
							{
								price: { unit: toNumber(item.montaz.price) },
								title: `Установка | ${item.montaz.type}`
							}
						];

						if (item.montaz.type !== tablesInstalationTypes.find(el => el.name === 'niche').title) {
							components.push({
								price: { unit: toNumber(item.fastening.price) },
								title: `Крепления | ${item.fastening.type === 'remote' ? 'Дистанционный' : 'Эконом'}`,
								count: item.fastening.amount
							});
						}

						if (item.changeClothes) {
							components.push({
								price: { unit: toNumber(0) },
								title: 'Переодеть'
							});
						}

						if (item.restoration.status) {
							components.push({
								price: { unit: toNumber(item.restoration.price) },
								title: 'Реставрация фото',
								size: {
									values: item.size.values,
									raw: item.size.value
								}
							});
						}

						if (item.background.status) {
							item.background.items.forEach(bg => {
								components.push({
									price: { unit: toNumber(bg.price?.unit ?? 0) },
									title: (bg?.title ?? 'Фон') + (bg?.sku ? ` | ${bg.sku}` : '')
								});
							});
						}

						const price = components.reduce((acc, cur) => acc + cur.price.unit, 0) + item.price;

						components.push({
							price: { unit: toNumber(item.price) },
							title: 'Табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							}
						});

						return {
							price: { unit: toNumber(price) },
							title: cartTranslation.glass + ' табличка',
							size: {
								values: item.size.values,
								raw: item.size.value
							},
							components
						};
					});

				const granitTable = bur.tables.granit.items
					.filter(el => el.type !== 'own')
					.map(item => {
						if (item.size === null) return null;
						const components = [
							{
								price: { unit: toNumber(item.montaz.price) },
								title: `Установка | ${item.montaz.type}`
							}
						];

						if (item.montaz.type !== tablesInstalationTypes.find(el => el.name === 'niche').title) {
							components.push({
								price: { unit: toNumber(item.fastening.price) },
								title: `Крепления | ${item.fastening.type === 'remote' ? 'Дистанционный' : 'Эконом'}`,
								count: item.fastening.amount
							});
						}

						if (item.ownForm.status) {
							components.push({
								price: { unit: toNumber(item.ownForm.price) },
								title: 'Форма клиента',
								size: {
									values: item.size.values,
									raw: item.size.value
								}
							});
						}

						if (item.restoration.status) {
							components.push({
								price: { unit: toNumber(item.restoration.price) },
								title: 'Реставрация фото',
								size: {
									values: item.size.values,
									raw: item.size.value
								}
							});
						}

						if (item.portrait.status) {
							item.portrait.items.forEach((portrait, i) => {
								components.push({
									price: { unit: toNumber(0) },
									title: 'Портрет ' + portrait.type + ' №' + (i + 1)
								});
							});
						}

						const price = components.reduce((acc, cur) => acc + cur.price.unit, 0) + item.price;

						components.push({
							price: { unit: toNumber(item.price) },
							title: 'Плита',
							size: {
								values: item.size.values,
								raw: item.size.value
							}
						});

						return {
							price: { unit: toNumber(price) },
							title: cartTranslation.granit,
							size: {
								values: item.size.values,
								raw: item.size.value
							},
							components
						};
					});

				const engravingOwnForm = [];
				if (bur.engraving?.ownForm?.status) {
					engravingOwnForm.push({
						price: { unit: toNumber(bur.engraving?.ownForm.price) },
						title: 'Гравировка свое изображение',
						components: []
					});
				}

				// step 3 no obligatory
				const { engraving, creed, paintAPortrait, fio, portrait: engravingPortrait } = bur.engraving;
				const { items: engravingItems } = engraving;
				const { status: paintPortraitStatus, price: paintPortraitPrice } = paintAPortrait;
				const { items: creedItems } = creed;
				const elements = bur.elements.elems.selectedProducts.map(el => ({ ...el, title: (el?.title ?? '') + ' | ' + (el?.sku ?? '') }));
				const tileArea = bur.improvement.tileArea.price;

				const engravingFio = [];
				if (fio.status && fio.items !== null) {
					const price = [fio.items.datesItems.items, fio.items.fio.items].flat().reduce((curr, item) => (curr += item?.price || 0), 0);
					engravingFio.push({
						price: { unit: toNumber(price) },
						title: 'Гравировка | ФИО'
					});
				}

				const engravingPortraits = [];
				if (engravingPortrait.status && engravingPortrait.items.length) {
					engravingPortrait.items.forEach(portrait => {
						engravingPortraits.push({
							price: { unit: toNumber(portrait.price) },
							title: 'Гравировка | Портрет'
						});
					});
				}

				const engravingEpitafia = [];
				if (bur.engraving.epitafia.status) {
					engravingEpitafia.push({
						price: { unit: toNumber(bur.engraving.epitafia.price) * getStringLengthWithoutSpaces(bur.engraving.epitafia.items?.text) },
						title: 'Гравировка | Эпитафия'
					});
				}

				const engravingPainting = [];
				if (paintPortraitStatus) {
					engravingPainting.push({
						price: { unit: toNumber(paintPortraitPrice) },
						title: 'Покраска портрета памятника'
					});
				}

				// elements
				const elementsData = [];

				if (bur.elements.fio.status && bur.elements.fio.items.length) {
					const price = bur.elements.fio.items.reduce((acc, el) => (acc += el?.price || 0), 0);
					elementsData.push({
						price: { unit: toNumber(price) },
						title: 'Элементы | ФИО'
					});
				}

				if (bur.elements.epitafia.status && bur.elements.epitafia.text !== '') {
					elementsData.push({
						price: { unit: toNumber(bur.elements.epitafia.price || 0) },
						title: 'Элементы | Эпитафия'
					});
				}

				let arr = [];

				if (bur.catalog.type !== 'go-step') {
					arr = [...burCatalogProducts];
				} else {
					arr = [
						...burPedestalItems,
						...burStellaItems,
						...burialFlower,
						...burTombstoneItems,
						...burPedestalCustomItems,
						...burStellaCustomItems,
						...burTombstoneCustomItems,
						...burTombstoneEpitafia,
						...burTombstoneElements,
						...burTombstoneEngravingElements,
						...burTombstoneElemsElements,
						// ...burBoxItems
						...burBoxCustomItems
					].flat();
				}

				const burAdditionItems = bur.pedestal.items.map(item => getAdditionPrice(item, 'Тумба', checkLocalMaterial(item)));

				const burAdditionStellaItems = bur.stella.items.map(item => getAdditionPrice(item, 'Стела', checkLocalMaterial(item)));

				const burFlowersAdditionItems = bur.flowers.items.map(item => getAdditionPrice(item, 'Цветник', checkLocalMaterial(item)));

				const burBoxAdditionItems = bur.boxes.items.map(item => getAdditionPrice(item, 'Куб', checkLocalMaterial(item)));

				const burTombstoneAdditionItems = bur.tombstone.items.map(item =>
					getAdditionPrice(item, 'Надгробная плита', checkLocalMaterial(item))
				);

				const ownTablesForm = Object.values(bur.tables)
					.map((table, i) => {
						if (!table.items.length) return null;
						const tableData = table.items
							.filter(item => item.type === 'own')
							.map((el, l) => ({
								...el,
								price: { unit: parseFloat(el.price) },
								title: 'Табличка ' + cartTranslation[Object.keys(bur.tables)[i]].toLowerCase()
							}));

						return tableData;
					})
					.flat()
					.filter(el => el);

				if (typeof tileArea === 'number' && instalation === 'Установка памятника') {
					arr.push({
						price: { unit: toNumber(tileArea) },
						title: 'Установка памятника'
					});
				}

				arr = [
					...arr,
					...ceramicTable,
					...metalCeramTable,
					...glassTable,
					...granitTable,
					...elements,
					...engravingItems.map(el => ({ ...el, title: (el?.title ?? '') + ' | ' + (el?.sku ?? '') })),
					...creedItems.map(el => ({ ...el, title: (el?.title ?? '') + ' | ' + (el?.sku ?? '') })),
					...engravingOwnForm,
					...engravingPainting,
					...burAdditionItems,
					...burAdditionStellaItems,
					...burFlowersAdditionItems,
					...burBoxAdditionItems,
					...burTombstoneAdditionItems,
					...ownTablesForm,
					...engravingEpitafia,
					...engravingFio,
					...engravingPortraits,
					...elementsData
				];

				return arr.flat().filter(el => el);
			});

		const step4 = [];

		if (dismantling.status) {
			dismantling.items.forEach(item => {
				if (item.status) {
					step4.push({
						price: { unit: toNumber(item.price) * toNumber(item.value) },
						title: `Демонтаж: ${item.text} | ${item.value}`
					});
				}
			});
		}

		if (dismantlingWithPreservation.status) {
			dismantlingWithPreservation.items.forEach(item => {
				step4.push({
					price: { unit: toNumber(item.price) },
					title: `Демонтаж с сохранением: ${item.size}`
				});
			});
		}

		const step5 = [];

		if (backfillArea.status) {
			step5.push({
				price: { unit: toNumber(backfillArea.price) },
				title: 'Засыпка территории'
			});
		}

		if (backfillFlower.status) {
			step5.push({
				price: { unit: toNumber(backfillFlower.price) },
				title: 'Засыпка цветника'
			});
		}

		decoratedElements.map(el => {
			step5.push({
				price: { unit: toNumber(el.items[0]?.price?.unit ?? 0) },
				title: el.items[0]?.title ?? ''
			});
		});

		// tiles
		if (tilesData.length !== 0) {
			tilesData.forEach(({ price, title, count }) => {
				step5.push({
					price: { unit: toNumber(price) },
					title: title,
					count: count + ' шт'
				});
			});
		}

		// fence
		if (fenceData.length !== 0) {
			step5.push({
				price: { unit: Math.max(0, toNumber(fenceData.price) / 10) },
				title: 'Оградка | ' + Math.max(0, fenceData.length / 10) + 'м'
			});
		}

		const step6 = [];

		[
			{ price: road.price, title: `Дорога для кладбища | ${road.length} км` },
			{ price: takeaway.price, title: `Донос до места | ${takeaway.length} м` }
		].forEach(el => {
			if (el.price !== 0) {
				step6.push({
					price: { unit: toNumber(el.price ?? 0) },
					title: el.title
				});
			}
		});

		if (additionalWork.status) {
			additionalWork.items.forEach(item => {
				if (item.status && item.amount !== 0) {
					step6.push({
						price: { unit: toNumber(item.price ?? 0) },
						title: `${item.text} | ${item.amount}`
					});
				}
			});
		}

		return {
			burialsData,
			step4,
			step5,
			step6
		};
	}, [burials, backfillArea, backfillFlower, decoratedElements, dismantling, dismantlingWithPreservation]);

	const totalCost = useMemo(
		(sum = 0) => {
			const doAction = item => {
				sum += parseFloat(item?.price?.unit ?? 0);
				if (item.drilling && item.drilling?.count) sum += parseFloat(item.drilling?.price ?? 0);
			};

			Object.values(selectedData)
				.flat()
				.forEach(el => {
					if (Array.isArray(el)) {
						el.forEach(item => doAction(item));
					} else {
						doAction(el);
					}
				});
			return sum;
		},
		[selectedData]
	);

	return {
		selectedData,
		totalCost
	};
};
