import { v4 as uuid } from 'uuid';

export const generateOwnTable = (type = null) => {
	const unique_id = uuid();
	return {
		id: unique_id,
		type: type,
		price: 0,
		img: []
	};
};
