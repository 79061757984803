import { generateDropZone } from '../forms/generateDropZone';
import { generateCatalog } from '../forms/generateCatalog';
import { dimensions } from 'config/defaultDimensions.config';

export const generatePedestal = ({
	title = 'Гранит',
	value = 'granite',
	stone = 'Габбро Диабаз',
	sverlovka = 1,
	width,
	length,
	height,
	sizesStatus = true
}) => {
	const { width: defaultWidth, length: defaultLength, height: defaultHeight } = dimensions.pedestal;
	return {
		title: title,
		value: value,
		stone: stone,
		selectedProducts: [],
		sverlovka: sverlovka,
		individualSizes: {
			status: sizesStatus,
			values: {
				width: width ?? defaultWidth,
				length: length ?? defaultLength,
				height: height ?? defaultHeight
			}
		},
		form: {
			clienForm: generateDropZone(),
			decorationFaska: generateCatalog(),
			ownFaska: generateDropZone()
		},
		position: { x: 0, y: 0 }
	};
};
