import React from 'react';

import ProductlistWithParams from 'components/UI/list/productsListWithParams';

import { productsAPI } from 'services/Products';
import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { useNotification } from 'hooks/useNotification';

const TombstoneProducts = ({ currentBurial, currentTomb, allTombstone }) => {
	const actions = useActions();
	const tombstoneItems = allTombstone[currentTomb];
	const selectedProducts = tombstoneItems.selectedProducts;
	const individualSizes = tombstoneItems.individualSizes;
	const categories = useConfiguratorCategories('modules');
	const cat_tax = categories.find(el => el.slug === 'tombstone')?.id;

	const { Notification, notificationToggle } = useNotification({
		callback: actions.onChangeHistoryTombstoneDimenstions,
		type: 'tombstone',
		currentElement: tombstoneItems,
		currentIndexElement: currentTomb
	});

	return (
		<>
			<Notification />
			<ProductlistWithParams
				fetchFunction={productsAPI.useFetchProductsQuery}
				selectedProducts={selectedProducts}
				moduleType='tombstone'
				currentBurial={currentBurial}
				title='Размер плиты:'
				blockClasses='sidebar__radio__block sidebar__radio__block__value--individual'
				contentClasses='configurator__light-box__content--list'
				onClickProduct={values => {
					const { width, length, thickness: height } = values.size.values;
					notificationToggle({
						values: [
							{
								type: 'tombstone',
								current: currentTomb,
								individual: {
									status: individualSizes.status,
									values: { width, length, height }
								}
							},
							{ type: 'tombstone', current: currentTomb, values: values, noChange: true }
						]
					});
				}}
				widthDefault={parseInt(individualSizes.values.width)}
				lengthDefault={parseInt(individualSizes.values.length)}
				heightDefault={parseInt(individualSizes.values.height)}
				toggleState={individualSizes.status}
				onChangeLength={value => {
					notificationToggle({
						values: [
							{
								type: 'tombstone',
								current: currentTomb,
								individual: {
									status: individualSizes.status,
									values: { width: individualSizes.values.width, length: parseInt(value), height: individualSizes.values.height }
								}
							}
						]
					});
				}}
				onChangeWidth={value => {
					notificationToggle({
						values: [
							{
								type: 'tombstone',
								current: currentTomb,
								individual: {
									status: individualSizes.status,
									values: { width: parseInt(value), length: individualSizes.values.length, height: individualSizes.values.height }
								}
							}
						]
					});
				}}
				onChangeHeight={value => {
					notificationToggle({
						values: [
							{
								type: 'tombstone',
								current: currentTomb,
								individual: {
									status: individualSizes.status,
									values: { width: individualSizes.values.width, length: individualSizes.values.length, height: parseInt(value) }
								}
							}
						]
					});
				}}
				onChangeToggle={e => {
					actions.onChangeHistoryTombstoneDimenstions(
						{
							type: 'tombstone',
							current: currentTomb,
							individual: {
								status: !individualSizes.status,
								values: {
									width: individualSizes.values.width,
									length: individualSizes.values.length,
									height: individualSizes.values.height
								}
							}
						},
						individualSizes.status !== true
							? {
									type: 'tombstone',
									current: currentTomb,
									values: selectedProducts[0],
									noChange: true
							  }
							: null
					);
				}}
				taxId={cat_tax}
				firstLabelApi='size_length'
				secondLabelApi='size_width'
				thirdLabelApi='size_thickness'
			/>
		</>
	);
};

export default TombstoneProducts;
