import React, { memo, useLayoutEffect, useCallback } from 'react';

import CreateCanvas from 'components/UI/canvas/createCanvas';

import useDataCanvas from 'hooks/useDataCanvas';
import { useActions } from 'hooks/useActions';
import { useSelector } from 'react-redux';

const Canvas = ({ openStatus, closeCanvasContainer, configurator, burial, item }) => {
	const actions = useActions();
	const canvasData = useSelector(state => state.canvas.data[burial.id]?.[item.id] || []);
	const hq = useSelector(state => state.configurator.present.step3.canvasHQ);
	const data = useDataCanvas(burial, canvasData, item.canvasType, item);
	const setCanvasData = useCallback(data => {
		actions.changeDataCanvas({
			data: data.map(el => {
				delete el.item;
				delete el.size;
				delete el.image;
				return el;
			}),
			burialId: burial.id,
			id: item.id
		});
	}, []);

	useLayoutEffect(() => {
		JSON.parse(JSON.stringify(data));
		setCanvasData(data);
	}, [data]);

	useLayoutEffect(() => {
		window.Konva.pixelRatio = hq ? 2 * devicePixelRatio : devicePixelRatio;
	}, [hq]);

	return (
		data && (
			<div className={`canvas__container canvas__type--${item.canvasType} ${openStatus ? 'active' : ''}`}>
				<a className='configurator__light-box__header__close --svg__close' onClick={closeCanvasContainer}></a>
				<a
					className='configurator__light-box__header__close --hq'
					style={{ color: hq ? '#090909' : '#aeaeae' }}
					onClick={() => actions.changeCanvasHQ(!hq)}
				>
					HQ
				</a>
				<CreateCanvas
					item={item}
					configurator={configurator}
					openStatus={openStatus}
					canvasId={item.id}
					url={canvasData}
					changeUrl={setCanvasData}
					stageDimensions={item?.stageDimensions}
					canvasData={data}
					stone={burial.stella.items[0]?.stone}
					burialId={burial.id}
					hq={hq}
				/>
			</div>
		)
	);
};

export default memo(Canvas);
