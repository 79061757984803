import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { data } from './constans';

export const productsAPI = createApi({
	reducerPath: 'productsAPI',
	tagTypes: ['Products'],
	baseQuery: fetchBaseQuery({
		baseUrl: data.baseUrl,
		prepareHeaders: headers => {
			headers.set('Access-Control-Allow-Origin', '*');
			return headers;
		}
	}),
	endpoints: build => ({
		fetchProducts: build.query({
			query: ({ type, args }) => ({
				url: '/modules',
				params: { ...args, module_tax: args?.module_tax }
			}),
			transformResponse(apiResponse, meta) {
				return {
					apiResponse,
					totalCount: meta.response.headers.get('X-WP-TotalPages')
				};
			},
			providesTags: result => ['Products']
		})
	})
});
