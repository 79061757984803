export const textColors = {
	black: {
		title: 'Черный',
		value: '#171717'
	},
	gray: {
		title: 'Серый',
		value: '#C5C5C5'
	},
	gold: {
		title: 'Золотой',
		value: '#FFD700'
	}
};

export const engravingTextColors = Object.values(textColors);
export const tablesTextColors = [textColors.black, textColors.gray];
export const defaultTextColor = textColors.black;
export const elementsTextColor = { title: 'Бронзовый', value: '#CD7F32' };

export const numericTypes = ['Арабские', 'Римские'];
export const textStylesTyle = ['Прямой', 'Под наклоном'];
export const epitafiaTextStyles = [
	{ id: 0, title: 'Прямой', name: 'normal' },
	{ id: 1, title: 'Под наклоном', name: 'italic' }
];
