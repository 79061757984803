import React from 'react';

import './radioStone.scss';

const RadioStoneInput = ({ title, img, defaultChecked, onChange = () => {}, name, id, classes = '', type = 'radio', size }) => {
	return (
		<div className='type__stone'>
			<img src={img} alt='stone type' />
			{size && <p>{size}</p>}
			<input type={type} className={`type__stone__radio ${classes}`} id={id} name={name} onChange={onChange} checked={defaultChecked} />
			<label htmlFor={id}>{title}</label>
		</div>
	);
};

export default RadioStoneInput;
