import { transformFontSize } from 'utils/canvas/transformFontSize';
import { OFFSET } from 'components/UI/canvas/constans';
import { v4 as uuid } from 'uuid';
import { defaultFontFamily } from 'config/fontSizeTransform.config';

export const imgObj = (obj, catType, x = OFFSET, y = OFFSET) => {
	const unique_id = uuid();
	return {
		...obj,
		id: obj.id?.toString() ?? `${unique_id}`,
		url: obj.url ?? obj.img,
		// type: 'anonymous',
		x: obj.x ?? x,
		y: obj.y ?? y,
		sku: obj.sku,
		catType,
		type: 'image'
		// konvaType: 'image'
	};
};

export const textObj = (obj, catType) => {
	const unique_id = uuid();
	return {
		...obj,
		id: obj.id ?? `${unique_id}`,
		text: obj.text,
		// type: 'anonymous',
		x: obj.x ?? 21,
		y: obj.y ?? 21,
		fontFamily: obj.fontFamily ?? defaultFontFamily.value,
		fontSize: transformFontSize(obj.fontSize),
		textAlign: obj.textAlign ?? 'Left',
		fontStyle: obj.textStyle ?? 'normal',
		catType,
		type: 'text'
		// konvaType: 'text'
	};
};
