import { useMemo } from 'react';

const doAction = (value, sort) => {
	value = value[sort];
	if (typeof value === 'object') {
		value = Object.values(value)?.[0];
	} else if (typeof value === 'string') {
		value = value.replace(/[^0-9]/g, '');
	} else {
		value = 0;
	}
	return value;
};

export const useSortedPosts = (posts, sort = 'title', type = 'az') => {
	const sortedPosts = useMemo(() => {
		if (sort && posts) {
			let forTitle = (a, b) => a[sort].toLowerCase().localeCompare(b[sort].toLowerCase()),
				forPrice = (a, b) => doAction(a, sort) - doAction(b, sort);

			posts = [...posts].sort((a, b) => (type == 'za' || type == 'az' ? forTitle(a, b) : forPrice(a, b)));
			if (type == 'za' || type == 'down') posts.reverse();
		}
		return posts;
	}, [posts, type]);
	return sortedPosts;
};

export const usePosts = (posts, sort = 'title', type = 'az', query = '', filterParametr = 'title') => {
	const sortedPosts = useSortedPosts(posts, sort, type);
	const sortedAndSearchedPosts = useMemo(() => {
		if (query === '') return sortedPosts;
		return sortedPosts.filter(post => post?.[filterParametr].toLowerCase().includes(query.toLowerCase()));
	}, [query, sortedPosts]);

	return sortedAndSearchedPosts;
};
