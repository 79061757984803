import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';

import { useActions } from 'hooks/useActions';
import { v4 as uuid } from 'uuid';

const EngravingOwnPicture = ({ burial, template }) => {
	const actions = useActions();
	const { status, price, img } = burial.ownForm;

	const onToggleHandler = useCallback(
		() => actions.setEngravingValue({ obj: 'ownForm', include: null, value: { status: !status, price: 0, img: [] } }),
		[status]
	);

	const onInputHandler = useCallback(value => actions.setEngravingValue({ obj: 'ownForm', include: 'price', value: value }), []);

	const onDropImgHandler = useCallback(
		files =>
			actions.setEngravingValue({
				obj: 'ownForm',
				include: null,
				value: {
					id: uuid(),
					status: true,
					price,
					img: files,
					...template.ownPicture
				}
			}),

		[price]
	);

	return (
		<LoadForm
			formTitle='Своя картинка'
			formState={status}
			onToggle={onToggleHandler}
			label='Введите стоимость'
			inputValue={price}
			onInput={onInputHandler}
			images={img}
			onDrop={onDropImgHandler}
		/>
	);
};

export default EngravingOwnPicture;
