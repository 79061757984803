import React, { useState, memo, useCallback } from 'react';
import { useShallowEffect } from '@mantine/hooks';

import BigCard from '../cards/big-card';
import ListCard from './../cards/list-card';
import EpitafiaCard from '../cards/epitafia-card';

import AdvancedPopUpSeach from './search';
import AdvancedPopUpSorting from './sorting';

import { usePosts } from 'hooks/usePosts';
import cn from 'classnames';

import './popUp.scss';

const searchTypes = [
	{
		sortType: 'az',
		sort: 'title',
		classes: '--svg__sorting-az'
	},
	{
		sortType: 'za',
		sort: 'title',
		classes: '--svg__sorting-za'
	},
	{
		sortType: 'up',
		sort: 'price',
		classes: '--svg__price-up'
	},
	{
		sortType: 'down',
		sort: 'price',
		classes: '--svg__price-down'
	}
];

const AdvancedPopUp = ({
	openState = false,
	changeState,
	type = 'standart',
	sortState = false,
	products = [],
	selectedProducts = [],
	onClick = () => {},
	sortTypes = searchTypes,
	hook,
	refetch,
	isLoading,
	isError,
	filterParametr = 'title',
	closeAllowCheck = true
}) => {
	const [items, setItems] = useState(hook ? products : []);
	const [sort, setSort] = useState(sortTypes[0]);
	const [search, setSearch] = useState('');
	const sortingPosts = usePosts(items, sort.sort, sort.sortType, search, filterParametr);

	useShallowEffect(() => {
		async function fetchData() {
			let response = [];
			if (refetch && typeof products !== 'function') {
				response = await refetch();
			} else {
				response = await products();
			}
			setItems(response);
		}

		if (openState && hook) {
			refetch();
		} else if (openState && items.length === 0) fetchData();
	}, [openState, items]);

	useShallowEffect(() => {
		if (openState && hook) setItems(products);
	}, [products, openState]);

	const closeBtnHandler = useCallback(() => changeState(false), [changeState]);
	const onSearchHandler = useCallback(value => setSearch(value), []);

	return (
		<div
			className={cn(`configurator__light-box configurator__light-box--${type}`, {
				active: openState
			})}
		>
			{openState && (
				<>
					<div className='configurator__light-box__header'>
						<AdvancedPopUpSeach onSearchHandler={onSearchHandler} />
						{sortState || type == 'standart' ? <AdvancedPopUpSorting sortBy={sort} setSort={setSort} availableSorts={sortTypes} /> : null}
					</div>
					{closeAllowCheck && <div className='configurator__light-box_close --svg__close' onClick={closeBtnHandler}></div>}
					<div className='configurator__light-box__content'>
						{isLoading && <h2 className='message'>Идет загрузка...</h2>}
						{isError && <h2 className='message'>Что то случилось, попробуйте позже</h2>}
						{!isError && !isLoading && sortingPosts.length === 0 && <h2 className='message'>Ничего не найдено</h2>}
						{!isError &&
							!isLoading &&
							sortingPosts.map((item, index) =>
								type == 'standart' ? (
									<BigCard key={index} item={item} onClick={onClick} status={selectedProducts.some(el => el.sku == item.sku)} />
								) : type == 'list' ? (
									<ListCard key={index} item={item} onClick={onClick} status={selectedProducts.some(el => el.sku == item.sku)} />
								) : type == 'epitafia' ? (
									<EpitafiaCard key={index} item={item} onClick={onClick} status={selectedProducts.some(el => el.text == item.text)} />
								) : null
							)}
					</div>
				</>
			)}
		</div>
	);
};

export default memo(AdvancedPopUp);
