import React, { useCallback } from 'react';

import { useActions } from 'hooks/useActions';
import TypeStone from 'components/UI/bigComponents/TypeStone';

const TypeStoneContent = ({ burial, currentPedestal, material }) => {
	const actions = useActions();
	const defaultMaterial = burial.pedestal.items[currentPedestal].stone;

	const onChangeMaterialHandler = useCallback(item => actions.changePedestalStone(item.title), [actions]);

	return (
		<TypeStone
			materialTitle='Вид камня'
			type={material}
			stone={defaultMaterial}
			onChangeMaterialHandler={onChangeMaterialHandler}
			showJustMaterials
		/>
	);
};

export default TypeStoneContent;
