import React, { useCallback } from 'react';

import { flowerEvents } from 'utils/events/flower';
import { useActions } from 'hooks/useActions';
import TypeStone from 'components/UI/bigComponents/TypeStone';

const TypeStoneContent = ({ burial, flowers, currentFlower, material }) => {
	const actions = useActions();
	const currentFlowerObj = flowers[currentFlower];
	const defaultMaterial = currentFlowerObj.stone;

	const onChangeMaterialHandler = useCallback(
		item => {
			actions.changeFlowerStone(item.title);
			// if (parseInt(currentFlower) === 0) {
			// 	flowerEvents.changeMaterialFlower(item.material, burial.id, currentFlowerObj.id);
			// } else {
			// 	flowerEvents.changeMaterialFlowerSecond(item.material, currentFlower, burial.id, currentFlowerObj.id);
			// }
		},
		[actions, currentFlower, burial.id, currentFlowerObj.id]
	);

	return (
		<TypeStone
			materialTitle='Вид камня'
			type={material}
			stone={defaultMaterial}
			onChangeMaterialHandler={onChangeMaterialHandler}
			showJustMaterials
		/>
	);
};

export default TypeStoneContent;
