import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import PrimaryButton from 'components/UI/button/primary';
import CheckBox from 'components/UI/input/checkbox';
import DecoratedElements from './DecoratedElements';
import PlateEditor from './PlateEditor';
import TileArea from './TileArea';
import TileStone from './TileStone';
import Fence from './Fence';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const Step5 = () => {
	const actions = useActions();
	const { burials, burial, firstImprovedBurialIndex } = useBurialsData();
	const tileIsNecessary = useSelector(state => state.configurator.present.step5.tileIsNecessary);
	const tileAreaObj = useMemo(() => burial.improvement.tileArea, [burial.improvement.tileArea]);
	const claddingState = useMemo(() => burial.improvement.cladding, [burial.improvement.cladding]);
	const materialsVert = useSelector(state => state.configurator.present.step5.tile.vertical);

	const changeCladdingHandler = useCallback(bool => actions.changeCladding(bool ?? !claddingState), [claddingState]);

	const goToNextStep = useCallback(() => {
		actions.goToStep(6);
		actions.changeCurrentBurial(firstImprovedBurialIndex);
		actions.clearHistory();
	}, [actions, firstImprovedBurialIndex]);

	const onChangeTileCheckbox = useCallback(() => actions.toggleTileIsNecessary(), []);

	useEffect(() => {
		const stone = materialsVert[0];
		if (!stone) return;
		let defaultSize;
		if (stone.default_plate_size.split('x').length === 2) {
			defaultSize = stone.default_plate_size.split('x').map(value => parseInt(value));
		} else if (stone.default_plate_size.split('х').length === 2) {
			defaultSize = stone.default_plate_size.split('х').map(value => parseInt(value));
		}
		console.log(stone);
		console.log(defaultSize);
		let pavingCount = 0;
		burials.forEach(burial => {
			const height = burial.improvement.tileArea.height;
			const perimeter = (2 * (burial.areaValues.length + burial.areaValues.width)) / 10;
			let verticalSize = Math.min(...defaultSize);
			let horizontalSize = Math.max(...defaultSize);

			const vertDiv = verticalSize / height;
			let count = 0;
			if (vertDiv >= 1) {
				//TODO убрать после дебага
				console.log('Плитка больше высоты основания');
				const onePlatePaving = horizontalSize * Math.floor(vertDiv);
				count = Math.ceil(perimeter / onePlatePaving);
			} else {
				//TODO убрать после дебага
				console.log('Плитка меньше высоты основания');
				const columnCount = Math.ceil(1 / vertDiv);
				count = Math.ceil((columnCount * perimeter) / horizontalSize);
			}
			console.log('Количество плитки для вертикальной облицовки ', count);
			actions.setRectCountByMaterialVert({ [stone.id]: count });
		});
	}, [materialsVert, burials]);

	return (
		<>
			<CheckBox
				title='Раскладка плитки'
				defaultChecked={tileIsNecessary}
				onChange={onChangeTileCheckbox}
				name='tileIsNecessary'
				id='tileIsNecessary'
			/>
			{tileIsNecessary && (
				<>
					<TileArea tileAreaObj={tileAreaObj} claddingState={claddingState} changeCladdingHandler={changeCladdingHandler} />
					<Fence />
					<TileStone categoryType='horizontal_tile' type='horizontal' module_tax='68' />
					{tileAreaObj.height >= 10 && (
						<>
							<BlockWithToggle
								title='Вертикальная облицовка'
								openState={claddingState}
								onChangeToggle={() => changeCladdingHandler(!claddingState)}
								disableContent={true}
							/>
							{claddingState && <TileStone categoryType='vertical_tile' type='vertical' module_tax='67' />}
						</>
					)}
					<PlateEditor />
				</>
			)}
			<DecoratedElements />
			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Step5;
