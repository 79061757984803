import React, { useCallback, useMemo } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

const Sverlovka = ({ burial, currentBox }) => {
	const actions = useActions();
	const sverlovka = burial.boxes.items[currentBox].sverlovka;
	const items = useMemo(() => [1, 2, 3, 4], []);

	const onChangeHandler = useCallback(item => actions.changeBoxSverlovka(item), [actions]);

	return (
		<BlockWithArrow title='Сверловка' blockClasses='sidebar__radio__block__value' contentClasses='sidebar__radio__block__value__items'>
			{items.map(item => (
				<RadioInput
					key={item}
					title={item}
					defaultChecked={sverlovka == item}
					onChange={() => onChangeHandler(item)}
					name='box_sverlovka'
					id={`box_sverlovka_${item}`}
				/>
			))}
		</BlockWithArrow>
	);
};

export default Sverlovka;
