import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import TypeStone from 'components/UI/bigComponents/TypeStone';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const Stone = () => {
	const actions = useActions();
	const { burial } = useBurialsData();
	const stone = useMemo(() => burial.materialStone, [burial.materialStone]);
	const type = useMemo(() => burial.material, [burial.material]);
	const defaultComplect = useSelector(state => state.configurator.present.step1.defaultComplect);
	const catalog = useMemo(() => burial.catalog, [burial.catalog]);

	const onChangeTypeHandler = useCallback(
		async e => {
			let value = e.target.dataset.value,
				targetId = e.target.id,
				burialId = burial.id;

			actions.changeHistoryMaterial({
				title: targetId,
				value: value,
				id: burialId,
				defaultComplect: defaultComplect[value]
			});
		},
		[burial.id, defaultComplect]
	);

	const onChangeMaterialHandler = useCallback(
		item => {
			actions.changeHistoryStone({
				title: item.title,
				materialID: item.id,
				selectedType: catalog.type,
				materialType: item.terms.includes('granite') ? 'granite' : 'marble',
				selectedItem: catalog.selectedProducts?.[0],
				polishing: burial.polishing
			});
		},
		[catalog, burial.polishing]
	);

	return (
		<TypeStone type={type} onChangeTypeHandler={onChangeTypeHandler} stone={stone} onChangeMaterialHandler={onChangeMaterialHandler} />
	);
};

export default Stone;
