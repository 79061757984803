import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { step3Events } from 'utils/events/steps/step3';
import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import { ReactComponent as Flower } from 'images/sidebar/Flower.svg';
import { ReactComponent as Form } from 'images/sidebar/Form.svg';
import { ReactComponent as Engraving } from 'images/sidebar/Engraving.svg';
import { ReactComponent as Tombstone } from 'images/sidebar/Tombstone.svg';
import { ReactComponent as Pedestal } from 'images/sidebar/Pedestal.svg';
import { ReactComponent as Stella } from 'images/sidebar/Stella.svg';
import { ReactComponent as Signs } from 'images/sidebar/Signs.svg';
import { ReactComponent as Elements } from 'images/sidebar/Elements.svg';
import { ReactComponent as Boxes } from 'images/sidebar/Boxes.svg';

const SidebarStep3 = () => {
	const actions = useActions();
	const { burial, burialsImproved, burials, currentBurial } = useBurialsData();
	const currentTab = useSelector(state => state.configurator.present.step3.currentSidebarTab);

	const pedestalItemsLength = useMemo(() => burial.pedestal.items.length, [burial.pedestal.items.length]);
	const boxesItemsLenght = useMemo(() => burial.boxes.items.length, [burial.boxes.items.length]);
	const stellaItemsLenght = useMemo(() => burial.stella.items.length, [burial.stella.items.length]);
	const catalogType = useMemo(() => burial.catalog.type, [burial.catalog.type]);
	const boxesPopUpStatus = useMemo(() => burial.boxes.position.status, [burial.boxes.position.status]);

	const showBoxes = useMemo(() => pedestalItemsLength < 2, [pedestalItemsLength]);
	const showStella = useMemo(() => pedestalItemsLength >= 1 || boxesItemsLenght >= 1, [pedestalItemsLength, boxesItemsLenght]);
	const showAddElements = useMemo(() => stellaItemsLenght >= 1 && showStella, [stellaItemsLenght, showStella]);

	const changeTabHandler = useCallback(
		tab => {
			actions.changeCurrentSidebarTab(tab);
			actions.toggleCanvasContainer({ status: false });
		},
		[actions]
	);

	const tabs = useMemo(() => {
		return [
			{
				title: 'тумба',
				icon: Pedestal,
				index: 0,
				show: catalogType === 'go-step'
			},
			{
				title: 'Кубы',
				icon: Boxes,
				index: 1,
				show: catalogType === 'go-step' && showBoxes
			},
			{
				title: 'стела',
				icon: Stella,
				index: 2,
				show: catalogType === 'go-step' && showStella
			},
			{
				title: 'цветник',
				icon: Flower,
				index: 3,
				show: catalogType === 'go-step' && showStella
			},
			{
				title: 'надгроб.',
				icon: Tombstone,
				index: 4,
				show: catalogType === 'go-step' && showStella
			},
			{
				title: 'форма',
				icon: Form,
				index: 5,
				show: showAddElements
			},
			{
				title: 'таблички',
				icon: Signs,
				index: 6,
				show: showAddElements
			},
			{
				title: 'гравир.',
				icon: Engraving,
				index: 7,
				show: showAddElements
			},
			{
				title: 'элементы',
				icon: Elements,
				index: 8,
				show: showAddElements
			}
		].filter(el => el.show === true);
	}, [showBoxes, showStella, showAddElements, catalogType]);

	useEffect(() => {
		const activeTab = tabs.map(el => el.index).find(el => el === currentTab) ?? 0;
		boxesPopUpStatus && actions.changeBoxPopUpState(false);
		actions.changeCurrentSidebarTab(activeTab);
	}, [currentTab, actions, tabs, boxesPopUpStatus]);

	const changeBurialHandler = useCallback(
		el => {
			const indexInBurials = burials.findIndex(item => item.id === el.id);
			if (el.id === currentBurial) return;
			const openTab = burials[indexInBurials].catalog.type === 'go-step' ? 0 : 5;
			actions.togglePopUp(false);
			actions.changeCurrentBurial(indexInBurials);
			actions.toggleCanvasContainer({ status: false });
			actions.changeCurrentSidebarTab(openTab);
		},
		[burials, actions, currentBurial]
	);
	const createBurials = useCallback(el => {
		let arr = el.title.replaceAll('Захоронение ', '').split(',');
		return arr.length >= 2 ? arr[0] + '·' + arr[arr.length - 1] : arr[0];
	}, []);

	useEffect(() => {
		if (!showStella && catalogType == 'go-step') step3Events.hideAllParts(burial.id);
	}, [currentBurial, burial.id, catalogType, showStella]);

	return (
		<div className='step3__sidebar'>
			<div className='step3__sidebar__header'>
				{tabs.map(tab => {
					const active = +currentTab === +tab.index;
					const Component = tab.icon;
					return (
						<a key={tab.title} onClick={() => changeTabHandler(tab.index)} className={`step3__sidebar__item ${active && 'active'}`}>
							<Component />
							<span>{tab.title}</span>
						</a>
					);
				})}
			</div>
			<div className='step3__sidebar__footer'>
				{burialsImproved.map((el, index) => (
					<a
						className={`step3__sidebar__footer__item ${el.id === burial.id && 'active'}`}
						key={index}
						onClick={() => changeBurialHandler(el)}
					>
						<span className='step3__sidebar__footer__item__title'>{createBurials(el)}</span>
						<span className='step3__sidebar__footer__item__pedestal'></span>
					</a>
				))}
			</div>
		</div>
	);
};

export default SidebarStep3;
