import React, { useCallback, useMemo } from 'react';

import { useGoToNextStep } from 'hooks/useGoToNextStep';

import Polishing from './Polishing';
import PrimaryButton from 'components/UI/button/primary';
import CatalogCollection from './CatalogCollection';
import Stone from './Stone';

import './add_values.scss';
import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const Step2 = () => {
	const actions = useActions();
	const { burialsImproved, indexNextBurialInBurials, currentBurialImproved, firstImprovedBurialIndex } = useBurialsData();
	const goToNextStep = useGoToNextStep();

	const goToNextStepFunc = id => goToNextStep.goToNextStep(total, id);

	const total = useCallback(
		id => {
			const openTab = burialsImproved[0].catalog.type === 'go-step' ? 0 : 5;

			actions.goToStep(id);
			actions.togglePopUp(false);
			actions.changeCurrentBurial(firstImprovedBurialIndex);
			actions.changeCurrentSidebarTab(openTab);
			actions.clearHistory();
		},
		[actions, burialsImproved, firstImprovedBurialIndex]
	);

	const totalSecond = useCallback(
		burialIndex => {
			actions.togglePopUp(false);
			actions.changeCurrentBurial(burialIndex);
		},
		[actions]
	);

	const title = useMemo(() => burialsImproved[currentBurialImproved].title, [burialsImproved, currentBurialImproved]);
	const isLast = burialsImproved.length > currentBurialImproved + 1;
	const primaryBtnTitle = useMemo(() => {
		return isLast ? burialsImproved[currentBurialImproved + 1].title : 'Продолжить';
	}, [burialsImproved, currentBurialImproved, isLast]);

	return (
		<>
			{burialsImproved.length > 1 && (
				<div className='burials__add__values'>
					{burialsImproved.map((element, index) => {
						if (index == currentBurialImproved) return;
						return (
							<div key={index} className='burials__add__values__item' onClick={() => totalSecond(element.id)}>
								<h3>{element.title.replace(' ', ' №')}</h3>
								<p>
									{parseInt(element.areaValues.length)}x{parseInt(element.areaValues.width)}
								</p>
							</div>
						);
					})}
				</div>
			)}
			<h1 className='heading-h1'>{title}</h1>
			<Stone />
			<Polishing />
			<CatalogCollection />
			<PrimaryButton
				title={primaryBtnTitle}
				onClick={() => {
					isLast ? totalSecond(indexNextBurialInBurials) : goToNextStepFunc(3);
				}}
			/>
		</>
	);
};

export default Step2;
