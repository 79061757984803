import { data } from 'services/constans';
import { deflate } from 'pako';
import { complectCartData } from 'utils/cart/complectCartData';

export const excel = {
	getExcel: async (cart, images, areaSize) => {
		const graves = complectCartData(cart);
		const { width, height } = areaSize;
		const store = {
			scene: images.scene,
			images: images.gravesImg,
			list: graves,
			manager: 'Nikita',
			branch: 'Istanbul',
			size: `${width}x${height}`,
			total: '',
			sale: '',
			price: graves.reduce((total, grave) => total + parseFloat(grave.total), 0)
		};

		if (window.pavingScreenshot) store.plate = window.pavingScreenshot;

		// 'http://localhost/graves/backend/wp-json/app/v1/estimate/xlsx';
		return await fetch(data.baseUrl + '/estimate/xlsx', {
			method: 'POST',
			headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
			body: deflate(JSON.stringify(store))
		}).then(res => res.json());
	}
};
