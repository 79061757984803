import React, { useCallback } from 'react';

import { v4 as uuid } from 'uuid';

import Portrait from 'components/UI/bigComponents/Portrait';

import { useActions } from 'hooks/useActions';

const EngravingPortrait = ({ burial, template }) => {
	const actions = useActions();
	const portraitItems = burial.portrait.items;
	const portraitCurrentItem = burial.portrait.currentPortrait;
	const portraitStatus = burial.portrait.status;

	const onToggleHandler = useCallback(() => {
		actions.setEngravingValue({
			obj: 'portrait',
			include: null,
			value: { status: !portraitStatus, currentPortrait: 0, items: [] }
		});
	}, [portraitStatus]);

	const onTabChangeHandler = useCallback(tab => actions.setEngravingValue({ obj: 'portrait', include: 'currentPortrait', value: tab }), []);

	const onChangeTypeHandler = useCallback(
		el => actions.setEngravingItemsFioDateTable({ type: 'portrait', obj: portraitCurrentItem, include: 'type', value: el }),
		[portraitCurrentItem]
	);

	const addPortraitHandler = useCallback(() => {
		const unique_id = uuid();
		const newArr = [...portraitItems, { img: [], price: 0, id: unique_id, ...template['portrait'] }];
		actions.universalEngravingPortraitHandler({
			data: [{ action: 'setEngravingValue', values: [{ obj: 'portrait', include: 'items', value: newArr }] }],
			tab: newArr.length !== 0 ? newArr.length - 1 : 0
		});
	}, [portraitItems]);

	const deletePortraitHandler = useCallback(() => {
		const newArr = portraitItems.filter((el, i) => portraitCurrentItem != i);
		actions.universalEngravingPortraitHandler({
			data: [{ action: 'setEngravingValue', values: [{ obj: 'portrait', include: 'items', value: newArr }] }],
			tab: newArr.length !== 0 ? newArr.length - 1 : 0
		});
	}, [portraitCurrentItem, portraitItems]);

	const onDropFileHandler = useCallback(
		file => {
			actions.setEngravingItemsFioDateTable({
				type: 'portrait',
				obj: portraitCurrentItem,
				include: 'img',
				value: [{ preview: URL.createObjectURL(file), name: file.name }]
			});
		},
		[portraitCurrentItem]
	);

	const onChangePriceHandler = useCallback(price => {
		actions.setEngravingItemsFioDateTable({
			type: 'portrait',
			obj: portraitCurrentItem,
			include: 'price',
			value: price
		});
	});

	return (
		<Portrait
			openState={portraitStatus}
			portraitCurrentItem={portraitCurrentItem}
			portraitItems={portraitItems}
			onDropFileHandler={onDropFileHandler}
			onToggleHandler={onToggleHandler}
			onChangeTypeHandler={onChangeTypeHandler}
			onTabChangeHandler={onTabChangeHandler}
			addPortraitHandler={addPortraitHandler}
			deletePortraitHandler={deletePortraitHandler}
			onChangePrice={onChangePriceHandler}
		/>
	);
};

export default EngravingPortrait;
