import React, { useCallback, useEffect } from 'react';

import SecondButton from 'components/UI/button/second';
import PrimaryButton from 'components/UI/button/primary';
import TabsComponent from 'components/UI/tabs/tabs';
import TypeStoneContent from './TypeStone';
import TombstoneProducts from './productList';

import useBurialsData from 'hooks/useBurialsData';
import { useActions } from 'hooks/useActions';

import { tombstoneEvents } from 'utils/events/tombstone';
import { dispatchEvent } from 'utils/events/dispatchEvent';

const Tombstone = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();
	const material = burial.material.value;
	const allTombstone = burial.tombstone.items;
	const currentTomb = burial.tombstone.currentTomb;
	const howManyFlower = burial.flowers.items;
	const addTombstoneCheck = howManyFlower.length > allTombstone.length || howManyFlower.length == 0;

	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [currentTab, actions]);
	const onChangeTabHandler = useCallback(e => actions.changeCurrentTombstone(e.target.dataset.value), [actions]);

	useEffect(() => {
		dispatchEvent(`setCameraTarget`, { target: 'tombstone', currentBurial, subTargetIndex: currentTomb });
	}, [currentTomb, currentBurial, allTombstone]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Надгробная плита</h1>
			{allTombstone.length > 0 && (
				<>
					<TabsComponent
						currentEl={currentTomb}
						currentBurial={currentBurial}
						AllEl={burial.tombstone.items}
						title='СТ'
						onClick={onChangeTabHandler}
					/>
					{material === 'granite' && (
						<TypeStoneContent currentTomb={currentTomb} burial={burial} tombstones={allTombstone} material={material} />
					)}
					<TombstoneProducts currentBurial={currentBurial} currentTomb={currentTomb} burial={burial} allTombstone={allTombstone} />
				</>
			)}
			{addTombstoneCheck && (
				<SecondButton
					title='Добавить плиту'
					classes='mb24 box__value__add'
					onClick={() => {
						actions.createTombstoneItem();
						if (howManyFlower.length == 0) {
							tombstoneEvents.addTombstone({ id: burial.id, attached_to_bed: false, bed: null });
						} else {
							tombstoneEvents.addTombstone({ id: burial.id, attached_to_bed: true, bed: allTombstone.length + 1 });
						}
					}}
				/>
			)}
			{allTombstone.length > 0 && (
				<SecondButton
					title='Удалить плиту'
					classes='mb24 box__value__delete step1__exclude__add'
					onClick={() => {
						tombstoneEvents.removeTombstone({ id: burial.id, tombId: currentTomb });
						actions.deleteCurrentTombstoneItem();
					}}
				/>
			)}
			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Tombstone;
