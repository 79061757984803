import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Installation from './Installation';
import Dismantling from './Dismantling';
import ExcludePlace from './ExcludePlace/ExcludePlace';
import DismantlingWithPreservation from './DismantlingWithPreservation';

import PrimaryButton from 'components/UI/button/primary';
import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const Step4 = () => {
	const actions = useActions();
	const { burial, burials, currentBurial, firstImprovedBurialIndex } = useBurialsData();
	const instalation = useSelector(state => state.configurator.present.step1.installation);
	const workZoneStatus = useSelector(state => state.configurator.present.step1.workZone.open);

	const goToNextStep = useCallback(() => {
		actions.goToStep(5);
		actions.changeCurrentBurial(firstImprovedBurialIndex);
		actions.clearHistory();
	}, [actions, firstImprovedBurialIndex]);

	return (
		<>
			<Installation burial={burial} burials={burials} currentBurial={currentBurial} />
			<Dismantling />
			<DismantlingWithPreservation currentBurial={currentBurial} />
			{instalation !== 'Самовывоз' && workZoneStatus && <ExcludePlace />}
			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Step4;
