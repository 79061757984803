import React, { useCallback } from 'react';

import PopUpPositionBox from 'components/UI/other/PopUpPositionBox';

import useArrayFromLenght from 'hooks/useArrayFromLenght';
import { useActions } from 'hooks/useActions';

const PopUpBoxes = ({ boxesLength, boxes, pedestalItemsLength }) => {
	const actions = useActions();
	const boxesArrLength = useArrayFromLenght(boxesLength);
	const getPosition = useCallback(index => boxes.items[index].indexPos, [boxes.items]);

	return (
		<div className='popup-position__boxes'>
			<div className='popup-position__boxes__line'></div>
			<div className='popup-position__boxes__wrapper'>
				{boxesArrLength.map((item, index) => (
					<PopUpPositionBox
						key={index}
						indexEl={index + 1}
						elementPos={getPosition(index)}
						isDragDisabled={pedestalItemsLength === 0}
						onDragEnd={pos => actions.changeIndexPos({ id: index, index: pos })}
					/>
				))}
			</div>
		</div>
	);
};

export default PopUpBoxes;
