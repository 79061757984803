import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Pedestal from 'components/step3/Pedestal';
import Stella from 'components/step3/Stella';
import Flower from 'components/step3/Flower';
import Form from 'components/step3/Form';
import Tombstone from 'components/step3/Tombstone';
import Elements from 'components/step3/Elements';
import Boxes from './Boxes';
import Tables from './Tables';
import Engraving from './Engraving';

const components = [Pedestal, Boxes, Stella, Flower, Tombstone, Form, Tables, Engraving, Elements];

const Step3 = () => {
	const currentTab = useSelector(state => state.configurator.present.step3.currentSidebarTab);
	const CurrentComp = useMemo(() => components[currentTab], [currentTab]);

	return <CurrentComp currentTab={currentTab} />;
};

export default Step3;
