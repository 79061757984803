import axios from 'axios';
import { productsAPI } from 'services/Products';
import { data as dataConsts } from 'services/constans';

export const axiosBaseQuery = async ({ url, method, data, params }) => {
	try {
		const result = await axios({ url: url, method, data, params });
		return { data: result.data ?? [] };
	} catch (axiosError) {
		let err = axiosError;
		return {
			error: {
				status: err.response?.status,
				data: err.response?.data || err.message
			}
		};
	}
};

export const tablesApi = productsAPI.injectEndpoints({
	endpoints: build => ({
		fetchTablesForm: build.query({
			query: args => ({
				url: '/field-values/collect',
				method: 'POST',
				body: args
			})
		}),
		fetchTablesFormIds: build.query({
			queryFn: async args => {
				const { data, error } = await axiosBaseQuery({
					url: dataConsts.baseUrl + '/modules',
					method: 'GET',
					params: {
						per_page: 100,
						form: args.form,
						color: args.color,
						caption: args.caption,
						module_tax: args.module_tax
					}
				});

				if (data) {
					const ids = data.map(el => el.id).join(',');
					const { data: sizes, error: sizesError } = await axiosBaseQuery({
						url: dataConsts.baseUrl + '/field-values/collect',
						method: 'POST',
						params: {
							post_type: args.post_type,
							taxonomy: args.taxonomy,
							term_ids: args.term_ids,
							acf_selector: args.acf_selector,
							include_post_ids: ids
						}
					});

					if (sizesError) {
						return sizesError;
					} else {
						return {
							data: sizes
								.map(({ width, height }) => {
									if (!width && !height) return null;
									return {
										title: [width, height].join('x'),
										values: { width: parseInt(width), height: parseInt(height) }
									};
								})
								.filter(el => el !== null)
						};
					}
				} else {
					return error;
				}
			}
		})
	}),
	overrideExisting: false
});
