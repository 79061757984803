export const tablesInstalationTypes = [
	{
		title: 'Монтаж на держатели',
		fastening: [2, 4],
		name: 'holders',
		id: 'holders'
	},
	{
		title: 'Монтаж в нишу',
		fastening: [],
		name: 'niche',
		id: 'niche'
	}
];

export const tablesFastingTypes = [
	{
		name: 'Эконом',
		type: 'economy',
		backName: 'эконом'
	},
	{
		name: 'Дистанционный',
		type: 'remote',
		backName: 'дистанционный'
	}
];
