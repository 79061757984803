import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import CalatolCollectionProductList from './ProductList';

import { complectsCatalog } from 'config/catalog.config';

import './catalog.scss';

const CatalogCollection = () => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();
	const materials = useSelector(state => state.configurator.present.configuratorSteps.categories.materials);
	const defaultMaterial = burial.catalog.type;
	const selectedProduct = burial.catalog.selectedProducts[0];
	const checkShow = useCallback(item => defaultMaterial === item.value && item.value !== 'go-step', [defaultMaterial]);

	const onChangeInput = useCallback(
		e => {
			actions.changeCurrentSidebarTab(0);
			actions.changeHistoryCatalogSelection({
				selection: e.target.dataset.value,
				title: burial.materialStone,
				materialID: materials.find(el => el.title === burial.materialStone)?.id,
				materialType: defaultMaterial,
				selectedItem: selectedProduct,
				polishing: burial.polishing
			});
		},
		[selectedProduct, defaultMaterial, burial.polishing, burial.materialStone]
	);

	return (
		<BlockWithArrow title='Состав комплектов'>
			{complectsCatalog.map((item, index) => (
				<Fragment key={index + currentBurial + item.label}>
					<RadioInput
						id={item.label}
						title={item.label}
						name={item.name}
						value={item.value}
						defaultChecked={defaultMaterial === item.value}
						onChange={onChangeInput}
					/>
					{checkShow(item) && <CalatolCollectionProductList burial={burial} type={defaultMaterial} currentBurial={currentBurial} />}
				</Fragment>
			))}
		</BlockWithArrow>
	);
};

export default CatalogCollection;
