import React, { useCallback } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioStoneInput from 'components/UI/input/radioStone';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';

const TypeStoneMaterial = ({ selectedStone, material, onChangeHandler, title }) => {
	const categories = useConfiguratorCategories('materials');
	const data = categories.filter(el => el.terms.some(term => term === material));

	const check = useCallback(
		title => (Array.isArray(selectedStone) ? selectedStone.some(el => el.title === title) : selectedStone === title),
		[selectedStone]
	);

	return (
		<BlockWithArrow
			title={title}
			blockClasses='sidebar__radio__block__value checkbox__type change__type__stone'
			contentClasses='sidebar__radio__block__value__items'
		>
			{data &&
				data.map((item, index) => (
					<RadioStoneInput
						key={index}
						img={item.img}
						name={item.material}
						title={item.title}
						id={item.material + item.title}
						defaultChecked={check(item.title)}
						onChange={() => onChangeHandler(item)}
						type={Array.isArray(selectedStone) ? 'checkbox' : 'radio'}
					/>
				))}
		</BlockWithArrow>
	);
};

export default TypeStoneMaterial;
