import React from 'react';
import TextInput from './text';

const CheckBox = ({
	title,
	children,
	defaultChecked,
	onChange = () => {},
	onChangeText = () => {},
	name,
	id,
	value = title,
	classNames = 'checkbox__input',
	addText = false,
	addTextClassName = 'sidebar__radio__block--values__volume',
	addTextLabel = '',
	addTextValue = value
}) => {
	return (
		<div className='checkbox_wrapper'>
			<div className={classNames}>
				<input type='checkbox' id={id} name={name} onChange={onChange} checked={defaultChecked} data-value={value} />
				<label htmlFor={id}>{title}</label>
			</div>
			{addText && defaultChecked && (
				<div className='checkbox__additation__text'>
					<TextInput
						blockClasses={addTextClassName}
						label={addTextLabel}
						name={`text_${name}`}
						id={`text_${name}`}
						onChange={onChangeText}
						required={true}
						type='number'
						disableLabel={true}
						defaultValue={addTextValue}
						useDelay={false}
					/>
				</div>
			)}
			{children}
		</div>
	);
};

export default CheckBox;
