import { useCallback } from 'react';

import { getScaledImageDimensions } from '../utils/getScaledImageDimensions';

import { OFFSET } from './../constans';

export const useAlign = () => {
	const alignItemsBySide = useCallback((stage, itemId, items, stageWidth, element, frame, stageHeight) => {
		const newItems = items.map(el => {
			if (el.id === itemId) {
				let { newWidth, newHeight } = getScaledImageDimensions(stageWidth, stageHeight, element.width(), element.height(), el.wasChanged);
				newWidth = frame ? newWidth : newWidth - OFFSET * 2;
				newHeight = frame ? stageHeight : newHeight - OFFSET * 2;

				const newX = frame ? 0 : stageWidth / 2 - newWidth / 2 + (el.alignBySide.type === 'front' ? 0 : stageWidth);
				const newY = frame ? 0 : stageHeight / 2 - newHeight / 2;

				if (frame) {
					newWidth = stageWidth;
					newHeight = stageHeight;
				}

				return { ...el, width: newWidth, height: newHeight, x: newX, y: newY };
			}
			return el;
		});

		return newItems;
	}, []);

	const alignItemsCenter = useCallback((stage, items, alignByY, stageDimensions, canvasType) => {
		const { status, containImage } = alignByY;
		let stageChildrens = stage.getLayers()[0].getChildren();

		let heightFull = 0;

		let newItems = items.map((el, index) => {
			if (el.catType.includes('fakeportrait')) return el;
			const item = stageChildrens.find(stageEl => stageEl.attrs.id == el.id);
			const { width, y } = item.getClientRect();
			const height = item.textHeight || el.height || item.height() || parseFloat(el.fontSize2) || parseFloat(el.fontSize);
			const isLast = items.length - 1 === index;
			heightFull += height + (isLast ? 0 : OFFSET / 2);
			return { ...el, clientRect: { height, width, y }, item };
		});

		return newItems.map((el, index) => {
			if (el.catType.includes('fakeportrait')) return { ...el, x: stageDimensions.width / 2 - el.width / 2, y: 20 };
			const { height, width, y } = el.clientRect;
			let newY = y;
			const getOffset = newItems.slice(0, index).reduce((sum, el, i) => {
				if (el.catType.includes('fakeportrait')) return sum;
				const elHeight = el.clientRect.height + OFFSET / 2;
				return sum + elHeight;
			}, 0);
			const heightForOffset = getOffset + (index === 0 ? 0 : OFFSET / 2);

			if (status) {
				if (containImage) {
					newY = stageDimensions.height - (heightFull + OFFSET * 2 - heightForOffset);
					if (newY < 0) newY = 0;
				} else {
					const topPoint = stageDimensions.height / 2 - heightFull / 2;
					newY = (topPoint > 0 ? topPoint : 0) + heightForOffset;
				}
			}

			const elWidth = status && el.type !== 'image' ? stageDimensions.width - OFFSET * 2 : width;
			const newX = stageDimensions.width / 2 - elWidth / 2;

			return {
				...el,
				width: containImage ? el.width : elWidth,
				height: containImage ? el.height : height,
				scaleX: 1,
				scaleY: 1,
				x: containImage ? 0 : el.type === 'image' && canvasType === 'plate' ? stageDimensions.width / 2 - el.width / 2 : newX,
				y: newY || 0
			};
		});
	}, []);

	return {
		alignItemsBySide,
		alignItemsCenter
	};
};
