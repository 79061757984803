import React from 'react';

import FileInput from 'components/UI/input/file';
import BlockWithToggle from 'components/UI/block/blockWithToggle';
import TextInput from 'components/UI/input/text';

const LoadForm = ({
	formTitle = 'Форма загрузки',
	formState = false,
	onToggle = () => {},
	label = 'Введите стоимость',
	inputValue = 0,
	onInput = () => {},
	images = [],
	onDrop = () => {},
	loadForm = true,
	disableToogle = false
}) => {
	return (
		<BlockWithToggle title={formTitle} openState={formState} onChangeToggle={onToggle} disableToogle={disableToogle}>
			<TextInput
				blockClasses='own__form__input'
				label={label}
				defaultValue={inputValue}
				name={`own__form__input__${Date.now()}`}
				id={`own__form__input__${Date.now()}`}
				onChange={onInput}
				required={true}
				useDelay={false}
			/>
			{loadForm ? <FileInput img={images} onDrop={file => onDrop([{ preview: URL.createObjectURL(file), name: file.name }])} /> : null}
		</BlockWithToggle>
	);
};

export default LoadForm;
