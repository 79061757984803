import React, { useCallback } from 'react';

import ProductlistWithParams from 'components/UI/list/productsListWithParams';

import { productsAPI } from 'services/Products';
import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { useNotification } from 'hooks/useNotification';

const PedestalProducts = ({ burial, currentBurial, currentPedestal }) => {
	const actions = useActions();
	const currentPedestalObj = burial.pedestal.items[currentPedestal];
	const selectedProducts = currentPedestalObj.selectedProducts;
	const individualSizes = currentPedestalObj.individualSizes;
	const categories = useConfiguratorCategories('modules');
	const cat_tax = categories.find(el => el.slug === 'pedestal')?.id;
	const polishing = burial.polishing;
	const defaultMaterial = currentPedestalObj.stone;

	const { Notification, notificationToggle } = useNotification({
		callback: actions.onChangeHistoryPedestalSizes,
		type: 'pedestal',
		currentElement: currentPedestalObj,
		currentIndexElement: currentPedestal
	});

	const onToggleHander = useCallback(
		e => {
			actions.onToggleHistoryPedestalSizes({
				statusState: individualSizes.status,
				status: !individualSizes.status,
				width: individualSizes.values.width,
				length: individualSizes.values.length,
				height: individualSizes.values.height,
				selectedProducts: selectedProducts,
				currentPedestal: currentPedestal,
				amountElements: burial.pedestal.items.length,
				amountBoxes: burial.boxes.items.length
			});
		},
		[individualSizes, burial.pedestal.items.length, burial.boxes.items.length, selectedProducts, currentPedestal]
	);

	return (
		<>
			<Notification />
			<ProductlistWithParams
				fetchFunction={productsAPI.useFetchProductsQuery}
				selectedProducts={selectedProducts}
				moduleType='pedestal'
				currentBurial={currentBurial}
				title='Размер тумбы'
				blockClasses='sidebar__radio__block sidebar__radio__block__value--individual'
				contentClasses='configurator__light-box__content--list'
				onClickProduct={values => {
					const { width, thickness: length, height } = values.size.values;
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								width: width,
								length: length,
								height: height,
								currentPedestal: currentPedestal,
								amountElements: burial.pedestal.items.length,
								amountBoxes: burial.boxes.items.length,
								clickedOnProduct: values
							}
						]
					});
				}}
				firstLabel='Высота'
				secondLabel='Ширина'
				thirdLabel='Толщина'
				lengthDefault={parseInt(individualSizes.values.height)}
				widthDefault={parseInt(individualSizes.values.width)}
				heightDefault={parseInt(individualSizes.values.length)}
				toggleState={individualSizes.status}
				onChangeLength={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								width: individualSizes.values.width,
								length: individualSizes.values.length,
								height: value !== '' ? parseInt(value) : individualSizes.values.height,
								currentPedestal: currentPedestal,
								amountElements: burial.pedestal.items.length,
								amountBoxes: burial.boxes.items.length
							}
						],
						state
					});
				}}
				onChangeWidth={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								width: value !== '' ? parseInt(value) : individualSizes.values.width,
								length: individualSizes.values.length,
								height: individualSizes.values.height,
								currentPedestal: currentPedestal,
								amountElements: burial.pedestal.items.length,
								amountBoxes: burial.boxes.items.length
							}
						],
						state
					});
				}}
				onChangeHeight={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								width: individualSizes.values.width,
								length: value !== '' ? parseInt(value) : individualSizes.values.length,
								height: individualSizes.values.height,
								currentPedestal: currentPedestal,
								amountElements: burial.pedestal.items.length,
								amountBoxes: burial.boxes.items.length
							}
						],
						state
					});
				}}
				onChangeToggle={onToggleHander}
				taxId={cat_tax}
				stone={defaultMaterial}
				polishing={polishing}
				firstLabelApi='size_height'
				secondLabelApi='size_width'
				thirdLabelApi='size_thickness'
			/>
		</>
	);
};

export default PedestalProducts;
