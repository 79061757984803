import React from 'react';

const AdvancedPopUpSorting = ({ sortBy, setSort, availableSorts }) => {
	const { sortType: type } = sortBy;
	return (
		<div className='configurator__light-box__header__sorting'>
			{availableSorts.map((el, i) => {
				const { sortType, classes } = el;
				return <a key={i} className={`${classes} ${type == sortType ? 'active' : ''}`} onClick={() => setSort(el)}></a>;
			})}
		</div>
	);
};

export default AdvancedPopUpSorting;
