import React, { useCallback, useEffect, useMemo, useState } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import PopUp from 'components/UI/popup/popup';

import { useActions } from 'hooks/useActions';

const Title = ({ openState, taskTable, genderTaskTable, type }) => {
	const actions = useActions();
	const [isOpen, setIsOpen] = useState(openState);
	const items = useMemo(() => ['Для мужчины', 'Для женщины'], []);

	const onChangeTypeHandler = useCallback(
		e => {
			const value = e.target.dataset.value;
			let obj = { state: value };
			if (value === 'Для портрета') obj.gender = null;
			actions.setTableValue({ type: type, obj: 'task', value: obj });
			setIsOpen(true);
		},
		[actions, type]
	);

	const onChangeGenderHandler = useCallback(
		e => actions.setTableValue({ type: type, obj: 'task', include: 'gender', value: e.target.dataset.value }),
		[actions, type]
	);

	useEffect(() => {
		if (!genderTaskTable) setIsOpen(true);
	}, [genderTaskTable]);

	return (
		<BlockWithArrow title='Назначение' openState={false} arrowDelete={true}>
			<RadioInput
				title='Для портрета'
				id='Для портрета'
				value='Для портрета'
				defaultChecked={taskTable === 'Для портрета'}
				onChange={onChangeTypeHandler}
			/>

			{taskTable == 'Для портрета' && (
				<PopUp state={isOpen} onClick={e => setIsOpen(false)} classes='tablePopUp configurator__light-box popUpGenderTables'>
					<h3>Для кого портрет?</h3>
					{items.map(el => (
						<RadioInput key={el} title={el} id={el} value={el} onChange={onChangeGenderHandler} defaultChecked={genderTaskTable === el} />
					))}
				</PopUp>
			)}

			<RadioInput
				title='Именная табличка'
				id='Именная табличка'
				defaultChecked={taskTable === 'Именная табличка'}
				value='Именная табличка'
				onChange={onChangeTypeHandler}
			/>
		</BlockWithArrow>
	);
};

export default Title;
