import { findBurial } from 'slices/step1Slice';

import { generateBox } from 'utils/helpers/step3_generateBox';
import { calculatePosition } from 'utils/helpers/boxes/calculatePosition';

const step3Boxes = () => {
	const changeCurrentBox = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.currentBox = action.payload;
	};
	const addBox = (state, action) => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;

		burial.boxes.items = [...burial.boxes.items, generateBox(title, value, stone)];
		if (!burial.boxes.currentBox) burial.boxes.currentBox = 0;
	};

	const removeBox = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items.pop();

		if (burial.boxes.items.length <= 1) {
			burial.boxes.position = {
				status: false,
				max: burial.boxes.position.max,
				items: [0, 0, 0, 0, 0]
			};
		}

		if (burial.boxes.items.length == 0) {
			burial.boxes.currentBox = null;
		} else {
			burial.boxes.currentBox = 0;
		}
	};

	const changeBoxStone = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items[burial.boxes.currentBox].stone = action.payload;
	};

	const changeBoxesAmount = (state, action) => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;

		if (action.payload == 0) {
			burial.boxes.items = [];
			burial.boxes.position.status = false;
		}
		for (let index = 0; index < action.payload; index++) {
			if (burial.boxes.items.length < action.payload) {
				burial.boxes.items = [...burial.boxes.items, generateBox(title, value, stone)];
			} else if (burial.boxes.items.length > action.payload) {
				burial.boxes.items.pop();
			}
		}
		burial.boxes.currentBox = 0;
	};

	const changeBoxSverlovka = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items[burial.boxes.currentBox].sverlovka = action.payload;
	};

	const changeBoxDepth = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items[burial.boxes.currentBox].size.depth = action.payload;
	};

	const changeBoxWidth = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items[burial.boxes.currentBox].size.width = action.payload;
	};

	const changeBoxHeight = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items.map(item => {
			let elements = {
				...item
			};
			elements.size.height = action.payload;
			return elements;
		});
	};

	const changeBoxPositionStatus = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.position.status = action.payload;
		burial.boxes.position.items = [0, 0, 0, 0, 0];
	};

	const changeIndexPos = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items[action.payload.id].indexPos = action.payload.index;
	};

	const changeBoxPopUpState = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.positionBoxState = action.payload;
	};

	const changeBoxPopUpValues = (state, action) => {
		const burial = findBurial(state);
		const pedestalItems = burial.pedestal.items;
		const boxes = burial.boxes.items;
		const max = (() => {
			const maxValue = boxes.reduce((res, cur) => {
				return (res += parseInt(cur.size.depth));
			}, 0);
			const pedestalWidth = pedestalItems?.[0]?.individualSizes.values.width ?? 1000;
			return parseFloat(pedestalWidth) - maxValue;
		})();

		const indexType = action.payload?.index ?? 0;
		const positionItems = burial.boxes.position.items;
		const boxesLength = boxes.length;

		burial.boxes.position.max = max;

		let typeValue =
			pedestalItems.length === 0
				? parseFloat(action.payload?.value ?? 0)
				: parseFloat(action.payload?.value ?? 0) >= max
				? max
				: parseFloat(action.payload?.value ?? 0);
		const isNotAside = indexType !== 0 && indexType !== boxesLength;

		burial.boxes.position.items = calculatePosition({
			isNotAside,
			indexType,
			typeValue,
			positionItems,
			boxesLength,
			max,
			pedestalItems
		});
	};

	const changeBoxPopAllPosition = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.items = burial.boxes.items.map((item, index) => ({
			...item,
			indexPos: action.payload[index]
		}));
	};

	const changeBoxPopUpAllValues = (state, action) => {
		const burial = findBurial(state);
		burial.boxes.position.items = action.payload.values;
		burial.boxes.position.items[burial.boxes.items.length] = burial.boxes.position.items[0];
		burial.boxes.position.max = action.payload.max;
	};

	return {
		changeCurrentBox,
		addBox,
		removeBox,
		changeBoxStone,
		changeBoxesAmount,
		changeBoxSverlovka,
		changeBoxDepth,
		changeBoxWidth,
		changeBoxHeight,
		changeBoxPositionStatus,
		changeIndexPos,
		changeBoxPopUpState,
		changeBoxPopUpValues,
		changeBoxPopAllPosition,
		changeBoxPopUpAllValues
	};
};

export default step3Boxes;
