import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Takeaway from './Takeaway';
import PrimaryButton from 'components/UI/button/primary';
import AdditionalWorks from './AdditionalWorks';
import Road from './Road';

import { excel } from 'services/Excel';
import useBurialsData from 'hooks/useBurialsData';
import { useCart } from 'hooks/useCart';
import { dispatchEvent } from 'utils/events/dispatchEvent';
import { generateImages } from 'utils/xlsx/generateImages';
import { preloaderEvents } from 'utils/events/preloader';

const Step6 = () => {
	const { burials } = useBurialsData();
	const cart = useCart(burials);
	const areaSize = useSelector(state => state.configurator.present.step1.workZone.zoneValues);

	const getxlsxHandler = useCallback(async () => {
		preloaderEvents.preloaderEnable();
		const images = await generateImages(burials);
		console.log(images);
		await dispatchEvent(`setCameraTarget`, { target: null, duration: 0.01 });
		const { url: link } = await excel.getExcel(cart, images, areaSize);
		preloaderEvents.preloaderDisable();
		const linkEl = document.createElement('a');
		linkEl.setAttribute('href', link);
		linkEl.setAttribute('target', '_blank');
		linkEl.click();
		linkEl.remove();
	}, [cart, areaSize]);

	return (
		<>
			<Road />
			<Takeaway />
			<AdditionalWorks />
			<PrimaryButton title='Получить XLSX' onClick={getxlsxHandler} />
		</>
	);
};

export default Step6;
