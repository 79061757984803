import React, { useMemo } from 'react';
import RadioInput from 'components/UI/input/radio';
import BlockWithToggle from 'components/UI/block/blockWithToggle';

const EngravingCoating = ({ сoatingWithEngraving, changeData }) => {
	const items = useMemo(() => ['Золотая краска', 'Сусальное золото'], []);

	return (
		<BlockWithToggle
			title='Покрытие гравировки'
			openState={сoatingWithEngraving.status}
			onChangeToggle={() => {
				changeData('сoatingWithEngraving', !сoatingWithEngraving.status, 'status');
			}}
			blockClasses='sidebar__radio__block'
		>
			{items.map((item, index) => (
				<RadioInput
					key={index}
					title={item}
					defaultChecked={сoatingWithEngraving.type == item}
					onChange={() => {
						changeData('сoatingWithEngraving', item, 'type');
					}}
					name='coating_type'
					id={`coating_type_${item}`}
				/>
			))}
		</BlockWithToggle>
	);
};

export default EngravingCoating;
