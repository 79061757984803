import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

export const religions = [
	{
		title: 'Православное',
		name: 'religion',
		id: 'orthodoxy'
	},
	{
		title: 'Мусульманское',
		name: 'religion',
		id: 'islam'
	}
];

const Religion = () => {
	const actions = useActions();
	const religion = useSelector(state => state.configurator.present.step1.religion);
	const onChange = useCallback(title => actions.changeReligion(title), [actions]);

	return (
		<BlockWithArrow title='Верование усопшего'>
			{religions.map(item => (
				<RadioInput
					key={item.title}
					title={item.title}
					defaultChecked={religion === item.title}
					onChange={onChange.bind(null, item.title)}
					name={item.name}
					id={item.id}
				/>
			))}
		</BlockWithArrow>
	);
};

export default Religion;
