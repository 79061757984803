import React from 'react';
import BlockWithArrow from '../block/blockWithArrow';
import RadioInput from '../input/radio';

const RadioButtonsList = ({ methods, onChange, currentInstallation, children }) => {
	return (
		<BlockWithArrow title='Способ монтажа'>
			{methods.map(item => {
				const value = item?.title ?? item;

				return (
					<RadioInput
						key={item?.title ?? item}
						title={item?.title ?? item}
						defaultChecked={currentInstallation === value}
						onChange={onChange.bind(this, item)}
						name={item?.name ?? item}
						id={item?.id ?? item}
					/>
				);
			})}
			{children}
		</BlockWithArrow>
	);
};

export default RadioButtonsList;
