import React, { memo, useCallback, useEffect } from 'react';
import { ActionCreators } from 'redux-undo';
import { useSelector } from 'react-redux';
import store from '../../../store';

import PrimaryButton from 'components/UI/button/primary';

import styles from './history.module.scss';

const History = () => {
	const historyData = useSelector(state => ({
		past: state.configurator.past.length,
		future: state.configurator.future.length,
		index: state.configurator.index,
		loading: state.configurator.present.step1.loading
	}));

	const undoHandler = useCallback(() => {
		store.dispatch(ActionCreators.undo());
	}, [ActionCreators, store]);

	const redoHandler = useCallback(() => {
		store.dispatch(ActionCreators.redo());
	}, [ActionCreators, store]);

	useEffect(() => {
		if (!historyData.loading) store.dispatch(ActionCreators.clearHistory());
	}, [historyData.loading]);

	return (
		<div className={styles.history}>
			<PrimaryButton onClick={undoHandler} className='prev btn --svg__arrow-after' disabled={historyData.past === 0} />
			<PrimaryButton onClick={redoHandler} className='next btn --svg__arrow-after' disabled={historyData.future === 0} />
		</div>
	);
};

export default memo(History);
