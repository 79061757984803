import React, { useCallback, useLayoutEffect, useState } from 'react';

import SecondButton from 'components/UI/button/second';
import ActionBlock from 'components/UI/block/actionBlock';
import BlockWithToggle from 'components/UI/block/blockWithToggle';
import TextEditor from 'components/UI/popup/textEditor';

import { generateEpitafia } from 'utils/forms/generateEpitafia';
import { useActions } from 'hooks/useActions';

const Epitafia = ({ currentTable, openState, type }) => {
	const actions = useActions();
	const [openEpitaphPop, setOpenEpitaphPop] = useState(openState);
	const currentTableEpitafiaStatus = currentTable?.epitafia?.status;
	const currentTableFioColor = currentTable?.epitafia?.color;
	const currentTableEpitafiaItems = currentTable?.epitafia?.items;

	const changeData = useCallback(data => actions.setTableValue(data), [actions]);

	useLayoutEffect(() => {
		if (!currentTableEpitafiaItems) setOpenEpitaphPop(false);
	}, [currentTableEpitafiaItems]);

	const onChangeToggle = useCallback(() => {
		changeData({
			type: type,
			obj: 'epitafia',
			value: { status: !currentTableEpitafiaStatus, color: currentTableFioColor, items: null }
		});
	}, [changeData, currentTableEpitafiaStatus, currentTableFioColor, type]);

	const onEditHandler = useCallback(() => setOpenEpitaphPop(true), []);

	const onBasketClickHandler = useCallback(() => {
		changeData({
			type: type,
			obj: 'epitafia',
			value: { status: currentTableEpitafiaStatus, color: currentTableFioColor, items: null }
		});
	}, [changeData, currentTableEpitafiaStatus, currentTableFioColor, type]);

	const onChangeAlignHandler = useCallback(
		el => {
			changeData({
				type,
				obj: 'epitafia',
				value: {
					status: currentTableEpitafiaStatus,
					color: currentTableFioColor,
					items: {
						...currentTableEpitafiaItems,
						textStyle: el.name
					}
				}
			});
		},
		[changeData, currentTableEpitafiaItems, currentTableEpitafiaStatus, currentTableFioColor]
	);

	const onSelectColorHandler = useCallback(
		value => {
			changeData({
				type,
				obj: 'epitafia',
				value: {
					status: currentTableEpitafiaStatus,
					color: value,
					items: currentTableEpitafiaItems
				}
			});
		},
		[changeData, currentTableEpitafiaItems, currentTableEpitafiaStatus, currentTableFioColor]
	);

	const addEpitafiaHandler = useCallback(
		e => {
			setOpenEpitaphPop(true);
			changeData({
				type,
				obj: 'epitafia',
				include: 'items',
				value: generateEpitafia()
			});
		},
		[actions]
	);

	const onChangefontSizeHandler = useCallback(
		value => {
			changeData({
				type,
				obj: 'epitafia',
				value: {
					status: currentTableEpitafiaStatus,
					color: currentTableFioColor,
					items: { ...currentTableEpitafiaItems, fontSize: value.value }
				}
			});
		},
		[changeData, currentTableEpitafiaItems, currentTableEpitafiaStatus, currentTableFioColor]
	);

	const onChangeTextAreaHandler = useCallback(
		(e, value) => {
			changeData({
				type,
				obj: 'epitafia',
				value: {
					status: currentTableEpitafiaStatus,
					color: currentTableFioColor,
					items: { ...currentTableEpitafiaItems, text: value }
				}
			});
		},
		[changeData, currentTableEpitafiaItems, currentTableEpitafiaStatus, currentTableFioColor]
	);

	return (
		<BlockWithToggle title='Эпитафия' openState={currentTableEpitafiaStatus} onChangeToggle={onChangeToggle} contentClasses='editDelete'>
			{openEpitaphPop && (
				<TextEditor
					openState={openEpitaphPop}
					onClick={() => setOpenEpitaphPop(false)}
					className='textEditor epitafiaElements'
					title='Редактор эпитафии:'
					defaultValue={currentTableEpitafiaItems?.text}
					onChangeTextArea={onChangeTextAreaHandler}
					libraryState={true}
					libraryBtnTitle='Библиотека эпитафий'
					onClickLibraryProductCard={item => {
						onChangeTextAreaHandler(null, item.text);
					}}
					libraryClassName='mt32 --svg__book-before'
					alignState={true}
					alignVariant='radio'
					alignType={currentTableEpitafiaItems?.textStyle}
					onChangeAlign={onChangeAlignHandler}
					fontSizeState
					fontSizeType='select'
					fontSizeSelect={currentTableEpitafiaItems?.fontSize}
					onChangefontSize={onChangefontSizeHandler}
					fontFamilyState={false}
					selectedLibraryProducts={[
						{
							id: 0,
							sku: 'epitafia-0',
							text: currentTableEpitafiaItems?.text
						}
					]}
					colorState
					colorTitle={currentTableFioColor?.title}
					onSelectColorHandler={onSelectColorHandler}
				/>
			)}

			{currentTableEpitafiaItems && currentTableEpitafiaItems.text !== '' && (
				<ActionBlock title={currentTableEpitafiaItems?.text} onEditClick={onEditHandler} onBasketClick={onBasketClickHandler} />
			)}

			{(!currentTableEpitafiaItems || currentTableEpitafiaItems?.text === '') && openEpitaphPop === false && (
				<SecondButton title='Добавить Эпитафию' onClick={addEpitafiaHandler} classes='second__btn box__value__add' />
			)}
		</BlockWithToggle>
	);
};

export default Epitafia;
