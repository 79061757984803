import React from 'react';
import { convertPrice } from 'utils/convertPrice';

import './list-card.scss';

const ListCard = ({ item, onClick = () => {}, status }) => {
	const { image, title, sku, price, by } = item;

	return (
		<div className='product__card product__card--list' onClick={() => onClick(item)}>
			<div className='product__card__image'>
				<img src={image} alt={title} />
			</div>
			<div className='product__card__heading'>
				<h2>{title}</h2>
				<span>Артикул: {sku}</span>
			</div>
			<div className='product__card__details'>
				<div className='product__card__details__text'>
					<h3>{convertPrice(price)}</h3>
					<p>{by ?? 'за 1 шт.'}</p>
				</div>
				<button className={`--svg__add_to_cart-after ${status ? 'active' : ''}`}></button>
			</div>
		</div>
	);
};

export default ListCard;
