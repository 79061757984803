import React from 'react';

import BurialItem from './BurialItem';

import useBurialsData from 'hooks/useBurialsData';

const BurialsList = () => {
	const { burials } = useBurialsData();

	return (
		<>
			{burials.map(item => (
				<BurialItem key={item.title} {...item} />
			))}
		</>
	);
};

export default BurialsList;
