import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useGetParametrs from './../../../API/getParametrs';

import CatalogForm from 'components/UI/form/catalogForm';

import { useActions } from 'hooks/useActions';

const Sculptures = () => {
	const actions = useActions();
	const { status, items } = useSelector(state => state.configurator.present.step5.decoratedElements.sculptures);

	const getParamsHandler = useGetParametrs();

	const onChangeToggleHandler = useCallback(
		() => actions.changeDecoratedElementsStatus({ type: 'sculptures', values: !status }),
		[actions, status]
	);

	const onClickProductHandler = useCallback(
		item => actions.changeDecoratedElementsSelectedProducts({ type: 'sculptures', values: item }),
		[actions]
	);
	return (
		<CatalogForm
			formTitle='Скульптуры'
			formState={status}
			onToggle={onChangeToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог скульптур'
			products={() => getParamsHandler.getModuleProducts('elements')}
			selectedProducts={items}
			plusState
			onPlusHandler={product => {
				actions.changeDecoratedElementsSelectedProducts({
					type: 'sculptures',
					values: { ...product, sku: product.sku + Array(items.length).fill(' ').join('') },
					clone: true
				});
			}}
		/>
	);
};

export default Sculptures;
