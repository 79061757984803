import { useEffect, useMemo } from 'react';
import { categoriesAPI } from 'services/Categories';
import { useActions } from 'hooks/useActions';
import { preloaderEvents } from 'utils/events/preloader';

export const useCategories = () => {
	const actions = useActions();

	const [refetchModules, { data: modules }] = categoriesAPI.useLazyFetchModulesQuery({ refetchOnReconnect: true });
	const [refetchMaterials, { data: materials }] = categoriesAPI.useLazyFetchMaterialsQuery({ refetchOnReconnect: true });
	const [refetchSets, { data: sets }] = categoriesAPI.useLazyFetchSetsQuery({ refetchOnReconnect: true });
	const [refetchWorks, { data: works }] = categoriesAPI.useLazyFetchWorksQuery({ refetchOnReconnect: true });

	useEffect(() => {
		preloaderEvents.preloaderEnable();
		Promise.all([refetchModules(), refetchMaterials(), refetchSets(), refetchWorks()]).then(res => preloaderEvents.preloaderDisable());
	}, []);

	const data = useMemo(() => ({ modules, materials, sets, works }), [materials, modules, sets, works]);

	useEffect(() => actions.setCategories(data), [data, actions]);

	return data;
};
