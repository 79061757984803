import { generateDropZone } from '../forms/generateDropZone';
import { generateCatalog } from '../forms/generateCatalog';
import { generateCatalogWithCanvas } from '../forms/generateCatalogWithCanvas';
import { v4 as uuid } from 'uuid';
import { dimensions } from 'config/defaultDimensions.config';

export const generateStella = ({
	title = 'Гранит',
	value = 'granite',
	stone = 'Габбро Диабаз',
	sverlovka = 1,
	width,
	length,
	height,
	sizesStatus = true
}) => {
	const { width: defaultWidth, length: defaultLength, height: defaultHeight } = dimensions.stella;
	const unique_id = uuid();
	return {
		id: unique_id,
		title: title,
		value: value,
		stone: stone,
		selectedProducts: [],
		sverlovka: sverlovka,
		individualSizes: {
			status: sizesStatus,
			values: {
				width: width ?? defaultWidth,
				length: length ?? defaultLength,
				height: height ?? defaultHeight
			}
		},
		form: {
			form: generateCatalog(),
			clienForm: generateDropZone(),
			decoration: generateCatalogWithCanvas(false, ['front']),
			decorationFaska: generateCatalog(),
			ownFaska: generateDropZone(),
			frames: generateCatalog()
		},
		position: {
			x: 0,
			y: 0
		}
	};
};
