import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { v4 as uuid } from 'uuid';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import CheckBox from 'components/UI/input/checkbox';
import SelectInput from 'components/UI/input/select';
import TextInput from 'components/UI/input/text';
import SecondButton from 'components/UI/button/second';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

const workTypeArr = [
	{ value: 'area', title: 'Площадь' },
	{ value: 'number', title: 'Число' }
];

const Dismantling = () => {
	const actions = useActions();
	const { status, items, another } = useSelector(state => state.configurator.present.step4.dismantling);

	const onChangeToggle = useCallback(() => actions.changeDismantlingStatus(!status), [status]);
	const onChange = useCallback(object => actions.changeDismantlingItemValue({ ...object }), []);

	const [workName, setWorkName] = useState('');
	const [workAmount, setWorkAmount] = useState(1);
	const [workPrice, setWorkPrice] = useState(0);
	const [workType, setWorkType] = useState('area');

	const resetStates = useCallback(() => {
		setWorkName('');
		setWorkAmount(1);
		setWorkPrice(0);
		setWorkType('area');
	}, []);

	const onClickAddAnotherWork = useCallback(() => {
		if (workName === '') return;
		actions.addDismantlingItem({
			value: workAmount,
			status: true,
			typeItem: 'another',
			price: workPrice,
			addText: true,
			text: workName,
			type: workType,
			label: workType === 'area' ? 'Площадь работ' : 'Количество работ'
		});
		resetStates();
	}, [actions, workAmount, workPrice, workName, workType, resetStates]);

	const selectTitle = useMemo(() => workTypeArr.find(el => el.value === workType), [workType, workTypeArr]);

	useEffect(resetStates, [resetStates]);

	const anotherWorkArr = useMemo(
		() => [
			{
				value: workName,
				id: uuid(),
				type: 'text',
				label: 'Название работ',
				onChange: value => setWorkName(value)
			},
			{
				value: workAmount,
				id: uuid(),
				type: 'number',
				label: 'Количество работ',
				onChange: value => setWorkAmount(value)
			},
			{
				value: workPrice,
				id: uuid(),
				type: 'number',
				label: 'Стоимость работ',
				onChange: value => setWorkPrice(value)
			}
		],
		[workName, workAmount, workPrice]
	);

	return (
		<BlockWithToggle title='Демонтаж' openState={status} onChangeToggle={onChangeToggle}>
			{items.map((el, index) => (
				<CheckBox
					key={index}
					title={el.text}
					defaultChecked={el.status}
					name={el.text}
					id={el.text}
					value={el.value}
					addText={el.addText}
					addTextLabel={el.label}
					onChange={e =>
						onChange({
							text: el.text,
							status: e.target.checked,
							value: el.value,
							price: el.price
						})
					}
					onChangeText={value =>
						onChange({
							text: el.text,
							status: el.status,
							value: value,
							price: el.price
						})
					}
					addTextClassName={el.type !== 'number' ? 'sidebar__radio__block--values__volume' : ''}
				>
					{el.status && (
						<div className='checkbox__additation__text'>
							<InputTypeNumber
								blockClasses='withCurrency'
								label='Введите стоимость'
								defaultValue={el.price}
								name={`own__form__input__${el.text}`}
								id={`own__form__input__${el.text}`}
								onChange={price =>
									onChange({
										text: el.text,
										status: el.status,
										value: el.value,
										price: price
									})
								}
								max={99999}
								useDelay={false}
							/>
						</div>
					)}
				</CheckBox>
			))}

			<CheckBox title='Другое' defaultChecked={another} name='Другое' id='Другое' onChange={e => actions.changeAnotherStatus(!another)} />

			{another && (
				<>
					{anotherWorkArr.map(item => (
						<TextInput
							key={item.id}
							label={item.label}
							name={item.id}
							id={item.id}
							onChange={item.onChange}
							defaultValue={item.value}
							type={item.type}
							useDelay={false}
						/>
					))}
					<SelectInput title={selectTitle.title} items={workTypeArr} onClick={value => setWorkType(value.value)} />
					<SecondButton title='Добавить тип работ' classes='mb12 box__value__add' onClick={onClickAddAnotherWork} />
				</>
			)}
		</BlockWithToggle>
	);
};

export default Dismantling;
