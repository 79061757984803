import { findBurial } from '../../step1Slice';

import { generateMetalTable } from 'utils/helpers/tables/generateMetalTable';
import { generateOwnTable } from 'utils/helpers/tables/generateOwnTable';
import { generateCeramTable } from 'utils/helpers/tables/generateCeramTable';
import { generateGlassTable } from 'utils/helpers/tables/generateGlassTable';
import { generateGranitTable } from 'utils/helpers/tables/generateGranitTable';

const step3Tables = () => {
	const changeCurrentTab = (state, action) => {
		const burial = findBurial(state);
		burial.tablesCurrentTab = action.payload;
	};

	const setItemsGranitTablePortrait = (state, action) => {
		const burial = findBurial(state);
		const { actionType, type, index, value, elInTable } = action.payload;
		const currentTableItem = burial.tables[type].currentItem;
		if (actionType == 'create') {
			burial.tables[type].items[currentTableItem][elInTable].items =
				burial.tables[type].items[currentTableItem][elInTable].items.concat(value);
		} else {
			burial.tables[type].items[currentTableItem][elInTable].items = burial.tables[type].items[currentTableItem][elInTable].items.filter(
				(el, i) => index != i
			);
		}
		burial.tables[type].items[currentTableItem][elInTable].currentPortrait =
			burial.tables[type].items[currentTableItem][elInTable].items.length - 1;
	};

	const generateTable = (state, action) => {
		const burial = findBurial(state);
		const { type, actionType, tableType, formType, size } = action.payload;
		if (actionType == 'create') {
			if (type == 'metal' && tableType !== 'own')
				burial.tables[type].items = burial.tables[type].items.concat(generateMetalTable(type, formType, size));
			if (type == 'ceramic' && tableType !== 'own')
				burial.tables[type].items = burial.tables[type].items.concat(generateCeramTable(type, formType, size));
			if (type == 'glass' && tableType !== 'own')
				burial.tables[type].items = burial.tables[type].items.concat(generateGlassTable(type, formType, size));
			if (type == 'granit' && tableType !== 'own')
				burial.tables[type].items = burial.tables[type].items.concat(generateGranitTable(type, formType, size));
			if (tableType === 'own') burial.tables[type].items = burial.tables[type].items.concat(generateOwnTable(tableType));
			burial.tables[type].currentItem = burial.tables[type].items.length - 1;
		} else {
			burial.tables[type].items = burial.tables[type].items.filter((el, index) => index != burial.tables[type].currentItem);
			burial.tables[type].currentItem = burial.tables[type].items.length != 0 ? burial.tables[type].items.length - 1 : 0;
		}
	};

	const changeCurrentTableItem = (state, action) => {
		const burial = findBurial(state);
		const { type, itemNumber } = action.payload;
		burial.tables[type].currentItem = itemNumber;
	};

	const setTableValue = (state, action) => {
		const burial = findBurial(state);
		const { type, obj, include, value } = action.payload;
		const currentTableItem = burial.tables[type].currentItem;
		if (include != null) {
			burial.tables[type].items[currentTableItem][obj][include] = value;
		} else {
			burial.tables[type].items[currentTableItem][obj] = value;
		}
	};

	const setItemsFioDateTable = (state, action) => {
		const burial = findBurial(state);
		const { type, obj, include, value } = action.payload;
		const currentTableItem = burial.tables[type].currentItem;
		if (include != null) {
			burial.tables[type].items[currentTableItem].fio.items[obj][include] = value;
		} else {
			burial.tables[type].items[currentTableItem].fio.items[obj] = value;
		}
	};

	const setItemsFioDateTableItems = (state, action) => {
		const burial = findBurial(state);
		const { actionType, type, obj, index, include, value, elInTable = 'fio' } = action.payload;
		const currentTableItem = burial.tables[type].currentItem;
		if (actionType != null) {
			if (actionType == 'create') {
				burial.tables[type].items[currentTableItem][elInTable].items[obj].items =
					burial.tables[type].items[currentTableItem][elInTable].items[obj].items.concat(value);
			} else {
				burial.tables[type].items[currentTableItem][elInTable].items[obj].items = burial.tables[type].items[currentTableItem][
					elInTable
				].items[obj].items.filter((el, i) => index != i);
			}
		} else {
			if (include != null) {
				burial.tables[type].items[currentTableItem][elInTable].items[obj].items[index][include] = value;
			} else {
				burial.tables[type].items[currentTableItem][elInTable].items[obj].items[index] = value;
			}
		}
	};

	const changePortraitData = (state, action) => {
		const burial = findBurial(state);
		const { type, index, include, value, elInTable = 'fio' } = action.payload;
		const currentTableItem = burial.tables[type].currentItem;
		if (include != null) {
			burial.tables[type].items[currentTableItem][elInTable].items[index][include] = value;
		} else {
			burial.tables[type].items[currentTableItem][elInTable].items[index] = value;
		}
	};

	return {
		changeCurrentTab,
		setItemsGranitTablePortrait,
		generateTable,
		changeCurrentTableItem,
		setTableValue,
		setItemsFioDateTable,
		setItemsFioDateTableItems,
		changePortraitData
	};
};

export default step3Tables;
