import React, { useMemo } from 'react';

import useArrayFromLenght from 'hooks/useArrayFromLenght';

import PopUpValuesItem from './PopUpValuesItem';

const PopUpValues = ({ boxesLength, pedestalItemsLength, burial }) => {
	const boxesArrLengthUp = useArrayFromLenght(boxesLength + 1, true);
	const items = useMemo(
		() => ({
			position: burial.boxes.position.items,
			max: burial.boxes.position.max,
			renderItems: boxesArrLengthUp
		}),
		[boxesArrLengthUp, burial.boxes]
	);

	return (
		<div className='popup-position__values'>
			{items.renderItems.map((item, i) => {
				const { position, max } = items;
				const value = position[item.index];
				return (
					<PopUpValuesItem
						key={i}
						item={item}
						boxesLength={boxesLength}
						pedestalItemsLength={pedestalItemsLength}
						max={max}
						positionItems={position}
						value={value}
					/>
				);
			})}
		</div>
	);
};

export default PopUpValues;
