import React from 'react';

const PopUp = ({ children, state = false, onClick, classes = 'configurator__light-box', title = '' }) => {
	const classNames = 'configurator__light-box_close --svg__close';

	return (
		<div className={`${classes} ${state ? 'active' : ''}`}>
			<a className={classNames} onClick={onClick}></a>
			{title !== '' && title}
			<div className='configurator__light-box__content configurator__light-box__content--list'>{children}</div>
		</div>
	);
};

export default PopUp;
