import React, { memo } from 'react';
import cn from 'classnames';

const StepItem = ({ step, clickHandle }) => {
	return (
		<div
			onClick={() => clickHandle(step.id)}
			className={cn('configurator__steps__item', {
				active: step.isActive
			})}
		>
			<div className='configurator__steps__item__number'>{step.id}</div>
			<span>{step.title}</span>
		</div>
	);
};

export default memo(StepItem);
