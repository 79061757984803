import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useBurialsData = () => {
	const burials = useSelector(state => state.configurator.present.step1.burials);
	const currentBurial = useSelector(state => state.configurator.present.step1.currentBurial);

	const burial = useMemo(() => burials[currentBurial], [burials, currentBurial]);
	const burialsImproved = useMemo(() => burials?.filter(el => el.selected === 'improved'), [burials]);

	const currentBurialImproved = useMemo(() => burialsImproved?.findIndex(el => el?.id == burial?.id), [burial?.id, burialsImproved]);
	const firstImprovedBurialIndex = useMemo(() => burials?.findIndex(el => el?.id === burialsImproved[0]?.id), [burials, burialsImproved]);
	const indexNextBurialInBurials = useMemo(
		() => burials?.findIndex(el => el?.id == burialsImproved[currentBurialImproved + 1]?.id),
		[burials, burialsImproved, currentBurialImproved]
	);

	const data = useMemo(
		() => ({
			burials,
			currentBurial,
			burial,
			burialsImproved,
			currentBurialImproved,
			indexNextBurialInBurials,
			firstImprovedBurialIndex
		}),
		[burial, burials, burialsImproved, currentBurial, currentBurialImproved, firstImprovedBurialIndex, indexNextBurialInBurials]
	);

	return data;
};

export default useBurialsData;
