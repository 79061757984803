import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { religions } from 'components/step1/Religion';

import { debounce } from 'lodash';
import { useActions } from './useActions';
import useBurialsData from './useBurialsData';
import { useResetSteps } from 'hooks/resetSteps';

import { burialEvents } from '../utils/events/burial';
import { dispatchEvent } from '../utils/events/dispatchEvent';
import { step1Events } from 'utils/events/steps/step1';
import { step5Events } from 'utils/events/steps/step5';

const updateBurialDebounce = debounce(burialEvents.updateBurials, 300);
export const useApiHook = () => {
	const actions = useActions();
	const { burials, burial, currentBurial } = useBurialsData();
	const categories = useSelector(state => state.configurator.present.configuratorSteps.categories.materials);
	const currentTab = useSelector(state => state.configurator.present.step3.currentSidebarTab);
	const steps = useSelector(state => state.configurator.present.configuratorSteps.steps);
	const limit = useSelector(state => state.configurator.limit);
	const index = useSelector(state => state.configurator.index);
	// const hq = useSelector(state => state.configurator.present.step3.canvasHQ);
	const decoratedElements = useSelector(state => state.configurator.present.step5.decoratedElements);
	const { resetToDefaultThirdStep } = useResetSteps();
	const stepIndex = useMemo(
		() =>
			Object.values(steps)
				.reverse()
				.find(el => el.isActive === true).id - 1,
		[steps]
	);

	// steps data with out burials data
	const step1 = useSelector(state => {
		const data = state.configurator.present.step1;

		return {
			installation: data.installation,
			religion: data.religion,
			workZone: data.workZone,
			defaultComplect: data.defaultComplect
		};
	});

	//TODO скажи что без этого ломается
	// useEffect(() => {
	// 	if (!burial || stepIndex !== 1) return;
	// 	resetToDefaultThirdStep({ value: 'standart', noChangeProducts: true });
	// 	step2Events.badChange('standard', burial.id);
	// 	step2Events.changeMaterial(burial.material.value, burial.id);
	// }, [burial?.material]);

	//TODO скажи что без этого ломается
	// useEffect(() => {
	// 	if (!burial || stepIndex !== 1) return;
	// 	const material = categories.find(el => el.title === burial.materialStone);
	// 	if (material) step2Events.changeStone(burial.material.value, material.material, burial.id);
	// }, [burial?.materialStone, categories]);

	//TODO скажи что без этого ломается
	// useEffect(() => {
	// 	if (!burial) return;
	// 	const currentTomb = burial?.tombstone.currentTomb;
	// 	const currentTombs = burial?.tombstone.items;
	// 	const currentEl = currentTombs?.[currentTomb];
	// 	if (!currentEl) return;
	// 	const { width, length, height } = currentEl.individualSizes.values;
	// 	const type = parseInt(currentTomb) === 0 ? 'marble' : 'granit';
	// 	const material = categories.find(el => el.title === currentEl?.stone);
	// 	if (material) tombstoneEvents.materialChange({ type, material: material.material, tombId: currentTomb, graveId: burial.id });
	// 	componentsEvent.setTombstone({ tombId: currentTomb, graveId: burial.id, width, length, height });
	// }, [burial?.tombstone.items, burial?.tombstone.currentTomb]);

	// history effects

	// изменение количества захоронений
	// useEffect(() => step1Events.amountChange(burials.length), [burials.length]);

	// изменения размера захоронения
	// я это событие не слушаю
	// useEffect(() => {
	// 	burials.forEach(burial => {
	// 		step1Events.changeDimensions('height', { width: burial.areaValues.width, length: burial.areaValues.length }, burial.id);
	// 	});
	// }, [burials]);

	// установка дефолтного комплекта
	useEffect(() => {
		if (step1.defaultComplect.granite && stepIndex === 0) {
			actions.changeSelectedProductsComplect({
				...step1.defaultComplect.granite,
				state: {
					all: true,
					step: 2,
					module: 'CatalogType'
				}
			});
			actions.selectComponentProduct({ items: [], all: true });
		}
	}, [burials.length, step1.defaultComplect, stepIndex, step1.installation, step1.religion]);

	// рабочия зона
	useEffect(() => {
		step1Events.workAreaChange(step1.workZone.open ? 'working_zone_dimensions' : 'working_zone_whole');
		step1Events.amountChange(1);
		if (step1.workZone.open) {
			step1Events.workZoneDimensionChange('height', step1.workZone.zoneValues.height);
			step1Events.workZoneDimensionChange('width', step1.workZone.zoneValues.width);
		}
	}, [step1.workZone]);

	// верование
	useEffect(() => {
		const currentReligion = religions.find(religion => religion.title === step1.religion);
		if (currentReligion) step1Events.changeReligion(currentReligion.id);
	}, [step1.religion]);

	// способ монтажа
	useEffect(() => {
		if (step1.installation === 'Самовывоз') {
			step1Events.workAreaChange('working_zone_whole');
			step1Events.pickUpSelfDelivery();
		} else if (step1.installation === 'Установка памятника') {
			step1Events.amountChange(1);
			step1Events.pickUpInstallation();
		}
	}, [step1.installation]);

	// end history effects

	// Bubrials
	useEffect(() => {
		if (!burials) return;

		updateBurialDebounce(burials, categories, index, limit);
	}, [burials, index, limit]);

	// Steps
	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let target = null;
		let burialId = null;
		// eslint-disable-next-line default-case
		switch (stepIndex) {
			case 0:
				break;
			case 1:
				burialId = currentBurial;
				break;
			case 2:
				return;
			case 3:
				break;
			case 4:
				break;
			case 5:
				break;
		}
		dispatchEvent(`setCameraTarget`, { target: null, burialId });
	}, [stepIndex, currentBurial]);

	// Tabs
	useEffect(() => {
		let target = null;
		// eslint-disable-next-line default-case
		switch (currentTab) {
			case 0:
			case 1:
				target = 'pedestal';
				break;
			case 2:
			case 6:
				target = 'stella';
				break;
			case 3:
			case 4:
			case 5:
			case 7:
			case 8:
				return;
		}
		dispatchEvent(`setCameraTarget`, { target, currentBurial });
	}, [currentTab, currentBurial]);

	useEffect(() => {
		step5Events.updateDecoratedElements(decoratedElements);
	}, [decoratedElements]);
	// useEffect(() => {
	// 	window.Konva.pixelRatio = hq ? 2 * devicePixelRatio : 1;
	// }, [hq]);
};
