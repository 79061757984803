import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import './styles/style.scss';

const App = () => {
	return (
		<div className='App'>
			<Router basename={process.env.PUBLIC_URL ?? ''}>
				<Switch>
					<Route exact path='/'>
						<Home />
					</Route>
				</Switch>
			</Router>
		</div>
	);
};

export default App;
