import React, { useCallback, useMemo, useRef } from 'react';

import NotificationPortal from 'components/UI/notification/NotificationPortal';

import { useToggle } from '@mantine/hooks';
import { useActions } from './useActions';
import useBurialsData from 'hooks/useBurialsData';

import { resetingElements } from 'config/resetingElements';

export const useNotification = ({ callback, type, currentElement, currentIndexElement }) => {
	const ref = useRef();
	const actions = useActions();
	const [show, toggle] = useToggle();
	const burialsData = useBurialsData();

	const showNotification = useMemo(() => {
		const data = resetingElements[type]
			.map(({ dependencies, title }) => ({
				status: dependencies(currentElement),
				title: title
			}))
			.filter(({ status }) => status);

		return { status: data.length !== 0, data: data.map(({ title }) => title) };
	}, [currentElement, type, resetingElements]);

	const hideHandler = useCallback(() => {
		toggle(false);
		if (ref.current.state) {
			const { value, setValue } = ref.current.state;
			setValue(value);
		}
	}, []);

	const callbackHandler = useCallback(() => {
		if (ref.current.callback) ref.current.callback();
		callback(...ref.current.values);
		if (!showNotification.status) return;
		// reseting
		resetingElements[type].forEach(item => {
			const func = actions[item.func];
			if (func) func(item.value(currentIndexElement));
			if (item.callback) item.callback(burialsData.burial.id);
		});
		toggle(false);
	}, [callback, type, currentIndexElement, showNotification, burialsData.burial.id]);

	const showHandler = useCallback(
		data => {
			ref.current = data;
			if (!showNotification.status) return callbackHandler();
			toggle(true);
		},
		[showNotification, callbackHandler]
	);

	const children = useCallback(() => {
		return (
			show && (
				<NotificationPortal
					heading='Сброс параментов'
					text='При изменении данного свойства, некоторые свойства элементы сбросятся.'
					type='changing'
					onClickNoHandler={hideHandler}
					onClickYesHander={callbackHandler}
					list={showNotification.data}
				/>
			)
		);
	}, [callbackHandler, show, showNotification]);

	return useMemo(() => ({ Notification: children, notificationToggle: showHandler }), [children, showHandler]);
};
