import React, { useMemo, useCallback, memo } from 'react';
import { useTransformers } from '../hooks/useTransform';
import { Layer, Transformer } from 'react-konva';
import { transformersConfig } from './../utils/transformersConfig';
import { getTotalBox } from '../utils/limitDragginFunctions';
import { OFFSET } from './../constans';

const Transformers = ({ selectedId, layer, stage, proportional, data, changeData, parentRef, selectedItem }) => {
	const elRef = useMemo(() => {
		if (!selectedId) return;
		const childrens = layer.current?.getChildren();
		const el = childrens.find(el => el.attrs.id === selectedId);
		return {
			el: el,
			alignCenter: el.attrs.alignCenter,
			resizeDisable: el.attrs.resizeDisable,
			type: selectedItem.type
		};
	}, [layer, selectedId, selectedItem]);

	const { trRef } = useTransformers({
		isSelected: selectedId,
		ref: { current: elRef?.el },
		parentRef
	});

	const onDragMoveHandler = useCallback(() => {
		let newData = [...data];
		const boxes = trRef.current.nodes().map(node => node.getClientRect());
		const box = getTotalBox(boxes);
		const stageDimensions = {
			width: stage.current.width(),
			height: stage.current.height()
		};

		trRef.current.nodes().forEach(shape => {
			if (selectedId !== shape.id()) return;

			const absPos = shape.getAbsolutePosition();
			// where are shapes inside bounding box of all shapes?
			const offsetX = box.x - absPos.x;
			const offsetY = box.y - absPos.y;

			// we total box goes outside of viewport, we need to move absolute position of shape
			const newAbsPos = { ...absPos };
			if (box.x < OFFSET) {
				newAbsPos.x = -offsetX + OFFSET;
			}
			if (box.y < OFFSET) {
				newAbsPos.y = -offsetY + OFFSET;
			}
			if (box.x + box.width > stageDimensions.width - OFFSET) {
				newAbsPos.x = stageDimensions.width - box.width - offsetX - OFFSET;
			}
			if (box.y + box.height > stageDimensions.height - OFFSET) {
				newAbsPos.y = stageDimensions.height - box.height - offsetY - OFFSET;
			}

			shape.setAbsolutePosition(newAbsPos);

			newData = data.map(el => {
				if (el.id === selectedId) return { ...el, ...shape.getPosition() };
				return el;
			});
		});
		changeData(newData);
	}, [trRef, stage, changeData, data]);

	return (
		selectedId &&
		elRef && (
			<Layer>
				<Transformer
					ref={trRef}
					onDragMove={onDragMoveHandler}
					{...transformersConfig({
						stage: stage.current,
						alignCenter: elRef.alignCenter,
						resizeDisable: elRef.resizeDisable,
						type: elRef.type,
						proportional
					})}
				/>
			</Layer>
		)
	);
};

export default memo(Transformers);
