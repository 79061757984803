import React from 'react';

import { useComplectCartData } from 'utils/cart/complectCartData';

import styles from './CartList.module.scss';
import { convertStringToPrice } from 'utils/convertStringToPrice';

const CartList = ({ cart }) => {
	const data = useComplectCartData(cart);

	return (
		<div className={styles.cartList}>
			{data.cartData.map((el, i) => (
				<div key={i} className={styles.cartList__item}>
					<h5>{el.heading}</h5>
					<div className={styles.list}>
						{el?.list.map((item, l) => {
							const arr = [item];
							if (item.drilling) arr.push(item.drilling);

							return arr.map((r, i) =>
								r.size ? (
									<div key={[l, i].join()} className={styles.item}>
										<h6>
											{r.title}
											{r.size && ` | ${r.size}`}
										</h6>
										<p>{item.count}</p>
										<p>{convertStringToPrice(r.price)}</p>
									</div>
								) : (
									<div key={[l, i].join()} className={styles.item}>
										<h6>{r.title}</h6>
										{r.count && <p>{r.count}</p>}
										<p>{convertStringToPrice(r.price)}</p>
									</div>
								)
							);
						})}
					</div>
				</div>
			))}
			<div className={styles.total}>
				{data.total.map((item, l) => (
					<div key={l} className={styles.item}>
						<h6> </h6>
						<p>{item.title}</p>
						<p>{convertStringToPrice(item.price)}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default CartList;
