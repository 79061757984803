import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

const BackfillFlower = () => {
	const actions = useActions();
	const { status, price } = useSelector(state => state.configurator.present.step5.decoratedElements.backfillFlower);

	const onChangeToggleHandler = useCallback(() => {
		actions.changeFilling({
			type: 'backfillFlower',
			values: { status: !status, price }
		});
	}, [price, status]);

	const onChangePriceHandler = useCallback(
		price => {
			actions.changeFilling({
				type: 'backfillFlower',
				values: { status, price }
			});
		},
		[price, status]
	);

	return (
		<BlockWithToggle title='Засыпка цветника' openState={status} onChangeToggle={onChangeToggleHandler}>
			<InputTypeNumber
				blockClasses='own__form__input'
				label='Введите стоимость'
				defaultValue={price}
				name={`own__form__input__faska}`}
				id={`own__form__input__faska}`}
				onChange={onChangePriceHandler}
				useDelay={false}
			/>
		</BlockWithToggle>
	);
};

export default BackfillFlower;
