import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PrimaryButton from 'components/UI/button/primary';
import SidebarElementTab from 'components/UI/tabs/sidebarElementTab';
import SecondButton from 'components/UI/button/second';
import Pedestal from './Pedestal';
import Tombstone from './Tombstone';

import useBurialsData from 'hooks/useBurialsData';
import { useActions } from 'hooks/useActions';

import { ReactComponent as TombstoneIcon } from 'images/sidebar/Tombstone.svg';
import { ReactComponent as PedestalIcon } from 'images/sidebar/Pedestal.svg';

import '../Tables/tables.scss';
import { dispatchEvent } from '../../../utils/events/dispatchEvent';

const Engraving = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial, burials } = useBurialsData();
	const currentStella = burial.stella.items[burial.stella.currentStella];
	const currentTombstone = burial.tombstone.items[burial.tombstone.currentTomb];
	const tombstoneLength = useMemo(() => burial.tombstone.items.length, [burial.tombstone.items.length]);
	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [actions, currentTab]);

	const [activeTab, setActiveTab] = useState('ПАМЯТНИК');
	let tablesTabs = useMemo(() => {
		let tabs = [
			{ name: 'ПАМЯТНИК', type: 'stele', icon: <PedestalIcon /> },
			{ name: 'НАДГРОБ.', type: 'tombstone', icon: <TombstoneIcon /> }
		];

		if (tombstoneLength === 0) tabs = tabs.splice(0, 1);

		return tabs;
	}, [tombstoneLength]);

	const openCanvasHandler = useCallback(() => {
		const tab = tablesTabs.find(el => el.name === activeTab);
		const type = tab.type === 'stele' && burial.stella.currentStella != 0 ? 'second_stele' : tab.type;
		const currentEl = type.includes('stele') ? currentStella : currentTombstone;
		actions.toggleCanvasContainer({
			status: true,
			id: currentEl.id
		});
	}, [activeTab, burial.stella.currentStella, currentStella?.id, currentTombstone?.id, tablesTabs]);

	useEffect(() => {
		let target = null;
		switch (activeTab) {
			case 'ПАМЯТНИК':
				target = 'stella';
				break;
			case 'НАДГРОБ.':
				target = 'tombstone';
				break;
			default:
				break;
		}
		dispatchEvent(`setCameraTarget`, { target, currentBurial });
	}, [activeTab, currentBurial]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Гравировка</h1>
			<SidebarElementTab
				activeTab={activeTab}
				changeTab={setActiveTab}
				items={tablesTabs}
				burials={burials}
				currentBurial={currentBurial}
			/>
			{activeTab == 'ПАМЯТНИК' && <Pedestal burial={burial} />}
			{activeTab == 'НАДГРОБ.' && <Tombstone burial={burial} currentBurial={currentBurial} />}

			<SecondButton title='Расположение' id='edit__tables_position' onClick={openCanvasHandler} classes='mb24 box__value__edit' />

			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Engraving;
