import { v4 as uuid } from 'uuid';
import { tablesInstalationTypes, tablesFastingTypes } from 'config/tablesInstalationTypes';

export const generateGranitTable = (type = null, form = 'Квадратная', size = null) => {
	const unique_id = uuid();
	return {
		id: unique_id,
		type: type,
		rotated: false,
		size: size ?? null,
		form: {
			status: true,
			form
		},
		ownForm: {
			status: false,
			price: 0,
			img: []
		},
		fio: {
			status: false,
			items: null
		},
		portrait: {
			status: false,
			currentPortrait: 0,
			items: []
		},
		restoration: {
			status: false,
			price: 0
		},
		montaz: { type: tablesInstalationTypes.find(el => el.name === 'holders').title, price: 0 },
		fastening: {
			amount: tablesInstalationTypes[0].fastening[0],
			type: tablesFastingTypes[0].type,
			price: 0
		},
		price: 0
	};
};
