import React from 'react';
import { useSelector } from 'react-redux';

import { step4Events } from 'utils/events/steps/step4';
import { useActions } from 'hooks/useActions';

import SecondButton from 'components/UI/button/second';
import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ExcludePlaceItem from './ExcludePlaceItem';

const ExcludePlace = () => {
	const actions = useActions();
	const excludedAreas = useSelector(state => state.configurator.present.step1.excludeArea);

	return (
		<BlockWithToggle
			title='Исключить участок(и)'
			openState={excludedAreas.isOpen}
			onChangeToggle={e => {
				step4Events.enableExcludeArea(e.target.checked);
				actions.changeExcludeAreaOpen(e.target.checked);
				if (excludedAreas.areas.length == 0) actions.addExcludeItem();
			}}
		>
			{excludedAreas.areas.map(item => (
				<ExcludePlaceItem key={item.id} item={item} />
			))}
			<SecondButton
				onClick={() => {
					step4Events.addExcludeItem();
					actions.addExcludeItem();
				}}
			/>
		</BlockWithToggle>
	);
};

export default ExcludePlace;
