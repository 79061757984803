import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { data } from './constans';

export const complectsAPI = createApi({
	reducerPath: 'complectsAPI',
	tagTypes: ['Complects'],
	baseQuery: fetchBaseQuery({
		baseUrl: data.baseUrl,
		prepareHeaders: headers => {
			headers.set('Access-Control-Allow-Origin', '*');
			return headers;
		}
	}),
	endpoints: build => ({
		fetchComplectsByType: build.query({
			query: ({ type, args }) => ({
				url: '/sets',
				params: { ...args, set_tax: args.module_tax }
			}),
			transformResponse(apiResponse, meta) {
				return {
					apiResponse,
					totalCount: meta.response.headers.get('X-WP-TotalPages')
				};
			},
			providesTags: result => ['Complects']
		})
	})
});
