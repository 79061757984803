import { flowerEvents } from 'utils/events/flower';
import { tombstoneEvents } from 'utils/events/tombstone';
// import { tablesEvents } from 'utils/events/tables';

export const resetFlowers = (items, id) => {
	items.forEach((flower, index) => {
		if (index === 0) return;
		flowerEvents.deleteSecondFlower({
			flowerType: 'second',
			id, // burial id
			flowerID: flower.id
		});
	});
};

export const resetTombstones = (items, id) => {
	items.forEach((tomb, index) => {
		tombstoneEvents.removeTombstone({ id, tombId: index });
	});
};

export const resetTables = (tables, id) => {
	const items = Object.values(tables)
		.map(el => el.items)
		.flat();
	items.forEach(table => {
		// tablesEvents.removePlate(table.id, id);
	});
};
