import { stellaEvents } from './stella';
import { tombstoneEvents } from './tombstone';

export const componentsEvent = {
	setStella({ id, length, width, height, type }) {
		stellaEvents.stellaWidth(type, width, id, true);
		stellaEvents.stellaLength(type, length, id, true);
		stellaEvents.stellaHeight(type, height, id, true);
	},
	setTombstone({ tombId, graveId, width, length, height }) {
		tombstoneEvents.changeLeftRightWidth({ width: length, graveId, tombId });
		tombstoneEvents.changeBackWidth({ width, graveId, tombId });
		tombstoneEvents.changeHeight({ height, graveId, tombId });
	}
};
