import axios from 'axios';
import { useCallback } from 'react';

const useGetParametrs = () => {
	const getModuleProducts = useCallback(async (type = 'pedestal') => {
		try {
			const response = await axios.get(`data/modules/${type}.json`);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}, []);

	return {
		getModuleProducts
	};
};

export default useGetParametrs;
