// eslint-disable-next-line no-unused-vars
import { getClientRect } from './limitDragginFunctions';
// eslint-disable-next-line no-unused-vars
import { OFFSET } from './../constans';

const proportionalCorners = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
const anotherCorners = [...proportionalCorners, 'top-center', 'middle-right', 'middle-left', 'bottom-center'];

export const transformersConfig = ({ stage, alignCenter, resizeDisable, type, proportional }) => {
	if (type === 'image') {
		return {
			rotateEnabled: alignCenter ?? true,
			flipEnabled: alignCenter ?? true,
			enabledAnchors: resizeDisable ? [] : proportional ? proportionalCorners : anotherCorners,
			boundBoxFunc: (oldBox, newBox) => {
				// const box = getClientRect(newBox);
				// const stageDimensions = {
				// 	width: stage.current.width(),
				// 	height: stage.current.height()
				// };
				// const isOut =
				// 	box.x < OFFSET ||
				// 	box.y < OFFSET ||
				// 	box.x + box.width > stageDimensions.width - OFFSET ||
				// 	box.y + box.height > stageDimensions.height - OFFSET;

				// if (isOut) return oldBox;

				if (newBox.width < 5 || newBox.height < 5) return oldBox;
				return newBox;
			}
		};
	} else if (type === 'text') {
		return {
			rotateEnabled: false,
			flipEnabled: false,
			enabledAnchors:
				stage.memorizedStageDimensions === 'stele' ? ['middle-left', 'middle-right'] : alignCenter ? [] : ['middle-left', 'middle-right'],
			boundBoxFunc: (oldBox, newBox) => {
				newBox.width = Math.max(30, newBox.width);
				return newBox;
			}
		};
	} else {
		return {
			rotateEnabled: false,
			flipEnabled: false,
			enabledAnchors: [],
			boundBoxFunc: (oldBox, newBox) => {
				newBox.width = Math.max(30, newBox.width);
				return newBox;
			}
		};
	}
};
