import axios from 'axios';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { configuratorSliceActions } from 'slices/configuratorStepsSlice';
import { step1SliceActions } from 'slices/step1Slice';
import { step2SliceActions } from 'slices/step2Slice';
import { step3SliceActions } from 'slices/step3Slice';
import { step4SliceActions } from 'slices/step4Slice';
import { step5SliceActions } from 'slices/step5Slice';
import { step6SliceActions } from 'slices/step6Slice';
import { canvasSliceActions } from 'slices/canvasSlice';

import { createCombinedAction } from 'redux-scc';
import { ActionCreators } from 'redux-undo';

import { setComplect } from 'utils/helpers/complects/setComplect';
import { asyncWrapper } from 'utils/asyncWrapper';

import { data } from 'services/constans';
import store from '../store';
import { getInstallation } from 'utils/helpers/complects/getInstallation';
import { stellaEvents } from 'utils/events/stella';

const actionsObj = {
	...configuratorSliceActions,
	...step1SliceActions,
	...step2SliceActions,
	...step3SliceActions,
	...step4SliceActions,
	...step5SliceActions,
	...step6SliceActions,
	...canvasSliceActions
};

const params = (id, polishing) => ({
	set_tax: id,
	module_tax: id,
	page: 1,
	per_page: 1,
	polishing
});

export const useActions = () => {
	const dispatch = useDispatch();
	const actions = useMemo(() => bindActionCreators(actionsObj, dispatch), [dispatch]);

	// catalog complects
	const setComplectByType = async ({ title, materialID, selectedType, materialType = 'granite', selectedItem, polishing }) => {
		const res = await asyncWrapper(async () => {
			const actionsArr = [];
			const selectedId = selectedType === 'standart' ? 9 : 8;
			const products = await axios.get(data.baseUrl + '/sets', {
				params: { ...params(selectedId, polishing), material: materialID }
			});
			if (products.data[0]) {
				const values = await setComplect(products.data[0], null, materialType);
				actionsArr.push({
					action: 'selectComponentProduct',
					values: [{ items: values.components, type: selectedType }]
				});
				if (selectedItem && selectedItem?.id === values.id) return actionsArr;
				actionsArr.push({
					action: 'changeSelectedProducts',
					values: [{ ...values, state: { step: 2, module: 'CatalogType' } }]
				});
			} else if (selectedItem) {
				actionsArr.push({
					action: 'changeSelectedProducts',
					values: [{ ...selectedItem, state: { step: 2, module: 'CatalogType' } }]
				});
			}

			actionsArr.push({
				action: 'changeMaterialStone',
				values: [title]
			});
			return actionsArr;
		});
		return res;
	};

	// pedestal
	const checkForDeleteSecondPedestal = ({ currentPedestal, amountElements, amountBoxes }) => {
		const arr = [];
		if (currentPedestal === 0) {
			if (amountElements >= 2 && currentPedestal == 0) {
				arr.push({
					action: 'removePedestal',
					values: []
				});
			}
			if (amountBoxes !== 0) {
				arr.push({
					action: 'changeBoxesAmount',
					values: [0]
				});
			}
		}
		return arr;
	};
	const checkChangeFlowerStatus = ({ flowersLength }) => {
		const arr = [];
		if (flowersLength !== 0) {
			arr.push({
				action: 'changecurrentFlower',
				values: [0]
			});
			arr.push({
				action: 'changeFlowerType',
				values: [
					{
						name: flowersLength === 1 ? 'standard' : 'separate',
						type: flowersLength === 1 ? 'standard' : 'separate',
						currentFlower: 0
					}
				]
			});
		}
		return arr;
	};
	// stella
	const checkForDeleteSecondStella = ({ amountElements, currentStella }) => {
		if (amountElements >= 2 && currentStella === 0) {
			return [{ action: 'removeStella', values: [] }];
		} else {
			return [];
		}
	};

	const historyActions = {
		// step 1
		changeHistoryInstallationMethod: (type, title) => {
			const arr = [actions.changeHowManyBurials(1), actions.changeInstallation(title)];
			if (type === 'pickup') {
				arr.push(actions.changeBurialSelectedState({ id: 0, value: 'improved' }));
				arr.push(actions.changeWorkZoneOpen(false));
			}

			const aCombinedAction = createCombinedAction({
				name: 'Installation method',
				actions: arr
			});

			dispatch(aCombinedAction);
		},
		changeHistoryWorkZone: ({ render, state }) => {
			dispatch(
				createCombinedAction({
					name: 'Work Zone state change',
					actions: [actions.changeWorkZoneOpen(state), actions.renderChangeHowManyBurials(render), actions.changeHowManyBurials(1)]
				})
			);
		},
		changeHistoryHowManyBurials: amount => {
			dispatch(
				createCombinedAction({
					name: 'Change burials length',
					actions: [actions.changeHowManyBurials(amount)]
				})
			);
		},
		changeHistoryBurialState: ({ id, value }) => {
			dispatch(
				createCombinedAction({
					name: 'Change burial state',
					actions: [actions.changeBurialSelectedState({ id, value })]
				})
			);
		},
		changeHistoryBurialDimensions: ({ value, id, type }) => {
			dispatch(
				createCombinedAction({
					name: 'Change burial dimensions',
					actions: [actions.changeBurialAreaValues({ value, id, type })]
				})
			);
		},
		// step 2
		changeHistoryMaterial: ({ title, value, id, defaultComplect }) => {
			dispatch(
				createCombinedAction({
					name: 'Change burial material',
					actions: [
						actions.changeMaterialType({ title, value, id }),
						actions.changeSelectedProducts({
							...defaultComplect,
							state: { step: 2, module: 'CatalogType' }
						}),
						actions.selectComponentProduct({ items: defaultComplect?.components || [] }),
						actions.changeCatalogTypeFirst('standart')
					]
				})
			);
		},
		changeHistoryStone: async props => {
			const actionsArr = await setComplectByType(props);
			dispatch(
				createCombinedAction({ name: 'Change burial stone', actions: actionsArr.map(item => actions[item.action](...item.values)) })
			);
		},
		changeHistoryPolishing: async props => {
			const actionsArr = await setComplectByType(props);
			dispatch(
				createCombinedAction({
					name: 'Change burial polishing',
					actions: [...actionsArr.map(item => actions[item.action](...item.values)), actions.changePolishing(props.polishing)]
				})
			);
		},
		changeHistoryCatalogSelection: async ({ title, materialID, selection, materialType, selectedItem, polishing }) => {
			const actionsArr = await setComplectByType({ title, materialID, selectedType: selection, materialType, selectedItem, polishing });
			dispatch(
				createCombinedAction({
					name: 'Change burial catalog type',
					actions: [...actionsArr.map(item => actions[item.action](...item.values)), actions.changeCatalogTypeFirst(selection)]
				})
			);
		},
		changeHistorySelectedProduct: values => {
			dispatch(
				createCombinedAction({
					name: 'Change burial catalog product',
					actions: [
						actions.changeSelectedProducts({
							...values,
							state: { step: 2, module: 'CatalogType' }
						}),
						actions.selectComponent({ items: values.components })
					]
				})
			);
		},
		// step 3

		// pedestal
		onToggleHistoryPedestalSizes: ({
			statusState,
			status,
			width,
			length,
			height,
			selectedProducts,
			currentPedestal,
			amountElements,
			amountBoxes
		}) => {
			const arr = [];

			if (statusState === true) {
				arr.push(actions.changePedestalIndividualStatus(!statusState));
			} else {
				if (selectedProducts.length !== 0) arr.push(actions.changePedestalSelectedProducts(selectedProducts[0]));
				arr.push(
					actions.changePedestalIndividualSizes({
						status: status,
						values: { width: parseFloat(width), length: parseFloat(length), height: parseFloat(height) }
					})
				);
			}

			const deleteSecondPedestalCheck = checkForDeleteSecondPedestal({ currentPedestal, amountElements, amountBoxes });

			dispatch(
				createCombinedAction({
					name: 'Toggle pedestal sizes toggle',
					actions: [...arr, ...deleteSecondPedestalCheck.map(item => actions[item.action](...item.values))]
				})
			);
		},
		onChangeHistoryPedestalSizes: ({ status, width, length, height, currentPedestal, amountElements, amountBoxes, clickedOnProduct }) => {
			const arr = [
				actions.changePedestalIndividualSizes({
					status: status,
					values: { width: parseFloat(width), length: parseFloat(length), height: parseFloat(height) }
				})
			];

			if (clickedOnProduct) arr.push(actions.changePedestalSelectedProducts(clickedOnProduct));

			const deleteSecondPedestalCheck = checkForDeleteSecondPedestal({ currentPedestal, amountElements, amountBoxes });

			dispatch(
				createCombinedAction({
					name: 'Change pedestal sizes',
					actions: [...arr, ...deleteSecondPedestalCheck.map(item => actions[item.action](...item.values))]
				})
			);
		},
		addPedestalHistoryHandler: ({ flowersLength }) => {
			const arr = [actions.changeBoxesAmount(0), actions.addPedestal()];

			if (flowersLength !== 0) {
				arr.push(actions.changecurrentFlower(0));
				arr.push(
					actions.changeFlowerType({
						name: flowersLength === 1 ? 'standard' : 'separate',
						type: flowersLength === 1 ? 'standard' : 'separate',
						currentFlower: 0
					})
				);
			}

			dispatch(createCombinedAction({ name: 'Add Pedestal', actions: arr }));
		},
		removePedestalHistoryHandler: ({ pedestalLength, flowersLength }) => {
			const arr = [actions.removePedestal()];

			if (pedestalLength === 1) {
				arr.push(actions.changeBoxesAmount(2));
				arr.push(actions.changeCurrentSidebarTab(1));
			}

			const checkFlowerTypeChange = checkChangeFlowerStatus({ flowersLength });
			dispatch(
				createCombinedAction({
					name: 'Remove Pedestal',
					actions: [...arr, ...checkFlowerTypeChange.map(item => actions[item.action](...item.values))]
				})
			);
		},
		// boxes
		removeBoxHandler: ({ check }) => {
			const arr = [actions.removeBox()];
			if (check) {
				arr.push(actions.addPedestal());
				arr.push(actions.changeCurrentSidebarTab(0));
			}
			dispatch(createCombinedAction({ name: 'Remove Box', actions: arr }));
		},
		toggleHistoryBoxesPosition: ({ status }) => {
			dispatch(
				createCombinedAction({
					name: 'Toggle boxes position',
					actions: [actions.changeBoxPositionStatus(!status), actions.changeBoxPopUpValues()]
				})
			);
		},
		// stella
		addHistoryStella: async ({ material, values, stellaItemsLength, burialId }) => {
			const installationPrice = await getInstallation(
				material,
				{ width: values.width, thickness: values.length, height: values.height },
				stellaItemsLength
			);
			const arr = [actions.addStella(), actions.changeTileAreaPrice(installationPrice)];
			stellaEvents.addStella(0, burialId);
			setTimeout(() => stellaEvents.changeAlignStella(burialId, 'parallel'), 500);

			dispatch(createCombinedAction({ name: 'Create stella', actions: arr }));
		},
		onChangeHistoryStellaDimensions: async ({ status, values, sendEvents, stellaItemsLength, currentStella, material, setProduct }) => {
			const installationPrice = await getInstallation(
				material,
				{ width: values.width, thickness: values.length, height: values.height },
				stellaItemsLength
			);
			const arr = [actions.changeStellaIndividualSizes({ individual: { status, values } }), actions.changeTileAreaPrice(installationPrice)];

			if (setProduct && setProduct !== null) arr.push(actions.changeStellaSelectedProducts(setProduct));

			if (sendEvents) {
				const data = checkForDeleteSecondStella({
					amountElements: stellaItemsLength,
					currentStella
				});
				arr.push(data.map(item => actions[item.action](...item.values)));
			}

			dispatch(createCombinedAction({ name: 'Change stalla dimensions', actions: arr }));
		},
		// flowers
		onChangeHistoryFlowersDimensions: (values, setProduct) => {
			const arr = [actions.changeFlowerIndividualSizes(values)];
			if (setProduct && setProduct !== null) {
				arr.push(
					values.type === 'poperehna'
						? actions.changeFlowerSelectedProductsPoperehna(setProduct)
						: actions.changeFlowerSelectedProductsProdolna(setProduct)
				);
			}

			dispatch(createCombinedAction({ name: 'Change flowers dimensions', actions: arr }));
		},
		// tombstone
		onChangeHistoryTombstoneDimenstions: (values, setProduct) => {
			const arr = [actions.changeListIndividualSizes(values)];
			if (setProduct && setProduct !== null) arr.push(actions.changeListSelectedProducts(setProduct));

			dispatch(createCombinedAction({ name: 'Change tombstone dimensions', actions: arr }));
		},
		// forms
		onToggleFaskaForm: data => {
			dispatch(
				createCombinedAction({
					name: `Change faska status`,
					actions: data.map(el => actions.changeFormElements(el))
				})
			);
		},
		// engraving
		universalEngravingPortraitHandler: ({ data, tab }) => {
			dispatch(
				createCombinedAction({
					name: `Change portrait data`,
					actions: [
						actions.setEngravingValue({ obj: 'portrait', include: 'currentPortrait', value: tab, historySkip: true }),
						...data.map(item => actions[item.action](...item.values))
					]
				})
			);
		},

		// general
		clearHistory: () => store.dispatch(ActionCreators.clearHistory())
	};

	return useMemo(() => ({ ...actions, ...historyActions }), []);
};
