import React, { useCallback, useMemo } from 'react';
import { useActions } from 'hooks/useActions';

import CatalogForm from 'components/UI/form/catalogForm';
import LoadForm from 'components/UI/form/loadForm';

import useBurialsData from 'hooks/useBurialsData';
import { worksAPI } from 'services/Works';
import { withCustomPrice } from '../HOC/withCustomPrice';
import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';

const FaskaModule = ({
	type,
	currentElement,
	children,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	isFetching,
	isError,
	changedData,
	customPrice,
	changeCustomPrice
}) => {
	const actions = useActions();
	const { burial } = useBurialsData();
	const stellaHeight = useMemo(
		() => burial.stella.items[burial.stella.currentStella].individualSizes.values.height,
		[burial.stella.currentStella]
	);

	const items = useMemo(() => burial[type].items, [burial[type].items]);

	const getValueItem = useCallback(
		(moduleObject, moduleType) => items[currentElement].form[moduleObject][moduleType],
		[items, currentElement]
	);

	const ownFaskaStatus = getValueItem('ownFaska', 'status');
	const ownFaskaImages = getValueItem('ownFaska', 'img');
	const ownFaskaValue = getValueItem('ownFaska', 'price');

	const decorationFaskaStatus = getValueItem('decorationFaska', 'status');
	const selectedProductsDecorationFaska = getValueItem('decorationFaska', 'selectedProducts');

	const refetchDataHandler = useCallback(
		(refetch, action) => {
			if (type === 'stella' && !action) {
				return refetch({ args: { work_tax: 38, size_height: stellaHeight / 10, per_page: 100 } }, true);
			}
			refetch({ args: { work_tax: 41, per_page: 100 } }, true);
		},
		[stellaHeight, type]
	);

	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (selectedProductsDecorationFaska.length === 0 || price === undefined) return;
			actions.changeFormElements({
				type,
				current: currentElement,
				moduleObject: 'decorationFaska',
				moduleType: 'selectedProducts',
				value: [{ ...selectedProductsDecorationFaska[0], price: { unit: parseFloat(price) } }]
			});
		},
		[selectedProductsDecorationFaska]
	);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price: selectedProductsDecorationFaska?.[0]?.price?.unit ?? 0,
		changeCustomPrice
	});

	return (
		<>
			<CatalogForm
				formTitle='Декоративная фаска'
				advancedPopUpType='list'
				formState={decorationFaskaStatus}
				onToggle={() => {
					actions.onToggleFaskaForm([
						{
							type,
							current: currentElement,
							moduleObject: 'decorationFaska',
							moduleType: 'status',
							historySkip: true,
							value: !decorationFaskaStatus
						},
						{
							type,
							current: currentElement,
							moduleObject: 'ownFaska',
							historySkip: true,
							value: { status: false, price: 0, img: [] }
						}
					]);
				}}
				onClickProductCard={item => {
					const include = selectedProductsDecorationFaska.some(el => el.sku === item.sku);
					const price = item.price?.unit ?? customPrice;
					actions.changeFormElements({
						type,
						current: currentElement,
						moduleObject: 'decorationFaska',
						moduleType: 'selectedProducts',
						value: include ? [] : [{ ...item, price: { unit: price } }]
					});
				}}
				btnTitle='Каталог фасок'
				products={changedData.items}
				hook
				isLoading={isFetching}
				isError={isError}
				refetch={refetchData}
				selectedProducts={selectedProductsDecorationFaska}
			>
				{children}
			</CatalogForm>
			<LoadForm
				formTitle='Своя форма фаски'
				formState={ownFaskaStatus}
				onToggle={() => {
					actions.onToggleFaskaForm([
						{
							type,
							current: currentElement,
							moduleObject: 'ownFaska',
							moduleType: 'status',
							historySkip: true,
							value: !ownFaskaStatus
						},
						{
							type,
							current: currentElement,
							moduleObject: 'decorationFaska',
							historySkip: true,
							value: {
								status: false,
								selectedProducts: []
							}
						}
					]);
				}}
				label='Введите стоимость'
				inputValue={ownFaskaValue}
				onInput={value => {
					actions.changeFormElements({
						type,
						current: currentElement,
						moduleObject: 'ownFaska',
						moduleType: 'price',
						value: value
					});
				}}
				images={ownFaskaImages}
				onDrop={files => {
					actions.changeFormElements({
						type,
						current: currentElement,
						moduleObject: 'ownFaska',
						moduleType: 'img',
						value: files
					});
				}}
			/>
		</>
	);
};

const withCustomPriceComponent = withCustomPrice(FaskaModule, worksAPI.useLazyFetchWorksQuery);
export default withCustomPriceComponent;
