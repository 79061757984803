import React, { useCallback } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import PrimaryButton from 'components/UI/button/primary';

import { useActions } from 'hooks/useActions';

const Sizes = ({ currentTableSize, loading, type, sizes }) => {
	const actions = useActions();
	const onChangeSizeHandler = useCallback(el => actions.setTableValue({ type, obj: 'size', value: el }), [actions, type]);

	return (
		<BlockWithArrow title='Размеры' blockClasses='sidebar__radio__block classForTablesForm'>
			{loading && <h2 className='message'>Загрузка ...</h2>}
			{!loading && !(sizes ?? []).length && <h2 className='message'>Не найдено</h2>}
			{!loading &&
				(sizes ?? []).map((el, index) => (
					<PrimaryButton
						key={el.title + index}
						title={el.title}
						onClick={onChangeSizeHandler.bind(this, el)}
						className={`btn ${el.title === currentTableSize?.title ? 'isActiveSize' : ''}`}
					/>
				))}
		</BlockWithArrow>
	);
};

export default Sizes;
