export const setGoToStepMessage = ({ checkBadBurials, clicked, activeStep, improvedBurials, willLostSteps }) => {
	const checkBadBurialsIds = checkBadBurials.map(el => el.id).join(', ');
	const burialsCheckSpells = arr => (arr.length !== 1 ? 'захоронениях' : 'захоронении');
	const stepsCheckSpells = willLostSteps.length !== 1 ? 'шагов' : 'шага';
	const willLostStepsSting = willLostSteps.join(', ');

	let message = {
		heading: '',
		text: '',
		type: 'warning',
		status: false
	};

	if (activeStep === 1 && improvedBurials.length === 0) {
		message = {
			heading: 'Не найдено',
			text: `Не 1 из захоронений не установлено как облагораживаемое!`,
			type: 'warning',
			status: true
		};
	} else if (activeStep > clicked && ![3, 4, 5].includes(clicked)) {
		message = {
			heading: 'Сброс параметров',
			text: `При переходе на ${clicked} шаг, настройки с ${stepsCheckSpells} ${willLostStepsSting} будут потеряны`,
			type: 'alert',
			status: true
		};
	} else if (activeStep === 2 && checkBadBurials.length !== 0) {
		message = {
			heading: 'Не выбран элемент',
			text: `В ${burialsCheckSpells(checkBadBurials)} ${checkBadBurialsIds} не выбран тип комплекта или сам комплект.`,
			type: 'warning',
			status: true
		};
	} else if (activeStep === 3 && checkBadBurials.length !== 0) {
		const badBasic = checkBadBurials.filter(el => el.step3.graveBasic === false);
		const badStella = checkBadBurials.filter(el => el.step3.stella === false);
		const badFlowers = checkBadBurials.filter(el => el.step3.flowers === false);
		const badTombstone = checkBadBurials.filter(el => el.step3.tombstone === false);

		let stepMessage = null;

		[
			{ obj: badTombstone, message: 'установлен размер надгробной плиты или не выбран из комплектов' },
			{ obj: badFlowers, message: 'установлен размер цветника или не выбран из комплектов' },
			{ obj: badStella, message: 'установлен размер стелы или не выбран из комплектов' },
			{ obj: badBasic, message: 'установлена тумба / ее размер или кубы' }
		].map(el => {
			if (el.obj.length !== 0) stepMessage = el;
		});

		if (stepMessage !== null) {
			const badIds = stepMessage.obj.map(el => el.id).join(', ');
			message = {
				heading: 'Не выбран элемент',
				text: `В ${burialsCheckSpells(stepMessage.obj)} ${badIds} не ${stepMessage.message}`,
				type: 'warning',
				status: true
			};
		}
	} else if (checkBadBurials.length !== 0) {
		message = {
			heading: 'Не установлены значения',
			text: `В ${burialsCheckSpells(checkBadBurials)} ${checkBadBurialsIds} не все параметры установлены что б перейти на шаг ${clicked}`,
			type: 'warning',
			status: false
		};
	}

	return message;
};
