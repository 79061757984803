import React, { useCallback } from 'react';

import { v4 as uuid } from 'uuid';

import PrimaryButton from 'components/UI/button/primary';
import TextInput from 'components/UI/input/text';
import SelectInput from 'components/UI/input/select';

import { useActions } from 'hooks/useActions';

import { ReactComponent as Basket } from 'images/icons/basket.svg';
import { ReactComponent as Left } from 'images/align/left.svg';
import { ReactComponent as Right } from 'images/align/right.svg';
import { ReactComponent as Center } from 'images/align/center.svg';
import { fontSizeTransform, fonts, defaultFontSizeTransform } from 'config/fontSizeTransform.config';
import { tablesTextColors } from 'config/canvas.config';
import { standaloneSize } from 'utils/standaloneSize';

const FioTab = ({ data, type, currentTableFioColor }) => {
	const actions = useActions();
	const unique_id = uuid();
	const { font, align, items } = data;
	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));

	const FontOptions = fonts;

	const changeData = useCallback(
		(value, include) => actions.setItemsFioDateTable({ type, obj: 'fio', include: include, value: value }),
		[actions, type]
	);

	const changeItemsData = useCallback(
		(actionType = null, value, include, index) => {
			actions.setItemsFioDateTableItems({ actionType, type, obj: 'fio', include, value, index });
		},
		[actions, type]
	);

	return (
		<>
			<div className='box'>
				<SelectInput title={currentTableFioColor} items={tablesTextColors} onClick={value => changeData(value, 'color')} />
				<div className='row'>
					<SelectInput title={font.title} items={FontOptions} onClick={value => changeData(value, 'font')} />
					<div className='align-btns'>
						<button className={`${align == 'left' ? 'active' : ''}`} onClick={e => changeData('left', 'align')}>
							<Left />
						</button>
						<button className={`${align == 'center' ? 'active' : ''}`} onClick={e => changeData('center', 'align')}>
							<Center />
						</button>
						<button className={`${align == 'right' ? 'active' : ''}`} onClick={e => changeData('right', 'align')}>
							<Right />
						</button>
					</div>
				</div>
				{items.map((el, index) => {
					const isLast = index + 1 === items.length;

					return (
						<div className='row' key={index}>
							{
								<TextInput
									type='text'
									id={`name_${index}`}
									name={`name_${index}`}
									defaultValue={el.value}
									blockClasses='restavrationInput'
									onChange={value => changeItemsData(null, value, 'value', index)}
									useDelay={false}
								/>
							}
							<div className='flex'>
								<SelectInput
									title={standaloneSize(el.fontSize)}
									items={options}
									onClick={value => changeItemsData(null, value.value, 'fontSize', index)}
								/>
								<button disabled={!(!isLast || items.length !== 1)} onClick={value => changeItemsData('remove', value, 'fontSize', index)}>
									<Basket />
								</button>
							</div>
						</div>
					);
				})}
			</div>
			<PrimaryButton
				title='Добавить строку'
				onClick={() =>
					changeItemsData('create', {
						value: 'Текст',
						fontSize: defaultFontSizeTransform,
						id: unique_id
					})
				}
				className='btn box__value__add'
			/>
		</>
	);
};

export default FioTab;
