import { dispatchEvent } from './dispatchEvent';

export const stellaEvents = {
	addStella(grave_index, child_index = 0) {
		// grave_index = Burials[currentBurial].id
		return dispatchEvent('create_child_stele', { child_index, grave_index });
	},
	deleteStella(child_index = 0, grave_index, notReply) {
		// grave_index = Burials[currentBurial].id
		return dispatchEvent('delete_child_stele', { child_index, grave_index, notReply });
	},
	stellaWidth(stellaType = 'second', width, id, notReply = false) {
		// id = currentBurial;
		let event = stellaType === 'second' ? 'second_stele_width_changed' : 'stele_width_changed';

		return dispatchEvent(event, { width, id, notReply });
	},
	stellaLength(stellaType = 'second', length, id, notReply = false) {
		// id = currentBurial;
		let event = stellaType === 'second' ? 'second_stele_length_changed' : 'stele_length_changed';

		return dispatchEvent(event, { length, id, notReply });
	},
	stellaHeight(stellaType = 'second', height, id, notReply = false) {
		// id = currentBurial;
		let event = stellaType === 'second' ? 'second_stele_height_changed' : 'stele_height_changed';

		return dispatchEvent(event, { height, id, notReply });
	},
	changeAlignStella(grave_index, alignment) {
		return dispatchEvent('alignment_changed', { grave_index, alignment });
	},
	steleModelChanged(graveId, index) {
		return dispatchEvent('stele_model_changed', { graveId, index });
	},

	// position stella
	oneStellaPosition(grave_index, position) {
		return dispatchEvent('one_stele_position', {
			grave_index,
			delta_left: position[0],
			delta_top: position[1],
			delta_right: position[2]
		});
	},
	twoStelesInLine(grave_index, position) {
		return dispatchEvent('two_steles_in_a_line', {
			grave_index,
			delta_left: position[0],
			delta_top_1: position[1],
			delta_between: position[2],
			delta_top_2: position[3],
			delta_right: position[4]
		});
	},
	twoStelesInParalel(type, grave_index, position) {
		let obj = {};
		if (type == 1) {
			obj = {
				grave_index,
				delta_left_1: position[0],
				delta_top_1: position[1],
				delta_between: position[2],
				delta_left_2: position[3],
				delta_bottom_2: position[4]
			};
		} else if (type == 2) {
			obj = {
				grave_index,
				delta_left_2: position[3],
				delta_right_1: position[1],
				delta_between: position[2],
				delta_top_1: position[0],
				delta_bottom_2: position[4]
			};
		} else {
			obj = {
				grave_index,
				delta_left_1: position[0],
				delta_top_1: position[1],
				delta_between: position[2],
				delta_bottom_2: position[3],
				delta_right_2: position[4]
			};
		}
		return dispatchEvent(`two_steles_in_parallel_${type}`, obj);
	}
};
