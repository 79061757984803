import { calculateCanvasSize } from 'components/UI/canvas/utils/calculateCanvasSize';

export const collectBurialDataForCanvas = el => {
	if (!el) return [];
	const { metal, ceramic, glass, granit } = el.tables;
	const granitItems = [...granit.items].map(item => {
		const portraitItems = item.portrait.items.filter(el => el.img.length !== 0).map(el => ({ ...el, img: el.img[0].preview }));

		return {
			...item,
			portrait: { ...item.portrait, items: portraitItems }
		};
	});
	const tables = [
		...metal.items,
		...ceramic.items,
		...glass.items.map(el => ({
			id: el.id,
			fakeportrait: el.fakeportrait,
			type: el.type,
			form: el.form,
			rotated: el.rotated,
			size: el.size
		})),
		...granitItems
	]
		.filter(el => el.type !== 'own')
		.map(el => {
			if (!el.size) return null;
			let { width, height } = el.size.values;
			if (el.rotated) [width, height] = [height, width];
			// const obj = calculateCanvasSize({ width, height }, 'plate');
			const obj = {
				containerWidth: width,
				containerHeight: height,
				scale: 1,
				width: width,
				height: height,
				type: 'table'
			};
			return {
				...el,
				canvasType: 'plate',
				stageDimensions: obj
			};
		});
	const stellas = [...el.stella.items].map((el, i) => {
		// const obj = calculateCanvasSize(el.individualSizes.values, 'stella');
		const width = el.individualSizes.values.width;
		const height = el.individualSizes.values.height;
		const obj = {
			containerWidth: width * 2,
			containerHeight: height,
			scale: 1,
			width: width * 2,
			height: height,
			type: i === 0 ? 'stele' : 'second_stele'
		};
		return {
			...el,
			canvasType: i === 0 ? 'stele' : 'second_stele',
			stageDimensions: obj
		};
	});
	const tombstone = [...el.tombstone.items].map(el => {
		// const dimensions = el.individualSizes.values;
		const width = el.individualSizes.values.width;
		const height = el.individualSizes.values.length;
		// const obj = calculateCanvasSize({ width: dimensions.width, height: dimensions.length });
		const obj = {
			containerWidth: width,
			containerHeight: height,
			scale: 1,
			width: width,
			height: height,
			type: 'tombstone'
		};
		return {
			...el,
			canvasType: 'tombstone',
			stageDimensions: obj
		};
	});

	return [...tables, ...stellas, ...tombstone].filter(el => el !== null).map(el => ({ ...el, data: [] }));
};
