import { dispatchEvent } from '../dispatchEvent';

export const step2Events = {
	changeMaterial(material, id) {
		// id - burial id
		return dispatchEvent('material_changed', {
			material,
			id
		});
	},
	changeStone(type, material, id) {
		// id - burial id
		return dispatchEvent(`${type}_grade_changed`, {
			material,
			id
		});
	},
	changePolishing(grave_index, polishing) {
		return dispatchEvent('polishing_changed', {
			grave_index,
			polishing
		});
	},
	badChange(state, id) {
		return dispatchEvent('bed_changed', {
			state,
			id
		});
	}
};
