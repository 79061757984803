import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './popUpPosition.scss';

const PopUpPositionBox = ({ indexEl, onDragEnd, elementPos, isDragDisabled }) => {
	const [dataIndex, setDataIndex] = useState([
		{ id: 0, status: 'disable' },
		{ id: 1, status: 'general' },
		{ id: 2, status: 'disable' }
	]);

	const moveElement = useCallback((array, initialIndex, finalIndex) => {
		array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
		return array;
	}, []);

	useEffect(() => {
		const arr = [
			{ id: 0, status: 'disable' },
			{ id: 1, status: 'general' },
			{ id: 2, status: 'disable' }
		];
		const array = moveElement(
			arr,
			arr.findIndex(el => el.status == 'general'),
			elementPos + 1
		);
		setDataIndex(array);
	}, [elementPos, moveElement]);

	const handleOnDragEnd = useCallback(
		result => {
			if (!result.destination) return;
			if (isDragDisabled) return;
			const items = Array.from(dataIndex);
			const [reorderedItem] = items.splice(result.source.index, 1);
			items.splice(result.destination.index, 0, reorderedItem);
			setDataIndex(items);
			onDragEnd(result.destination.index - 1);
		},
		[dataIndex, isDragDisabled, onDragEnd]
	);

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId={`box__${indexEl}`}>
				{provided => (
					<div className='popup-position__boxes__wrapper__item' {...provided.droppableProps} ref={provided.innerRef}>
						{dataIndex.map((item, index) => (
							<Draggable key={`${index + 1}`} draggableId={`${index + 1}`} isDragDisabled={isDragDisabled} index={index}>
								{provided => (
									<div
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										ref={provided.innerRef}
										className={`popup-position__boxes__item ${item.status}`}
									>
										{item.status != 'disable' ? indexEl : null}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default PopUpPositionBox;
