import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import TextInput from 'components/UI/input/text';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

const items = ['Менее 20 м', 'Более 20 м'];

const Takeaway = () => {
	const actions = useActions();
	const { type, length, price } = useSelector(state => state.configurator.present.step6.takeaway);
	const check = el => el === 'Более 20 м' && el === type;

	const onChangeTypeHandler = useCallback(e => actions.changeTakeAway({ type: e.target.dataset.value, length: 0 }), []);
	const onChangeValueHandler = useCallback(value => actions.changeTakeAway({ type, length: value }), [type]);
	const onChangeCustomPrice = useCallback(price => actions.changeTakeAway({ type, length, price }), [type, length]);

	return (
		<BlockWithArrow title='Донос до места' blockClasses='sidebar__radio__block'>
			{items.map((el, i) => (
				<div className='checkbox_wrapper' key={el}>
					<RadioInput
						title={el}
						defaultChecked={el === type}
						onChange={onChangeTypeHandler}
						name={`installation_${i}`}
						id={`installation_${i}`}
						value={el}
						classNames={items.length === i + 1 && check(el) ? 'mb12' : ''}
					/>
					{check(el) && (
						<>
							<div className='checkbox__additation__text'>
								<TextInput
									blockClasses='sidebar__radio__block--values__length__m'
									label='Ввести расстояние'
									name={`text_${el}`}
									id={`text_${el}`}
									onChange={onChangeValueHandler}
									required={true}
									type='number'
									disableLabel={true}
									defaultValue={length}
									useDelay={false}
								/>
							</div>
							<InputTypeNumber
								blockClasses='own__form__input mt12'
								label='Введите стоимость'
								defaultValue={price}
								name={`own__form__input__delivery}`}
								id={`own__form__input__delivery}`}
								onChange={onChangeCustomPrice}
								max={99999}
								useDelay={false}
							/>
						</>
					)}
				</div>
			))}
		</BlockWithArrow>
	);
};

export default Takeaway;
