import React, { useCallback, useMemo, memo } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import TextInput from 'components/UI/input/text';

import { useActions } from 'hooks/useActions';
import { useNotification } from 'hooks/useNotification';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';

const BoxSize = ({ burial, currentBox, pedestalAmount }) => {
	const actions = useActions();
	const { height, width, depth } = useMemo(() => burial.boxes.items[currentBox].size, [currentBox, burial.boxes.items]);

	const onChangeDimensions = useCallback((type, value) => {
		switch (type) {
			case 'width':
				actions.changeBoxWidth(value);
				break;
			case 'height':
				actions.changeBoxHeight(value);
				break;
			case 'depth':
				actions.changeBoxDepth(value);
				break;
			default:
				console.log('case is not found');
		}
	}, []);

	const notificationToggleHandler = useCallback((type, value, state) => {
		notificationToggle({
			values: [type, value],
			state
		});
	}, []);

	const { Notification, notificationToggle } = useNotification({
		callback: onChangeDimensions,
		type: 'boxes',
		currentElement: burial.boxes.items[currentBox],
		currentIndexElement: currentBox
	});

	return (
		<BlockWithArrow
			title='Размер куба:'
			blockClasses='sidebar__radio__block sidebar__radio__block__value--individual'
			contentClasses='configurator__light-box__content--list'
		>
			<Notification />
			<div className='exception__item'>
				<div className='sidebar__radio__block--values'>
					<TextInput
						blockClasses='sidebar__radio__block--values__height'
						label='Высота'
						defaultValue={height}
						name='box_size_height'
						id='box_size_height'
						required={true}
						readOnly={false}
						onChange={notificationToggleHandler.bind(null, 'height')}
						useDelay={false}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Глубина'
						defaultValue={width}
						name='box_size_width'
						id='box_size_width'
						required={true}
						onChange={notificationToggleHandler.bind(null, 'width')}
						useDelay={false}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Ширина'
						defaultValue={depth}
						name='box_size_depth'
						id='box_size_depth'
						required={true}
						onChange={notificationToggleHandler.bind(null, 'depth')}
						useDelay={false}
					/>
					<span>мм</span>
				</div>
			</div>
		</BlockWithArrow>
	);
};

export default memo(BoxSize);
