import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchUserById = createAsyncThunk('user/fetchUserById', async (userId, thunkAPI) => {
	return await fetch(`https://swapi.dev/api/people/${userId}`).then(res => res.json());
});

const user = {
	name: '',
	mass: 0,
	gender: ''
};

const UserSlice = createSlice({
	name: 'user',
	initialState: { user },
	reducers: {},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(fetchUserById.fulfilled, (state, action) => {
			// Add user to the state
			state.user = action.payload;
		});
	}
});

// Action creators are generated for each case reducer function
// export const { fetchUserById } = UserSlice.actions;

export default UserSlice.reducer;
