import React, { useLayoutEffect, useMemo, useState, memo } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import SecondButton from 'components/UI/button/second';
import AdvancedPopUp from 'components/UI/popup/advanced/popup';
import ActionBlock from 'components/UI/block/actionBlock';

const CatalogForm = ({
	formTitle = 'Декоративная фаска',
	formState = false,
	onToggle = () => {},
	onClick = () => {},
	onEditClick = () => {},
	btnTitle = 'Каталог фасок',
	products = [],
	onClickProductCard = () => {},
	selectedProducts = [],
	advancedPopUpType = 'standart',
	sortState = false,
	sortTypes,
	children,
	onBasketClick = () => {},
	refetch = () => {},
	hook = false,
	isLoading = false,
	isError = false,
	parentClasses = 'exception__block',
	plusState = false,
	onPlusHandler = () => {},
	forseOpen = false
}) => {
	const [popUpIsOpen, setPopUpIsOpen] = useState(false);
	const selectedProductsLength = useMemo(() => selectedProducts.length, [selectedProducts.length]);
	const forseOpenCheck = forseOpen && selectedProducts.length === 0 && popUpIsOpen === false;

	useLayoutEffect(() => {
		if (forseOpenCheck) setPopUpIsOpen(true);
	}, [forseOpenCheck]);

	useLayoutEffect(() => {
		if (!formState) setPopUpIsOpen(false);
	}, [formState]);

	return (
		<BlockWithToggle blockClasses={parentClasses} title={formTitle} openState={formState} onChangeToggle={onToggle}>
			{selectedProductsLength !== 0 &&
				selectedProducts.map((el, index) => (
					<ActionBlock
						key={index}
						title={el?.title}
						onEditClick={() => {
							onEditClick();
							setPopUpIsOpen(true);
						}}
						onBasketClick={() => {
							setPopUpIsOpen(false);
							onClickProductCard(el);
							onBasketClick(el);
						}}
						plusState={plusState}
						onPlusHandler={() => onPlusHandler(el)}
					/>
				))}
			<AdvancedPopUp
				openState={popUpIsOpen}
				changeState={setPopUpIsOpen}
				products={hook ? products : []}
				onClick={onClickProductCard}
				selectedProducts={selectedProducts}
				type={advancedPopUpType}
				sortState={sortState}
				sortTypes={sortTypes && sortTypes != null}
				hook={hook}
				refetch={hook ? refetch : products}
				isLoading={isLoading}
				isError={isError}
				closeAllowCheck={!(forseOpen && selectedProducts.length === 0)}
			/>
			{children}
			<SecondButton
				classes='--svg__book-before'
				onClick={() => {
					setPopUpIsOpen(true);
					onClick();
				}}
				title={btnTitle}
			/>
		</BlockWithToggle>
	);
};

export default memo(CatalogForm);
