import { useRef, useCallback, useMemo } from 'react';
import { useShallowEffect } from '@mantine/hooks';

export const useTransformers = ({ isSelected, ref, parentRef }) => {
	const trRef = useRef();

	const dblclickEventHandler = useCallback(
		e => {
			trRef.current.nodes().forEach(shape => {
				if (isSelected === shape.id()) shape.moveToTop();
			});
		},
		[trRef, isSelected]
	);

	useShallowEffect(() => {
		if (!parentRef.current) return;
		if (isSelected) {
			parentRef.current.addEventListener('dblclick', dblclickEventHandler);
		} else {
			parentRef.current.removeEventListener('dblclick', dblclickEventHandler);
		}
		return () => parentRef.current?.removeEventListener('dblclick', dblclickEventHandler);
	}, [parentRef, isSelected]);

	useShallowEffect(() => {
		if (trRef.current) {
			if (isSelected) {
				trRef.current.nodes([ref.current]);
				trRef.current.getLayer().batchDraw();
			} else {
				trRef.current.detach();
				trRef.current.getLayer().batchDraw();
			}
		}
	}, [isSelected, ref]);

	return useMemo(() => ({ trRef }), [trRef]);
};
