import React, { useCallback } from 'react';

import Portrait from 'components/UI/bigComponents/Portrait';

import { v4 as uuid } from 'uuid';

import { useActions } from 'hooks/useActions';
import { A3, A5 } from '../../../../config/sizes';

const PortraitTable = ({ type, currentPortraitStatus, currentPortrait, portraitItems }) => {
	const actions = useActions();

	const onToggleHandler = useCallback(() => {
		actions.setTableValue({
			type,
			obj: 'portrait',
			value: { status: !currentPortraitStatus, currentPortrait: 0, items: [] }
		});
	}, [currentPortraitStatus, actions, type]);

	const onTabChangeHandler = useCallback(
		tab => actions.setTableValue({ type, obj: 'portrait', include: 'currentPortrait', value: tab }),
		[actions, type]
	);

	const onChangeTypeHandler = useCallback(
		el => {
			actions.changePortraitData({
				actionType: null,
				type,
				elInTable: 'portrait',
				include: 'type',
				value: el,
				index: currentPortrait
			});
		},
		[currentPortrait, actions, type]
	);

	const onDropFileHandler = useCallback(
		file => {
			actions.changePortraitData({
				actionType: null,
				type,
				elInTable: 'portrait',
				include: 'img',
				value: [{ preview: URL.createObjectURL(file), name: file.name }],
				index: currentPortrait
			});
		},
		[currentPortrait, actions, type]
	);

	const addPortraitHandler = useCallback(() => {
		const unique_id = uuid();
		// TODO get template from settings
		actions.setItemsGranitTablePortrait({
			actionType: 'create',
			type,
			elInTable: 'portrait',
			value: { type: 'Стандартный', img: [], id: unique_id, align: 'center', fitHeight: A5.height, fitWidth: A5.width }
		});
	}, [actions, type]);

	const deletePortraitHandler = useCallback(() => {
		actions.setItemsGranitTablePortrait({
			actionType: 'remove',
			type,
			elInTable: 'portrait',
			index: currentPortrait
		});
	}, [currentPortrait, actions, type]);

	return (
		<Portrait
			openState={currentPortraitStatus}
			portraitCurrentItem={currentPortrait}
			portraitItems={portraitItems}
			onToggleHandler={onToggleHandler}
			onTabChangeHandler={onTabChangeHandler}
			onChangeTypeHandler={onChangeTypeHandler}
			onDropFileHandler={onDropFileHandler}
			addPortraitHandler={addPortraitHandler}
			deletePortraitHandler={deletePortraitHandler}
			moreThanOne={false}
		/>
	);
};

export default PortraitTable;
