import React, { useMemo } from 'react';

import FioAndDates from './FioAndDates';
import Epitafia from './Epitafia';
import PedestalCatalogForm from './CatalogForm';
import { fontSizeTransform } from 'config/fontSizeTransform.config';
import { getStelaTemplate } from '../../../../config/stelaTemplates.config';

const Pedestal = ({ burial }) => {
	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));
	const template = useMemo(() => {
		const stone = burial.stella.items[0].stone ?? burial.materialStone;

		return getStelaTemplate({
			stone,
			width: burial.stella.items[0].individualSizes.values.width,
			height: burial.stella.items[0].individualSizes.values.height,
			portrait: burial.engraving.portrait?.items?.length
		});
	}, [burial.stella, burial.engraving, burial.materialStone]);
	return (
		<>
			<PedestalCatalogForm burial={burial} template={template} />
			<FioAndDates burial={burial} options={options} template={template} />
			<Epitafia burial={burial} options={options} template={template} />
		</>
	);
};

export default Pedestal;
