import React, { useCallback, useMemo, useState } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import SecondButton from 'components/UI/button/second';
import PopUp from 'components/UI/popup/popup';
import TextInput from 'components/UI/input/text';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import DateInput from 'components/UI/input/date';
import RadioInput from 'components/UI/input/radio';
import SelectInput from 'components/UI/input/select';

import { generateFio } from 'utils/forms/generateFio';
import { useActions } from 'hooks/useActions';

import { textStylesTyle, numericTypes, tablesTextColors } from 'config/canvas.config';
import { fontSizeOptions } from 'config/fontSizeTransform.config';
import { standaloneSize } from 'utils/standaloneSize';

const FioAndDates = ({ currentTable, openState, type, template }) => {
	const actions = useActions();
	const [openFIOPop, setOpenFIOPop] = useState(openState);
	const currentTableFioStatus = useMemo(() => currentTable.fio.status, [currentTable.fio.status]);
	const currentTableFioColor = useMemo(() => currentTable.fio.color, [currentTable.fio.color]);
	const currentTableFioItems = useMemo(() => currentTable.fio.items, [currentTable.fio.items]);

	const fontItems = textStylesTyle;
	const numericItems = numericTypes;

	const changeData = useCallback(data => actions.setTableValue(data), [actions]);

	const onToggleHandler = useCallback(() => {
		changeData({
			type,
			obj: 'fio',
			value: { status: !currentTableFioStatus, color: currentTableFioColor, items: null }
		});
	}, [changeData, currentTableFioColor, currentTableFioStatus, type]);

	const onBasketClickHandler = useCallback(() => {
		setOpenFIOPop(false);
		changeData({
			type,
			obj: 'fio',
			value: { status: currentTableFioStatus, color: currentTableFioColor, items: null }
		});
	}, [changeData, currentTableFioColor, currentTableFioStatus, type]);

	const addFio = useCallback(
		e => {
			setOpenFIOPop(!openFIOPop);
			changeData({
				type,
				obj: 'fio',
				include: 'items',
				value: generateFio(template)
			});
		},
		[changeData, openFIOPop, type, template]
	);

	const onSelectColorHandler = useCallback(
		value => {
			changeData({
				type,
				obj: 'fio',
				include: 'color',
				value: value
			});
		},
		[changeData, type]
	);

	return (
		<BlockWithToggle
			title='ФИО + даты'
			openState={currentTableFioStatus}
			onChangeToggle={onToggleHandler}
			blockClasses='sidebar__radio__block classForTablesForm'
			contentClasses='editDelete'
		>
			{currentTableFioItems !== null && (
				<PopUp
					state={openFIOPop}
					title={<h3>Данные усопшего:</h3>}
					classes='deceasedData configurator__light-box'
					onClick={e => setOpenFIOPop(!openFIOPop)}
				>
					<TextInput
						type='text'
						label='Имя'
						id={currentTableFioItems.name}
						defaultValue={currentTableFioItems.name}
						blockClasses='restavrationInput'
						onChange={value => {
							changeData({
								type,
								obj: 'fio',
								include: 'items',
								value: { ...currentTableFioItems, name: value }
							});
						}}
						useDelay={false}
					/>
					<TextInput
						type='text'
						label='Фамилия'
						id={currentTableFioItems.surname}
						defaultValue={currentTableFioItems.surname}
						blockClasses='restavrationInput'
						onChange={value => {
							changeData({
								type,
								obj: 'fio',
								include: 'items',
								value: { ...currentTableFioItems, surname: value }
							});
						}}
						useDelay={false}
					/>
					<TextInput
						type='text'
						label='Отчество'
						id={currentTableFioItems.middleName}
						defaultValue={currentTableFioItems.middleName}
						blockClasses='restavrationInput'
						onChange={value => {
							changeData({
								type,
								obj: 'fio',
								include: 'items',
								value: { ...currentTableFioItems, middleName: value }
							});
						}}
						useDelay={false}
					/>
					<BlockWithArrow title='Размер:' openState={false} arrowDelete={true}>
						<SelectInput
							title={standaloneSize(currentTableFioItems.fontSize)}
							items={fontSizeOptions}
							onClick={value => {
								changeData({
									type,
									obj: 'fio',
									include: 'items',
									value: { ...currentTableFioItems, fontSize: value.value }
								});
							}}
						/>
					</BlockWithArrow>
					<BlockWithArrow title='Цвет:' openState={false} arrowDelete={true}>
						<SelectInput title={currentTableFioColor.title} items={tablesTextColors} onClick={onSelectColorHandler} />
					</BlockWithArrow>
					<BlockWithArrow title='Шрифт:' openState={false} arrowDelete={true}>
						{fontItems.map((el, index) => (
							<RadioInput
								key={index}
								title={el}
								id={el}
								onChange={e => {
									changeData({
										type,
										obj: 'fio',
										include: 'items',
										value: {
											...currentTableFioItems,
											fontStyle: el
										}
									});
								}}
								defaultChecked={currentTableFioItems.fontStyle == el ? true : false}
							/>
						))}
					</BlockWithArrow>
					<BlockWithArrow openState={false} arrowDelete={true} blockClasses='sidebar__radio__block deadDate'>
						<DateInput
							label='Дата рождения'
							id={'Дата рождения'}
							blockClasses='restavrationInput date'
							value={currentTableFioItems.dateOfBorn}
							onChange={(e, value) => {
								changeData({
									type,
									obj: 'fio',
									include: 'items',
									value: {
										...currentTableFioItems,
										dateOfBorn: value
									}
								});
							}}
						/>
						<DateInput
							label='Дата смерти'
							id={'Дата смерти'}
							blockClasses='restavrationInput date'
							value={currentTableFioItems.dateOfDeath}
							onChange={(e, value) => {
								changeData({
									type,
									obj: 'fio',
									include: 'items',
									value: {
										...currentTableFioItems,
										dateOfDeath: value
									}
								});
							}}
						/>
					</BlockWithArrow>
					<BlockWithArrow title='Формат цифр:' openState={false} arrowDelete={true}>
						{numericItems.map((el, index) => (
							<RadioInput
								key={index}
								title={el}
								id={el}
								onChange={e => {
									changeData({
										type,
										obj: 'fio',
										include: 'items',
										value: {
											...currentTableFioItems,
											numericFormat: el
										}
									});
								}}
								defaultChecked={currentTableFioItems.numericFormat == el ? true : false}
							/>
						))}
					</BlockWithArrow>
				</PopUp>
			)}

			{currentTableFioItems !== null && currentTableFioItems.name !== '' && (
				<ActionBlock title={currentTableFioItems.name} onEditClick={() => setOpenFIOPop(true)} onBasketClick={onBasketClickHandler} />
			)}

			{(currentTableFioItems === null || currentTableFioItems?.name === '') && (
				<SecondButton title='Добавить ФИО' onClick={addFio} classes='box__value__add' />
			)}
		</BlockWithToggle>
	);
};

export default FioAndDates;
