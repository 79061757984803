import { findBurial, changeSelectProductStatus } from 'slices/step1Slice';

const step5Reducers = () => {
	const changeTileArea = (state, action) => {
		const { isFullArea, type, height, price, cladding } = action.payload;

		if (!isFullArea) {
			const burial = findBurial(state);
			burial.improvement.tileArea = { type, height, price };
		} else {
			const claddingValue = height < 10 ? false : cladding;
			state.burials = state.burials.map(el => ({
				...el,
				improvement: { ...el.improvement, tileArea: { type, height, price }, cladding: claddingValue }
			}));
		}
	};

	const changeCladding = (state, action) => {
		const burial = findBurial(state);
		burial.improvement.cladding = action.payload;
	};

	const changePlateProducts = (state, action) => {
		const burial = findBurial(state);
		burial.improvement.plate = changeSelectProductStatus(burial.improvement.plate, action.payload, true);
	};

	return {
		changeTileArea,
		changeCladding,
		changePlateProducts
	};
};

export default step5Reducers;
