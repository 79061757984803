export const generateEngravingForTombstone = () => {
	return {
		type: 'Ручная',
		engraving: {
			status: false,
			items: []
		},
		epitafia: {
			status: false,
			price: 0,
			items: null
		},
		сoatingWithEngraving: {
			status: false,
			type: 'Золотая краска'
		}
	};
};
