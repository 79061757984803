import { flatten } from './../flatten';
import { blobToBase64 } from './../blobToBase64';
import { deflate } from 'pako';
import { data as serviceData } from 'services/constans';

const checkElements = {
	pedestal: ['clienForm', 'ownFaska'],
	boxes: ['ownFaska'],
	stella: ['clienForm', 'ownFaska'],
	tombstone: ['clienForm', 'ownFaska'],
	tables: {
		granit: [
			{ value: 'ownForm', check: 'img' },
			{ value: 'portrait', check: 'items' }
		]
	},
	burial: {
		engraving: [
			{ value: 'ownForm', check: 'img' },
			{ value: 'portrait', check: 'items' }
		]
	}
};

export const collectCustomFields = async burial => {
	const data = flatten(
		Object.entries(checkElements).map(([key, values]) => {
			if (key === 'burial') {
				return (values = Object.entries(values).map(([key, arrValue]) => {
					return arrValue.map(value => {
						const item = burial[key][value.value];
						return item.status && item[value.check].length
							? item[value.check].map(el => {
									const obj = { ...el, price: parseFloat(item.price ?? 0).toFixed(2) };
									if (Array.isArray(el.img)) {
										const newObj = { ...obj, ...obj.img[0] };
										delete newObj.img;
										return newObj;
									}
									return obj;
							  })
							: [];
					});
				}));
			}

			if (key === 'tables') {
				return (values = Object.entries(values).map(([key, arrValue]) => {
					return arrValue.map(value => {
						return burial.tables[key].items.map(table => {
							const item = table[value.value];
							return item.status && item[value.check].length
								? item[value.check].map(el => {
										const obj = { ...el, price: parseFloat(item.price ?? 0).toFixed(2) };
										if (Array.isArray(el.img)) {
											const newObj = { ...obj, ...obj.img[0] };
											delete newObj.img;
											return newObj;
										}
										return obj;
								  })
								: [];
						});
					});
				}));
			}

			return burial[key].items.map(item =>
				values.map(value => {
					const data = item.form[value];
					if (!(data.status && data.img.length)) return [];

					return data.img.map(el => {
						const price = parseFloat(data.price || el.price || 0).toFixed(2);
						const obj = { ...el, price };
						if (data.type) obj.type = data.type;
						return obj;
					});
				})
			);
		})
	);

	const newData = await Promise.all(
		data.map(async el => {
			if (!el.preview) return el;
			const blob = await fetch(el.preview).then(r => r.blob());
			const img = await blobToBase64(blob);

			const data = await fetch(serviceData.baseUrl + '/customforms', {
				method: 'POST',
				headers: { 'Content-Type': 'text/plain;charset=UTF-8' },
				body: deflate(JSON.stringify(img))
			}).then(res => res.json());

			delete el.preview;
			const imgUrl = data?.url && data?.url !== 'null' && data?.url !== null ? data?.url : '';
			return { ...el, url: imgUrl };
		})
	);

	return newData;
};
