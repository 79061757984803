import React, { useCallback } from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import LoadForm from 'components/UI/form/loadForm';
import CatalogForm from 'components/UI/form/catalogForm';
import FaskaModule from 'components/UI/bigComponents/FaskaModule';

import { useActions } from 'hooks/useActions';
import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';
import { withCustomPrice } from 'components/UI/HOC/withCustomPrice';
import { worksAPI } from 'services/Works';

const Tombstone = ({
	burial,
	currentBurial,
	children,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	isFetching,
	isError,
	changedData,
	customPrice,
	changeCustomPrice
}) => {
	const actions = useActions();

	const currentTomb = burial.tombstone.currentTomb;

	const getValueItem = useCallback(
		(moduleObject, moduleType) => burial.tombstone.items[currentTomb].form[moduleObject][moduleType],
		[burial.tombstone.items, currentTomb]
	);

	const clientFormStatus = getValueItem('clienForm', 'status');
	const clientFormValue = getValueItem('clienForm', 'price');
	const clientFormImages = getValueItem('clienForm', 'img');

	const decorationStatus = getValueItem('decoration', 'status');
	const decorationProductsSelected = getValueItem('decoration', 'selectedProducts');

	const refetchDataHandler = useCallback(
		refetch => {
			refetch({ args: { work_tax: 47, per_page: 100, material_tax: burial.material.value } }, true);
		},
		[burial.material.value]
	);

	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (decorationProductsSelected.length === 0 || price === undefined) return;
			actions.changeFormElements({
				type: 'tombstone',
				current: currentTomb,
				moduleObject: 'decoration',
				moduleType: 'selectedProducts',
				value: [{ ...decorationProductsSelected[0], price: { unit: parseFloat(price) } }]
			});
		},
		[decorationProductsSelected]
	);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price: decorationProductsSelected?.[0]?.price?.unit ?? 0,
		changeCustomPrice
	});

	return (
		<>
			<TabsComponent
				currentEl={currentTomb}
				currentBurial={currentBurial}
				AllEl={burial.tombstone.items}
				title='СТ'
				onClick={e => actions.changeCurrentTombstone(e.target.dataset.value)}
			/>
			<LoadForm
				formTitle='Форма клиента'
				formState={clientFormStatus}
				onToggle={() => {
					actions.changeFormElements({
						type: 'tombstone',
						current: currentTomb,
						moduleObject: 'clienForm',
						value: { status: !clientFormStatus, price: 0, img: [] }
					});
				}}
				label='Введите стоимость'
				inputValue={clientFormValue}
				onInput={value => {
					actions.changeFormElements({
						type: 'tombstone',
						current: currentTomb,
						moduleObject: 'clienForm',
						moduleType: 'price',
						value: value
					});
				}}
				images={clientFormImages}
				onDrop={files => {
					actions.changeFormElements({
						type: 'tombstone',
						current: currentTomb,
						moduleObject: 'clienForm',
						moduleType: 'img',
						value: files
					});
				}}
			/>
			<CatalogForm
				formTitle='Художественное оформление'
				formState={decorationStatus}
				onToggle={() => {
					actions.changeFormElements({
						type: 'tombstone',
						current: currentTomb,
						moduleObject: 'decoration',
						value: {
							status: !decorationStatus,
							selectedProducts: []
						}
					});
				}}
				onClickProductCard={item => {
					const include = decorationProductsSelected.some(el => el.sku === item.sku);
					const price = item.price?.unit ?? customPrice;

					actions.changeFormElements({
						type: 'tombstone',
						current: currentTomb,
						moduleObject: 'decoration',
						moduleType: 'selectedProducts',
						value: include ? [] : [{ ...item, price: { unit: price } }]
					});
				}}
				btnTitle='Каталог оформления'
				products={changedData.items}
				selectedProducts={decorationProductsSelected}
				refetch={refetchData}
				hook
				isLoading={isFetching}
				isError={isError}
			>
				{children}
			</CatalogForm>
			<FaskaModule type='tombstone' currentElement={currentTomb} />
		</>
	);
};

const withCustomPriceDecoration = withCustomPrice(Tombstone, worksAPI.useLazyFetchWorksQuery);
export default withCustomPriceDecoration;
