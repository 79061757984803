import React, { useMemo } from 'react';

import { step4Events } from 'utils/events/steps/step4';
import { CHANGE_HEIGHT, CHANGE_WIDTH } from 'components/step1/constants';

import TextInput from 'components/UI/input/text';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';
import { ReactComponent as BasketIcon } from 'images/icons/basket.svg';
import { useActions } from 'hooks/useActions';

const ExcludePlaceItem = ({ item }) => {
	const actions = useActions();
	const changeHandle = (type, value) => actions.changeExcludedItemValue({ id: item.id, type, value });
	const itemId = useMemo(() => (item.id !== 0 ? `exclude_space_input_${item.id}` : 'exclude_space_input'), [item.id]);

	return (
		<div className='exception__item' id={itemId}>
			<div className='sidebar__radio__block--values'>
				<TextInput
					blockClasses='sidebar__radio__block--values__length'
					label='Длина'
					defaultValue={item.width}
					name={`excluded_space_width_${item.id}`}
					id={`excluded_space_width_${item.id}`}
					required={true}
					onChange={value => {
						changeHandle(CHANGE_WIDTH, value);
						step4Events.excludeItemWidthChange({
							height: item.height,
							width: parseInt(value),
							id: item.id != 0 ? item.id : NaN
						});
					}}
				/>
				<span className='grayscale-gray-4 icon-16'>
					<CrossIcon />
				</span>
				<TextInput
					blockClasses='sidebar__radio__block--values__width'
					label='Ширина'
					defaultValue={item.height}
					name={`excluded_space_height_${item.id}`}
					id={`excluded_space_height_${item.id}`}
					required={true}
					onChange={value => {
						changeHandle(CHANGE_HEIGHT, value);
						step4Events.excludeItemHeightChange({
							width: item.width,
							height: parseInt(value),
							id: item.id != 0 ? item.id : NaN
						});
					}}
				/>
			</div>
			{item.id !== 0 && (
				<span
					className='icon-btn icon-16'
					id={`remove_button_${item.id}`}
					onClick={e => {
						step4Events.removeExcludeItem(item.id);
						actions.removeExcludeItem(item.id);
					}}
				>
					<BasketIcon />
				</span>
			)}
		</div>
	);
};

export default ExcludePlaceItem;
