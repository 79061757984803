import { dispatchEvent } from './../dispatchEvent';

export const step4Events = {
	enableExcludeArea(status) {
		return dispatchEvent('exclude_space_existence_changed', status);
	},
	addExcludeItem() {
		return dispatchEvent('add_excluded_space');
	},
	excludeItemWidthChange({ height, width, id }) {
		return dispatchEvent('excluded_space_width_changed', { height, width, id });
	},
	excludeItemHeightChange({ height, width, id }) {
		return dispatchEvent('excluded_space_height_changed', { height, width, id });
	},
	removeExcludeItem(id) {
		return dispatchEvent('remove_excluded_space', id);
	}
};
