import { dispatchEvent } from 'utils/events/dispatchEvent';
import { collectCustomFields } from './collectCustomFields';

const sleep = time =>
	new Promise(resolve => {
		setTimeout(() => resolve(), time);
	});

const generateImage = async ({ iframe, type }) => {
	return await new Promise(res => {
		var img = new Image();
		img.onload = function () {
			res({ img: img.src, type });
		};
		img.src = iframe.contentDocument.getElementsByClassName('v3d-canvas')[0].toDataURL('image/jpeg', 0.9);
	});
};

export const generateImages = async burials => {
	const iframe = document.getElementById('iframe');

	iframe.contentDocument.querySelector('#v3d-container').style.width = '1080px';
	iframe.contentDocument.querySelector('#v3d-container').style.height = '1080px';
	window.dispatchEvent(new Event('resize'));
	const gravesImg = {};
	const duration = 0.01;
	const timeout = 600;
	const scene = await generateImage({ cameraParams: { target: null, duration }, iframe, type: 'scene' });

	for (let i = 0; i < burials.length; i++) {
		await dispatchEvent(`setCameraTarget`, { target: 'stella', currentBurial: i, duration });
		await sleep(timeout);
		const front = await generateImage({ iframe, type: 'stella-front' });
		await dispatchEvent(`setCameraTarget`, { target: 'stella', currentBurial: i, duration, subTargetIndex: 1 });
		await sleep(timeout);
		const back = await generateImage({ iframe, type: 'stella-back' });
		await dispatchEvent(`setCameraTarget`, { target: 'flower', currentBurial: i, duration });
		await sleep(timeout);
		const tomb = await generateImage({ iframe, type: 'tombstone' });

		const customImgs = await collectCustomFields(burials[i]);

		gravesImg[i] = { front, back, tomb, customImgs };
	}

	iframe.contentDocument.querySelector('#v3d-container').style.width = '';
	iframe.contentDocument.querySelector('#v3d-container').style.height = '';
	window.dispatchEvent(new Event('resize'));
	return { scene, gravesImg };
};
