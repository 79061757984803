import React from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import TabsComponent from 'components/UI/tabs/tabs';
import SecondButton from 'components/UI/button/second';
import FileInput from 'components/UI/input/file';
import PrimaryButton from 'components/UI/button/primary';
import InputTypeNumber from 'components/UI/input/text/number';

const items = ['Стандартный', 'По грудь', 'С рукой', 'По пояс', 'По бедро', 'В полный рост'];

const Portrait = ({
	openState,
	portraitCurrentItem,
	portraitItems,
	onDropFileHandler,
	onToggleHandler,
	onChangeTypeHandler,
	onTabChangeHandler,
	addPortraitHandler,
	deletePortraitHandler,
	moreThanOne = true,
	onChangePrice
}) => {
	const currentPortrait = portraitItems[portraitCurrentItem];
	const isPrice = (currentPortrait?.price !== null || currentPortrait?.price !== undefined) && onChangePrice;

	return (
		<BlockWithToggle
			title='Портрет'
			openState={openState}
			blockClasses='sidebar__radio__block classForTablesForm'
			onChangeToggle={onToggleHandler}
		>
			<TabsComponent
				currentEl={portraitCurrentItem}
				AllEl={portraitItems}
				title='№'
				onClick={e => onTabChangeHandler(e.target.dataset.value)}
			/>
			<div className='box'>
				{currentPortrait && (
					<>
						<div className='row'>
							{items.map((el, index) => (
								<PrimaryButton
									key={index}
									title={el}
									onClick={() => onChangeTypeHandler(el)}
									className={`btn ${currentPortrait?.type == el ? 'isActiveSize' : ''}`}
								/>
							))}
						</div>
						{isPrice && (
							<div className='row'>
								<InputTypeNumber
									blockClasses='own__form__input'
									label='Введите стоимость'
									defaultValue={currentPortrait?.price || 0}
									name={`own__form__input__faska}`}
									id={`own__form__input__faska}`}
									onChange={onChangePrice}
									useDelay={false}
								/>
							</div>
						)}
					</>
				)}
				{(moreThanOne || (!moreThanOne && portraitItems.length < 1)) && (
					<SecondButton title='Добавить портрет' onClick={addPortraitHandler} classes='mb24 box__value__basket' />
				)}
				{portraitItems.length >= 1 && (
					<SecondButton title='Удалить портрет' id='delete_button' onClick={deletePortraitHandler} classes='mb24 box__value__basket' />
				)}
				{currentPortrait && <FileInput img={currentPortrait?.img} onDrop={onDropFileHandler} />}
			</div>
		</BlockWithToggle>
	);
};

export default Portrait;
