import { useShallowEffect } from '@mantine/hooks';
import { useActions } from 'hooks/useActions';
import { useMemo } from 'react';

export const usePositionCalculate = ({ burial }) => {
	const action = useActions();
	const value = useMemo(
		() => ({
			grave_index: burial.id,
			delta_1: parseFloat(burial.boxes.position.items[0]),
			delta_12: parseFloat(burial.boxes.position.items[1]),
			delta_23: parseFloat(burial.boxes.position.items[2]),
			delta_34: parseFloat(burial.boxes.position.items[3]),
			delta_4: parseFloat(burial.boxes.position.items[4]),
			stele_length: burial.stella.items[0].individualSizes.values.length,
			level_1: burial.boxes.items[0]?.indexPos,
			level_2: burial.boxes.items[1]?.indexPos,
			level_3: burial.boxes.items[2]?.indexPos,
			level_4: burial.boxes.items[3]?.indexPos
		}),
		[burial.id, burial.boxes.position.items, burial.boxes.items]
	);

	useShallowEffect(() => action.changeBoxPopUpValues(), [burial.boxes.position.status, burial.boxes.items]);

	return value;
};
