import React, { useState } from 'react';
import './popup.scss';

const FormWithTabs = ({ childrens = [], state = false, classNames = 'tabs' }) => {
	const [active, setActive] = useState('tab1');
	return (
		<div className={`${classNames} fio_tabs ${state ? 'active' : ''}`}>
			{childrens.map((el, index) => (
				<div className='tab' key={index}>
					<input
						type='radio'
						id={`tab${index}`}
						name='tab-group'
						checked={active == `tab${index}` ? true : false}
						onChange={() => {}}
						onClick={() => setActive(`tab${index}`)}
					/>
					<label htmlFor={`tab${index}`} className='tab-title'>
						<h3>{el.title}</h3>
					</label>
					<div className='tab-content'>{el.content}</div>
				</div>
			))}
		</div>
	);
};

export default FormWithTabs;
