import { useMemo } from 'react';

export const complectCartData = cart => {
	const graves = cart.selectedData.burialsData.map((grave, index) => {
		const data = {
			heading: 'Захоронение ' + (index + 1) + ':',
			total: grave.reduce((total, kit) => total + parseFloat(kit.price?.unit ?? 0), 0),
			list: grave
				.map(kit => {
					const components =
						kit?.components?.map(item => ({
							title: ' - ' + item.title,
							size: item.size ? item.size.raw : '',
							count: item?.count ? item.count + ' шт' : '1 шт',
							price: parseFloat(item.price?.unit ?? 0)
						})) ?? [];

					const obj = {
						title: kit?.title ?? '',
						size: kit.size ? kit.size.raw : '',
						count: (kit?.count ?? '1') + ' шт',
						price: kit.price?.unit
					};

					if (kit.drilling && kit.drilling?.count) obj.drilling = kit.drilling;

					return [obj, ...components];
				})
				.flat()
		};
		return data;
	});

	const steps = [];

	if (cart.selectedData.step4.length) {
		steps.push({
			heading: 'Установка',
			total: cart.selectedData.step4.reduce((total, kit) => total + parseFloat(kit.price?.unit ?? 0), 0),
			list: cart.selectedData.step4.map(el => ({ ...el, price: parseFloat(el.price?.unit ?? 0) }))
		});
	}

	if (cart.selectedData.step5.length) {
		steps.push({
			heading: 'Благоустройство',
			total: cart.selectedData.step5.reduce((total, kit) => total + parseFloat(kit.price?.unit ?? 0), 0),
			list: cart.selectedData.step5.map(el => ({ ...el, price: parseFloat(el.price?.unit ?? 0) }))
		});
	}

	if (cart.selectedData.step6.length) {
		steps.push({
			heading: 'Доставка',
			total: cart.selectedData.step6.reduce((total, kit) => total + parseFloat(kit.price?.unit ?? 0), 0),
			list: cart.selectedData.step6.map(el => ({ ...el, price: parseFloat(el.price?.unit ?? 0) }))
		});
	}

	return [graves, ...steps].flat();
};

export const useComplectCartData = cart => {
	const cartData = complectCartData(cart);

	const sale = 0;

	const total = useMemo(() => {
		const total = cart.totalCost - cart.totalCost * sale;

		return [
			{ title: 'Сумма', price: cart.totalCost.toFixed(2) },
			{ title: 'Скидка', price: (cart.totalCost * sale).toFixed(2) },
			{ title: 'Итого:', price: total.toFixed(2) }
		];
	}, [cartData]);

	return useMemo(() => ({ cartData, total }), [cartData, total]);
};
