import React, { useCallback, memo, useLayoutEffect } from 'react';

import { useTransforming } from './hooks/useTransforming';
import { useAlign } from './hooks/useAlign';
import { useDragging } from './hooks/useDragging';

import CreateImage from './modules/createImage';
import CreateText from './modules/createText';
import CreateDate from './modules/createDate';
import CreateDateRome from './modules/createDateRome';

const CreateCanvasRenderItems = ({
	items,
	changeItems,
	stageRef,
	selectShape,
	canvasType,
	alignCenter,
	canvasData,
	stageDimensions,
	canvasInfo,
	selectedId
}) => {
	const { handleTransformEnd } = useTransforming();
	const { alignItemsCenter, alignItemsBySide } = useAlign();
	const { handleDragEnd } = useDragging();

	const transformHandler = useCallback(e => handleTransformEnd(e, items, changeItems), [changeItems, handleTransformEnd, items]);
	const onSelectHandler = useCallback(id => selectShape(id), [selectShape]);
	const onDragEndHandler = useCallback(
		e => handleDragEnd(e, items, changeItems, stageRef, alignCenter),
		[alignCenter, changeItems, handleDragEnd, items, stageRef]
	);

	useLayoutEffect(() => {
		if (alignCenter && stageRef.current) {
			const alignByY = {
				status: canvasType === 'plate',
				containImage: canvasData.some(el => el.type === 'image' && !el.catType.includes('fakeportrait'))
			};

			const interval = setTimeout(() => {
				const alignedItems = alignItemsCenter(stageRef.current, canvasData, alignByY, stageDimensions, canvasType);
				changeItems(alignedItems);
			}, 400);

			return () => clearTimeout(interval);
		}
	}, [canvasData.length, stageDimensions, canvasInfo?.form?.form]);

	return (
		<>
			{items.map((item, index) => {
				switch (item.type) {
					case 'image':
						return (
							<CreateImage
								key={index}
								item={item}
								handleTransformEnd={transformHandler}
								onSelect={onSelectHandler}
								draggable={selectedId && selectedId === item.id ? item.draggable ?? true : false}
								items={items}
								changeItems={changeItems}
								alignItemsBySide={alignItemsBySide}
								stageDimensions={stageDimensions}
								onDragEnd={onDragEndHandler}
							/>
						);
					case 'text':
						return (
							<CreateText
								key={index}
								item={item}
								handleTransformEnd={transformHandler}
								onSelect={onSelectHandler}
								draggable={selectedId && selectedId === item.id ? item.draggable ?? true : false}
								stageRef={stageRef}
								alignCenter={alignCenter}
								changeItems={changeItems}
								items={items}
								canvasType={canvasType}
								stageDimensions={stageDimensions}
								onDragEnd={onDragEndHandler}
							/>
						);
					case 'date':
						return (
							<CreateDate
								key={index}
								item={item}
								handleTransformEnd={transformHandler}
								onSelect={onSelectHandler}
								draggable={selectedId && selectedId === item.id ? item.draggable ?? true : false}
								stageDimensions={stageDimensions}
								onDragEnd={onDragEndHandler}
							/>
						);
					case 'dateRome':
						return (
							<CreateDateRome
								key={index}
								item={item}
								handleTransformEnd={transformHandler}
								onSelect={onSelectHandler}
								draggable={selectedId && selectedId === item.id ? item.draggable ?? true : false}
								stageDimensions={stageDimensions}
								onDragEnd={onDragEndHandler}
							/>
						);
					default:
						console.log(`Sorry, we are out type`);
				}
			})}
		</>
	);
};

export default memo(CreateCanvasRenderItems);
