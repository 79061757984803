import React, { useCallback, useState } from 'react';

import { useWindowEvent } from '@mantine/hooks';

import styles from './preloader.module.scss';

const EventsPreloader = () => {
	const [open, setOpen] = useState(false);

	const eventHandler = useCallback(
		e => {
			const { value } = e.detail ?? { value: false };
			setOpen(value ?? false);
		},
		[setOpen]
	);

	useWindowEvent('setpreloader', eventHandler);

	return (
		<div className={`${styles.preloader__events} ${open && styles.active}`}>
			<div className={`${styles.preloader__logo} --svg__logo`}></div>
			<div className={styles.lds__ellipsis}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default EventsPreloader;
