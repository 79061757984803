import React, { memo } from 'react';

import './sidebarElementTab.scss';

const SidebarElementTab = ({ activeTab, changeTab, items, classNames = '' }) => {
	return (
		<div className={`sibedar__element__tab ${classNames}`.trim()}>
			{items.map((item, index) => {
				if (item.count !== 0)
					return (
						<div
							className={`
								sibedar__element__tab__item 
								${item.name == activeTab ? ' active' : ''}
								${item.disabled ? 'disabled' : ''}
							`}
							key={index}
							onClick={changeTab.bind(null, item.name)}
						>
							{item.icon}
							<span>{item.name}</span>
						</div>
					);
			})}
		</div>
	);
};

export default memo(SidebarElementTab);
