import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

const Sverlovka = ({ burial, currentPedestal }) => {
	const actions = useActions();
	const arr = Array.apply(null, { length: 4 }).map(Number.call, Number);
	const sverlovka = burial.pedestal.items[currentPedestal].sverlovka;

	return (
		<BlockWithArrow title='Сверловка' blockClasses='sidebar__radio__block__value' contentClasses='sidebar__radio__block__value__items'>
			{arr.map((item, index) => (
				<RadioInput
					key={index}
					title={index + 1}
					defaultChecked={sverlovka == index + 1}
					name='sverlovka'
					id={'sverlovka' + index + 1}
					onChange={e => actions.changePedestalSverlovka(index + 1)}
				/>
			))}
		</BlockWithArrow>
	);
};

export default Sverlovka;
