import React, { useMemo } from 'react';

import TextInput from 'components/UI/input/text';
import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';

const PopUpStellaSizes = ({ items }) => {
	const { values } = useMemo(() => items[0].individualSizes, [items]);
	const { height, width, length } = useMemo(() => values, [values]);

	return (
		<div className='popup-position__add__sizes'>
			<h2>Размер стелы:</h2>
			<div className='exception__item'>
				<div className='sidebar__radio__block--values'>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Высота'
						type='number'
						defaultValue={height}
						name='popUp_stella_height'
						id='popUp_stella_height'
						required={true}
						readOnly={true}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Ширина'
						type='number'
						defaultValue={width}
						name='popUp_stella_width'
						id='popUp_stella_width'
						required={true}
						readOnly={true}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Толщина'
						type='number'
						defaultValue={length}
						name='popUp_stella_length'
						id='popUp_stella_length'
						required={true}
						readOnly={true}
					/>
					<span>мм</span>
				</div>
			</div>
		</div>
	);
};

export default PopUpStellaSizes;
