import { dispatchEvent } from './dispatchEvent';

export const flowerEvents = {
	showBed(grave_index) {
		return dispatchEvent('show_bed', { grave_index });
	},
	addFlower({ flowerType = 'second', id, flowerID, state = 'standard' }) {
		// id = currentBurial
		// let event = flowerType === 'second' ? 'add_garden_button_changed' : 'bed_changed';
		// здесь вместо просто id отправлять flowerID или вместе
		const action = 'create';
		return dispatchEvent('bed_changed', { state, id, flowerType, flowerID, action });
	},
	deleteSecondFlower({ flowerType = 'second', id, flowerID, state = 'without' }) {
		// id = currentBurial
		// let event = flowerType === 'second' ? 'remove_free_bed_changed' : 'bed_changed';
		const action = 'delete';
		return dispatchEvent('bed_changed', { state, id, flowerID, action });
	}
};
