import React, { useCallback } from 'react';

import { step3Events } from 'utils/events/steps/step3';
import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import Processing from 'components/UI/other/processing';
import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import PrimaryButton from 'components/UI/button/primary';
import TypeStoneContent from './TypeStone';
import PedestalProducts from './productList';
import Sverlovka from './sverlovka';

const Pedestal = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();

	const material = burial.material.value;
	const id = burial.id;
	const pedestalData = burial.pedestal;
	const currentPedestal = pedestalData.currentPedestal;
	const howManyPedestals = pedestalData.items.length;

	const goToNextStep = useCallback(() => {
		let tab = currentTab + 1;
		if (howManyPedestals >= 2) tab += 1;
		actions.changeCurrentSidebarTab(tab);
	}, [currentTab, howManyPedestals, actions]);

	const removePedestalHandler = useCallback(() => {
		actions.removePedestalHistoryHandler({
			pedestalLength: howManyPedestals,
			flowersLength: burial.flowers.items.length
		});
		actions.changeFlowerType({
			name: 'separate',
			type: 'separate',
			currentFlower: 0
		});
		// if (howManyPedestals !== 2) step3Events.hideAllParts(id);
	}, [howManyPedestals, id]);

	const addPedestalHandler = useCallback(() => {
		actions.addPedestalHistoryHandler({ flowersLength: burial.flowers.items.length });
	}, [burial.flowers.items.length]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Тумба</h1>

			{howManyPedestals > 0 && (
				<>
					<TabsComponent
						currentEl={currentPedestal}
						currentBurial={currentBurial}
						AllEl={burial.pedestal.items}
						title='ТБ'
						onClick={e => actions.changeCurrentPedestalItem(e.target.dataset.value)}
					/>
					{material === 'granite' && <TypeStoneContent burial={burial} currentPedestal={currentPedestal} material={material} />}
					<PedestalProducts currentBurial={currentBurial} currentPedestal={currentPedestal} burial={burial} />
					<Sverlovka currentPedestal={currentPedestal} burial={burial} />
					<Processing />
				</>
			)}

			{howManyPedestals < 2 && <SecondButton title='Добавить тумбу' classes='mb24 box__value__add' onClick={addPedestalHandler} />}

			{howManyPedestals > 0 && (
				<SecondButton
					title={howManyPedestals === 1 ? 'Заменить на кубы' : 'Удалить тумбу'}
					classes={`mb24 step1__exclude__add ${howManyPedestals === 1 ? 'box__value__switch' : 'box__value__delete'}`}
					onClick={removePedestalHandler}
				/>
			)}

			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Pedestal;
