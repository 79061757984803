export const dimensions = {
	burial: {
		// we take this data from 3d
		length: 1200,
		width: 730
	},
	pedestal: {
		width: 600,
		length: 130,
		height: 117
	},
	boxes: {
		height: 100,
		width: 100,
		depth: 100
	},
	stella: {
		width: 400,
		length: 70,
		height: 885
	},
	flower: {
		poperehnaSizes: {
			width: 50,
			length: 570,
			height: 30
		},
		prodolnaSizes: {
			width: 1070,
			length: 15,
			height: 30
		}
	},
	tombstone: {
		width: 600,
		length: 990,
		height: 50
	}
};
