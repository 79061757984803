import React from 'react';
import './list-card.scss';

const EpitafiaCard = ({ item, onClick = () => {}, status }) => {
	const { text } = item;
	return (
		<div
			className={`product__card product__card--list product__card--epitafia ${status ? 'product__card--epitafia--active' : ''}`}
			onClick={() => onClick(item)}
		>
			<div className='product__card__details'>
				<div className='product__card__details__text'>
					<p>{text}</p>
				</div>
				<div className='product__card__details__epitafia'>
					<div className='product__card__details__text__length'>
						<span>{text.replace(/ /gi, '').length}</span>
					</div>
					<button className={`--svg__add_to_cart-after ${status ? 'active' : ''}`}></button>
				</div>
			</div>
		</div>
	);
};

export default EpitafiaCard;
