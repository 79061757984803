import { dispatchEvent } from './dispatchEvent';

export const burialEvents = {
	updateBurials(burials, categories, index, limit) {
		return dispatchEvent('updateBurials', {
			burials,
			categories,
			index,
			limit
		});
	}
};
