export const flatten = items => {
	const flat = [];

	items.forEach(item => {
		if (Array.isArray(item)) {
			flat.push(...flatten(item));
		} else {
			flat.push(item);
		}
	});

	return flat;
};
