import React, { useCallback, useMemo } from 'react';

import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';

import OwnTable from './OwnTable';
import Title from './components/Title';
import Forms from './components/Forms';
import Sizes from './components/Sizes';
import FioAndDates from './components/Fio';
import ColorVariation from './components/ColorVariation';
import PhotoRestoration from './components/PhotoRestoration';
import BackgroundCatalog from './components/BackgroundCatalog';
import Instalation from './components/Instalation';

import { useActions } from 'hooks/useActions';
import { useTableSizes } from 'hooks/useTableSizes';
import { useShallowEffect } from '@mantine/hooks';

import './tables.scss';

const CeramGranit = ({ burial, getAllTablesLength, currentBurial, type: tableType, template }) => {
	const actions = useActions();

	const { ceramic } = burial.tables;

	const metalCeramItems = ceramic.items;
	const currentMetalItem = ceramic.currentItem;
	const maxAvailableTablesLength = burial.unionCount * 3;

	const currentTable = metalCeramItems[currentMetalItem];
	const typeTable = currentTable?.type;

	const taskTable = currentTable?.task?.state;
	const genderTaskTable = currentTable?.task?.gender;
	const currentTableForm = currentTable?.form?.form;
	const currentTableSize = currentTable?.size;

	const currentTableColorVariation = currentTable?.colorVariation;
	const currentTableRotated = currentTable?.rotated;

	const captionEnabled = useMemo(() => currentTable?.fio?.items !== null, [currentTable?.fio?.items]);

	const { dataSizes, forms, currentSizeData, formsLoading, sizesLoading } = useTableSizes({
		tableVariation: 'ceramic',
		tableType: 'plaque-granite-ceramic',
		currentTableForm,
		currentTableColorVariation,
		currentTableCaptionStatus: captionEnabled,
		currentTableSize,
		currentTable
	});

	useShallowEffect(() => {
		if (!currentTableSize || currentTableSize === null) return;

		const price = parseFloat(currentSizeData?.price?.unit ?? 0);
		actions.setTableValue({
			type: tableType,
			obj: 'price',
			value: price,
			historySkip: true
		});
	}, [currentSizeData]);

	const checkTaskTableAndGender = useMemo(
		() => taskTable !== null && ((taskTable === 'Для портрета' && genderTaskTable !== null) || taskTable !== 'Для портрета'),
		[genderTaskTable, taskTable]
	);

	const onChangeTabHandler = useCallback(
		e => actions.changeCurrentTableItem({ type: tableType, itemNumber: +e.target.dataset.value }),
		[actions]
	);

	const addTableHandler = useCallback(
		e => {
			actions.generateTable({
				type: tableType,
				actionType: 'create',
				tableType: 'normal',
				formType: forms?.[0]?.type,
				size: dataSizes?.[0]
			});
		},
		[actions, forms, dataSizes]
	);

	const addOwnTableHandler = useCallback(
		e => actions.generateTable({ type: tableType, actionType: 'create', tableType: 'own' }),
		[actions]
	);

	const removeTableHandler = useCallback(() => {
		actions.generateTable({ type: tableType, actionType: 'remove' });
	}, [burial.id, currentTable?.id, actions]);

	const rotateCurrentTable = useCallback(() => {
		actions.setTableValue({
			type: tableType,
			obj: 'rotated',
			value: !currentTableRotated
		});
	}, [actions, currentTableRotated, tableType]);

	const showRotation = currentTable && typeTable !== 'own' && currentTableForm && currentTableSize;

	return (
		<>
			{metalCeramItems.length !== 0 && (
				<>
					<TabsComponent
						currentEl={currentMetalItem}
						currentBurial={currentBurial}
						AllEl={metalCeramItems}
						title='№'
						onClick={onChangeTabHandler}
					/>
					{typeTable === 'own' ? (
						<OwnTable type={tableType} currentTable={currentTable} />
					) : (
						<>
							<Title openState={false} type={tableType} taskTable={taskTable} genderTaskTable={genderTaskTable} />

							{checkTaskTableAndGender && (
								<>
									<Forms formIcons={forms} loading={formsLoading} type={tableType} currentTableForm={currentTableForm} />
									<Sizes currentTableSize={currentTableSize} loading={sizesLoading} type={tableType} sizes={dataSizes} />
									{currentTableSize !== null && (
										<>
											<ColorVariation currentTable={currentTable} type={tableType} />

											<FioAndDates currentTable={currentTable} type={tableType} openState={false} template={template} />

											<PhotoRestoration currentTable={currentTable} type={tableType} />

											<BackgroundCatalog currentTable={currentTable} type={tableType} openState={false} />

											<Instalation currentTable={currentTable} tableType={tableType} />
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}

			{showRotation && <SecondButton title='Повернуть' onClick={rotateCurrentTable} classes={`mb12 box__value__switch`} />}
			{getAllTablesLength < maxAvailableTablesLength && (
				<>
					<SecondButton title='Добавить фарфор' onClick={addTableHandler} classes={`mb12 box__value__add`} />
					<SecondButton title='Свой фарфор' onClick={addOwnTableHandler} classes={`mb12 box__value__add`} />
				</>
			)}

			{metalCeramItems.length !== 0 && (
				<SecondButton title='Удалить табличку' id='delete_button' onClick={removeTableHandler} classes='mb24 box__value__basket' />
			)}
		</>
	);
};

export default CeramGranit;
