import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import TextInput from 'components/UI/input/text';
import TabsComponent from 'components/UI/tabs/tabs';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const TileArea = ({ tileAreaObj, claddingState, changeCladdingHandler }) => {
	const actions = useActions();
	const { burial, currentBurial, burialsImproved } = useBurialsData();
	const {
		open,
		zoneValues: { width: widthZone, height: heightZone }
	} = useSelector(state => state.configurator.present.step1.workZone);
	const size = useMemo(() => `${widthZone}х${heightZone}`, [heightZone, widthZone]);
	const isFullArea = useMemo(
		() => burialsImproved.some(el => el.improvement.tileArea.type === `Весь участок (${size})`),
		[burialsImproved, size]
	);

	const areaTypes = useMemo(() => {
		const arr = [
			{ name: 'Стандартное (30 см)', isFullArea: false },
			{ name: 'Бюджетное (15 см)', isFullArea: false }
		].filter(el => el.name === burial.installation.type);

		if (open) arr.push({ name: `Весь участок (${size})`, isFullArea: true });

		return arr;
	}, [size, open]);
	const { type, height, price } = tileAreaObj;

	const onChangeHandler = useCallback(obj => actions.changeTileArea({ ...obj, cladding: claddingState }), [claddingState, actions]);
	const onChangeTabHandler = useCallback(e => actions.changeCurrentBurial(e.target.dataset.value), [actions]);

	return (
		<BlockWithArrow title={'Зона покрытия плитки'} blockClasses='sidebar__radio__block'>
			{!isFullArea && (
				<TabsComponent
					currentEl={currentBurial}
					currentBurial={currentBurial}
					AllEl={burialsImproved}
					title='Зах.'
					onClick={onChangeTabHandler}
				/>
			)}
			{areaTypes.map((el, i) => (
				<RadioInput
					key={i}
					title={el.name}
					defaultChecked={el.name === type}
					onChange={e => {
						const checkisFullArea = el.isFullArea ? el.isFullArea : isFullArea;
						onChangeHandler({
							isFullArea: checkisFullArea,
							type: e.target.dataset.value,
							height,
							price
						});
						if (height < 10 && !checkisFullArea) changeCladdingHandler(false);
					}}
					name={`installation_${i}`}
					id={`installation_${i}`}
					value={el.name}
				/>
			))}
			<TextInput
				blockClasses='sidebar__radio__block--values__length__cm mt32'
				label='Высота основания:'
				defaultValue={height}
				name={`own__form__input__height`}
				id={`own__form__input__height`}
				onChange={value => {
					onChangeHandler({
						isFullArea,
						type,
						height: parseInt(value),
						price
					});
					if (value < 10 && !isFullArea) changeCladdingHandler(false);
				}}
				required={true}
				type='number'
				useDelay={false}
			/>
			<TextInput
				blockClasses='sidebar__radio__block--values__length__cm mt12'
				label='Длина продольной части цветника:'
				defaultValue={burial.flowers?.items[0]?.prodolna.sizes.width / 10}
				name={`own__form__input__height`}
				id={`own__form__input__height`}
				onChange={value => {
					const individualSizes = burial.flowers?.items[0]?.prodolna;
					actions.changeFlowerIndividualSizes({
						type: 'prodolna',
						sizes: { width: parseFloat(value) * 10, length: individualSizes.sizes.length, height: individualSizes.sizes.height },
						status: individualSizes.status
					});
					if (value < 10 && !isFullArea) changeCladdingHandler(false);
				}}
				required={true}
				type='number'
				useDelay={false}
			/>
		</BlockWithArrow>
	);
};

export default TileArea;
