/* eslint-disable no-unused-vars */
import { useActions } from 'hooks/useActions';

const HomeEvents = () => {
	const actions = useActions();

	const changeBurialPosition = e => {
		actions.changeBurialPosition(e.detail.value);
	};
	const changeAreaValues = e => {
		const { height, width, x, y, grave_id } = e.detail.value;
		if (height !== undefined) actions.changeBurialAreaValues({ type: 'CHANGE_HEIGHT', value: height, id: grave_id });
		if (width !== undefined) actions.changeBurialAreaValues({ type: 'CHANGE_WIDTH', value: width, id: grave_id });
		if (x !== undefined) actions.changeBurialAreaValues({ type: 'CHANGE_X', value: x, id: grave_id });
		if (y !== undefined) actions.changeBurialAreaValues({ type: 'CHANGE_Y', value: y, id: grave_id });
	};
	const changeWorkingZoneHandler = e => {
		const { height, width } = e.detail.value;
		if (height) actions.changeWorkZone({ type: 'changeZoneValuesWidth', value: height });
		if (width) actions.changeWorkZone({ type: 'changeZoneValuesHeight', value: width });
	};
	const changeGravesHandler = e => {
		actions.changeGraves(e.detail.value);
		actions.changeLoading(false);
	};

	const changeBoxPopValuesHandler = e => {
		const { delta_1, delta_12, delta_23, delta_34, delta_4, level_1, level_2, level_3, level_4, delta_max } = e.detail.value;

		actions.changeBoxPopUpAllValues({
			values: [delta_1 ?? 0, delta_12 ?? 0, delta_23 ?? 0, delta_34 ?? 0, delta_4 ?? 0],
			max: delta_max ?? 0
		});
		actions.changeBoxPopAllPosition([level_1 ?? 0, level_2 ?? 0, level_3 ?? 0, level_4 ?? 0]);
	};

	// when width, lenght in second stella change
	const update_allowed_alignments = e => {
		// actions.changeDisabledAlignInStellaPopUp(e.detail.value);
	};

	// when change depth in first stella
	const will_the_second_stele_fit = e => {
		// actions.changeDisableToCreateStella(e.detail.value);
	};
	const update_one_stele_popup = e => {
		const { delta_left_1, delta_right_1, delta_top_1 } = e.detail.value;
		// actions.changeValuesStellaPopUpAll([delta_left_1 ?? 0, delta_right_1 ?? 0, delta_top_1 ?? 0]);
	};
	const set_max_for_parallel = e => {
		const { delta_width_1, delta_width_2, delta_depth } = e.detail.value;
		// actions.changeMaxValuesPopUpStella({
		// 	first: delta_width_1,
		// 	second: delta_width_2,
		// 	merged: delta_depth
		// });
	};
	const set_max_for_line = e => {
		const { delta_width, delta_depth_1, delta_depth_2 } = e.detail.value;
		// actions.changeMaxValuesPopUpStella({
		// 	first: delta_width,
		// 	second: delta_depth_1,
		// 	merged: delta_depth_2
		// });
	};
	const update_stele_popup = e => {
		const { delta_left_1, delta_top_1, delta_top_2, delta_between, delta_left_2, delta_bottom_2, delta_right_1, delta_right_2 } =
			e.detail.value;
		let arr = {
			center: [delta_left_1 ?? 0, delta_top_1 ?? 0, delta_between ?? 0, delta_left_2 ?? 0, delta_bottom_2 ?? 0],
			right: [delta_top_1 ?? 0, delta_right_1 ?? 0, delta_between ?? 0, delta_left_2 ?? 0, delta_bottom_2 ?? 0],
			left: [delta_left_1 ?? 0, delta_top_1 ?? 0, delta_between ?? 0, delta_bottom_2 ?? 0, delta_right_2 ?? 0],
			space: [delta_left_1 ?? 0, delta_top_1 ?? 0, delta_between ?? 0, delta_top_2 ?? 0, delta_right_2 ?? 0]
		};
		// actions.changeValuesStellaPopUpAllFromEvent(arr);
	};

	const changeExcludedItemPosition = e => {
		actions.changeExcludedItemPosition(e.detail.value);
	};

	return {
		changeWorkingZoneHandler,
		changeGravesHandler,
		changeBoxPopValuesHandler,
		update_allowed_alignments,
		will_the_second_stele_fit,
		update_one_stele_popup,
		set_max_for_parallel,
		set_max_for_line,
		update_stele_popup,
		changeAreaValues,
		changeBurialPosition,
		changeExcludedItemPosition
	};
};

export default HomeEvents;
