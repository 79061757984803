import { createSlice } from '@reduxjs/toolkit';

const defaultDismantling = [
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Железный крест',
		type: 'number',
		label: 'Количество крестов'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Железный памятник',
		type: 'number',
		label: 'Количество памятников'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Мраморный памятник (<120)',
		type: 'number',
		label: 'Количество памятников'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Мраморный памятник (>120)',
		type: 'number',
		label: 'Количество памятников'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Мраморная крошка',
		type: 'number',
		label: 'Количество крошки'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Памятник с двойной тумбой',
		type: 'number',
		label: 'Количество памятников'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Гранитный памятник',
		type: 'number',
		label: 'Количество памятников'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Железный столик',
		type: 'number',
		label: 'Количество столиков'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Железная скамейка',
		type: 'number',
		label: 'Количество скамеек'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Железные столбы',
		type: 'number',
		label: 'Количество столбов'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Бетонная стяжка (м2)',
		type: 'area',
		label: 'Площадь стяжки'
	},
	{
		value: 1,
		status: false,
		typeItem: 'general',
		price: 0,
		addText: true,
		text: 'Облицовка (м2)',
		type: 'area',
		label: 'Площадь облицовки'
	}
];

const initialState = {
	dismantling: {
		status: false,
		items: [...defaultDismantling],
		another: false
	},
	dismantlingWithPreservation: {
		status: false,
		currentItem: 0,
		items: []
	}
};

export const step4Slice = createSlice({
	name: 'step4',
	initialState,
	reducers: {
		changeDismantlingStatus: (state, action) => {
			state.dismantling.status = action.payload;
			if (action.payload === false) state.dismantling.items = [...defaultDismantling];
		},
		changeAnotherStatus: (state, action) => {
			state.dismantling.another = action.payload;
			if (action.payload === false) {
				state.dismantling.items = state.dismantling.items.filter(item => item.typeItem === 'general');
			}
		},
		addDismantlingItem: (state, action) => {
			state.dismantling.items = [...state.dismantling.items, action.payload];
		},
		changeDismantlingItemValue: (state, action) => {
			const { text, status, value, price } = action.payload;
			const findedItem = state.dismantling.items.find(item => item.text === text);
			let changedItems = state.dismantling.items;
			if (status === false && findedItem.typeItem !== 'general') {
				changedItems = changedItems.filter(el => el.text !== text);
			} else {
				changedItems = state.dismantling.items.map(item => {
					if (item.text === text) {
						item.status = status;
						item.value = value;
						item.price = price;
					}
					return item;
				});
			}
			state.dismantling.items = changedItems;
		},
		changeStatusDismantlingWithPreservation: (state, action) => {
			state.dismantlingWithPreservation.status = action.payload;
			if (action.payload === true) {
				state.dismantlingWithPreservation.items = [{ price: 0, size: '1100x600x80' }];
			} else {
				state.dismantlingWithPreservation.items = [];
			}
			state.dismantlingWithPreservation.currentItem = 0;
		},
		changeCurrentDismantlingWithPreservationItem: (state, action) => {
			state.dismantlingWithPreservation.currentItem = action.payload;
		},
		addDismantlingWithPreservationItem: (state, action) => {
			state.dismantlingWithPreservation.items = [...state.dismantlingWithPreservation.items, { price: 0, size: '1100x600x80' }];
			state.dismantlingWithPreservation.currentItem = state.dismantlingWithPreservation.items.length - 1;
		},
		removeDismantlingWithPreservationItem: (state, action) => {
			state.dismantlingWithPreservation.items = state.dismantlingWithPreservation.items.filter(
				(el, index) => index !== state.dismantlingWithPreservation.currentItem
			);
			state.dismantlingWithPreservation.currentItem = state.dismantlingWithPreservation.items.length - 1;
		},
		changeDismantlingWithPreservationItem: (state, action) => {
			const changedItem = state.dismantlingWithPreservation.items.map((item, index) => {
				if (index === state.dismantlingWithPreservation.currentItem) {
					item = { ...action.payload };
				}

				return item;
			});

			state.dismantlingWithPreservation.items = changedItem;
		}
	}
});

export const step4SliceActions = step4Slice.actions;

export default step4Slice.reducer;
