import React, { useCallback, useMemo, useState, memo } from 'react';
import { useSelector } from 'react-redux';

import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import AdvancedPopUp from 'components/UI/popup/advanced/popup';
import ActionBlock from 'components/UI/block/actionBlock';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import { productsAPI } from 'services/Products';

const CatalogPlate = () => {
	const actions = useActions();
	const { currentBurial, burialsImproved, burial } = useBurialsData();
	const { width: widthZone, height: heightZone } = useSelector(state => state.configurator.present.step1.workZone.zoneValues);
	const size = useMemo(() => `${widthZone}х${heightZone}`, [heightZone, widthZone]);
	const isFullArea = useMemo(
		() => burialsImproved.some(el => el.improvement.tileArea.type === `Весь участок (${size})`),
		[burialsImproved, size]
	);

	const onChangeTabHandler = useCallback(e => actions.changeCurrentBurial(e.target.dataset.value), [actions]);

	const [refetch, { data, isFetching, isError }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => {
		refetch({ args: { per_page: 100, module_tax: 66 } }, true);
	}, []);

	const { plate: products } = burial.improvement;
	const [popUpIsOpen, setPopUpIsOpen] = useState(false);

	const onClickProductHandler = useCallback(item => actions.changePlateProducts(item), []);

	return (
		<>
			{!isFullArea && (
				<>
					<TabsComponent
						currentEl={currentBurial}
						currentBurial={currentBurial}
						AllEl={burialsImproved}
						title='Зах.'
						onClick={onChangeTabHandler}
					/>
					{products.length !== 0 &&
						products.map((el, index) => (
							<ActionBlock
								key={index}
								title={el?.title}
								className='mb12'
								onEditClick={() => setPopUpIsOpen(true)}
								onBasketClick={() => {
									setPopUpIsOpen(false);
									onClickProductHandler(el);
								}}
							/>
						))}
					{popUpIsOpen && (
						<AdvancedPopUp
							openState={popUpIsOpen}
							changeState={setPopUpIsOpen}
							products={data?.apiResponse ?? []}
							onClick={onClickProductHandler}
							selectedProducts={products}
							hook
							isFetching={isFetching}
							isError={isError}
							refetch={refetchData}
						/>
					)}
				</>
			)}
			<SecondButton classes='--svg__book-before mb12' onClick={() => setPopUpIsOpen(true)} title='Каталог раскладки' />
		</>
	);
};

export default memo(CatalogPlate);
