import React, { useState } from 'react';

import { ReactComponent as ArrowUp } from 'images/icons/up.svg';

import './sidebar__block__header.scss';

const BlockWithArrow = ({
	title,
	children,
	openState = true,
	blockClasses = 'sidebar__radio__block',
	contentClasses = '',
	arrowDelete = false,
	hideHeader = false,
	boxValueStyles = {}
}) => {
	const [isClose, setIsClose] = useState(openState);

	return (
		<div className={blockClasses}>
			{!hideHeader && (
				<div className={`sidebar__block__header ${arrowDelete ? 'arrowDelete' : ''}`} onClick={() => setIsClose(!isClose)}>
					<h3>{title}</h3>
					{!arrowDelete && (
						<div className={`--svg__mini-arrow ${isClose ? 'active' : ''}`}>
							<ArrowUp />
						</div>
					)}
				</div>
			)}
			{(arrowDelete === true || !isClose) && (
				<div style={boxValueStyles} className={`${contentClasses} box__value ${isClose ? 'hidden' : ''}`}>
					{children}
				</div>
			)}
		</div>
	);
};

export default BlockWithArrow;
