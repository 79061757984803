import React from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import SecondButton from 'components/UI/button/second';

import { useActions } from 'hooks/useActions';

const PositionSettings = ({ burial }) => {
	const actions = useActions();
	const status = burial.stella.position.status;

	return (
		<BlockWithToggle title='Настроить размещение' onChangeToggle={() => actions.changeStellaPositionStatus(!status)} openState={status}>
			<SecondButton title='Редактировать' onClick={() => actions.changeStellaPopUpState(true)} classes='box__value__edit' />
		</BlockWithToggle>
	);
};

export default PositionSettings;
