import { v4 as uuid } from 'uuid';
import { defaultTextColor } from 'config/canvas.config';
import { tablesInstalationTypes, tablesFastingTypes } from 'config/tablesInstalationTypes';

export const generateCeramTable = (type = null, form = 'Квадратная', size = null) => {
	const unique_id = uuid();
	const fakePortraitId = uuid();
	return {
		id: unique_id,
		type: type,
		task: {
			state: null
		},
		form: {
			status: true,
			form
		},
		rotated: false,
		size: size ?? null,
		colorVariation: 'Черно-белая',
		fio: {
			status: false,
			items: null,
			color: defaultTextColor
		},
		restoration: {
			status: false,
			price: 0
		},
		background: {
			status: false,
			items: []
		},
		montaz: { type: tablesInstalationTypes[0].title, price: 0 },
		fastening: {
			amount: tablesInstalationTypes[0].fastening[0],
			type: tablesFastingTypes[0].type,
			price: 0
		},
		fakeportrait: {
			status: true,
			items: [
				{
					type: 'Стандартный',
					img: (window.location.href + '/images/canvas/man.svg').replace('//images', '/images'),
					id: fakePortraitId
				}
			]
		},
		price: 0
	};
};
