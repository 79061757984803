import React from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import useBurialsData from 'hooks/useBurialsData';
import { useActions } from 'hooks/useActions';

const Polishing = () => {
	const { burial } = useBurialsData();
	const actions = useActions();
	const polishingTypes = useSelector(state => state.configurator.present.step2.polishingTypes);
	const materials = useSelector(state => state.configurator.present.configuratorSteps.categories.materials);
	const currentMaterialComplect = burial.material.value;
	const currentPolishing = burial.polishing;
	let data = [...polishingTypes];

	if (currentMaterialComplect === 'marble') {
		let el = data.shift();
		data = [el];
	}

	return (
		<BlockWithArrow
			title='Полировка комплекта'
			blockClasses='sidebar__radio__block__value'
			contentClasses='sidebar__radio__block__value__items box__value'
		>
			{data.map(item => (
				<RadioInput
					key={item + currentPolishing}
					value={item}
					title={item}
					name='polishing'
					id={'grave_polishing_' + item}
					defaultChecked={currentPolishing == item}
					onChange={() =>
						actions.changeHistoryPolishing({
							polishing: item,
							title: burial.materialStone,
							materialID: materials.find(el => el.title === burial.materialStone)?.id,
							selectedType: burial.catalog.type,
							materialType: burial.material.value,
							selectedItem: burial.catalog.selectedProducts?.[0]
						})
					}
				/>
			))}
		</BlockWithArrow>
	);
};

export default Polishing;
