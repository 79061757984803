import { useEffect } from 'react';
import WebFont from 'webfontloader';

const Preload = () => {
	useEffect(() => {
		WebFont.load({
			custom: { families: ['artscript', 'CometaC', 'time-roman-bold'] }
		});
	}, []);

	return null;
};

export default Preload;
