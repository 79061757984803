import React, { useCallback, useMemo } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import BlockWithToggle from 'components/UI/block/blockWithToggle';

import { useActions } from 'hooks/useActions';

import { ReactComponent as Demo } from 'images/canvas/man.svg';

const wrapperTypes = {
	BlockWithArrow: BlockWithArrow,
	BlockWithToggle: BlockWithToggle
};

const Forms = ({ formIcons, loading, currentTableForm, type, wrapperType = 'BlockWithArrow', wrapperArgs = {} }) => {
	const actions = useActions();
	const Wrapper = useMemo(() => wrapperTypes[wrapperType], [wrapperType]);

	const onChangeFormHandler = useCallback(
		name => {
			actions.setTableValue({
				type: type,
				obj: 'form',
				include: 'form',
				value: name,
				historySkip: true
			});
			actions.setTableValue({
				type: type,
				obj: 'size',
				value: null,
				historySkip: true
			});
		},
		[type]
	);

	return (
		<Wrapper title='Форма' {...wrapperArgs} blockClasses='sidebar__radio__block classForTablesForm'>
			{loading && <h2 className='message'>Загрузка ...</h2>}
			{!loading && !(formIcons ?? []).length && <h2 className='message'>Не найдено</h2>}
			<div className={'sibedar__element__tab tables__tabs'}>
				{!loading &&
					(formIcons ?? []).map((el, index) => {
						const { type, icon } = el;
						const Icon = icon ?? Demo;
						return (
							<div
								className={`sibedar__element__tab__item ${type == currentTableForm ? 'active' : ''}`}
								key={index}
								data-value={type}
								onClick={onChangeFormHandler.bind(this, type)}
							>
								<Icon />
							</div>
						);
					})}
			</div>
		</Wrapper>
	);
};

export default Forms;
