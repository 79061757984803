import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import RadioStoneInput from 'components/UI/input/radioStone';
import CatalogForm from 'components/UI/form/catalogForm';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import BlockWithToggle from 'components/UI/block/blockWithToggle';

import { useActions } from 'hooks/useActions';
import { useShallowEffect } from '@mantine/hooks';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';

import { productsAPI } from 'services/Products';

const StoneElements = () => {
	const actions = useActions();
	const categories = useConfiguratorCategories('materials');
	const stone = useSelector(state => state.configurator.present.step5.decoratedElements.stone);

	const [selectedStone, setSelectedStone] = useState(() => stone.material);

	const materials = useMemo(() => {
		return categories.filter(cat => ['marble', 'gabbo_diabez', 'gabbo_faski'].includes(cat.material));
	}, [categories, selectedStone]);

	useLayoutEffect(() => {
		if (materials?.[0]?.title && selectedStone === null) {
			actions.changeDecoratedElementsMaterial({ type: 'stone', values: materials[0].title });
			setSelectedStone(prev => materials[0].title);
		}
	}, [materials, selectedStone]);

	const [refetch, { data, isFetching, isError }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => {
		const material = materials.find(el => el.title === selectedStone)?.id;
		refetch({ args: { per_page: 100, module_tax: 58, material } }, true);
	}, [materials, selectedStone]);

	useShallowEffect(() => {
		if (!stone.status) return;
		refetchData();
	}, [selectedStone]);

	const onChangeToggleHandler = useCallback(
		() => actions.changeDecoratedElementsStatus({ type: 'stone', values: !stone.status }),
		[stone.status]
	);
	const onChangeMaterialHandler = useCallback(title => actions.changeDecoratedElementsMaterial({ type: 'stone', values: title }), []);
	const onClickProductHandler = useCallback(
		item => {
			onChangeMaterialHandler(selectedStone);
			actions.changeDecoratedElementsSelectedProducts({ type: 'stone', values: item });
		},
		[selectedStone]
	);
	const onEditClickHandler = useCallback(item => setSelectedStone(prev => item ?? stone.material), [stone.material]);

	return materials.length !== 0 ? (
		<CatalogForm
			formTitle='Каменные элементы'
			formState={stone.status}
			onToggle={onChangeToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог элементов'
			products={data?.apiResponse ?? []}
			selectedProducts={stone.items}
			refetch={refetchData}
			hook
			isLoading={isFetching}
			isError={isError}
			onEditClick={onEditClickHandler}
			plusState
			onPlusHandler={product => {
				actions.changeDecoratedElementsSelectedProducts({
					type: 'stone',
					values: { ...product, sku: product.sku + Array(stone.items.length).fill(' ').join('') },
					clone: true
				});
			}}
		>
			<BlockWithArrow
				title='Вид камня'
				blockClasses='sidebar__radio__block__value checkbox__type change__type__stone'
				contentClasses='sidebar__radio__block__value__items'
				hideHeader={true}
				openState={false}
			>
				{materials.map((item, index) => (
					<RadioStoneInput
						key={index}
						img={item.img}
						name={item.type}
						title={item.title}
						id={item.material + item.title}
						defaultChecked={selectedStone === item.title}
						onChange={onEditClickHandler.bind(null, item.title)}
					/>
				))}
			</BlockWithArrow>
		</CatalogForm>
	) : (
		<BlockWithToggle
			title='Каменные элементы'
			blockClasses='sidebar__radio__block__value'
			contentClasses='sidebar__radio__block__value__items'
			disableToogle={false}
		>
			<h2 className='message'>Не найдено</h2>
		</BlockWithToggle>
	);
};

export default StoneElements;
