import React, { useCallback, useState, memo } from 'react';
import { useSelector } from 'react-redux';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import InputTypeNumber from 'components/UI/input/text/number';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';
import { useActions } from 'hooks/useActions';

const WorkZone = () => {
	const [minZone] = useState({ width: 1, height: 1 });
	const actions = useActions();

	const { width, height } = useSelector(state => state.configurator.present.step1.workZone.zoneValues);
	const openState = useSelector(state => state.configurator.present.step1.workZone.open);

	const onChangeToggleHandler = useCallback(
		e => {
			actions.changeHistoryWorkZone({
				render: !openState === true ? 'true' : 'false',
				state: !openState
			});
		},
		[openState]
	);

	const handlerOnChangeValue = useCallback((value, type) => actions.changeWorkZone({ type: type, value: value }), [actions]);
	const onChangeWidthHandler = useCallback(value => handlerOnChangeValue(value, 'changeZoneValuesHeight'), [handlerOnChangeValue]);
	const onChangeLenghtHandler = useCallback(value => handlerOnChangeValue(+value, 'changeZoneValuesWidth'), [handlerOnChangeValue]);

	return (
		<BlockWithToggle blockClasses='radio_link_buttons' title='Рабочая зона' openState={openState} onChangeToggle={onChangeToggleHandler}>
			<div className={`sidebar__radio__block--values ${openState === 'false' && 'hidden'}`}>
				<InputTypeNumber
					min={minZone.height}
					max='20000'
					blockClasses='sidebar__radio__block--values__length'
					label='Длина'
					defaultValue={width}
					name='working_zone_dimensions_width'
					id='working_zone_dimensions_width'
					required={true}
					onChange={onChangeLenghtHandler}
					useDelay={false}
				/>
				<span className='grayscale-gray-4 icon-16'>
					<CrossIcon />
				</span>
				<InputTypeNumber
					min={minZone.width}
					max='20000'
					blockClasses='sidebar__radio__block--values__width'
					label='Ширина'
					defaultValue={height}
					name='working_zone_dimensions_height'
					id='working_zone_dimensions_height'
					required={true}
					onChange={onChangeWidthHandler}
					useDelay={false}
				/>
			</div>
		</BlockWithToggle>
	);
};

export default memo(WorkZone);
