import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';

import { useActions } from 'hooks/useActions';

const OwnForm = ({ currentTable, type }) => {
	const actions = useActions();
	const currentTableOwnFormStatus = currentTable?.ownForm?.status;
	const currentTableOwnFormPrice = currentTable?.ownForm?.price;
	const currentTableOwnFormImg = currentTable?.ownForm?.img;

	const onToggleHandler = useCallback(() => {
		actions.setTableValue({
			type,
			obj: 'form',
			include: 'status',
			value: false
		});
		actions.setTableValue({
			type,
			obj: 'ownForm',
			include: 'status',
			value: !currentTableOwnFormStatus
		});
	}, [currentTableOwnFormStatus, actions, type]);

	const onDropHandler = useCallback(
		files => {
			actions.setTableValue({
				type,
				obj: 'ownForm',
				include: 'img',
				value: files
			});
		},
		[actions, type]
	);

	const onInput = useCallback(
		value => {
			actions.setTableValue({
				type: 'granit',
				obj: 'ownForm',
				include: 'price',
				value: value
			});
		},
		[actions]
	);

	return (
		<LoadForm
			formTitle='Форма клиента'
			formState={currentTableOwnFormStatus}
			label='Введите стоимость'
			onToggle={onToggleHandler}
			inputValue={currentTableOwnFormPrice}
			onInput={onInput}
			images={currentTableOwnFormImg}
			onDrop={onDropHandler}
		/>
	);
};

export default OwnForm;
