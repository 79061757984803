import React, { Fragment, useCallback, useEffect, useState } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import SecondButton from 'components/UI/button/second';
import PopUp from 'components/UI/popup/popup';
import TextInput from 'components/UI/input/text';
import SelectInput from 'components/UI/input/select';
import PrimaryButton from 'components/UI/button/primary';
import InputTypeNumber from 'components/UI/input/text/number';

import { generateEpitafia } from 'utils/forms/generateEpitafia';
import { standaloneSize } from 'utils/standaloneSize';
import { useActions } from 'hooks/useActions';

import { ReactComponent as Basket } from 'images/icons/basket.svg';

const FioAndDates = ({ burial, options, template }) => {
	const actions = useActions();
	const [openFIOPop, setOpenFIOPop] = useState(false);

	const currentTableFioStatus = burial.elements.fio.status;
	const currentTableFioItems = burial.elements.fio.items;

	useEffect(() => {
		if (currentTableFioItems.length === 0) setOpenFIOPop(false);
	}, [currentTableFioItems.length]);

	const onFioBasketClickHandler = useCallback(
		index => {
			if (currentTableFioItems.length === 1) setOpenFIOPop(false);
			actions.setElementsValue({
				general: 'elements',
				type: 'fio',
				value: {
					status: currentTableFioStatus,
					items: currentTableFioItems.filter((el, i) => i !== index)
				}
			});
		},
		[currentTableFioItems, currentTableFioStatus, actions]
	);

	const onToggle = useCallback(() => {
		actions.setElementsValue({
			general: 'elements',
			type: 'fio',
			value: {
				status: !currentTableFioStatus,
				items: []
			}
		});
	}, [currentTableFioStatus, actions]);

	const onEditProduct = useCallback(() => setOpenFIOPop(true), []);
	const onBasketProduct = useCallback(index => onFioBasketClickHandler(index), [onFioBasketClickHandler]);

	const addFioItem = useCallback(() => {
		setOpenFIOPop(true);
		actions.setElementsValue({
			general: 'elements',
			type: 'fio',
			value: {
				status: currentTableFioStatus,
				items: currentTableFioItems.concat(generateEpitafia(template.elementsName(currentTableFioItems.length), 0))
			}
		});
	}, [currentTableFioItems, currentTableFioStatus, actions]);

	const onInputChangeHandler = useCallback(
		(value, index, type = 'text') => {
			if (value === '') return;
			actions.setElementsValue({
				index: index,
				obj: type,
				value
			});
		},
		[actions]
	);

	const onSelectHandler = useCallback(
		(value, index) => {
			actions.setElementsValue({
				index: index,
				obj: 'fontSize',
				value
			});
		},
		[actions]
	);

	return (
		<BlockWithToggle
			title='ФИО + даты'
			openState={currentTableFioStatus}
			onChangeToggle={onToggle}
			blockClasses='sidebar__radio__block classForTablesForm'
			contentClasses='editDelete'
		>
			{currentTableFioItems.length !== 0 &&
				currentTableFioItems.map((el, index) => (
					<ActionBlock key={index} title={el.text} onEditClick={onEditProduct} onBasketClick={() => onBasketProduct(index)} />
				))}

			<SecondButton title='Добавить ФИО' onClick={addFioItem} classes='box__value__add' />
			<PopUp
				state={openFIOPop}
				classes='configurator__light-box elementsPopUp'
				onClick={e => setOpenFIOPop(!openFIOPop)}
				title={<h3>Данные усопшего:</h3>}
			>
				<div className='box'>
					{currentTableFioItems.map((el, index) => (
						<Fragment key={index}>
							<div className='row'>
								<TextInput
									type='text'
									defaultValue={el.text}
									blockClasses='restavrationInput'
									onChange={value => onInputChangeHandler(value, index)}
									delay={500}
								/>
								<div className='flex'>
									<SelectInput title={standaloneSize(el.fontSize)} items={options} onClick={value => onSelectHandler(value.value, index)} />
									<button onClick={() => onBasketProduct(index)}>
										<Basket />
									</button>
								</div>
							</div>
							<div className='row'>
								<InputTypeNumber
									blockClasses='own__form__input'
									label='Введите стоимость'
									defaultValue={el.price}
									name={`own__form__input__faska}`}
									id={`own__form__input__faska}`}
									onChange={price => onInputChangeHandler(price, index, 'price')}
									useDelay={false}
								/>
							</div>
						</Fragment>
					))}
					<PrimaryButton title='Добавить строку' onClick={addFioItem} className='btn box__value__add' />
				</div>
			</PopUp>
		</BlockWithToggle>
	);
};

export default FioAndDates;
