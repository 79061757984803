import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { materialsAPI } from './services/Materials';
import { complectsAPI } from './services/Complects';
import { productsAPI } from './services/Products';
import { categoriesAPI } from './services/Categories';
import { worksAPI } from './services/Works';

import userReducer from 'slices/userSlice';
import step1Reducer from 'slices/step1Slice';
import step2Reducer from 'slices/step2Slice';
import step3Reducer from 'slices/step3Slice';
import step4Reducer from 'slices/step4Slice';
import step5Reducer from 'slices/step5Slice';
import step6Reducer from 'slices/step6Slice';
import canvasReducer from 'slices/canvasSlice';
import configuratorStepsReducer from 'slices/configuratorStepsSlice';

import undoable, { excludeAction, combineFilters, groupByActionTypes } from 'redux-undo';

import { storeFilters, filterActions } from 'config/storeFilters';

const rootReducer = combineReducers({
	configurator: undoable(
		combineReducers({
			user: userReducer,
			step1: step1Reducer,
			step2: step2Reducer,
			step3: step3Reducer,
			step4: step4Reducer,
			step5: step5Reducer,
			step6: step6Reducer,
			configuratorSteps: configuratorStepsReducer
		}),
		{
			// groupBy: groupByActionTypes('step1/changeBurialPosition'),
			filter: combineFilters(excludeAction(storeFilters), filterActions)
		}
	),
	canvas: canvasReducer,
	[materialsAPI.reducerPath]: materialsAPI.reducer,
	[complectsAPI.reducerPath]: complectsAPI.reducer,
	[productsAPI.reducerPath]: productsAPI.reducer,
	[categoriesAPI.reducerPath]: categoriesAPI.reducer,
	[worksAPI.reducerPath]: worksAPI.reducer
});

export default configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			worksAPI.middleware,
			materialsAPI.middleware,
			complectsAPI.middleware,
			productsAPI.middleware,
			categoriesAPI.middleware
		)
	// devTools: process.env.NODE_ENV !== 'production',
});
