// import { defaultFontSizeTransform, fontSizeTransform } from './fontSizeTransform.config';
import { getHexColor } from '../components/UI/canvas/getHexFromImg';
import { A3, A6 } from './sizes';
import { textColors } from './canvas.config';

export const getTableTemplate = ({ width, height }) => {
	const PORTRAIT_GAP = 100; //mm;
	const PORTRAIT_NAME_GAP = 40;
	// const TOP_GAP = 220; //(portrait ? 220 : 0); //mm
	const THIRD_NAME_S = 20; //mm
	// const THIRD_NAME_S = fontSizeTransform[THIRD_NAME_PT];
	const THIRD_FIRST_GAP = 0; //20; //mm
	const FIRST_NAME_S = 20; //mm
	// const FIRST_NAME_S = fontSizeTransform[FIRST_NAME_PT];
	const FIRST_SECOND_GAP = 0; //15; //mm
	const SECOND_NAME_S = 22; //mm
	// const SECOND_NAME_S = fontSizeTransform[SECOND_NAME_PT];
	const EPITAFIA_S = 17; //mm
	// const EPITAFIA_S = fontSizeTransform[EPITAFIA_PT];
	const DATE_SIZE = 15; //mm
	const DATE_SIZE2 = 20; //mm год
	const DATE_GAP = 25; //mm

	const template = {};
	template.portrait = {
		x: 10,
		y: PORTRAIT_GAP,
		fitHeight: A3.height,
		type: 'image',
		side: 'front',
		align: 'center'
	};

	template.thirdName = {
		value: 'ФАМИЛИЯ',
		text: 'ФАМИЛИЯ',
		x: 10,
		y: template.portrait.y + A3.height + PORTRAIT_NAME_GAP,
		fontSize: THIRD_NAME_S,
		textAlign: 'center',
		fill: textColors.black.value,
		type: 'text'
	};

	template.firstName = {
		value: 'ИМЯ',
		text: 'ИМЯ',
		x: 10,
		y: template.thirdName.y + THIRD_NAME_S + THIRD_FIRST_GAP,
		fontSize: FIRST_NAME_S,
		textAlign: 'center',
		fill: textColors.black.value,
		type: 'text'
	};

	template.secondName = {
		value: 'ОТЧЕСТВО',
		text: 'ОТЧЕСТВО',
		x: 10,
		y: template.firstName.y + FIRST_NAME_S + FIRST_SECOND_GAP,
		fontSize: SECOND_NAME_S,
		textAlign: 'center',
		fill: textColors.black.value,
		type: 'text'
	};

	template.dates = {
		fontSize: DATE_SIZE,
		fontSize2: DATE_SIZE2,
		textAlign: 'center',
		fill: textColors.black.value,
		startDate: new Date(1964, 5, 11).toDateString(),
		endDate: new Date(1998, 2, 28).toDateString(),
		type: 'date'
	};

	template.epitafia = {
		x: 10,
		y: height - 100 - 2 * EPITAFIA_S,
		fontSize: EPITAFIA_S,
		textAlign: 'center',
		fill: textColors.black.value,

		type: 'text'
	};

	return template;
};
