import { dispatchEvent } from './dispatchEvent';

export const tombstoneEvents = {
	addTombstone({ id, attached_to_bed, bed }) {
		return dispatchEvent('add_tombstone', {
			grave: id,
			attached_to_bed,
			bed
		});
	},
	removeTombstone({ id, tombId }) {
		return dispatchEvent('remove_tombstone', {
			grave: id,
			id: tombId
		});
	},
	materialChange({ type, material, tombId, graveId }) {
		return dispatchEvent(`tombstone_${type}_grade_changed`, {
			material,
			id: tombId,
			grave: graveId
		});
	},
	async changeLeftRightWidth({ width, graveId, tombId }) {
		await dispatchEvent('tombstone_left_width_changed', {
			left_width: width,
			grave: graveId,
			id: tombId
		});
		await dispatchEvent('tombstone_right_width_changed', {
			right_width: width,
			grave: graveId,
			id: tombId
		});
	},
	changeBackWidth({ width, graveId, tombId }) {
		return dispatchEvent('tombstone_back_width_changed', {
			back_width: width,
			grave: graveId,
			id: tombId
		});
	},
	changeHeight({ height, graveId, tombId }) {
		return dispatchEvent('tombstone_height_changed', {
			height: height,
			grave: graveId,
			id: tombId
		});
	},
	modelChanged(grave_id, tombstone_id, shape_id) {
		return dispatchEvent('tombstone_model_changed', {
			grave_id,
			tombstone_id,
			shape_id
		});
	},
	bevelChange(grave_id, tombstone_id, bevel_id) {
		return dispatchEvent('tombstone_bevel_changed', {
			grave_id,
			tombstone_id,
			bevel_id
		});
	}
};
