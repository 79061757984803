import React from 'react';

import TypeStoneType from './TypeStoneType';
import TypeStoneMaterial from './TypeStoneMaterial';

const TypeStone = ({
	showJustMaterials = false,
	type,
	onChangeTypeHandler,
	stone,
	onChangeMaterialHandler,
	TypeTitle = 'Материал',
	materialTitle = 'Вид камня'
}) => {
	return (
		<>
			{!showJustMaterials && <TypeStoneType onChangeHandler={onChangeTypeHandler} defaultMaterial={type.title} title={TypeTitle} />}
			{(type.value === 'granite' || type === 'granite') && (
				<TypeStoneMaterial
					selectedStone={stone}
					material={type.value ?? type}
					onChangeHandler={onChangeMaterialHandler}
					title={materialTitle}
				/>
			)}
		</>
	);
};

export default TypeStone;
