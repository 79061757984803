import React, { useCallback, useMemo } from 'react';

import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import Sizes from './components/Sizes';
import Forms from './components/Forms';
import OwnForm from './components/OwnForm';
import OwnTable from './OwnTable';
import PortraitTable from './components/PortraitTable';
import FioAndDates from './components/FioAndDates';
import PhotoRestoration from './components/PhotoRestoration';

import { useActions } from 'hooks/useActions';
import { useTableSizes } from 'hooks/useTableSizes';
import { useShallowEffect } from '@mantine/hooks';

import Instalation from './components/Instalation';

const Granit = ({ burial, currentBurial, getAllTablesLength, granit, type: tableType, template }) => {
	const actions = useActions();

	const metalCeramItems = granit.items;
	const currentMetalItem = granit.currentItem;
	const maxAvailableTablesLength = burial.unionCount * 3;

	const currentTable = metalCeramItems[currentMetalItem];
	const typeTable = currentTable?.type;

	const currentTableForm = currentTable?.form?.form;

	const currentTableSize = currentTable?.size;

	const currentPortrait = currentTable?.portrait?.currentPortrait;
	const currentPortraitStatus = currentTable?.portrait?.status;
	const portraitItems = currentTable?.portrait?.items;

	const currentTableRotated = currentTable?.rotated;
	const captionEnabled = useMemo(() => currentTable?.fio?.items !== null, [currentTable?.fio?.items]);

	const { dataSizes, forms, currentSizeData, formsLoading, sizesLoading } = useTableSizes({
		tableVariation: 'granit',
		tableType: 'plaque-granite',
		currentTableForm,
		currentTableCaptionStatus: captionEnabled,
		currentTableSize,
		currentTableColorVariation: 'hide',
		currentTable
	});

	useShallowEffect(() => {
		if (!currentTableSize || currentTableSize === null) return;

		const price = parseFloat(currentSizeData?.price?.unit ?? 0);
		actions.setTableValue({
			type: tableType,
			obj: 'price',
			value: price,
			historySkip: true
		});
	}, [currentSizeData]);

	const changeTabHandler = useCallback(
		e => actions.changeCurrentTableItem({ type: tableType, itemNumber: +e.target.dataset.value }),
		[actions]
	);

	const addPlateHandler = useCallback(
		e => {
			actions.generateTable({
				type: tableType,
				actionType: 'create',
				tableType: 'normal',
				formType: forms?.[0]?.type,
				size: dataSizes?.[0]
			});
		},
		[actions, forms, dataSizes]
	);

	const removePlateHandler = useCallback(() => {
		actions.generateTable({ type: tableType, actionType: 'remove' });
	}, [burial?.id, currentTable?.id, actions]);

	const rotateCurrentTable = useCallback(() => {
		actions.setTableValue({
			type: tableType,
			obj: 'rotated',
			value: !currentTableRotated
		});
	}, [actions, currentTableRotated, tableType]);

	const showRotation = currentTable && typeTable !== 'own' && currentTableForm && currentTableSize;

	return (
		<>
			{metalCeramItems.length !== 0 && (
				<>
					<TabsComponent
						currentEl={currentMetalItem}
						currentBurial={currentBurial}
						AllEl={metalCeramItems}
						title='№'
						onClick={changeTabHandler}
					/>
					{typeTable === 'own' ? (
						<OwnTable type={tableType} currentTable={currentTable} />
					) : (
						<>
							<Forms formIcons={forms} loading={formsLoading} type={tableType} currentTableForm={currentTableForm} />
							<Sizes
								loading={sizesLoading}
								currentTableForm={currentTableForm}
								currentTableSize={currentTableSize}
								type='granit'
								sizes={dataSizes}
							/>

							{currentTableSize !== null && (
								<>
									<OwnForm currentTable={currentTable} type={tableType} template={template} />

									<FioAndDates currentTable={currentTable} type={tableType} template={template} />

									<PhotoRestoration currentTable={currentTable} type={tableType} />

									<Instalation currentTable={currentTable} tableType={tableType} />

									<PortraitTable
										type={tableType}
										currentPortraitStatus={currentPortraitStatus}
										currentPortrait={currentPortrait}
										portraitItems={portraitItems}
									/>
								</>
							)}
						</>
					)}
				</>
			)}
			{showRotation && <SecondButton title='Повернуть' onClick={rotateCurrentTable} classes={`mb12 box__value__switch`} />}
			{getAllTablesLength < maxAvailableTablesLength && (
				<SecondButton title='Добавить плиту' onClick={addPlateHandler} classes={`mb12 box__value__add`} />
			)}

			{metalCeramItems.length !== 0 && (
				<SecondButton title='Удалить плиту' id='delete_button' onClick={removePlateHandler} classes='mb24 box__value__basket' />
			)}
		</>
	);
};

export default Granit;
