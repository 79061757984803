import { findBurial, changeSelectProductStatus } from './../../step1Slice';

import { generateStella } from 'utils/helpers/step3_generateStella';
import { dimensions } from 'config/defaultDimensions.config';

const step3Stella = () => {
	const addStella = (state, action) => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;

		const stellaDimensions =
			state.defaultComplect[value]?.components.find(el => el.terms.includes('stella')).size.values || dimensions.stella;

		burial.stella.items = [
			...burial.stella.items,
			generateStella({
				title,
				value,
				stone,
				width: stellaDimensions?.width,
				length: stellaDimensions?.thickness,
				height: stellaDimensions?.height
			})
		];
		burial.stella.currentStella = burial.stella.items.length - 1;
		if (burial.stella.items.length === 2) burial.improvement.tileArea.price = 0;
	};

	const removeStella = (state, action) => {
		const burial = findBurial(state);
		burial.stella.items.pop();
		if (burial.stella.items.length != 0) burial.stella.currentStella = 0;
	};

	const changeCurrentStellalItem = (state, action) => {
		const burial = findBurial(state);
		burial.stella.currentStella = action.payload;
	};

	const changeMaxValuesPopUpStella = (state, action) => {
		const burial = findBurial(state);
		burial.stella.maxValues = action.payload;
	};

	const changeDisabledAlignInStellaPopUp = (state, action) => {
		const burial = findBurial(state);
		burial.stella.noDisabled.lineAlign = action.payload.line;
		burial.stella.noDisabled.parralelAlign = action.payload.parallel;
	};

	const changeDisableToCreateStella = (state, action) => {
		const burial = findBurial(state);
		burial.stella.noDisabled.stellaCreating = action.payload;
	};

	const changeValuesStellaPopUpAllFromEvent = (state, action) => {
		const burial = findBurial(state);
		burial.stella.position.items = action.payload[burial.stella.position.align];
	};

	const changeValuesStellaPopUpAll = (state, action) => {
		const burial = findBurial(state);
		burial.stella.position.items = action.payload;
	};

	const changeValuesStellaPopUp = (state, action) => {
		const burial = findBurial(state);

		function getMaxValus(arr, i = action.payload.index) {
			let value = 0;
			for (let index = 0; index < arr.length; index++) {
				if (index != i) value += burial.stella.position.items[index];
			}
			value = burial.stella.maxValues.merged > value ? burial.stella.maxValues.merged - value : burial.stella.maxValues.merged;
			return value >= action.payload.value ? action.payload.value : value;
		}
		if (burial.stella.items.length != 1) {
			if (burial.stella.position.align == 'center') {
				let arr = [1, 2, 4];
				if (arr.includes(action.payload.index)) {
					burial.stella.position.items[action.payload.index] = getMaxValus(arr);
				} else {
					if (action.payload.index == 0) {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.first >= action.payload.value ? action.payload.value : burial.stella.maxValues.first;
					} else {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.second >= action.payload.value ? action.payload.value : burial.stella.maxValues.second;
					}
				}
			} else if (burial.stella.position.align == 'left') {
				let arr = [1, 2, 3];
				if (arr.includes(action.payload.index)) {
					burial.stella.position.items[action.payload.index] = getMaxValus(arr);
				} else {
					if (action.payload.index == 0) {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.first >= action.payload.value ? action.payload.value : burial.stella.maxValues.first;
					} else {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.second >= action.payload.value ? action.payload.value : burial.stella.maxValues.second;
					}
				}
			} else {
				let arr = [0, 2, 4];
				if (arr.includes(action.payload.index)) {
					burial.stella.position.items[action.payload.index] = getMaxValus(arr);
				} else {
					if (action.payload.index == 1) {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.first >= action.payload.value ? action.payload.value : burial.stella.maxValues.first;
					} else {
						burial.stella.position.items[action.payload.index] =
							burial.stella.maxValues.second >= action.payload.value ? action.payload.value : burial.stella.maxValues.second;
					}
				}
			}
		} else {
			burial.stella.position.items[action.payload.index] = action.payload.value;
		}
	};

	const changeStellaPositionAlign = (state, action) => {
		const burial = findBurial(state);
		burial.stella.position.align = action.payload;
	};

	const changeStellaPositionStatus = (state, action) => {
		const burial = findBurial(state);
		burial.stella.position.status = action.payload;
	};

	const changeStellaPopUpState = (state, action) => {
		const burial = findBurial(state);
		burial.stella.positionBoxState = action.payload;
	};

	const changeStellaType = (state, action) => {
		const burial = findBurial(state);
		burial.stella.title = action.payload.title;
		burial.stella.value = action.payload.value;
		action.payload.value == 'granite' ? (burial.stella.stone = 'Габбро Диабаз') : (burial.stella.stone = 'Мрамор');
		// sendEventToChangeMaterial(burial.id, action.payload.value, 'stele_granite_grade_changed', 'stele_granite_grade_changed');
	};

	const changeStellaSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		burial.stella.items[burial.stella.currentStella].selectedProducts = changeSelectProductStatus(
			burial.stella.items[burial.stella.currentStella].selectedProducts,
			action.payload,
			true
		);
	};

	const changeStellaIndividualSizes = (state, action) => {
		const burial = findBurial(state);
		if (action.payload.current) {
			burial.stella.items[action.payload.current].individualSizes = action.payload.individual;
		} else {
			burial.stella.items[burial.stella.currentStella].individualSizes = action.payload.individual;
		}
	};

	const changeStellaIndividualStatus = (state, action) => {
		const burial = findBurial(state);
		burial.stella.items[burial.stella.currentStella].individualSizes.status = action.payload;
	};

	const changeStellaStone = (state, action) => {
		const burial = findBurial(state);
		burial.stella.items[burial.stella.currentStella].stone = action.payload;
	};

	const changeStellaSverlovka = (state, action) => {
		const burial = findBurial(state);
		burial.stella.items[burial.stella.currentStella].sverlovka = action.payload;
	};

	return {
		addStella,
		removeStella,
		changeCurrentStellalItem,
		changeMaxValuesPopUpStella,
		changeDisabledAlignInStellaPopUp,
		changeDisableToCreateStella,
		changeValuesStellaPopUpAllFromEvent,
		changeValuesStellaPopUpAll,
		changeValuesStellaPopUp,
		changeStellaPositionAlign,
		changeStellaPositionStatus,
		changeStellaPopUpState,
		changeStellaType,
		changeStellaSelectedProducts,
		changeStellaIndividualSizes,
		changeStellaIndividualStatus,
		changeStellaStone,
		changeStellaSverlovka
	};
};

export default step3Stella;
