import React, { useCallback, useMemo } from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import EngravingCatalog from './EngravingCatalog';
import EngravingEpitafia from './EngravingEpitafia';
import EngravingCoating from './EngravingCoating';

import { useActions } from 'hooks/useActions';
import EngravingType from './EngravingType';

import { getTombstoneTemplate } from 'config/tombstoneTemplates.config';

const Tombstone = ({ burial, currentBurial }) => {
	const actions = useActions();

	const currentTomb = burial.tombstone.currentTomb;
	const tombItems = burial.tombstone.items;
	const currentTomsItem = tombItems[currentTomb].engraving;

	const { engraving, epitafia, сoatingWithEngraving, type } = currentTomsItem;
	const currentEpitafiaItems = epitafia.items;

	const template = useMemo(() => {
		const stone = tombItems[currentTomb].stone ?? burial.materialStone;
		return getTombstoneTemplate({
			stone,
			width: tombItems[currentTomb].individualSizes.values.width,
			height: tombItems[currentTomb].individualSizes.values.length
		});
	}, [currentTomb, tombItems, burial.engraving, burial.materialStone]);

	const burialMaterial = burial.material.title == 'Мрамор';

	const changeData = useCallback(
		(type, value, include, skip = false) => {
			actions.changeEpitafia({
				type,
				include,
				value,
				moduleType: 'tombstone',
				currentEl: currentTomb,
				moduleElement: 'engraving',
				historySkip: skip
			});
		},
		[actions, currentTomb]
	);

	const onChangeTabHandler = useCallback(e => actions.changeCurrentTombstone(e.target.dataset.value), [actions]);

	return (
		<>
			<TabsComponent currentEl={currentTomb} currentBurial={currentBurial} AllEl={tombItems} title='СТ' onClick={onChangeTabHandler} />

			<EngravingType type={type} changeData={changeData} />
			<EngravingCatalog changeData={changeData} engraving={engraving} template={template} />
			<EngravingEpitafia
				type={type}
				material={burial.material.value}
				changeData={changeData}
				epitafia={epitafia}
				currentEpitafiaItems={currentEpitafiaItems}
				template={template}
			/>

			{currentEpitafiaItems !== null && currentEpitafiaItems?.text !== '' && burialMaterial === false && (
				<EngravingCoating сoatingWithEngraving={сoatingWithEngraving} changeData={changeData} template={template} />
			)}
		</>
	);
};

export default Tombstone;
