import React, { useCallback } from 'react';

import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import OwnTable from './OwnTable';
import Forms from './components/Forms';
import Sizes from './components/Sizes';
import ColorVariation from './components/ColorVariation';
import BackgroundCatalog from './components/BackgroundCatalog';
import ChangeClothes from './components/ChangeClothes';
import PhotoRestoration from './components/PhotoRestoration';
import Instalation from './components/Instalation';

import { useActions } from 'hooks/useActions';
import { useTableSizes } from 'hooks/useTableSizes';
import { useShallowEffect } from '@mantine/hooks';

import './tables.scss';

const MetalCeram = ({ burial, getAllTablesLength, currentBurial, type: tableType }) => {
	const actions = useActions();

	const { glass } = burial.tables;

	const metalCeramItems = glass.items;
	const currentMetalItem = glass.currentItem;
	const maxAvailableTablesLength = burial.unionCount * 3;

	const currentTable = metalCeramItems[currentMetalItem];
	const typeTable = currentTable?.type;

	const currentTableForm = currentTable?.form?.form;
	const currentTableSize = currentTable?.size;

	const currentTableColorVariation = currentTable?.colorVariation;

	const currentTableRotated = currentTable?.rotated;

	const { dataSizes, forms, currentSizeData, formsLoading, sizesLoading } = useTableSizes({
		tableVariation: 'glass',
		tableType: 'plaque-glass',
		currentTableForm,
		currentTableColorVariation,
		currentTableCaptionStatus: null,
		currentTableSize,
		currentTable
	});

	useShallowEffect(() => {
		if (!currentTableSize || currentTableSize === null) return;

		const price = parseFloat(currentSizeData?.price?.unit ?? 0);
		actions.setTableValue({
			type: tableType,
			obj: 'price',
			value: price,
			historySkip: true
		});
	}, [currentSizeData]);

	const onChangeTabHandler = useCallback(
		e => actions.changeCurrentTableItem({ type: tableType, itemNumber: +e.target.dataset.value }),
		[actions]
	);

	const removeTableHandler = useCallback(() => {
		actions.generateTable({ type: tableType, actionType: 'remove' });
	}, [burial.id, currentTable?.id, actions]);

	const addTableHandler = useCallback(
		e => {
			actions.generateTable({
				type: tableType,
				actionType: 'create',
				tableType: 'normal',
				formType: forms?.[0]?.type,
				size: dataSizes?.[0]
			});
		},
		[actions, forms, dataSizes]
	);

	const rotateCurrentTable = useCallback(() => {
		actions.setTableValue({
			type: tableType,
			obj: 'rotated',
			value: !currentTableRotated
		});
	}, [actions, currentTableRotated, tableType]);

	const showRotation = currentTable && typeTable !== 'own' && currentTableForm && currentTableSize;

	return (
		<>
			{metalCeramItems.length != 0 && (
				<>
					<TabsComponent
						currentEl={currentMetalItem}
						currentBurial={currentBurial}
						AllEl={metalCeramItems}
						title='№'
						onClick={onChangeTabHandler}
					/>
					{typeTable === 'own' ? (
						<OwnTable type={tableType} currentTable={currentTable} />
					) : (
						<>
							<Forms formIcons={forms} loading={formsLoading} type={tableType} currentTableForm={currentTableForm} />
							<Sizes currentTableSize={currentTableSize} loading={sizesLoading} type={tableType} sizes={dataSizes} />
							{currentTableSize !== null && (
								<>
									<ColorVariation currentTable={currentTable} type={tableType} />

									<BackgroundCatalog currentTable={currentTable} type={tableType} openState={false} />

									<ChangeClothes currentTable={currentTable} type={tableType} />

									<PhotoRestoration currentTable={currentTable} type={tableType} />

									<Instalation currentTable={currentTable} tableType={tableType} />
								</>
							)}
						</>
					)}
				</>
			)}
			{showRotation && <SecondButton title='Повернуть' onClick={rotateCurrentTable} classes={`mb12 box__value__switch`} />}
			{getAllTablesLength < maxAvailableTablesLength && (
				<SecondButton title='Добавить стекло' onClick={addTableHandler} classes={`mb12 box__value__add`} />
			)}

			{metalCeramItems.length !== 0 && (
				<SecondButton title='Удалить табличку' id='delete_button' onClick={removeTableHandler} classes='mb24 box__value__basket' />
			)}
		</>
	);
};

export default MetalCeram;
