export const fontSizeTransform = {
	// milimetrs: font size
	8: 8,
	10: 10,
	13: 13,
	15: 15,
	17: 17,
	20: 20,
	22: 22,
	25: 25,
	30: 30,
	32: 32,
	35: 35,
	40: 40,
	45: 45,
	50: 50
};

export const fonts = [
	{ value: 'artscript', title: 'Artscript', id: 3 },
	{ value: 'time-roman-bold', title: 'Times New Roman', id: 1 },
	{ value: 'CometaC', title: 'CometaC', id: 4 }
];

export const defaultFontFamily = fonts.find(el => el.title === 'Times New Roman');
export const defaultFontSizeTransform = 13 + ' мм';
export const fontSizeOptions = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));
