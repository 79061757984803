import React, { useCallback } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';

import { useActions } from 'hooks/useActions';

const Vignette = ({ currentTable, type }) => {
	const actions = useActions();
	const currentStateVignette = currentTable?.vignette;

	const onChangeToggle = useCallback(() => {
		actions.setTableValue({ type, obj: 'vignette', value: !currentStateVignette });
	}, [currentStateVignette, actions, type]);

	return <BlockWithToggle title='Виньетка' openState={currentStateVignette} onChangeToggle={onChangeToggle} disableContent={true} />;
};

export default Vignette;
