import { useCallback, useEffect } from 'react';
import { canvasEvents } from 'utils/events/canvas';
import { useWindowEvent } from '@mantine/hooks';

//TODO deprecated, mark to delete
export const useCanvasAttach = ({ canvasId, stageRef, item, stageDimensions, burialId }) => {
	const attachCanvas = useCallback(() => {
		canvasEvents.attachCanvas({
			type: item.canvasType,
			grave_id: burialId,
			element_id: canvasId,
			plate_id: canvasId,
			canvas: stageRef,
			id: canvasId,
			rotated: item.rotated
		});
	}, [canvasId, item.canvasType, canvasId, burialId, stageRef]);

	useEffect(() => attachCanvas(), [attachCanvas, canvasId, stageDimensions]);

	useEffect(() => {
		if (item.canvasType === 'plate') attachCanvas();
	}, [attachCanvas, item.canvasType, item?.form, item?.size, item?.individualSizes, item?.rotated, stageDimensions]);

	useWindowEvent(`stele_shape_changed_${canvasId}`, attachCanvas);

	return {
		grave_id: burialId,
		canvasType: item.canvasType,
		elementIndex: canvasId
	};
};
