import React, { memo, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from './../block/blockWithArrow';
import TextInput from './../input/text';
import BlockWithToggle from '../block/blockWithToggle';
import Productlist from './productsList';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';

import './productList.scss';

const ProductlistWithParams = ({
	fetchFunction,
	selectedProducts,
	moduleType,
	currentBurial,
	title,
	blockClasses,
	contentClasses,
	onClickProduct,
	openState = true,
	firstLabel = !moduleType.includes('stella') ? 'Длина' : 'Толщина',
	secondLabel = 'Ширина',
	thirdLabel = 'Высота',
	lengthDefault: firstValue,
	widthDefault: secondValue,
	heightDefault: thirdValue,
	onChangeLength,
	onChangeWidth,
	onChangeHeight,
	onChangeToggle,
	toggleState,
	taxId,
	stone = '',
	polishing = null,
	firstLabelApi = null,
	secondLabelApi = null,
	thirdLabelApi = null
}) => {
	const [dimensions, setDimensions] = useState(() => ({
		length: toggleState === true ? firstValue : '',
		width: toggleState === true ? secondValue : '',
		height: toggleState === true ? thirdValue : ''
	}));

	useLayoutEffect(() => {
		const data = {
			length: toggleState === true ? firstValue : '',
			width: toggleState === true ? secondValue : '',
			height: toggleState === true ? thirdValue : ''
		};
		setDimensions(prev => data);
	}, [toggleState]);

	const materials = useSelector(state => state.configurator.present.configuratorSteps.categories.materials);
	const material = useMemo(() => {
		let material = materials.find(el => el.title === stone);
		if (material) material = material.terms.length !== 1 ? material.terms.filter(el => el !== 'granite') : material.terms;
		return material;
	}, [stone, materials]);

	const query = useMemo(() => {
		const { length, width, height } = dimensions;
		const checkIsDefault = length === firstValue && width === secondValue && height === thirdValue;
		if (checkIsDefault) return '';

		const search = Object.keys(dimensions)
			.filter(el => dimensions[el] !== '')
			.map(el => parseFloat(dimensions[el]))
			.join('x');

		let obj = {};

		if (!(firstLabelApi && secondLabelApi && thirdLabelApi)) {
			obj.search = search;
		} else {
			if (length !== '') obj[firstLabelApi] = length;
			if (width !== '') obj[secondLabelApi] = width;
			if (height !== '') obj[thirdLabelApi] = height;
		}

		if (material) obj.material_tax = material[0];
		if (polishing) obj.polishing = polishing;

		return obj;
	}, [dimensions, material, polishing]);

	const onTyping = useCallback(
		(value, func, localStateValue, state) => {
			toggleState === true ? func(value, state) : setDimensions(prev => ({ ...prev, [localStateValue]: value }));
		},
		[toggleState]
	);

	return (
		<BlockWithArrow title={title} blockClasses={blockClasses} contentClasses={contentClasses} openState={openState}>
			{openState === true && (
				<div className='productList productList__catalog'>
					<div className='exception__item'>
						<div className='sidebar__radio__block--values sidebar__radio__block--values--full-width'>
							<TextInput
								blockClasses='sidebar__radio__block--values__length'
								label={firstLabel}
								defaultValue={dimensions.length}
								name={`${title}_lenght`}
								id={`${title}_lenght`}
								required={true}
								onChange={(value, state) => onTyping(value, onChangeLength, 'length', state)}
								useDelay={!toggleState}
							/>
							<span className='grayscale-gray-4 icon-16'>
								<CrossIcon />
							</span>
							<TextInput
								blockClasses='sidebar__radio__block--values__width'
								label={secondLabel}
								defaultValue={dimensions.width}
								name={`${title}_width`}
								id={`${title}_width`}
								required={true}
								onChange={(value, state) => onTyping(value, onChangeWidth, 'width', state)}
								useDelay={!toggleState}
							/>
							<span className='grayscale-gray-4 icon-16'>
								<CrossIcon />
							</span>
							<TextInput
								blockClasses='sidebar__radio__block--values__width'
								label={thirdLabel}
								defaultValue={dimensions.height}
								name={`${title}_height`}
								id={`${title}_height`}
								required={true}
								onChange={(value, state) => onTyping(value, onChangeHeight, 'height', state)}
								useDelay={!toggleState}
							/>
							<span>мм</span>
						</div>
					</div>
					<BlockWithToggle
						title='Применить мои размеры'
						onChangeToggle={onChangeToggle}
						openState={toggleState}
						blockClasses=''
						contentClasses='mt0'
						headingClasses='fs14'
					/>
					{toggleState == false && (
						<Productlist
							fetchData={fetchFunction}
							type={moduleType}
							selectedProducts={selectedProducts}
							currentBurial={currentBurial}
							query={query}
							onClickProduct={onClickProduct}
							taxId={taxId}
						/>
					)}
				</div>
			)}
		</BlockWithArrow>
	);
};

export default memo(ProductlistWithParams);
