import React from 'react';

const PrimaryButton = ({ title, onClick, id = 'continue_button', className = 'btn --svg__arrow-after', disabled = false }) => {
	return (
		<button id={id} disabled={disabled} className={className} onClick={onClick}>
			{title}
		</button>
	);
};

export default PrimaryButton;
