import React, { useCallback, useMemo } from 'react';

import PopUp from 'components/UI/popup/popup';
import PopUpBoxes from './PopUpBoxes';
import PopUpValues from './PopUpValues';
import PopUpStellaSizes from './PopUpStellaSizes';

import { useActions } from 'hooks/useActions';
import { usePositionCalculate } from './PopUpValues/hook/usePositionCalculate';

import './popupPosition.scss';

const PopUpPosition = ({ burial }) => {
	const actions = useActions();
	const boxes = useMemo(() => burial.boxes, [burial.boxes]);
	const stellaItems = useMemo(() => burial.stella.items, [burial.stella.items]);
	const pedestalItems = useMemo(() => burial.pedestal.items, [burial.pedestal.items]);
	const pedestalItemsLength = useMemo(() => pedestalItems.length, [pedestalItems.length]);
	const maxPosition = useMemo(() => burial.boxes.position.max, [burial.boxes.position.max]);
	const boxesLength = useMemo(() => boxes.items.length, [boxes.items.length]);

	const onPopUpToggle = useCallback(() => actions.changeBoxPopUpState(false), [actions]);

	usePositionCalculate({ burial });

	return (
		boxes.positionBoxState && (
			<PopUp state={boxes.positionBoxState} onClick={onPopUpToggle} classes='configurator__light-box boxes-popUp'>
				<h2 className='mb24 title'>Выбрать расположение:</h2>
				<div className='popup popup-position mb24'>
					<PopUpBoxes boxesLength={boxesLength} boxes={boxes} pedestalItemsLength={pedestalItemsLength} />
					<PopUpValues maxPosition={maxPosition} burial={burial} boxesLength={boxesLength} pedestalItemsLength={pedestalItemsLength} />
				</div>
				<div className='popup popup-position__add'>
					<PopUpStellaSizes items={stellaItems} burial={burial} />
				</div>
			</PopUp>
		)
	);
};

export default PopUpPosition;
