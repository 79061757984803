import React, { useCallback, useMemo } from 'react';

import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import Title from './components/Title';
import Forms from './components/Forms';
import Sizes from './components/Sizes';
import ColorVariation from './components/ColorVariation';
import FioAndDates from './components/Fio';
import Epitafia from './components/Epitafia';
import BackgroundCatalog from './components/BackgroundCatalog';
import ChangeClothes from './components/ChangeClothes';
import OwnTable from './OwnTable';
import Vignette from './components/Vignette';
import PhotoRestoration from './components/PhotoRestoration';

import { useActions } from 'hooks/useActions';
import { useTableSizes } from 'hooks/useTableSizes';
import { useShallowEffect } from '@mantine/hooks';

import Instalation from './components/Instalation';

const MetalCeram = ({ burial, getAllTablesLength, currentBurial, openState = false, metal, type: tableType, template }) => {
	const actions = useActions();
	const metalCeramItems = metal.items;
	const currentMetalItem = metal.currentItem;
	const maxAvailableTablesLength = burial.unionCount * 3;

	const currentTable = metalCeramItems[currentMetalItem];
	const typeTable = currentTable?.type;

	const taskTable = currentTable?.task?.state;
	const genderTaskTable = currentTable?.task?.gender;
	const currentTableForm = currentTable?.form?.form;
	const currentTableSize = currentTable?.size;

	const currentTableColorVariation = currentTable?.colorVariation;
	const currentTableRotated = currentTable?.rotated;

	const captionEnabled = useMemo(
		() => currentTable?.fio?.items !== null || currentTable?.epitafia?.items !== null,
		[currentTable?.fio?.items, currentTable?.epitafia?.items]
	);

	const { dataSizes, forms, currentSizeData, formsLoading, sizesLoading } = useTableSizes({
		tableVariation: 'metal',
		tableType: 'plaque-metal-ceramic',
		currentTableForm,
		currentTableColorVariation,
		currentTableCaptionStatus: captionEnabled,
		currentTableSize,
		currentTable
	});

	useShallowEffect(() => {
		if (!currentTableSize || currentTableSize === null) return;

		const price = parseFloat(currentSizeData?.price?.unit ?? 0);
		actions.setTableValue({
			type: tableType,
			obj: 'price',
			value: price,
			historySkip: true
		});
	}, [currentSizeData]);

	const checkTaskTableAndGender = useMemo(
		() => taskTable !== null && ((taskTable === 'Для портрета' && genderTaskTable !== null) || taskTable !== 'Для портрета'),
		[genderTaskTable, taskTable]
	);

	const addTable = useCallback(
		e => {
			actions.generateTable({
				type: tableType,
				actionType: 'create',
				tableType: 'normal',
				formType: forms?.[0]?.type,
				size: dataSizes?.[0]
			});
		},
		[actions, forms, dataSizes]
	);

	const addOwnTable = useCallback(e => actions.generateTable({ type: tableType, actionType: 'create', tableType: 'own' }), [actions]);

	const deleteTable = useCallback(() => {
		actions.generateTable({ type: tableType, actionType: 'remove' });
	}, [burial.id, currentTable, actions]);

	const onChangeTabChandler = useCallback(
		e => actions.changeCurrentTableItem({ type: tableType, itemNumber: +e.target.dataset.value }),
		[actions]
	);

	const rotateCurrentTable = useCallback(() => {
		actions.setTableValue({
			type: tableType,
			obj: 'rotated',
			value: !currentTableRotated
		});
	}, [actions, currentTableRotated, tableType]);

	const showRotation = currentTable && typeTable !== 'own' && currentTableForm && currentTableSize;

	return (
		<>
			{metalCeramItems.length !== 0 && currentTable && (
				<>
					<TabsComponent
						currentEl={currentMetalItem}
						currentBurial={currentBurial}
						AllEl={metalCeramItems}
						title='№'
						onClick={onChangeTabChandler}
					/>
					{typeTable === 'own' ? (
						<OwnTable currentTable={currentTable} type={tableType} />
					) : (
						<>
							<Title openState={openState} type={tableType} taskTable={taskTable} genderTaskTable={genderTaskTable} />

							{checkTaskTableAndGender && (
								<>
									<Forms formIcons={forms} loading={formsLoading} type={tableType} currentTableForm={currentTableForm} />
									<Sizes currentTableSize={currentTableSize} loading={sizesLoading} type={tableType} sizes={dataSizes} />
									{currentTableSize !== null && (
										<>
											<ColorVariation currentTable={currentTable} type={tableType} />
											<FioAndDates currentTable={currentTable} type={tableType} openState={openState} template={template} />
											<Epitafia currentTable={currentTable} type={tableType} openState={openState} template={template} />
											<PhotoRestoration currentTable={currentTable} type={tableType} />
											<Instalation currentTable={currentTable} tableType={tableType} />
											<BackgroundCatalog currentTable={currentTable} type={tableType} openState={openState} template={template} />
											<ChangeClothes currentTable={currentTable} type={tableType} />
											{currentTableForm == 'oval' && <Vignette type={tableType} currentTable={currentTable} />}
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
			{showRotation && <SecondButton title='Повернуть' onClick={rotateCurrentTable} classes={`mb12 box__value__switch`} />}
			{getAllTablesLength < maxAvailableTablesLength && (
				<>
					<SecondButton title='Добавить табличку' onClick={addTable} classes={`mb12 box__value__add`} />
					<SecondButton title='Своя табличка' onClick={addOwnTable} classes={`mb12 box__value__add`} />
				</>
			)}

			{metalCeramItems.length !== 0 && (
				<SecondButton title='Удалить табличку' id='delete_button' onClick={deleteTable} classes='mb24 box__value__basket' />
			)}
		</>
	);
};

export default MetalCeram;
