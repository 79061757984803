import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useConfiguratorCategories = (type, accessibility = 'modules') => {
	const categories = useSelector(state => state.configurator.present.configuratorSteps.categories?.[type] || []);
	return useMemo(() => {
		return categories.filter(cat => {
			if (type === 'materials') {
				return cat.accessibility.some(term => term.includes(accessibility));
			}

			return cat;
		});
	}, [categories]);
};
