import React, { useCallback } from 'react';

import PrimaryButton from 'components/UI/button/primary';
import TextInput from 'components/UI/input/text';
import SelectInput from 'components/UI/input/select';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

import { fontSizeTransform, fonts } from 'config/fontSizeTransform.config';
import { engravingTextColors } from 'config/canvas.config';
import { generateEpitafia } from '../../../../../utils/forms/generateEpitafia';
import { standaloneSize } from 'utils/standaloneSize';

import { ReactComponent as Basket } from 'images/icons/basket.svg';
import { ReactComponent as Left } from 'images/align/left.svg';
import { ReactComponent as Right } from 'images/align/right.svg';
import { ReactComponent as Center } from 'images/align/center.svg';
import { ReactComponent as AlignVert } from 'images/icons/alignVertical.svg';
import { OFFSET } from 'components/UI/canvas/constans';

const FontOptions = fonts;
const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));

const FioTab = ({ data, currentTableFioColor }) => {
	const actions = useActions();
	const { font, align, items } = data;

	const changeData = useCallback(
		(value, include) => actions.setEngravingItemsFioDateTable({ obj: 'fio', type: 'fio', include, value }),
		[]
	);

	const changeItemsData = useCallback(
		(actionType = null, value, include, index) =>
			actions.setEngravingItemsFioDateTableItems({ actionType, obj: 'fio', type: 'fio', include, value, index }),
		[]
	);

	return (
		<>
			<div className='box'>
				<SelectInput title={currentTableFioColor} items={engravingTextColors} onClick={value => changeData(value, 'color')} />
				<div
					className='row'
					style={{
						paddingBottom: '12px',
						borderBottom: '1px solid #c6cbd9'
					}}
				>
					<SelectInput title={font.title} items={FontOptions} onClick={value => changeData(value, 'font')} />
					<div className='align-btns'>
						<button className={`${align == 'left' ? 'active' : ''}`} onClick={e => changeData('left', 'align')}>
							<Left />
						</button>
						<button className={`${align == 'center' ? 'active' : ''}`} onClick={e => changeData('center', 'align')}>
							<Center />
						</button>
						<button className={`${align == 'right' ? 'active' : ''}`} onClick={e => changeData('right', 'align')}>
							<Right />
						</button>
					</div>
				</div>
				{items.map((el, index) => (
					<div className='row' key={index}>
						<TextInput
							type='text'
							defaultValue={el.text}
							blockClasses='restavrationInput'
							onChange={value => changeItemsData(null, value, 'text', index)}
						/>
						<div className='flex'>
							<InputTypeNumber
								blockClasses='own__form__input'
								label='Введите стоимость'
								defaultValue={el.price}
								name={`own__form__input__${index}`}
								id={`own__form__input__${index}`}
								onChange={price => changeItemsData(null, price, 'price', index)}
								useDelay={false}
							/>
							<SelectInput
								title={standaloneSize(el.fontSize)}
								items={options}
								onClick={value => changeItemsData(null, value.value, 'fontSize', index)}
							/>
							<button
								className={`vertical_align ${el.textAlign == 'vertical' ? 'active' : ''}`}
								onClick={() => {
									let value = el.textAlign == 'vertical' ? 'horisontal' : 'vertical';
									changeItemsData(null, value, 'textAlign', index);
								}}
							>
								<AlignVert />
							</button>
							<button onClick={() => changeItemsData('remove', null, 'fontSize', index)}>
								<Basket />
							</button>
						</div>
					</div>
				))}
			</div>
			<PrimaryButton
				title='Добавить строку'
				onClick={() => changeItemsData('create', generateEpitafia({ text: 'Текст', x: OFFSET, y: OFFSET }, 0))}
				className='btn box__value__add'
			/>
		</>
	);
};

export default FioTab;
