import React, { useEffect, useCallback } from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import SecondButton from 'components/UI/button/second';
import PrimaryButton from 'components/UI/button/primary';
import Processing from 'components/UI/other/processing';
import FlowerType from './flowerType';
import TypeStoneContent from './typeStone';
import PoperehnaProducts from './ProductsLists/Poperehna';
import ProdolnaProducts from './ProductsLists/Prodolna';

import { flowerEvents } from 'utils/events/flower';
import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';
import { v4 as uuid } from 'uuid';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { tombstoneEvents } from 'utils/events/tombstone';
import { dispatchEvent } from 'utils/events/dispatchEvent';
import { useSelector } from 'react-redux';

const Flower = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial, burials } = useBurialsData();
	const material = burial.material.value;
	const currentFlower = burial.flowers.currentFlower;
	const flowers = burial.flowers.items;
	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [actions, currentTab]);
	const categories = useConfiguratorCategories('modules');
	const poperehna_tax = categories.find(el => el.slug === 'poperehna')?.id;
	const prodolna_tax = categories.find(el => el.slug === 'prodolna')?.id;
	const zoneOpen = useSelector(state => state.configurator.present.step1.workZone.open);

	useEffect(() => {
		if (flowers.length !== 0) flowerEvents.showBed(burial.id);
	}, [currentBurial, burial.id, flowers.length]);

	useEffect(() => {
		if (flowers.length !== 0) {
			dispatchEvent(`setCameraTarget`, { target: 'flower', currentBurial, subTargetIndex: flowers[currentFlower].id });
		}
	}, [currentFlower, currentBurial, flowers]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Цветник</h1>
			{flowers.length > 0 && (
				<>
					<TabsComponent
						currentEl={currentFlower}
						currentBurial={currentBurial}
						AllEl={flowers}
						title='ЦВ'
						onClick={e => actions.changecurrentFlower(e.target.dataset.value)}
					/>
					<FlowerType burial={burial} currentFlower={currentFlower} flowers={flowers} />
					{material === 'granite' && (
						<TypeStoneContent flowers={flowers} burial={burial} currentFlower={currentFlower} material={material} />
					)}
					<ProdolnaProducts
						flowers={flowers}
						currentFlower={currentFlower}
						burial={burial}
						burials={burials}
						currentBurial={currentBurial}
						cat_tax={prodolna_tax}
					/>
					<PoperehnaProducts
						flowers={flowers}
						currentFlower={currentFlower}
						burial={burial}
						burials={burials}
						currentBurial={currentBurial}
						cat_tax={poperehna_tax}
					/>
					<Processing />
				</>
			)}
			{(flowers.length < 2 || zoneOpen) && (
				<SecondButton
					title='Добавить цветник'
					classes='mb24 box__value__add'
					onClick={() => {
						const unique_id = uuid();
						burial.tombstone.items.forEach((tombstone, index) => {
							tombstoneEvents.removeTombstone({ id: burial.id, tombId: index });
						});
						actions.addFlower(unique_id);
						flowerEvents.addFlower({
							flowerType: flowers.length === 0 ? 'first' : 'second',
							id: burial.id,
							flowerID: unique_id,
							state: 'standard'
						});
					}}
				/>
			)}
			{flowers.length > 0 && (flowers.length === 1 || parseFloat(currentFlower) !== 0) && (
				<SecondButton
					title='Удалить цветник'
					classes='mb24 box__value__delete step1__exclude__add'
					onClick={() => {
						actions.removeFlower();
						if (flowers.length === 1) {
							burial.tombstone.items.forEach((tombstone, index) => {
								tombstoneEvents.removeTombstone({ id: burial.id, tombId: index });
							});
							flowerEvents.addFlower({ flowerType: 'first', id: burial.id, flowerID: flowers[currentFlower].id, state: 'without' });
						} else {
							flowerEvents.deleteSecondFlower({
								flowerType: 'second',
								id: burial.id,
								flowerID: flowers[currentFlower].id
							});
						}
					}}
				/>
			)}
			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Flower;
