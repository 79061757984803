import React, { useCallback, useMemo } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import TabsComponent from 'components/UI/tabs/tabs';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';
import { getInstallation } from 'utils/helpers/complects/getInstallation';
import { asyncWrapper } from 'utils/asyncWrapper';

const installationTypes = [
	{
		title: 'Стандартное (30 см)',
		slugs: 'installation-grave-30',
		catId: 56
	},
	{
		title: 'Бюджетное (15 см)',
		slugs: 'installation-grave-15',
		catId: 55
	}
	// {
	// 	title: 'Установка на землю',
	// 	slugs: 'installation-grave-on-the-ground',
	// 	catId: 54
	// }
];

const Installation = ({ burial, burials, currentBurial }) => {
	const actions = useActions();
	const improvedBurials = burials.map(el => ({ ...el, hide: el.selected !== 'improved' }));
	const tilePrice = useMemo(() => improvedBurials.map(el => el.improvement.tileArea.price), [burial.installation.type]);
	const material = burial.material.value;
	const stellaDimensions = burial.stella.items[0].individualSizes;

	const onChangeCustomPrice = useCallback(price => actions.changeTileAreaPrice(price), []);
	const onChangeInstallation = useCallback(
		async item => {
			await asyncWrapper(async () => {
				const installationPrice = await getInstallation(
					material,
					{
						width: stellaDimensions.values.width,
						thickness: stellaDimensions.values.length,
						height: stellaDimensions.values.height
					},
					burial.stella.items.length,
					item.catId
				);
				onChangeCustomPrice(installationPrice);
				actions.changeBurialInstallation(item.title);
			});
		},
		[material, stellaDimensions]
	);

	return (
		<BlockWithArrow title={'Установка'} blockClasses='sidebar__radio__block'>
			<TabsComponent
				currentEl={currentBurial}
				currentBurial={currentBurial}
				AllEl={improvedBurials}
				title='Зах.'
				onClick={e => actions.changeCurrentBurial(e.target.dataset.value)}
			/>
			{installationTypes.map((el, i) => (
				<RadioInput
					key={i}
					title={el.title}
					defaultChecked={el.title === burial.installation.type}
					onChange={onChangeInstallation.bind(null, el)}
					name={`installation_${i}`}
					id={`installation_${i}`}
					value={el.title}
				/>
			))}
			{tilePrice[currentBurial] === 0 && (
				<InputTypeNumber
					blockClasses='own__form__input'
					label='Введите стоимость'
					defaultValue={burial.improvement.tileArea.price}
					name={`own__form__input__installation`}
					id={`own__form__input__installation`}
					onChange={onChangeCustomPrice}
					useDelay={false}
				/>
			)}
		</BlockWithArrow>
	);
};

export default Installation;
