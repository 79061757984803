import { createSlice } from '@reduxjs/toolkit';
import { changeSelectProductStatus } from './step1Slice';

const initialState = {
	tileIsNecessary: true,
	tile: {
		vertical: [],
		horizontal: []
	},
	pavingEditor: {
		rectangles: [],
		fence: [],
		rectCount: 0,
		rectCountByMaterial: null,
		rectCountByMaterialVert: null,
		fenceCount: 0
	},
	decoratedElements: {
		stone: {
			title: 'Каменные элементы',
			material: null,
			status: false,
			items: []
		},
		metal: {
			title: 'Металлические элементы',
			status: false,
			items: []
		},
		forged: {
			title: 'Кованные элементы',
			status: false,
			items: []
		},
		sculptures: {
			title: 'Скульптуры',
			status: false,
			items: []
		},
		backfillFlower: {
			status: false,
			price: 0
		},
		backfillArea: {
			status: false,
			price: 0
		}
	},
	fence: {
		status: false,
		selectedProducts: [],
		forseOpen: false
	}
};

export const step5Slice = createSlice({
	name: 'step5',
	initialState,
	reducers: {
		setRectCount: (state, action) => {
			state.pavingEditor.rectCount = action.payload;
		},
		setRectCountByMaterial: (state, action) => {
			state.pavingEditor.rectCountByMaterial = action.payload;
		},
		setRectCountByMaterialVert: (state, action) => {
			state.pavingEditor.rectCountByMaterialVert = action.payload;
		},
		setFenceCount: (state, action) => {
			state.pavingEditor.fenceCount = action.payload;
		},
		setRectangles: (state, action) => {
			state.pavingEditor.rectangles = action.payload;
		},
		addRectangles: (state, action) => {
			const { rectangles, id } = action.payload;
			state.pavingEditor.rectangles = [...state.pavingEditor.rectangles.filter(rect => rect.burialId !== id), ...rectangles];
		},
		setFence: (state, action) => {
			state.pavingEditor.fence = action.payload;
		},
		toggleTileIsNecessary: state => {
			state.tileIsNecessary = !state.tileIsNecessary;
		},
		changeTileStone: (state, action) => {
			const { type, value } = action.payload;
			state.tile[type] = [value];
		},
		changeDecoratedElementsStatus: (state, action) => {
			const { type, values } = action.payload;
			state.decoratedElements[type] = {
				status: values,
				items: []
			};
		},
		changeDecoratedElementsMaterial: (state, action) => {
			const { type, values } = action.payload;
			state.decoratedElements[type].material = values;
		},
		changeDecoratedElementsSelectedProducts: (state, action) => {
			const { type, values, clone } = action.payload;
			if (clone) {
				state.decoratedElements[type].items = [...state.decoratedElements[type].items, values];
			} else {
				state.decoratedElements[type].items = changeSelectProductStatus(state.decoratedElements[type].items, values);
			}
		},
		changeFilling: (state, action) => {
			const { type, values } = action.payload;
			state.decoratedElements[type] = values;
		},
		changeFenceData: (state, action) => {
			const { status, data } = action.payload;
			state.fence = { ...state.fence, status, selectedProducts: data };
			if (!data.length) state.pavingEditor = { ...state.pavingEditor, fence: [], fenceCount: 0 };
			state.fence.forseOpen = data.length === 0 && status ? true : false;
		}
	}
});

export const step5SliceActions = step5Slice.actions;

export default step5Slice.reducer;
