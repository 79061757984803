import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';
import TabsComponent from 'components/UI/tabs/tabs';
import FaskaModule from 'components/UI/bigComponents/FaskaModule';

import { useActions } from 'hooks/useActions';

const Pedestal = ({ burial, currentBurial }) => {
	const actions = useActions();

	const currentPedestal = burial.pedestal.currentPedestal;
	const pedestalsLenght = burial.pedestal.items.length;

	const getValueItem = useCallback(
		(moduleObject, moduleType) => {
			return burial.pedestal.items[currentPedestal].form[moduleObject][moduleType];
		},
		[burial.pedestal.items, currentPedestal]
	);

	const clientFormStatus = getValueItem('clienForm', 'status');
	const clientFormValue = getValueItem('clienForm', 'price');
	const clientFormImages = getValueItem('clienForm', 'img');

	return (
		<>
			{pedestalsLenght !== 0 && (
				<>
					<TabsComponent
						currentEl={currentPedestal}
						currentBurial={currentBurial}
						AllEl={burial.pedestal.items}
						title='ТБ'
						onClick={e => actions.changeCurrentPedestalItem(e.target.dataset.value)}
					/>
					<LoadForm
						formTitle='Форма клиента'
						formState={clientFormStatus}
						onToggle={() => {
							actions.changeFormElements({
								type: 'pedestal',
								current: currentPedestal,
								moduleObject: 'clienForm',
								value: { status: !clientFormStatus, price: 0, img: [] }
							});
						}}
						label='Введите стоимость'
						inputValue={clientFormValue}
						onInput={value => {
							actions.changeFormElements({
								type: 'pedestal',
								current: currentPedestal,
								moduleObject: 'clienForm',
								moduleType: 'price',
								value: value
							});
						}}
						images={clientFormImages}
						onDrop={files => {
							actions.changeFormElements({
								type: 'pedestal',
								current: currentPedestal,
								moduleObject: 'clienForm',
								moduleType: 'img',
								value: files
							});
						}}
					/>
					<FaskaModule type='pedestal' currentElement={currentPedestal} />
				</>
			)}
		</>
	);
};

export default Pedestal;
