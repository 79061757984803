import React, { useCallback, useMemo } from 'react';

import CeramGranit from './CeramGranit';
import Glass from './Glass';
import MetalCeram from './MetalCeram';
import Granit from './Granit';

import SidebarElementTab from 'components/UI/tabs/sidebarElementTab';
import SecondButton from 'components/UI/button/second';
import PrimaryButton from 'components/UI/button/primary';

import useBurialsData from 'hooks/useBurialsData';
import { useActions } from 'hooks/useActions';
// import { tablesEvents } from 'utils/events/tables';
import { getTableTemplate } from 'config/tableTemplates.config';
import { tablesTabs } from 'utils/helpers/tables/step3_createTables';

import './tables.scss';

const tabs = tablesTabs.map(el => ({ ...el, icon: <el.icon /> }));

const Tables = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();
	const activeTab = burial.tablesCurrentTab;
	const currentTablesType = tablesTabs.find(el => el.name === activeTab);
	const { metal, ceramic, glass, granit } = burial.tables;
	const getAllTablesLength = useMemo(
		() => metal.items.length + ceramic.items.length + glass.items.length + granit.items.length,
		[ceramic.items.length, glass.items.length, granit.items.length, metal.items.length]
	);

	const currentPlates = useMemo(() => burial.tables[currentTablesType.type], [burial.tables, currentTablesType.type]);
	const currentPlate = useMemo(() => currentPlates.items[currentPlates.currentItem], [currentPlates.currentItem, currentPlates.items]);
	const checkShowPositionTab = useMemo(() => {
		const checkType = currentPlate && currentPlate?.type !== 'own';
		const checkAnotherType = currentTablesType.type === 'glass' || currentTablesType.type === 'granit';
		const checkTaskType = !checkAnotherType
			? currentPlate?.task?.state === 'Именная табличка' || (currentPlate?.task?.state !== 'Именная табличка' && currentPlate?.task?.gender)
			: true;

		return getAllTablesLength >= 1 && checkType && checkTaskType;
	}, [currentPlate, currentTablesType.type, getAllTablesLength]);

	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [currentTab, actions]);

	const openCanvasHandler = useCallback(() => {
		actions.toggleCanvasContainer({
			status: true,
			id: currentPlate.id
		});
	}, [currentPlate?.id]);

	const template = useMemo(() => {
		return getTableTemplate({
			width: currentPlate?.size?.values.width,
			height: currentPlate?.size?.values.height
		});
	}, [currentPlate]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Портрет и таблички</h1>
			<SidebarElementTab activeTab={activeTab} changeTab={actions.changeCurrentTab} items={tabs} classNames='tables__tabs' />
			{activeTab == 'Метал.Керам' && (
				<MetalCeram
					metal={metal}
					type='metal'
					burial={burial}
					currentBurial={currentBurial}
					getAllTablesLength={getAllTablesLength}
					template={template}
				/>
			)}
			{activeTab == 'Фарфор' && (
				<CeramGranit
					ceramic={ceramic}
					type='ceramic'
					burial={burial}
					currentBurial={currentBurial}
					getAllTablesLength={getAllTablesLength}
					template={template}
				/>
			)}
			{activeTab == 'Стекло' && (
				<Glass
					glass={glass}
					type='glass'
					burial={burial}
					currentBurial={currentBurial}
					getAllTablesLength={getAllTablesLength}
					template={template}
				/>
			)}
			{activeTab == 'гранит.плит' && (
				<Granit
					granit={granit}
					type='granit'
					burial={burial}
					currentBurial={currentBurial}
					getAllTablesLength={getAllTablesLength}
					template={template}
				/>
			)}

			{checkShowPositionTab && (
				<SecondButton title='Расположение' id='edit__tables_position' onClick={openCanvasHandler} classes='mb24 box__value__edit' />
			)}

			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Tables;
