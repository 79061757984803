import React, { useCallback } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';
import { withCustomPrice } from 'components/UI/HOC/withCustomPrice';

import { useActions } from 'hooks/useActions';
import { worksAPI } from 'services/Works';
import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';

const Frames = ({
	framesStatus,
	selectedProductsFrames,
	currentStella,
	children,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	isFetching,
	isError,
	changedData,
	customPrice,
	stellaHeight,
	price,
	changeCustomPrice
}) => {
	const actions = useActions();

	const refetchDataHandler = useCallback(
		(refetch, action) => {
			if (!action) {
				return refetch({ args: { work_tax: 45, size_height: stellaHeight, per_page: 100 } }, true);
			}
			refetch({ args: { work_tax: 43, per_page: 100 } }, true);
		},
		[stellaHeight]
	);
	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (selectedProductsFrames.length === 0 || price === undefined) return;
			actions.changeFormElements({
				type: 'stella',
				current: currentStella,
				moduleObject: 'frames',
				moduleType: 'selectedProducts',
				value: [{ ...selectedProductsFrames[0], price: { unit: parseFloat(price) } }]
			});
		},
		[selectedProductsFrames]
	);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price,
		changeCustomPrice
	});

	return (
		<CatalogForm
			formTitle='Рамки'
			formState={framesStatus}
			onToggle={() => {
				actions.changeFormElements({
					type: 'stella',
					current: currentStella,
					moduleObject: 'frames',
					value: { status: !framesStatus, selectedProducts: [] }
				});
			}}
			onClickProductCard={item => {
				const include = selectedProductsFrames.some(el => el.sku === item.sku);
				const price = item.price?.unit ?? customPrice;

				actions.changeFormElements({
					type: 'stella',
					current: currentStella,
					moduleObject: 'frames',
					moduleType: 'selectedProducts',
					value: include ? [] : [{ ...item, price: { unit: price } }]
				});
			}}
			btnTitle='Каталог рамок'
			products={changedData.items}
			selectedProducts={selectedProductsFrames}
			refetch={refetchData}
			hook
			isLoading={isFetching}
			isError={isError}
		>
			{children}
		</CatalogForm>
	);
};

const withCustomPriceFrames = withCustomPrice(Frames, worksAPI.useLazyFetchWorksQuery);
export default withCustomPriceFrames;
