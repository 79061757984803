import React from 'react';

import { stellaEvents } from 'utils/events/stella';
import { useActions } from 'hooks/useActions';

const ParralelAlign = ({ burial, alignType }) => {
	const actions = useActions();
	const centerClasses = alignType() == 'center' ? 'active' : '';
	const rightClasses = alignType() == 'right' ? 'active' : '';
	const leftClasses = alignType() == 'left' ? 'active' : '';

	return (
		<>
			<a
				onClick={() => {
					actions.changeStellaPositionAlign('center');
					stellaEvents.changeAlignStella(burial.id, 'parallel');
				}}
				className={`stella-position-variables__item ${centerClasses}`}
				data-value='center'
			>
				<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect y='5' width='16' height='2' rx='1' fill='#9A9AAF' />
					<rect y='9' width='16' height='2' rx='1' fill='#9A9AAF' />
				</svg>
			</a>
			<a
				onClick={() => {
					actions.changeStellaPositionAlign('right');
					stellaEvents.changeAlignStella(burial.id, 'parallel');
				}}
				className={`stella-position-variables__item ${rightClasses}`}
				data-value='right'
			>
				<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='7' y='5' width='9' height='2' rx='1' fill='#9A9AAF' />
					<rect y='9' width='9' height='2' rx='1' fill='#9A9AAF' />
				</svg>
			</a>
			<a
				onClick={() => {
					actions.changeStellaPositionAlign('left');
					stellaEvents.changeAlignStella(burial.id, 'parallel');
				}}
				className={`stella-position-variables__item ${leftClasses}`}
				data-value='left'
			>
				<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect y='5' width='9' height='2' rx='1' fill='#9A9AAF' />
					<rect x='7' y='9' width='9' height='2' rx='1' fill='#9A9AAF' />
				</svg>
			</a>
		</>
	);
};

export default ParralelAlign;
