import React, { useCallback } from 'react';
import CatalogForm from 'components/UI/form/catalogForm';

import { getFitArgs } from '../../../../config/sizes';
import { worksAPI } from 'services/Works';

const EngravingCatalog = ({ changeData, engraving, template }) => {
	const items = engraving.items;

	const [refetch, { data, isFetching, isError }] = worksAPI.useLazyFetchWorksQuery();

	const refetchData = useCallback(() => refetch({ args: { work_tax: 24, per_page: 100 } }, true), []); // the second arg is preferCacheValue

	return (
		<CatalogForm
			formTitle='Гравировка'
			formState={engraving.status}
			onToggle={() => {
				changeData(
					'engraving',
					{
						status: !engraving.status,
						items: []
					},
					null
				);
			}}
			onClickProductCard={item =>
				changeData(
					'engraving',
					items.length == 0 || items[0]?.sku != item.sku ? [{ ...item, ...getFitArgs(item), ...template.engraving }] : [],
					'items'
				)
			}
			btnTitle='Каталог гравировки'
			products={data ?? []}
			selectedProducts={items}
			hook
			isLoading={isFetching}
			isError={isError}
			refetch={refetchData}
			parentClasses='exception__block engraving'
		/>
	);
};

export default EngravingCatalog;
