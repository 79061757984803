import React from 'react';

const RadioInput = ({
	title,
	children = title,
	defaultChecked = false,
	onChange = () => {},
	name,
	id,
	readOnly = false,
	value = title,
	classNames
}) => {
	return (
		<div className={classNames}>
			<input type='radio' id={id} name={name} onChange={onChange} checked={defaultChecked} readOnly={readOnly} data-value={value} />
			<label htmlFor={id}>{children}</label>
		</div>
	);
};

export default RadioInput;
