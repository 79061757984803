import { findBurial, changeSelectProductStatus } from './../../step1Slice';

const step3Elements = () => {
	const changeElementsSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		const { type, value, module } = action.payload;
		if (type == 'tombstone') {
			if (module == 'selectedProducts') {
				burial.tombstone.items[burial.tombstone.currentTomb].elements.elems.selectedProducts = changeSelectProductStatus(
					burial.tombstone.items[burial.tombstone.currentTomb].elements.elems.selectedProducts,
					value,
					true
				);
			} else {
				burial.tombstone.items[burial.tombstone.currentTomb].elements.elems.status = value;
			}
		} else {
			if (module == 'selectedProducts') {
				burial.elements.elems.selectedProducts = changeSelectProductStatus(burial.elements.elems.selectedProducts, value, true);
			} else {
				burial.elements.elems.status = value;
			}
		}
	};

	const changeEpitafia = (state, action) => {
		const burial = findBurial(state);
		const { type, include, value, moduleType, currentEl, moduleElement = 'elements' } = action.payload;
		if (moduleType != null) {
			if (include != null) {
				burial[moduleType].items[currentEl][moduleElement][type][include] = value;
			} else {
				burial[moduleType].items[currentEl][moduleElement][type] = value;
			}
		} else {
			if (include != null) {
				burial[moduleElement][type][include] = value;
			} else {
				burial[moduleElement][type] = value;
			}
		}
	};

	const setElementsValue = (state, action) => {
		const burial = findBurial(state);
		const { index, obj, value, general, type } = action.payload;
		if (general != null) {
			burial[general][type] = value;
		} else {
			burial.elements.fio.items[index][obj] = value;
		}
	};

	return {
		changeElementsSelectedProducts,
		changeEpitafia,
		setElementsValue
	};
};

export default step3Elements;
