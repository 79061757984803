import React from 'react';

import { stellaEvents } from 'utils/events/stella';
import { useActions } from 'hooks/useActions';

const LineAlign = ({ burial, alignType }) => {
	const actions = useActions();
	const classes = alignType() == 'space' ? 'active' : '';

	return (
		<>
			<a
				onClick={() => {
					actions.changeStellaPositionAlign('space');
					stellaEvents.changeAlignStella(burial.id, 'line');
				}}
				className={`stella-position-variables__item ${classes}`}
				data-value='space'
			>
				<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect x='9' y='7' width='7' height='2' rx='1' fill='#9A9AAF' />
					<rect y='7' width='7' height='2' rx='1' fill='#9A9AAF' />
				</svg>
			</a>
		</>
	);
};

export default LineAlign;
