// import { dateToRoman } from 'utils/dateToRoman';
import { v4 as uuid } from 'uuid';
import { elementsTextColor } from 'config/canvas.config';
// import { stelaTemplates, getStelaTemplate } from 'config/stelaTemplates.config';
// import { getHexColor } from 'components/UI/canvas/getHexFromImg';

export const generateStellaObj = (burial, item) => {
	let scope = [];
	const { creed, engraving, fio: fioEngraving, ownForm, portrait, epitafia: engravingEpitafia } = burial.engraving;
	const { fio: elementsFio, epitafia: elementsEpitafia, elems } = burial.elements;
	const {
		status: stellaFormStatus,
		side: stellaFormSides,
		selectedProducts: stellaForm
	} = burial.stella.items[burial.stella.currentStella].form.decoration;

	const { status: stellaFramesStatus, selectedProducts: stellaFramesProducts } =
		burial.stella.items[burial.stella.currentStella].form.frames;

	const stone = burial.stella.items[0].stone ?? burial.materialStone;
	// const template = getStelaTemplate({
	// 	stone,
	// 	width: item.stageDimensions.width,
	// 	height: item.stageDimensions.height,
	// 	portrait: portrait?.items?.length
	// });
	const fioItems = [fioEngraving.items].map(el => {
		if (el === null) return [];
		let arr = [el];
		const { datesItems, fio } = el;
		const transformedDatesItems = datesItems.items.map((item, index) => {
			// const [dateOfBorn, dateOfDeath] = item.value.split(' – ');
			return {
				...item,
				type: datesItems.numericTypes === 'Арабские' ? 'date' : 'dateRome',
				fontFamily: datesItems.font.value,
				textAlign: datesItems.align,
				// numericTypes: datesItems.numericTypes,
				fill: datesItems.color.value,
				index
			};
		});
		// console.log('transformedDatesItems');
		// console.log(transformedDatesItems);

		const transformedFioItems = fio.items.map(item => ({
			...item,
			fontFamily: fio.font.value,
			// fontSize: fio.textSize,
			textAlign: fio.align, //template[item.class].textAlign, // fio.align,
			fill: fio.color.value
		}));

		arr = [...transformedFioItems, ...transformedDatesItems];

		return arr.map(item => ({ status: fioEngraving.status, obj: item, category: `engraving-text-${item.id}`, type: item.type }));
	});

	const portraitItems =
		portrait?.items.map(el => ({
			status: portrait.status,
			obj: el.img?.[0]?.preview
				? {
						...el,
						id: el.id ?? uuid(),
						// alignBySide: { status: true, type: 'front' },
						// ...template['portrait'],
						url: el.img[0].preview
				  }
				: null,
			category: `engraving-portrait-${el.id}`,
			type: 'image'
		})) ?? [];

	scope = [
		...fioItems.flat(),
		...portraitItems,
		{ status: creed.status, obj: creed?.items[0], category: 'engraving-creed', type: 'image' },
		{
			status: engraving.status,
			obj: engraving?.items[0] ? { ...engraving.items[0], type: 'image' } : null,
			category: 'engraving-engraving',
			type: 'image'
		},
		// Бронзовые элементы эпитафия
		{
			status: elementsEpitafia.status,
			obj: { ...elementsEpitafia, fill: elementsTextColor.value, type: 'text' },
			category: 'elements-epitafia',
			type: 'text'
		},
		// Бронзовые элементы ФИО
		...elementsFio.items.map(el => ({
			status: elementsFio.status,
			obj: { ...el, fill: elementsTextColor.value, type: 'text' },
			category: `elements-fio-${el.id}`,
			type: 'text'
		})),
		{
			status: ownForm.status,
			obj: ownForm.img?.[0]?.preview ? { ...ownForm, img: ownForm.img?.[0]?.preview } : null,
			type: 'image',
			category: 'engraving-own-picture'
		}
	];

	if (engravingEpitafia?.items) {
		scope.push({
			status: engravingEpitafia.status,
			obj: {
				...engravingEpitafia?.items
			},
			category: 'engraving-epitafia',
			type: 'text'
		});
	}

	// Рамки
	if (stellaFramesProducts.length) {
		stellaFramesProducts.forEach(el => {
			// console.log(el);
			scope.push({
				status: stellaFramesStatus,
				obj: { ...el, x: 0, y: 0, moveBottom: true, alignBySide: { status: true, type: 'front' }, frame: true, type: 'image' },
				category: `stella-frames-front`,
				type: 'image'
			});
		});
	}

	// Художественное оформление
	if (stellaFormSides.length) {
		stellaFormSides.forEach(el => {
			scope.push({
				status: stellaFormStatus,
				obj: stellaForm[0] && { ...stellaForm[0], id: stellaForm[0].id + el, alignBySide: { status: true, type: el }, type: 'image' },
				category: `stella-form-${el}`,
				type: 'image'
			});
		});
	}

	if (elems?.selectedProducts[0] && elems?.selectedProducts[0]?.img) {
		scope = [
			...scope,
			{ status: elems.status, obj: { ...elems?.selectedProducts[0], resizeDisable: true }, category: 'elements-elems', type: 'image' }
		];
	}

	return scope;
};
