import { ReactComponent as Forma1 } from 'images/sidebar/formsOfTableIcons/Forma1.svg';
import { ReactComponent as Forma2 } from 'images/sidebar/formsOfTableIcons/Forma2.svg';
import { ReactComponent as Forma3 } from 'images/sidebar/formsOfTableIcons/Forma3.svg';
import { ReactComponent as Forma4 } from 'images/sidebar/formsOfTableIcons/Forma4.svg';
import { ReactComponent as Forma5 } from 'images/sidebar/formsOfTableIcons/Forma5.svg';
// eslint-disable-next-line no-unused-vars
import { ReactComponent as Forma6 } from 'images/sidebar/formsOfTableIcons/Forma6.svg';
import { ReactComponent as Forma7 } from 'images/sidebar/formsOfTableIcons/Forma7.svg';

const icons = [
	{ name: 'Квадратная', type: 'rectangle', confType: 'rectangle', icon: Forma1 },
	{ name: 'Рамка', type: 'arc', confType: 'arch', icon: Forma3 },
	// { name: 'Горизонтальный овал', type: 'halfcircle', confType: 'half_oval', icon: Forma6 },
	{ name: 'Овал', type: 'oval', confType: 'oval', icon: Forma5 }
];
const ceramIcons = [...icons, { name: 'Прямоугольная', type: 'polygon', confType: 'polygon', icon: Forma2 }];
const glassIcons = icons;
const granitForms = icons;
const metalForms = [
	...icons,
	{ name: 'Прямоугольная', type: 'polygon', confType: 'polygon', icon: Forma2 },
	{ name: 'Прямоугольная с острыми углами', type: 'star', confType: 'star', icon: Forma4 },
	{ name: 'Сердце', type: 'heart', confType: 'heart', icon: Forma7 }
];

export const tableForms = {
	metal: metalForms,
	ceramic: ceramIcons,
	glass: glassIcons,
	granit: granitForms
};
