import { findBurial, changeSelectProductStatus } from './../../step1Slice';

import { generatePedestal } from 'utils/helpers/step3_generatePedestal';
import { dimensions } from 'config/defaultDimensions.config';

const step3Pedestal = () => {
	const addPedestal = state => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;
		const pedestalDimensions =
			state.defaultComplect[value]?.components.find(el => el.terms.includes('pedestal')).size.values || dimensions.pedestal;

		burial.pedestal.items = [
			...burial.pedestal.items,
			generatePedestal({
				title,
				value,
				stone,
				width: pedestalDimensions?.width,
				length: pedestalDimensions?.thickness,
				height: pedestalDimensions?.height
			})
		];
		burial.pedestal.currentPedestal = burial.pedestal.items.length - 1;
	};

	const removePedestal = state => {
		const burial = findBurial(state);
		burial.pedestal.items.pop();
		if (burial.pedestal.items.length != 0) burial.pedestal.currentPedestal = 0;
	};

	const changeCurrentPedestalItem = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.currentPedestal = action.payload;
	};

	const changePedestalType = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].title = action.payload.title;
		burial.pedestal.items[burial.pedestal.currentPedestal].value = action.payload.value;
		action.payload.value == 'granite'
			? (burial.pedestal.items[burial.pedestal.currentPedestal].stone = 'Габбро Диабаз')
			: (burial.pedestal.items[burial.pedestal.currentPedestal].stone = 'Мрамор');
	};

	const changePedestalStone = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].stone = action.payload;
	};

	const changePedestalSverlovka = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].sverlovka = action.payload;
	};

	const changePedestalSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].selectedProducts = changeSelectProductStatus(
			burial.pedestal.items[burial.pedestal.currentPedestal].selectedProducts,
			action.payload,
			true
		);
	};

	const changePedestalIndividualStatus = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].individualSizes.status = action.payload;
	};

	const changePedestalIndividualSizes = (state, action) => {
		const burial = findBurial(state);
		burial.pedestal.items[burial.pedestal.currentPedestal].individualSizes = action.payload;
		if (burial.stella.items.length >= 2) {
			burial.stella.items.pop();
			burial.stella.currentStella = burial.stella.items.length - 1;
		}
		if (burial.boxes.items.length) {
			const boxesLength = burial.boxes.items.reduce((res, cur) => {
				return (res += parseFloat(cur.size.width));
			}, 0);
			burial.boxes.position.max = action.payload.values.width - boxesLength;
		}
	};

	return {
		addPedestal,
		removePedestal,
		changeCurrentPedestalItem,
		changePedestalType,
		changePedestalStone,
		changePedestalSverlovka,
		changePedestalSelectedProducts,
		changePedestalIndividualStatus,
		changePedestalIndividualSizes
	};
};

export default step3Pedestal;
