import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';
import TextInput from 'components/UI/input/text';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

import './road.scss';

const Road = () => {
	const actions = useActions();
	const items = useMemo(() => ['Не требуется', 'Требуется'], []);
	const { type, length, price } = useSelector(state => state.configurator.present.step6.road);

	const paidLength = useMemo(() => (length - 15 <= 0 ? 0 : length - 15), [length]);

	const onChangeTypeHandler = useCallback(e => actions.changeRoad({ type: e.target.dataset.value, length: 0, price: 0 }), []);
	const onChangeValueHandler = useCallback(value => actions.changeRoad({ type, length: value, price: 0 }), [type]);
	const onChangeCustomPrice = useCallback(price => actions.changeRoad({ type, length, price }), [type, length]);

	return (
		<BlockWithArrow title='Дорога до кладбища' blockClasses='sidebar__radio__block'>
			{items.map((el, i) => (
				<RadioInput
					key={i}
					title={el}
					defaultChecked={el === type}
					onChange={onChangeTypeHandler}
					name={`road_${el}`}
					id={`road_${el}`}
					value={el}
				/>
			))}
			{type === 'Требуется' && (
				<>
					<div className='road_wrapper'>
						<div className='road_wrapper__item'>
							<div className='road_wrapper__item__title'>Общее расстояние:</div>
							<div className='road_wrapper__item__value'>
								<div className='checkbox__additation__text'>
									<TextInput
										blockClasses='sidebar__radio__block--values__volume sidebar__radio__block--values__length__km input-number'
										name={`road`}
										id={`road`}
										onChange={onChangeValueHandler}
										required={true}
										type='number'
										defaultValue={length}
										min={0}
										useDelay={false}
									/>
								</div>
							</div>
						</div>
						<div className='road_wrapper__item'>
							<div className='road_wrapper__item__title'>Бесплатно:</div>
							<div className='road_wrapper__item__value'>
								<p>15 км</p>
							</div>
						</div>
						<div className='road_wrapper__item'>
							<div className='road_wrapper__item__title'>Платно:</div>
							<div className='road_wrapper__item__value'>
								<p>{paidLength} км</p>
							</div>
						</div>
						<div className='road_wrapper__item'>
							<div className='road_wrapper__item__title road_wrapper__item__title--bold'>Транспорт. расходы:</div>
							<div className='road_wrapper__item__value road_wrapper__item__value--bold'>
								<p>{price} руб.</p>
							</div>
						</div>
					</div>
					{paidLength !== 0 && (
						<InputTypeNumber
							blockClasses='own__form__input mt12'
							label='Введите стоимость'
							defaultValue={price}
							name={`own__form__input__delivery}`}
							id={`own__form__input__delivery}`}
							onChange={onChangeCustomPrice}
							max={99999}
							useDelay={false}
						/>
					)}
				</>
			)}
		</BlockWithArrow>
	);
};

export default Road;
