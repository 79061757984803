import React, { useState, useCallback, useLayoutEffect } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import TextEditor from 'components/UI/popup/textEditor';
import SecondButton from 'components/UI/button/second';
import InputTypeNumber from 'components/UI/input/text/number';

import { useEngravingEpitafiaPrice } from 'hooks/useEngravingEpitafiaPrice';
import { generateEpitafia } from 'utils/forms/generateEpitafia';
import { standaloneSize } from 'utils/standaloneSize';

import { fonts } from 'config/fontSizeTransform.config';
import { fontSizeTransform } from 'config/fontSizeTransform.config';

const EngravingEpitafia = ({ type, epitafia, currentEpitafiaItems, changeData, material, template }) => {
	const [openEpitaphPop, setOpenEpitaphPop] = useState(false);
	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));

	const FontOptions = fonts;

	const check = currentEpitafiaItems !== null && currentEpitafiaItems?.text !== '';

	const changeCustomPriceHandler = useCallback(price => changeData('epitafia', price, 'price', true), []);

	const returnData = useEngravingEpitafiaPrice({
		status: epitafia.status && currentEpitafiaItems !== null,
		engravingType: type,
		changePrice: changeCustomPriceHandler,
		fontSizeType: FontOptions.find(el => el.value === currentEpitafiaItems?.fontFamily)?.id,
		fontSize: currentEpitafiaItems?.fontSize,
		materialSlug: material
	});

	useLayoutEffect(() => {
		if (currentEpitafiaItems === null) setOpenEpitaphPop(false);
	}, [currentEpitafiaItems]);

	return (
		<BlockWithToggle
			title='Эпитафия'
			openState={epitafia.status}
			onChangeToggle={() => {
				setOpenEpitaphPop(false);
				changeData('epitafia', null, 'items', true);
				changeData('epitafia', !epitafia.status, 'status');
			}}
			contentClasses='editDelete'
		>
			{openEpitaphPop && (
				<TextEditor
					openState={openEpitaphPop}
					onClick={() => setOpenEpitaphPop(false)}
					className='textEditor'
					title='Редактор эпитафии:'
					defaultValue={currentEpitafiaItems?.text}
					onChangeTextArea={(e, value) => {
						changeData('epitafia', { ...currentEpitafiaItems, text: value }, 'items');
					}}
					onChangeLibraryState={state => {
						if (state == false) setOpenEpitaphPop(true);
					}}
					libraryState={true}
					libraryBtnTitle='Библиотека эпитафий'
					libraryClassName='mt32 --svg__book-before'
					alignState={true}
					alignType={currentEpitafiaItems?.textAlign}
					onChangeAlign={type => {
						changeData('epitafia', { ...currentEpitafiaItems, textAlign: type }, 'items');
					}}
					fontSizeState={true}
					fontSizeType='select'
					fontSizeSelect={standaloneSize(currentEpitafiaItems?.fontSize)}
					onChangefontSize={value => {
						changeData('epitafia', { ...currentEpitafiaItems, fontSize: value.value }, 'items');
					}}
					fontSizeOptions={options}
					fontFamilyState={true}
					fontFamylyOption={FontOptions}
					fontFamilySelect={currentEpitafiaItems?.fontFamily}
					onChangeFontFamily={value => {
						changeData('epitafia', { ...currentEpitafiaItems, fontFamily: value.value }, 'items');
					}}
					onClickLibraryProductCard={item => {
						changeData('epitafia', { ...currentEpitafiaItems, text: item.text }, 'items');
					}}
					selectedLibraryProducts={[{ id: 0, sku: 'epitafia-0', text: currentEpitafiaItems?.text }]}
				/>
			)}

			{check && (
				<ActionBlock
					title={currentEpitafiaItems.text}
					onEditClick={() => setOpenEpitaphPop(true)}
					onBasketClick={() => {
						changeData('epitafia', null, 'items');
						setOpenEpitaphPop(false);
					}}
				/>
			)}

			{currentEpitafiaItems !== null && !returnData.data.length && (
				<InputTypeNumber
					blockClasses='own__form__input'
					label='Введите стоимость'
					defaultValue={currentEpitafiaItems.price}
					name={`own__form__input__faska}`}
					id={`own__form__input__faska}`}
					onChange={price => {
						changeData('epitafia', { ...currentEpitafiaItems, price: price }, 'items');
					}}
					useDelay={false}
				/>
			)}

			{(currentEpitafiaItems === null || currentEpitafiaItems?.text === '') && openEpitaphPop === false && (
				<SecondButton
					title='Добавить Эпитафию'
					onClick={e => {
						setOpenEpitaphPop(true);
						changeData('epitafia', generateEpitafia(template.engravingEpitafia, 0), 'items');
					}}
					classes='second__btn box__value__add'
				/>
			)}
		</BlockWithToggle>
	);
};

export default EngravingEpitafia;
