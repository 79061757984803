export const dateToRoman = (str, numericFormat) => {
	if (numericFormat === 'Арабские') return str;
	const dates = str
		.split('.')
		.map(date => romanize(parseFloat(date)))
		.join('.');
	return dates;
};

export function romanize(num) {
	if (isNaN(num)) return NaN;
	var digits = String(+num).split(''),
		key = [
			'',
			'C',
			' CC',
			'C CC',
			'CD ',
			'D',
			'DC',
			'DCC',
			'DCCC',
			'CM',
			'',
			'X',
			'XX',
			'XXX',
			'XL',
			'L',
			'LX',
			'LXX',
			'LXXX',
			'XC',
			'',
			'I',
			'II',
			'III',
			'IV',
			'V',
			'VI',
			'VII',
			'VIII',
			'IX'
		],
		roman = '',
		i = 3;
	while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
	return Array(+digits.join('') + 1).join('M') + roman;
}
