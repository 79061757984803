import { generateTables } from './../tables/step3_createTables';
import { defaultFontSizeTransform } from 'config/fontSizeTransform.config';
import { generateEngraving } from '../engraving/generateEngraving';
import { generateStella } from 'utils/helpers/step3_generateStella';

export const pedestal = items => ({
	currentPedestal: 0,
	items: items ?? []
});

export const stella = items => ({
	currentStella: 0,
	positionBoxState: false,
	maxValues: {
		first: 1000,
		second: 1000,
		merged: 1000
	},
	noDisabled: {
		stellaCreating: true,
		lineAlign: true,
		parralelAlign: true
	},
	position: {
		status: false,
		align: 'center',
		items: [0, 0, 0, 0, 0]
	},
	items: items ?? [generateStella({})]
});

export const tombstone = {
	currentTomb: 0,
	items: []
};

export const boxes = (max = 0) => ({
	currentBox: null,
	positionBoxState: false,
	position: {
		status: false,
		max: max,
		items: [0, 0, 0, 0, 0]
	},
	items: []
});

export const tables = generateTables();
export const engraving = generateEngraving();

export const elements = {
	elems: {
		status: false,
		selectedProducts: []
	},
	fio: {
		status: false,
		items: [
			{
				value: 'Фамилия',
				fontSize: defaultFontSizeTransform
			},
			{
				value: 'Имя Отчество',
				fontSize: defaultFontSizeTransform
			},
			{
				value: '11.05.1964 – 28.02.1998',
				fontSize: defaultFontSizeTransform
			}
		]
	},
	epitafia: {
		status: false,
		text: '',
		fontSize: defaultFontSizeTransform,
		textAlign: 'center'
	}
};

export const installation = {
	type: 'Стандартное (30 см)'
};

export const improvement = price => ({
	tileArea: {
		type: 'Стандартное (30 см)',
		height: 5,
		price: parseFloat(price) || 0
	},
	cladding: false,
	plate: []
});
