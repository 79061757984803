import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	canvasContainer: {
		status: false,
		id: null
	},
	currentSidebarTab: 0,
	canvasHQ: false
};

export const step3Slice = createSlice({
	name: 'step3',
	initialState,
	reducers: {
		changeCurrentSidebarTab: (state, action) => {
			state.currentSidebarTab = action.payload;
		},
		changeCanvasContainerStatus: (state, action) => {
			state.canvasContainer.status = action.payload;
		},
		changeCanvasContainerId: (state, action) => {
			state.canvasContainer.id = action.payload;
		},
		changeCanvasHQ: (state, action) => {
			state.canvasHQ = action.payload;
		}
	}
});

export const step3SliceActions = step3Slice.actions;

export default step3Slice.reducer;
