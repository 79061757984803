import React, { useCallback } from 'react';

import { v4 as uuid } from 'uuid';

import PrimaryButton from 'components/UI/button/primary';
import TextInput from 'components/UI/input/text';
import SelectInput from 'components/UI/input/select';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

import { ReactComponent as Basket } from 'images/icons/basket.svg';
import { ReactComponent as Left } from 'images/align/left.svg';
import { ReactComponent as Right } from 'images/align/right.svg';
import { ReactComponent as Center } from 'images/align/center.svg';
import { fontSizeTransform, fonts, defaultFontSizeTransform } from 'config/fontSizeTransform.config';
import { numericTypes as numericData, tablesTextColors } from 'config/canvas.config';
import { standaloneSize } from 'utils/standaloneSize';
import { generateDateItem } from '../../../../../utils/forms/generateFioAndDates';
import DateInput from '../../../../UI/input/date';

const DatesOfLife = ({ template, data, type, currentTableFioColor }) => {
	const actions = useActions();
	const unique_id = uuid();
	const { font, align, items, numericTypes } = data;

	const numericItems = numericData;

	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));

	const FontOptions = fonts;

	const changeData = useCallback(
		(value, include) => actions.setItemsFioDateTable({ type, obj: 'datesItems', include: include, value: value }),
		[actions, type]
	);

	const changeItemsData = useCallback(
		(actionType = null, value, include, index) => {
			actions.setItemsFioDateTableItems({ actionType, type, obj: 'datesItems', include, value, index });
		},
		[actions, type]
	);

	return (
		<>
			<div className='box'>
				<SelectInput title={currentTableFioColor} items={tablesTextColors} onClick={value => changeData(value, 'color')} />
				<div className='row'>
					<SelectInput title={font.title} items={FontOptions} onClick={value => changeData(value, 'font')} />
					<div className='align-btns'>
						<button className={`${align == 'left' ? 'active' : ''}`} onClick={e => changeData('left', 'align')}>
							<Left />
						</button>
						<button className={`${align == 'center' ? 'active' : ''}`} onClick={e => changeData('center', 'align')}>
							<Center />
						</button>
						<button className={`${align == 'right' ? 'active' : ''}`} onClick={e => changeData('right', 'align')}>
							<Right />
						</button>
					</div>
				</div>
				{items.map((el, index) => {
					const isLast = index + 1 === items.length;

					return (
						<div className='row' key={index}>
							<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
								<div className='flex'>
									<DateInput
										label='Дата рождения'
										id={'Дата рождения'}
										blockClasses='restavrationInput date'
										value={new Date(el.startDate).toISOString().split('T')[0]}
										onChange={(e, value) => {
											changeItemsData(null, value, 'startDate', index);
										}}
									/>
									<DateInput
										label='Дата смерти'
										id={'Дата смерти'}
										blockClasses='restavrationInput date'
										value={new Date(el.endDate).toISOString().split('T')[0]}
										onChange={(e, value) => {
											changeItemsData(null, value, 'endDate', index);
										}}
									/>
								</div>
								<div style={{ marginTop: '5px' }}>
									<div className='flex'>
										<h3 style={{ margin: '5px' }}>Высота дата</h3>
										<SelectInput
											title={standaloneSize(el.fontSize)}
											items={options}
											onClick={value => changeItemsData(null, value.value, 'fontSize', index)}
										/>
										<h3 style={{ margin: '5px' }}>Высота год</h3>
										<SelectInput
											title={standaloneSize(el.fontSize2)}
											items={options}
											onClick={value => changeItemsData(null, value.value, 'fontSize2', index)}
										/>
										<button
											disabled={!(!isLast || items.length !== 1)}
											onClick={value => changeItemsData('remove', value, 'fontSize', index)}
										>
											<Basket />
										</button>
									</div>
								</div>
							</div>
						</div>
					);
				})}

				<BlockWithArrow title='Формат цифр:' openState={false} arrowDelete={true} blockClasses='sidebar__radio__block numericTypes'>
					{numericItems.map((el, index) => (
						<RadioInput
							key={index}
							title={el}
							id={`numericTypes_${index}`}
							name={`numericTypes_${index}`}
							onChange={e => changeData(el, 'numericTypes')}
							defaultChecked={numericTypes == el ? true : false}
						/>
					))}
				</BlockWithArrow>
			</div>
			<PrimaryButton
				title='Добавить строку'
				onClick={() => changeItemsData('create', generateDateItem(template))}
				// onClick={() =>
				// 	changeItemsData('create', {
				// 		value: '11.05.1964 – 28.02.1998',
				// 		fontSize: defaultFontSizeTransform,
				// 		id: unique_id
				// 	})
				// }
				className='btn box__value__add'
			/>
		</>
	);
};

export default DatesOfLife;
