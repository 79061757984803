import React from 'react';

const TabsComponent = ({ currentEl, currentBurial, AllEl, title, onClick }) => {
	return (
		<>
			{AllEl.length > 1 ? (
				<div className='flowers__tabs'>
					{AllEl.map((item, index) => {
						return (
							item?.hide !== true && (
								<a key={index} data-value={index} onClick={onClick} className={`flowers__tabs__item ${currentEl == index ? 'active' : ''}`}>
									<span>{title}</span>
									<span>{index + 1}</span>
								</a>
							)
						);
					})}
				</div>
			) : null}
		</>
	);
};

export default TabsComponent;
