import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import RadioButtonsList from 'components/UI/bigComponents/RadioButtonsList';
import { useActions } from 'hooks/useActions';

const InstallationMethod = () => {
	const actions = useActions();
	const instalation = useSelector(state => state.configurator.present.step1.installation);
	const onChange = useCallback(item => item.event(item.title), [actions]);

	const methods = useMemo(
		() => [
			{
				title: 'Самовывоз',
				name: 'pickup',
				id: 'pickup',
				event: title => actions.changeHistoryInstallationMethod('pickup', title)
			},
			{
				title: 'Установка памятника',
				name: 'pickup',
				id: 'grave_installation',
				event: title => actions.changeHistoryInstallationMethod(null, title)
			}
		],
		[actions]
	);

	return <RadioButtonsList methods={methods} onChange={onChange} currentInstallation={instalation} />;
};

export default InstallationMethod;
