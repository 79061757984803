import { v4 as uuid } from 'uuid';
import { defaultFontSizeTransform, defaultFontFamily } from 'config/fontSizeTransform.config';
import { defaultTextColor, textColors } from '../../config/canvas.config';

export const generateDateItem = (template, price) => {
	const unique_idDate = uuid();
	const obj = {
		id: unique_idDate,
		textStyle: 'Прямой',
		...template['dates'],
		price: 0,
		type: 'text'
	};

	if (price) obj.price = price;

	return obj;
};
export const generateFioAndDates = template => {
	const unique_idFioFirst = uuid();
	const unique_idFioSecond = uuid();
	const unique_idFioThird = uuid();

	return {
		datesItems: {
			//TODO как то упростить
			color: template['dates'].fill
				? Object.entries(textColors).find(color => color[1].value === template['dates'].fill)[1]
				: defaultTextColor,
			font: defaultFontFamily,
			align: template['dates'].textAlign ?? 'center',
			numericTypes: template['dates'].numericTypes ?? 'Арабские',
			items: [generateDateItem(template)]
		},
		fio: {
			color: template['firstName'].fill
				? Object.entries(textColors).find(color => color[1].value === template['dates'].fill)[1]
				: defaultTextColor,
			font: defaultFontFamily,
			align: template['firstName'].textAlign ?? 'center',
			items: [
				{
					id: unique_idFioFirst,
					textStyle: 'Прямой',
					...template['firstName'],
					price: 0
				},
				{
					id: unique_idFioSecond,
					textStyle: 'Прямой',
					...template['secondName'],
					price: 0
				},
				{
					id: unique_idFioThird,
					textStyle: 'Прямой',
					...template['thirdName'],
					price: 0
				}
			]
		}
	};
};
