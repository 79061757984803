import React, { memo } from 'react';

const DateInput = ({
	blockClasses = '',
	label,
	name,
	id,
	required,
	readOnly = false,
	type = 'date',
	value = '1900-10-10',
	onChange = () => {}
}) => {
	return (
		<div className={blockClasses}>
			<label>{label}</label>
			<input
				type={type}
				onChange={event => {
					const value = event.target.value;
					if (!value || value === '') return;
					onChange(event, value);
				}}
				value={value}
				name={name}
				id={id}
				required={required}
				readOnly={readOnly}
			/>
		</div>
	);
};

export default memo(DateInput);
