import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Group, Text } from 'react-konva';
import { defaultFontFamily, defaultFontSizeTransform } from 'config/fontSizeTransform.config';
import { defaultTextColor } from 'config/canvas.config';
import { OFFSET } from '../constans';

const fontStyleTypes = [
	{ name: 'Прямой', type: 'normal' },
	{ name: 'Под наклоном', type: 'italic' }
];

const CreateDate = ({ item, handleTransformEnd, onDragEnd, onSelect, draggable, stageDimensions }) => {
	const { fontSize, fontSize2, textAlign, fontStyle, fontFamily, text, x, y, width, fill } = item;
	const [firstPartWidth, setFirstPartWidth] = useState(0);
	const [firstYearWidth, setFirstYearWidth] = useState(0);
	const [secondPartWidth, setSecondPartWidth] = useState(0);
	const [secondYearWidth, setSecondYearWidth] = useState(0);
	const [groupX, setGroupX] = useState(0);
	const groupRef = useRef(null);

	const fontStyleKonva = useMemo(() => {
		let el = fontStyleTypes.find(el => el.name === fontStyle || el.type === fontStyle);
		return el?.type ?? 'normal';
	}, [fontStyle, fontStyleTypes]);

	const firstPartRef = useCallback(
		node => {
			if (node === null) return;
			setFirstPartWidth(node.textWidth);
		},
		[fontSize, fontFamily]
	);

	const firstYearRef = useCallback(
		node => {
			if (node === null) return;
			setFirstYearWidth(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const secondPartRef = useCallback(
		node => {
			if (node === null) return;
			setSecondPartWidth(node.textWidth);
		},
		[fontSize, fontFamily]
	);

	const secondYearRef = useCallback(
		node => {
			if (node === null) return;
			setSecondYearWidth(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const zeros = num => {
		return ('00' + num).slice(-2);
	};

	const getFirstPart = date => {
		const dataObj = new Date(date);
		let stringDate = zeros(dataObj.getDate());
		stringDate += '.';
		stringDate += zeros(dataObj.getMonth());
		stringDate += '.';
		return stringDate;
	};

	useEffect(() => {
		if (item.index !== 0) return;

		const stageWidth = stageDimensions.type === 'stele' ? stageDimensions.width / 2 - OFFSET * 2 : stageDimensions.width;
		const width = firstPartWidth + firstYearWidth + secondPartWidth + secondYearWidth;
		if (textAlign === 'center') {
			setGroupX(stageWidth / 2 - width / 2);
		} else if (textAlign === 'left') {
			setGroupX(0);
		} else {
			setGroupX(stageWidth - width);
		}
	}, [fontFamily, fontSize, fontSize2, textAlign, stageDimensions, firstPartWidth, firstYearWidth, secondPartWidth, secondYearWidth]);

	const getYear = date => {
		return new Date(date).getFullYear();
	};

	return (
		<Group
			ref={groupRef}
			x={groupX}
			y={y}
			onClick={e => onSelect(e.target.parent.id())}
			onTap={e => onSelect(e.target.parent.id())}
			onDragEnd={onDragEnd}
			onTransformEnd={handleTransformEnd}
			draggable={draggable}
			id={item?.id.toString()}
			width={stageDimensions.width / 2}
			resizeDisable={true}
		>
			<Text
				ref={firstPartRef}
				x={0}
				y={0}
				text={getFirstPart(item.startDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				scaleX={item?.scaleX ?? 1}
				scaleY={item?.scaleY ?? 1}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={firstYearRef}
				x={firstPartWidth}
				y={parseFloat(fontSize) - parseFloat(fontSize2)}
				text={getYear(item.startDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				// id={item?.id.toString()}
				scaleX={item?.scaleX ?? 1}
				scaleY={item?.scaleY ?? 1}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={secondPartRef}
				x={firstPartWidth + firstYearWidth}
				y={0}
				text={' - ' + getFirstPart(item.endDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={secondYearRef}
				x={firstPartWidth + firstYearWidth + secondPartWidth}
				y={parseFloat(fontSize) - parseFloat(fontSize2)}
				text={getYear(item.endDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
		</Group>
	);
};

export default CreateDate;
