import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';
import { useMemo } from 'react';
import useBurialsData from 'hooks/useBurialsData';

export const useResetSteps = () => {
	const actions = useActions();
	const burialsData = useBurialsData();
	const defaultComplect = useSelector(state => state.configurator.present.step1.defaultComplect);

	return useMemo(
		() => ({
			resetToDefaultSecondStep: () => {},
			resetToDefaultThirdStep: ({ value = 'standart', all = false, noChangeProducts = false }) => {
				let items = defaultComplect[burialsData.burial.material.value];

				if (value === 'standart' && noChangeProducts === false) {
					actions.changeSelectedProductsComplect({
						...items,
						state: {
							all,
							step: 2,
							module: 'CatalogType'
						}
					});
				}

				items = items.components;

				if (all === true) items = [];

				actions.selectComponentProduct({ items, all });
			}
		}),
		[defaultComplect, burialsData]
	);
};
