import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useStepsData = () => {
	const stepsArr = useSelector(state => state.configurator.present.configuratorSteps.steps);
	const instalation = useSelector(state => state.configurator.present.step1.installation);

	const steps = useMemo(() => {
		let data = [...stepsArr];
		return instalation !== 'Самовывоз' ? data : data.slice(0, 3);
	}, [instalation, stepsArr]);

	const reversed = useMemo(() => [...steps].reverse(), [steps]);
	const activeStep = useMemo(() => reversed.find(step => step.isActive), [reversed]);
	const stepIndex = useMemo(() => steps.findIndex(step => step.id === activeStep.id), [activeStep.id, steps]);

	return { steps, activeStep, stepIndex };
};
