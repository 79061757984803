import { useCallback } from 'react';

export const useDragging = () => {
	const handleDragEnd = useCallback((e, data, callback, stageRef, round, alignCenter) => {
		let stageChildrens = stageRef.current.getLayers()[0].getChildren();
		let newUrl = data.map(el => {
			const item = stageChildrens.find(el => el.attrs.id == el.id);
			const position = value => {
				const roundValue = round?.status ? round?.value : value;
				return Math.floor(value / roundValue) * roundValue;
			};
			return {
				...el,
				width: el.width ?? item?.width(),
				height: el.height ?? item?.height(),
				scaleX: el.scaleX ?? item?.scaleX(),
				scaleY: el.scaleY ?? item?.scaleY(),
				x: alignCenter ? el.x : el.id == e.target.id() ? position(e.target.x()) : el.x,
				y: el.id == e.target.id() ? position(e.target.y()) : el.y,
				isDragging: false
			};
		});
		callback(newUrl);
	}, []);

	return {
		handleDragEnd
	};
};
