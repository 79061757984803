import React, { useCallback, useMemo } from 'react';

import { stellaEvents } from 'utils/events/stella';
import { useActions } from 'hooks/useActions';
import TypeStone from 'components/UI/bigComponents/TypeStone';

const TypeStoneContent = ({ burial, currentStella, material }) => {
	const actions = useActions();
	const defaultMaterial = useMemo(() => burial.stella.items[currentStella].stone, [burial.stella.items, currentStella]);

	const stoneHandler = useCallback(
		material => {
			// if (currentStella == 0) {
			// 	stellaEvents.changeMaterialStella(material, burial.id);
			// } else {
			// 	stellaEvents.changeMaterialStellaSecond(material, burial.id);
			// }
		},
		[currentStella, burial.id]
	);

	const onChangeMaterialHandler = useCallback(
		item => {
			actions.changeStellaStone(item.title);
			stoneHandler(item.material);
		},
		[actions, stoneHandler]
	);

	return (
		<TypeStone
			materialTitle='Вид камня'
			type={material}
			stone={defaultMaterial}
			onChangeMaterialHandler={onChangeMaterialHandler}
			showJustMaterials
		/>
	);
};

export default TypeStoneContent;
