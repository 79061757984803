import { v4 as uuid } from 'uuid';
import { tablesInstalationTypes, tablesFastingTypes } from 'config/tablesInstalationTypes';

export const generateGlassTable = (type = null, form = 'Квадратная', size = null) => {
	const unique_id = uuid();
	const fakePortraitId = uuid();
	return {
		id: unique_id,
		type: type,
		form: {
			status: true,
			form
		},
		rotated: false,
		size: size ?? null,
		colorVariation: 'Черно-белая',
		background: {
			status: false,
			items: []
		},
		restoration: {
			status: false,
			price: 0
		},
		changeClothes: false,
		montaz: { type: tablesInstalationTypes[0].title, price: 0 },
		fastening: {
			amount: tablesInstalationTypes[0].fastening[0],
			type: tablesFastingTypes[0].type,
			price: 0
		},
		fakeportrait: {
			status: true,
			items: [
				{
					type: 'Стандартный',
					img: (window.location.href + '/images/canvas/man.svg').replace('//images', '/images'),
					id: fakePortraitId
				}
			]
		},
		price: 0
	};
};
