import React, { useCallback } from 'react';

import ProductlistWithParams from 'components/UI/list/productsListWithParams';

import { productsAPI } from 'services/Products';

import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { useNotification } from 'hooks/useNotification';

import { componentsEvent } from 'utils/events/components';
import { stellaEvents } from 'utils/events/stella';

const StellaProducts = ({ burial, currentBurial, currentStella, material }) => {
	const actions = useActions();
	const stellaItems = burial.stella.items;
	const cureenStellaObj = stellaItems[currentStella];
	const selectedProducts = cureenStellaObj.selectedProducts;
	const individualSizes = cureenStellaObj.individualSizes;
	const align = burial.stella.position.align;
	const categories = useConfiguratorCategories('modules');
	const cat_tax = categories.find(el => el.slug === 'stella')?.id;
	const polishing = burial.polishing;
	const defaultMaterial = cureenStellaObj.stone;

	const dimensionsHandler = useCallback(
		({ status, values, sendEvents, setProduct, callback }) => {
			actions.onChangeHistoryStellaDimensions({
				status,
				values,
				sendEvents,
				stellaItems: stellaItems.length,
				currentStella,
				material,
				setProduct
			});

			if (sendEvents) {
				if (currentStella === 0) {
					callback.first();
				} else {
					callback.second();
					const type = align !== 'space' ? 'parallel' : 'line';
					stellaEvents.changeAlignStella(burial.id, type);
				}
			}
		},
		[align, burial.id, currentStella, stellaItems.length, material]
	);

	const { Notification, notificationToggle } = useNotification({
		callback: dimensionsHandler,
		type: 'stella',
		currentElement: cureenStellaObj,
		currentIndexElement: currentStella
	});

	return (
		<>
			<Notification />
			<ProductlistWithParams
				fetchFunction={productsAPI.useFetchProductsQuery}
				selectedProducts={selectedProducts}
				moduleType={`stella-${material}`}
				currentBurial={currentBurial}
				title='Размер стелы:'
				blockClasses='sidebar__radio__block sidebar__radio__block__value--individual'
				contentClasses='configurator__light-box__content--list'
				onClickProduct={values => {
					const { width, thickness: length, height } = values.size.values;
					const type = currentStella === 0 ? 'first' : 'second';

					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								values: { width, length, height },
								sendEvents: false,
								setProduct: values
							}
						],
						callback: async () => {
							componentsEvent.setStella({
								length: width,
								width: length,
								height,
								type,
								id: burial.id
							});
						}
					});
				}}
				firstLabel='Высота'
				secondLabel='Ширина'
				thirdLabel='Толщина'
				lengthDefault={parseInt(individualSizes.values.height)}
				widthDefault={parseInt(individualSizes.values.width)}
				heightDefault={parseInt(individualSizes.values.length)}
				toggleState={individualSizes.status}
				onChangeLength={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								values: { width: individualSizes.values.width, length: individualSizes.values.length, height: parseInt(value) },
								sendEvents: individualSizes.status === true,
								callback: {
									first: () => stellaEvents.stellaHeight('first', value, burial.id),
									second: () => stellaEvents.stellaHeight('second', value, burial.id)
								}
							}
						],
						state
					});
				}}
				onChangeWidth={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								values: { width: parseInt(value), length: individualSizes.values.length, height: individualSizes.values.height },
								sendEvents: individualSizes.status === true,
								callback: {
									first: () => stellaEvents.stellaLength('first', value, burial.id),
									second: () => stellaEvents.stellaLength('second', value, burial.id)
								}
							}
						],
						state
					});
				}}
				onChangeHeight={(value, state) => {
					notificationToggle({
						values: [
							{
								status: individualSizes.status,
								values: {
									width: individualSizes.values.width,
									length: parseInt(value),
									height: individualSizes.values.height
								},
								sendEvents: individualSizes.status === true,
								callback: {
									first: () => stellaEvents.stellaWidth('first', value, burial.id),
									second: () => stellaEvents.stellaWidth('second', value, burial.id)
								}
							}
						],
						state
					});
				}}
				onChangeToggle={e => {
					if (individualSizes.status == true) {
						actions.changeStellaIndividualStatus(!individualSizes.status);
					} else {
						dimensionsHandler({
							status: !individualSizes.status,
							values: { width: individualSizes.values.width, length: individualSizes.values.length, height: individualSizes.values.height },
							sendEvents: individualSizes.status === true,
							setProduct: selectedProducts.length !== 0 ? selectedProducts[0] : null,
							callback: {
								first: () => {
									stellaEvents.stellaLength('first', individualSizes.values.length, burial.id);
									stellaEvents.stellaHeight('first', individualSizes.values.height, burial.id);
									stellaEvents.stellaWidth('first', individualSizes.values.width, burial.id);
								},
								second: () => {
									stellaEvents.stellaLength('second', individualSizes.values.length, burial.id);
									stellaEvents.stellaHeight('second', individualSizes.values.height, burial.id);
									stellaEvents.stellaWidth('second', individualSizes.values.width, burial.id);
								}
							}
						});
					}
				}}
				taxId={cat_tax}
				stone={defaultMaterial}
				polishing={polishing}
				firstLabelApi='size_height'
				secondLabelApi='size_width'
				thirdLabelApi='size_thickness'
			/>
		</>
	);
};

export default StellaProducts;
