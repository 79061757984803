import React, { memo } from 'react';
import ActionButton from './../button/action/index';

import { ReactComponent as Basket } from 'images/icons/basket.svg';
import { ReactComponent as Edit } from 'images/icons/edit.svg';
import { ReactComponent as Plus } from 'images/icons/plus_16.svg';

import './actionBlock.scss';

const ActionBlock = ({
	title,
	className = '',
	onEditClick = () => {},
	onBasketClick = () => {},
	plusState = false,
	onPlusHandler = () => {}
}) => {
	return (
		<div className={`actionBlock ${className}`}>
			<p>{title}</p>
			{plusState && <ActionButton title={<Plus />} onClick={onPlusHandler} classes='' />}
			<ActionButton title={<Edit />} onClick={onEditClick} classes='' />
			<ActionButton title={<Basket />} onClick={onBasketClick} classes='' />
		</div>
	);
};

export default memo(ActionBlock);
