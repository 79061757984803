import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import SecondButton from 'components/UI/button/second';
import TextInput from 'components/UI/input/text';
import TabsComponent from 'components/UI/tabs/tabs';

import { useActions } from 'hooks/useActions';

const DismantlingWithPreservation = ({ currentBurial }) => {
	const actions = useActions();
	const { status, currentItem, items } = useSelector(state => state.configurator.present.step4.dismantlingWithPreservation);
	const item = useMemo(() => items[currentItem], [items, currentItem]);

	return (
		<BlockWithToggle
			title='Демонтаж с сохранением'
			openState={status}
			onChangeToggle={() => actions.changeStatusDismantlingWithPreservation(!status)}
		>
			{items.length !== 0 && (
				<>
					<TabsComponent
						currentEl={currentItem}
						currentBurial={currentBurial}
						AllEl={items}
						title='Дем.'
						onClick={e => actions.changeCurrentDismantlingWithPreservationItem(+e.target.dataset.value)}
					/>
					<TextInput
						label='Размер демонтируемого элемента'
						name='DismantlingElement'
						id='DismantlingElement'
						onChange={value =>
							actions.changeDismantlingWithPreservationItem({
								price: item.price,
								size: value
							})
						}
						defaultValue={item.size}
						useDelay={false}
					/>
					<TextInput
						label='Цена демонтируемого элемента'
						name='DismantlingElementPrice'
						id='DismantlingElementPrice'
						type='number'
						onChange={value =>
							actions.changeDismantlingWithPreservationItem({
								price: value,
								size: item.size
							})
						}
						defaultValue={item.price}
						useDelay={false}
					/>
				</>
			)}
			<SecondButton title='Добавить демонтаж' classes='mb12 box__value__add' onClick={() => actions.addDismantlingWithPreservationItem()} />
			{items.length > 1 && (
				<SecondButton
					title='Удалить демонтаж'
					classes='mb0 box__value__basket'
					onClick={() => actions.removeDismantlingWithPreservationItem()}
				/>
			)}
		</BlockWithToggle>
	);
};

export default DismantlingWithPreservation;
