import React, { useCallback, useMemo, useRef, useLayoutEffect } from 'react';

import { Text } from 'react-konva';
import { defaultFontSizeTransform, defaultFontFamily } from 'config/fontSizeTransform.config';
import { defaultTextColor } from 'config/canvas.config';
import { OFFSET } from '../constans';

const fontStyleTypes = [
	{ name: 'Прямой', type: 'normal' },
	{ name: 'Под наклоном', type: 'italic' }
];

const CreateText = ({
	item,
	items,
	handleTransformEnd,
	onDragEnd,
	onSelect,
	draggable,
	stageRef,
	alignCenter,
	canvasType,
	stageDimensions,
	changeItems
}) => {
	const textRef = useRef();
	const { fontSize, textAlign, fontStyle, fontFamily, text, x, y, width, fill } = item;

	const fontStyleKonva = useMemo(() => {
		let el = fontStyleTypes.find(el => el.name === fontStyle || el.type === fontStyle);
		return el?.type ?? 'normal';
	}, [fontStyle, fontStyleTypes]);

	const handleResize = useCallback(() => {
		if (textRef.current !== null) {
			const textNode = textRef.current;
			const newWidth = textNode.width() * textNode.scaleX();
			// const newHeight = textNode.height() * textNode.scaleY();
			textNode.setAttrs({
				width: newWidth,
				scaleX: 1
			});
		}
	}, []);

	useLayoutEffect(() => {
		if (textRef.current) {
			// textRef.current.cache();
			// const stageWidth = stageDimensions.width; // stageRef.current.width();
			// const textWidth = stageWidth - OFFSET * 2;
			// const maxWidthTextStella = stageWidth / 2 - OFFSET * 2;
			// let newItems = [...items];
			// console.log(item);
			// if (alignCenter) {
			// 	textRef.current.width(textWidth);
			// 	textRef.current.x(stageWidth / 2 - textWidth / 2);
			// } else if (canvasType === 'stele') {
			// 	if (textRef.current.width() > maxWidthTextStella) {
			// 		textRef.current.width(maxWidthTextStella);
			// 	}
			// 	textRef.current.x(OFFSET);
			// } else if (textRef.current.width() > textWidth) {
			// 	textRef.current.width(textWidth);
			// 	textRef.current.x(stageWidth / 2 - textWidth / 2);
			// }
			// console.log(alignCenter);
			// if (!item.wasChanged) {
			// 	newItems = newItems.map(el => {
			// 		if (el.id === item.id) {
			// 			if (alignCenter) {
			// 				return {
			// 					...item,
			// 					width: textWidth,
			// 					x: stageWidth / 2 - textWidth / 2,
			// 					wasChanged: true
			// 				};
			// 			} else if (canvasType === 'stele') {
			// 				return {
			// 					...item,
			// 					width: Math.min(textRef.current.width(), maxWidthTextStella),
			// 					x: OFFSET,
			// 					wasChanged: true
			// 				};
			// 			} else if (textRef.current.width() > textWidth) {
			// 				return {
			// 					...item,
			// 					width: textWidth,
			// 					x: stageWidth / 2 - textWidth / 2,
			// 					wasChanged: true
			// 				};
			// 			}
			// 		}
			// 		return el;
			// 	});
			// 	changeItems(newItems);
			// }
		}
	}, [alignCenter, canvasType, stageRef, textRef, stageDimensions, items, item]);

	return (
		<Text
			x={x}
			y={y}
			text={text}
			// width={stageDimensions.width}
			width={stageDimensions.type === 'stele' ? stageDimensions.width / 2 - OFFSET * 2 : stageDimensions.width}
			// width={stageDimensions.width / 2 - OFFSET * 2}
			fill={fill ?? defaultTextColor.value}
			draggable={draggable}
			wrap='word'
			fontSize={parseFloat(fontSize ?? defaultFontSizeTransform.replace(/\D/g, ''))}
			align={textAlign}
			fontStyle={fontStyleKonva}
			fontFamily={fontFamily ?? defaultFontFamily}
			onClick={e => onSelect(e.target.id())}
			onTap={e => onSelect(e.target.id())}
			onDragEnd={onDragEnd}
			onTransform={handleResize}
			onTransformEnd={handleTransformEnd}
			id={item?.id.toString()}
			scaleX={item?.scaleX ?? 1}
			scaleY={item?.scaleY ?? 1}
			ref={textRef}
			perfectDrawEnabled={false}
		/>
	);
};

export default CreateText;
