import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { useDebouncedValue } from '@mantine/hooks';

const InputTypeText = ({
	blockClasses = '',
	label = '',
	name = '',
	id = '',
	onChange = () => {},
	required = false,
	readOnly = false,
	defaultValue = '',
	delay = 300,
	useDelay = true
}) => {
	const ref = useRef(onChange);
	const [value, setValue] = useState(defaultValue);
	const [debouncedSearchTerm] = useDebouncedValue(value, delay);

	useLayoutEffect(() => {
		ref.current = onChange;
	}, [onChange]);

	useLayoutEffect(() => setValue(prev => defaultValue), [defaultValue]);

	const handleChange = useCallback(
		e => {
			if (e.type === 'blur' && defaultValue == e.target.value) return;
			ref.current(e.target.value, { value: defaultValue, setValue });
		},
		[defaultValue]
	);
	const handleKeyDown = useCallback(e => e.key === 'Enter' && handleChange(e), [handleChange]);
	const onChangeHandler = useCallback(
		e => {
			if (value == e.target.value || e.key === 'Enter') return;
			setValue(e.target.value);
		},
		[value]
	);

	useLayoutEffect(() => {
		if (useDelay) ref.current(debouncedSearchTerm);
	}, [debouncedSearchTerm]);

	return (
		<div className={`${blockClasses} input-text`}>
			{!blockClasses.includes('own__form__input') && label !== '' && <label>{label}</label>}
			<input
				type='text'
				value={value}
				name={name}
				id={id}
				required={required}
				onChange={onChangeHandler}
				onBlur={!useDelay ? handleChange : () => {}}
				onKeyDown={!useDelay ? handleKeyDown : () => {}}
				readOnly={readOnly}
				data-value={value}
			/>
		</div>
	);
};

export default InputTypeText;
