import { haveIntersection } from './haveIntersection';

function getCheckSide({ from, to }, children, rectSize, gridSize) {
	const { width, height } = rectSize;
	let side = null;
	let anotherSide = null;
	let isIntersection = false;

	// get side to compare
	if (from.x === to.x && from.y !== to.y) {
		anotherSide = 'x';
		side = 'y';
	}
	if (from.y === to.y && from.x !== to.x) {
		anotherSide = 'y';
		side = 'x';
	}

	const way = to[side] - from[side] > 0 ? 'inc' : 'dec';

	const getArrPositionForNewBlocks = () => {
		const length = Math.abs((to[side] - from[side]) / gridSize) + 1;

		const arr = Array.apply(null, { length: length })
			.map(Number.call, Number)
			.map(el => ({
				[side]: way === 'inc' ? from[side] + gridSize * el : from[side] - gridSize * el,
				[anotherSide]: to[anotherSide]
			}));

		return arr;
	};
	const arrNewBlockPosition = getArrPositionForNewBlocks();

	children
		.filter(el => el.attrs.type !== 'shadowRect')
		.forEach(el => {
			const elRect = { ...el.getClientRect(), id: el.attrs.id };

			for (let index = 0; index < arrNewBlockPosition.length; index++) {
				const block = arrNewBlockPosition[index];
				if (isIntersection === true) break;

				const check = haveIntersection(elRect, { x: block.x, y: block.y, width, height }, gridSize);
				if (check) isIntersection = true;
			}
		});

	return { side, isIntersection, arrNewBlockPosition };
}

function getArrayCoordinates({ x: startX, y: startY }, { x: finishX, y: finishY }, blocksize = 5) {
	const howIterationX = Math.abs(finishX - startX) / blocksize;
	const howIterationY = Math.abs(finishY - startY) / blocksize;
	let arr = [];

	for (let index = 0; index < howIterationX; index++) {
		const startXThis = startX + index * blocksize;

		arr.push({ x: startXThis, y: startY });
		// change it, this is bad
		for (let i = 0; i < Math.max(howIterationY * 6, 6); i++) {
			arr.push({ x: startXThis, y: startY + (i + 1) * blocksize });
		}
	}

	return arr;
}

export const checkIntersectionInArea = ({ from, to }, layer, rectSize, gridSize, getArray = false) => {
	const children = layer.children;
	const check = getCheckSide({ from, to }, children, rectSize, gridSize);

	if (getArray) check.coordinatesArray = getArrayCoordinates(from, to, gridSize);

	return check;
};
