import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import TextEditor from 'components/UI/popup/textEditor';
import SecondButton from 'components/UI/button/second';
import { withCustomPrice } from 'components/UI/HOC/withCustomPrice';

import { generateEpitafia } from 'utils/forms/generateEpitafia';
import { standaloneSize } from 'utils/standaloneSize';
import { useActions } from 'hooks/useActions';
import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';

import { fontSizeTransform, fonts } from 'config/fontSizeTransform.config';
import { engravingTextColors } from 'config/canvas.config';
import { worksAPI } from 'services/Works';

const EngravingEpitafia = ({
	burial,
	material,
	template,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	changedData,
	children,
	changeCustomPrice,
	isFetching
}) => {
	const actions = useActions();
	const currentEpitafiaStatus = burial.epitafia.status;
	const currentEpitafiaColor = burial.epitafia.color;
	const currentEpitafiaPrice = burial.epitafia.price;
	const currentEpitafiaItems = burial.epitafia.items;

	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));

	const FontOptions = fonts;

	const [openEpitaphPop, setOpenEpitaphPop] = useState(false);

	const onToggleHandler = useCallback(() => {
		setOpenEpitaphPop(false);
		actions.setEngravingValue({
			obj: 'epitafia',
			include: null,
			value: { status: !currentEpitafiaStatus, color: currentEpitafiaColor, price: 0, items: null }
		});
	}, [currentEpitafiaStatus, currentEpitafiaColor]);

	const onBasketClick = useCallback(() => {
		actions.setEngravingValue({
			obj: 'epitafia',
			value: { status: currentEpitafiaStatus, color: currentEpitafiaColor, price: 0, items: null }
		});
		setOpenEpitaphPop(false);
	}, [currentEpitafiaStatus, currentEpitafiaColor]);

	const onChangeTextHandler = useCallback(
		(e, value) => {
			if (value === '') return;
			actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, text: value } });
		},
		[currentEpitafiaItems]
	);

	const onChangeAlignHandler = useCallback(
		type => actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, textAlign: type } }),
		[currentEpitafiaItems]
	);

	const onChangeFontSizeHandler = useCallback(
		value => actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, fontSize: value.value } }),
		[currentEpitafiaItems]
	);

	const onChangeFontFamilyHandler = useCallback(
		value => actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, fontFamily: value.value } }),
		[currentEpitafiaItems]
	);

	const onClickLibraryProduct = useCallback(
		item => actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, text: item.text } }),
		[currentEpitafiaItems]
	);

	const changeColorHandler = useCallback(
		value => {
			actions.setEngravingValue({ obj: 'epitafia', include: 'color', value, historySkip: true });
			actions.setEngravingValue({ obj: 'epitafia', include: 'items', value: { ...currentEpitafiaItems, fill: value.value } });
		},
		[currentEpitafiaItems]
	);

	const params = useMemo(() => {
		return {
			status: currentEpitafiaStatus && currentEpitafiaItems !== null,
			engravingType: burial.type,
			fontSizeType: FontOptions.find(el => el.value === currentEpitafiaItems?.fontFamily)?.id,
			fontSize: currentEpitafiaItems?.fontSize,
			materialSlug: material
		};
	}, [burial.type, material, currentEpitafiaItems, currentEpitafiaStatus]);

	const refetchDataHandler = useCallback(
		(refetch, action) => {
			const args = {
				work_tax: params.engravingType === 'Ручная' ? 60 : 61,
				material_tax: params.materialSlug,
				per_page: 100
			};

			if (params.engravingType === 'Ручная') {
				const sizeinmm = parseInt((`${params.fontSize}` || '').replace(/\D+/g, ''));
				args.type = params.fontSizeType;
				args.size_height = sizeinmm < 20 ? 2 : 5;
			}

			if (!params.status) return;
			refetch({ args }, true);
		},
		[params]
	);

	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (currentEpitafiaItems == null || price === undefined) return;
			actions.setEngravingValue({ obj: 'epitafia', include: 'price', value: parseFloat(price), historySkip: true });
		},
		[currentEpitafiaItems]
	);

	useLayoutEffect(() => {
		if (changedData.items.length) {
			onChangeCustomPriceHandler(changedData.items[0]?.price?.unit || 0);
		}
	}, [changedData]);

	useEffect(() => {
		if (!currentEpitafiaStatus || currentEpitafiaItems == null) return;
		refetchData();
	}, [params]);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price: currentEpitafiaPrice,
		changeCustomPrice
	});

	const addEpitafiaHandler = useCallback(
		e => {
			setOpenEpitaphPop(true);
			actions.setEngravingValue({
				obj: 'epitafia',
				include: null,
				value: {
					status: currentEpitafiaStatus,
					color: template.engravingEpitafia.textColor,
					price: 0,
					items: generateEpitafia(template.engravingEpitafia)
				}
			});
		},
		[currentEpitafiaStatus, currentEpitafiaColor]
	);

	useLayoutEffect(() => {
		if (currentEpitafiaItems === null) setOpenEpitaphPop(false);
	}, [currentEpitafiaItems, currentEpitafiaStatus]);

	return (
		<BlockWithToggle title='Эпитафия' openState={currentEpitafiaStatus} onChangeToggle={onToggleHandler} contentClasses='editDelete'>
			{openEpitaphPop && (
				<TextEditor
					openState={openEpitaphPop}
					onClick={() => setOpenEpitaphPop(false)}
					className='textEditor'
					title='Редактор эпитафии:'
					defaultValue={currentEpitafiaItems?.text}
					onChangeTextArea={onChangeTextHandler}
					libraryState={true}
					onChangeLibraryState={state => {
						if (state == false) setOpenEpitaphPop(true);
					}}
					libraryBtnTitle='Библиотека эпитафий'
					libraryClassName='mt32 --svg__book-before'
					alignState={true}
					alignType={currentEpitafiaItems?.textAlign}
					onChangeAlign={onChangeAlignHandler}
					fontSizeState={true}
					fontSizeType='select'
					fontSizeSelect={standaloneSize(currentEpitafiaItems?.fontSize)}
					onChangefontSize={onChangeFontSizeHandler}
					fontSizeOptions={options}
					fontFamilyState={true}
					fontFamylyOption={FontOptions}
					fontFamilySelect={currentEpitafiaItems?.fontFamily}
					onChangeFontFamily={onChangeFontFamilyHandler}
					onClickLibraryProductCard={onClickLibraryProduct}
					selectedLibraryProducts={[{ id: 0, sku: 'epitafia-0', text: currentEpitafiaItems?.text }]}
					colorState
					colorTitle={currentEpitafiaColor.title}
					colors={engravingTextColors}
					onSelectColorHandler={changeColorHandler}
				/>
			)}

			{currentEpitafiaItems != null && currentEpitafiaItems?.text !== '' && (
				<ActionBlock title={currentEpitafiaItems.text} onEditClick={() => setOpenEpitaphPop(true)} onBasketClick={onBasketClick} />
			)}

			{currentEpitafiaStatus && currentEpitafiaItems != null && !isFetching && !changedData.items.length && children}

			{(currentEpitafiaItems === null || currentEpitafiaItems?.text === '') && openEpitaphPop === false && (
				<SecondButton title='Добавить Эпитафию' onClick={addEpitafiaHandler} classes='second__btn box__value__add' />
			)}
		</BlockWithToggle>
	);
};

const withCustomPriceEngravingEpitafia = withCustomPrice(EngravingEpitafia, worksAPI.useLazyFetchWorksQuery, 'engraving');
export default withCustomPriceEngravingEpitafia;
