export const tablesColorVariations = {
	metal: ['Черно-белая', 'Цветная'],
	ceramic: ['Черно-белая', 'Цветная'],
	glass: ['Черно-белая', 'Цветная', 'С декором', 'С золотом'],
	granit: ['Черно-белая', 'Цветная']
};

export const tablesColorVariationsBD = {
	'Черно-белая': 'black',
	Цветная: 'color',
	'С декором': 'decor',
	'С золотом': 'gold'
};
