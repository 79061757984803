import React, { useMemo, useCallback } from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import SecondButton from 'components/UI/button/second';
import PrimaryButton from 'components/UI/button/primary';
import Processing from 'components/UI/other/processing';
import TypeStoneContent from './typeStone';
import HowManyBoxes from './howManyBoxes';
import Sverlovka from './sverlovka';
import BoxSize from './boxSize';
import PositionSettings from './positionSettings';
import PopUpPosition from './PopUpPosition';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

const Boxes = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();
	const material = useMemo(() => burial.material.value, [burial.material.value]);
	const currentBox = useMemo(() => burial.boxes.currentBox, [burial.boxes.currentBox]);
	const boxesLength = useMemo(() => burial.boxes.items.length, [burial.boxes.items.length]);
	const pedestalAmount = useMemo(() => burial.pedestal.items.length, [burial.pedestal.items.length]);
	const status = useMemo(() => burial.boxes.position.status, [burial.boxes.position.status]);

	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [currentTab]);
	const addBoxHandler = useCallback(() => actions.addBox(), []);

	const removeBoxHandler = useCallback(() => {
		const check = boxesLength - 1 === 0 && pedestalAmount <= 0;
		actions.removeBoxHandler({ check });
	}, [boxesLength, pedestalAmount]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Кубы</h1>
			<HowManyBoxes burial={burial} />
			<TabsComponent
				currentEl={currentBox}
				currentBurial={currentBurial}
				AllEl={burial.boxes.items}
				title='КУБ'
				onClick={e => actions.changeCurrentBox(e.target.dataset.value)}
			/>
			{boxesLength >= 1 && (
				<>
					{material === 'granite' && <TypeStoneContent burial={burial} currentBox={currentBox} pedestalAmount={pedestalAmount} />}
					<BoxSize burial={burial} currentBox={currentBox} pedestalAmount={pedestalAmount} />
					<Sverlovka burial={burial} currentBox={currentBox} />
					{boxesLength >= 2 && (
						<>
							<PositionSettings status={status} />
							{status && <PopUpPosition burial={burial} />}
						</>
					)}
					<Processing />
				</>
			)}
			{boxesLength < 4 && <SecondButton title={'Добавить куб'} classes='mb24 box__value__add' onClick={addBoxHandler} />}
			{boxesLength >= 1 && (
				<SecondButton
					title={boxesLength === 1 && pedestalAmount === 0 ? 'Заменить на тумбу' : 'Удалить куб'}
					classes={`mb24 step1__exclude__add ${boxesLength === 1 && pedestalAmount === 0 ? 'box__value__switch' : 'box__value__delete'}`}
					onClick={removeBoxHandler}
				/>
			)}
			{(boxesLength !== 0 || pedestalAmount !== 0) && <PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />}
		</>
	);
};

export default Boxes;
