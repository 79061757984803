import React, { useCallback, useMemo } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';
import { tablesColorVariations } from 'config/tablesColorVariations';

const ColorVariation = ({ currentTable, type }) => {
	const actions = useActions();
	const currentTableColorVariation = currentTable?.colorVariation;
	const items = useMemo(() => tablesColorVariations[type], [type]);

	const onChangeSizeHandler = useCallback(el => actions.setTableValue({ type: type, obj: 'colorVariation', value: el }), [actions, type]);

	return (
		<BlockWithArrow title='Цветовая вариация'>
			{items.map((el, index) => (
				<RadioInput
					key={index}
					title={el}
					id={el}
					defaultChecked={currentTableColorVariation == el}
					onChange={() => onChangeSizeHandler(el)}
				/>
			))}
		</BlockWithArrow>
	);
};

export default ColorVariation;
