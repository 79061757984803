import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	polishingTypes: [5, 2, 1],
	catalog: {
		isOpen: false
	}
};

export const step2Slice = createSlice({
	name: 'step2',
	initialState,
	reducers: {
		togglePopUp: (state, action) => {
			state.catalog.isOpen = action.payload;
		}
	}
});

export const step2SliceActions = step2Slice.actions;

export default step2Slice.reducer;
