import React from 'react';

const SecondButton = ({
	title = 'Добавить',
	disabled = false,
	onClick = () => {},
	id = 'add_button',
	classes = 'mb24 box__value__add'
}) => {
	return (
		<button className={`second__btn ${classes}`} disabled={disabled} id={id} onClick={onClick}>
			<span>{title}</span>
		</button>
	);
};

export default SecondButton;
