import React, { useCallback } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';
import CheckBox from 'components/UI/input/checkbox';
import { withCustomPrice } from 'components/UI/HOC/withCustomPrice';

import { useActions } from 'hooks/useActions';
import { worksAPI } from 'services/Works';
import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';

const Decoration = ({
	decorationStatus,
	decorationSides,
	decorationProductsSelected,
	currentStella,
	children,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	isFetching,
	isError,
	changedData,
	customPrice,
	stellaHeight,
	price,
	changeCustomPrice,
	burialMaterial
}) => {
	const actions = useActions();

	const refetchDataHandler = useCallback(
		(refetch, action) => {
			if (!action) {
				return refetch({ args: { work_tax: 48, size_height: stellaHeight, per_page: 100, material_tax: burialMaterial } }, true);
			}
			refetch({ args: { work_tax: 47, per_page: 100, material_tax: burialMaterial } }, true);
		},
		[stellaHeight, burialMaterial]
	);
	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (decorationProductsSelected.length === 0 || price === undefined) return;
			actions.changeFormElements({
				type: 'stella',
				current: currentStella,
				moduleObject: 'decoration',
				moduleType: 'selectedProducts',
				value: [{ ...decorationProductsSelected[0], price: { unit: parseFloat(price) } }]
			});
		},
		[decorationProductsSelected]
	);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price,
		changeCustomPrice
	});

	return (
		<CatalogForm
			formTitle='Художественное оформление'
			formState={decorationStatus}
			onToggle={() => {
				actions.changeFormElements({
					type: 'stella',
					current: currentStella,
					moduleObject: 'decoration',
					value: {
						status: !decorationStatus,
						side: ['front'],
						selectedProducts: []
					}
				});
			}}
			onClickProductCard={item => {
				const include = decorationProductsSelected.some(el => el.sku === item.sku);
				const price = item.price?.unit ?? customPrice;

				actions.changeFormElements({
					type: 'stella',
					current: currentStella,
					moduleObject: 'decoration',
					moduleType: 'selectedProducts',
					value: include ? [] : [{ ...item, price: { unit: price } }]
				});
			}}
			btnTitle='Каталог оформления'
			products={changedData.items}
			selectedProducts={decorationProductsSelected}
			refetch={refetchData}
			hook
			isLoading={isFetching}
			isError={isError}
		>
			<CheckBox
				title='Лицевая сторона'
				defaultChecked={decorationSides.includes('front')}
				onChange={() => {
					const include = decorationSides.some(el => el === 'front');
					actions.changeFormElements({
						type: 'stella',
						current: currentStella,
						moduleObject: 'decoration',
						moduleType: 'side',
						value: include ? ['back'] : [...decorationSides, 'front']
					});
				}}
				name='Художественное оформление'
				id='front__side'
				value='front'
			/>
			<CheckBox
				title='Задняя сторона'
				defaultChecked={decorationSides.includes('back')}
				onChange={() => {
					const include = decorationSides.some(el => el === 'back');
					actions.changeFormElements({
						type: 'stella',
						current: currentStella,
						moduleObject: 'decoration',
						moduleType: 'side',
						value: include ? ['front'] : [...decorationSides, 'back']
					});
				}}
				name='Художественное оформление'
				id='back__side'
				value='back'
			/>
			{children}
		</CatalogForm>
	);
};

const withCustomPriceDecoration = withCustomPrice(Decoration, worksAPI.useLazyFetchWorksQuery);
export default withCustomPriceDecoration;
