import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import StepItem from './StepItem';
import Notification from 'components/UI/notification/index';

import { useGoToNextStep } from 'hooks/useGoToNextStep';
import { useActions } from 'hooks/useActions';
import { useStepsData } from 'hooks/useStepsData';
import { useResetSteps } from 'hooks/resetSteps';
import useBurialsData from 'hooks/useBurialsData';

import { complectsCatalog } from 'config/catalog.config';

const Steps = ({ clickHandle }) => {
	const { burialsImproved, firstImprovedBurialIndex, burials } = useBurialsData();
	const notification = useSelector(state => state.configurator.present.configuratorSteps.notification);
	const { willLostSteps } = notification;
	const { steps } = useStepsData();
	const { resetToDefaultThirdStep } = useResetSteps();

	const goToNextStep = useGoToNextStep();
	const actions = useActions();

	const goToStep = useCallback(
		id => {
			goToNextStep.goToNextStep(id => {
				if (id === 3) {
					const openTab = burialsImproved[0].catalog.type === 'go-step' ? 0 : 5;
					actions.changeCurrentSidebarTab(openTab);
				}

				actions.changeCurrentBurial(firstImprovedBurialIndex);
				clickHandle(id);
			}, id);
		},
		[actions, burialsImproved, clickHandle, firstImprovedBurialIndex, goToNextStep]
	);

	const goToStepInNotification = useCallback(
		id => {
			if (willLostSteps.includes(2)) actions.resetToDefaultSecondStep();
			if (willLostSteps.includes(3)) {
				resetToDefaultThirdStep({ all: true });
				actions.changeCatalogTypeFirst(complectsCatalog[0].value ?? 'standart');
			}

			clickHandle(id);
			actions.resetBurialsInstallations();
		},
		[clickHandle, resetToDefaultThirdStep, willLostSteps, burials, actions]
	);

	return (
		<div className='configurator__steps'>
			{steps.map(step => (
				<StepItem clickHandle={goToStep} key={step.id} step={step} />
			))}
			{notification.status && <Notification {...notification} goToStep={goToStepInNotification} />}
		</div>
	);
};

export default memo(Steps);
