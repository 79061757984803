import { useCallback } from 'react';

export const useTransforming = () => {
	const handleTransformEnd = useCallback((e, data, callback) => {
		let newUrl = data.map(el => {
			const isChangedEl = el.id == e.target.id();
			const visibleOnCanvas = isChangedEl && e.target.isClientRectOnScreen();

			return {
				...el,
				x: visibleOnCanvas ? e.target.x() : el.x,
				y: visibleOnCanvas ? e.target.y() : el.y,
				width: visibleOnCanvas ? e.target.width() : el.width,
				height: visibleOnCanvas ? e.target.height() : el.height,
				scaleX: visibleOnCanvas ? e.target.scaleX() : el.scaleX ?? 1,
				scaleY: visibleOnCanvas ? e.target.scaleY() : el.scaleY ?? 1,
				isDragging: false
			};
		});

		callback(newUrl);
	}, []);

	return {
		handleTransformEnd
	};
};
