export const complectsCatalog = [
	{
		label: 'Стандартный комплект',
		name: 'standard kit',
		value: 'standart',
		eventType: 'standard'
	},
	{
		label: 'Без цветника',
		name: 'standard kit',
		value: 'noflower',
		eventType: 'without'
	},
	{
		label: 'Индивидуальный проект',
		name: 'standard kit',
		value: 'go-step',
		eventType: 'standard'
	}
];
