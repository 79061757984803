import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';

import { useActions } from 'hooks/useActions';

const OwnTable = ({ currentTable, type }) => {
	const actions = useActions();
	const priceOwnTypeTable = currentTable?.price;
	const imgOwnTypeTable = currentTable?.img;

	const onInputHandler = useCallback(value => actions.setTableValue({ type, obj: 'price', value }), [actions, type]);

	const onDropHandler = useCallback(files => actions.setTableValue({ type, obj: 'img', value: files }), [actions, type]);

	return (
		<LoadForm
			formTitle='Своя табличка'
			formState={true}
			disableToogle={true}
			label='Введите стоимость'
			inputValue={priceOwnTypeTable}
			onInput={onInputHandler}
			images={imgOwnTypeTable}
			onDrop={onDropHandler}
		/>
	);
};

export default OwnTable;
