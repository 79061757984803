import { defaultFontFamily } from 'config/fontSizeTransform.config';

function revertString(str) {
	return str.split('-').reverse().join('.');
}

export const generatePlateObj = item => {
	let scope = [];
	const { fio, epitafia, portrait, fakeportrait } = item;
	if (epitafia && epitafia?.items !== null) {
		scope = scope.concat({
			status: epitafia.status,
			obj: { ...epitafia?.items, sort: 3, fill: epitafia.color.value },
			category: `plate-epitafia`,
			type: 'text'
		});
	}
	const fioItems = fio?.items
		? [fio.items].map(el => {
				if (el === null) return [];
				let arr = [el];
				if (item.type === 'granit') {
					const { datesItems, fio } = el;
					const transformedDatesItems = datesItems.items.map(item => {
						return {
							...item,
							text: `${item.startDate} - ${item.endDate}`,
							fontFamily: datesItems.font.value,
							textAlign: datesItems.align,
							textStyle: 'Прямой',
							sort: 2,
							fill: datesItems.color.value,
							type: datesItems.numericTypes === 'Арабские' ? 'date' : 'dateRome'
						};
					});
					const transformedFioItems = fio.items.map(item => ({
						id: item.id,
						text: item.value,
						fontFamily: fio.font.value,
						fontSize: item.fontSize,
						textAlign: fio.align,
						textStyle: 'Прямой',
						sort: 1,
						fill: fio.color.value
					}));

					arr = [...transformedFioItems, ...transformedDatesItems];
				} else {
					arr = arr
						.map(item => {
							const obj = {
								id: item.id,
								fontFamily: defaultFontFamily.value,
								fontSize: item.fontSize,
								textAlign: 'center',
								textStyle: item.fontStyle,
								sort: 1,
								fill: fio.color.value,
								class: 'custom'
							};
							const items = [
								{
									text: item.name,
									id: item.nameId
								},
								{
									text: item.surname,
									id: item.surnameId
								},
								{
									text: item.middleName,
									id: item.middlenameId
								}
							].map(({ text, id }) => ({ ...obj, text, id }));

							// const date = `${dateToRoman(revertString(item.dateOfBorn), item.numericFormat)} - ${dateToRoman(
							// 	revertString(item.dateOfDeath),
							// 	item.numericFormat
							// )}`;

							const dateObj = {
								...obj,
								type: item.numericFormat === 'Арабские' ? 'date' : 'dateRome',
								fontSize2: item.fontSize,
								startDate: item.dateOfBorn,
								endDate: item.dateOfDeath,
								id: item.dateId
							};

							return [...items, dateObj];
						})
						.flat();
				}

				return arr.map(item => ({
					status: fio.status,
					obj: item,
					category: `plate-text-${item.id}`,
					type: item.type ?? 'text'
				}));
		  })
		: [];

	const portraitItems =
		portrait?.items.map(el => {
			return { status: portrait.status, obj: { ...el, sort: 0 }, category: `plate-portrait-${el.id}`, type: 'image' };
		}) ?? [];

	const fakePortraitItems =
		fakeportrait?.items.map(el => {
			return {
				status: fakeportrait.status,
				obj: { ...el, sort: 0, align: 'center', fitHeight: 80, y: 20 },
				category: `fakeportrait-portrait-${el.id}`,
				type: 'image'
			};
		}) ?? [];

	scope = [...portraitItems, ...fakePortraitItems, ...fioItems.flat(), ...scope].flat().sort((a, b) => a.obj.sort - b.obj.sort);

	return scope;
};
