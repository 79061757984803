import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

const items = [1, 2, 3, 4];

const HowManyBurials = () => {
	const actions = useActions();
	const howManyBurials = useSelector(state => state.configurator.present.step1.howManyBurials);

	const onChange = useCallback(amount => actions.changeHistoryHowManyBurials(amount), []);

	return (
		<BlockWithArrow
			title='Сколько захоронений?'
			blockClasses='sidebar__radio__block__value'
			contentClasses='sidebar__radio__block__value__items'
		>
			{items.map(item => (
				<RadioInput
					key={item}
					title={item}
					defaultChecked={howManyBurials === item}
					onChange={() => onChange(item)}
					name='quantity'
					id={`grave_amount_${item}`}
				/>
			))}
		</BlockWithArrow>
	);
};

export default memo(HowManyBurials);
