import React, { useState } from 'react';

const Processing = () => {
	const [open, setOpen] = useState(true);
	return (
		<div className='sidebar__radio__block__value exception__block heading_off' id='exclude_space_block'>
			<div className={`sidebar__block__header heading_off ${open ? 'active' : ''}`}>
				<h3>Обработка</h3>
				<input
					type='checkbox'
					className='toggle disable'
					checked={open}
					onChange={e => {
						setOpen(!open);
					}}
				/>
			</div>
			{open && <></>}
		</div>
	);
};

export default Processing;
