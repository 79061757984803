import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeNotification } from 'slices/configuratorStepsSlice';

import { checkGoToStep } from 'utils/checkGoToStep';

export const useGoToNextStep = () => {
	const dispatch = useDispatch();
	const burials = useSelector(state => state.configurator.present.step1.burials);
	const instalation = useSelector(state => state.configurator.present.step1.installation);
	const stepsObj = useSelector(state => state.configurator.present.configuratorSteps.steps);
	let steps = useMemo(() => {
		return instalation === 'Самовывоз' ? [...stepsObj].splice(0, 3) : [...stepsObj];
	}, [stepsObj, instalation]);

	const notificationHandler = useCallback(
		({ type, clicked, heading, text, willLostSteps }) =>
			dispatch(
				changeNotification({
					status: true,
					type,
					clicked,
					heading,
					text,
					willLostSteps
				})
			),
		[dispatch]
	);

	const goToNextStep = useCallback(
		(clickHandle, id) => {
			const check = checkGoToStep(id, steps, burials);
			// eslint-disable-next-line no-unused-vars
			const { checkBadBurials, willLostSteps, improvedBurials, activeStep, message } = check;
			const { heading, text, type, status } = message;

			// console.log({
			// 	clicked: id,
			// 	activeStep,
			// 	checkBadBurials,
			// 	message
			// });

			if (checkBadBurials.length === 0 && improvedBurials.length !== 0 && status === false) {
				clickHandle(id);
			} else {
				notificationHandler({ type, clicked: id, willLostSteps, heading, text });
			}
		},
		[burials, notificationHandler, steps]
	);

	return {
		goToNextStep
	};
};
