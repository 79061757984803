import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import RadioStoneInput from 'components/UI/input/radioStone';
import CatalogForm from 'components/UI/form/catalogForm';
import BlockWithArrow from 'components/UI/block/blockWithArrow';

import { useActions } from 'hooks/useActions';
import useGetParametrs from './../../../API/getParametrs';

const materialItems = [
	{
		img: 'images/stones/black.jpg',
		title: 'Черный',
		type: 'metal',
		material: 'black'
	},
	{
		img: 'images/stones/antique_silver.jpg',
		title: 'Серебро антик',
		type: 'metal',
		material: 'antique_silver'
	},
	{
		img: 'images/stones/antique_copper.jpg',
		title: 'Медный антик',
		type: 'metal',
		material: 'antique_copper'
	}
];

const MetalElements = () => {
	const actions = useActions();
	const getParamsHandler = useGetParametrs();
	const { status, items } = useSelector(state => state.configurator.present.step5.decoratedElements.metal);
	const fetchData = useCallback(() => getParamsHandler.getModuleProducts('elements'), [getParamsHandler]);

	const [selectedStone, setSelectedStone] = useState(materialItems[0].title);

	const onChangeToggleHandler = useCallback(
		() => actions.changeDecoratedElementsStatus({ type: 'metal', values: !status }),
		[actions, status]
	);

	const onClickProductHandler = useCallback(
		item => actions.changeDecoratedElementsSelectedProducts({ type: 'metal', values: item }),
		[actions]
	);

	return (
		<CatalogForm
			formTitle='Металлические элементы'
			formState={status}
			onToggle={onChangeToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог элементов'
			products={fetchData}
			selectedProducts={items}
			plusState
			onPlusHandler={product => {
				actions.changeDecoratedElementsSelectedProducts({
					type: 'metal',
					values: { ...product, sku: product.sku + Array(items.length).fill(' ').join('') },
					clone: true
				});
			}}
		>
			<BlockWithArrow
				title='Вид камня'
				blockClasses='sidebar__radio__block__value checkbox__type change__type__stone'
				contentClasses='sidebar__radio__block__value__items'
				hideHeader={true}
				openState={false}
			>
				{materialItems.map((item, index) => (
					<RadioStoneInput
						key={index}
						img={item.img}
						name={item.type}
						title={item.title}
						id={item.material + item.title}
						defaultChecked={selectedStone === item.title}
						onChange={() => setSelectedStone(item.title)}
					/>
				))}
			</BlockWithArrow>
		</CatalogForm>
	);
};

export default MetalElements;
