import React, { useCallback, useEffect, useMemo } from 'react';

import TextInput from 'components/UI/input/text';
import PopUp from 'components/UI/popup/popup';
import ParralelAlign from './ParralelAlign';
import LineAlign from './LineAlign';

import useArrayFromLenght from 'hooks/useArrayFromLenght';
import { stellaEvents } from 'utils/events/stella';
import { useActions } from 'hooks/useActions';

import './../stellaPopUp.scss';

const PopUpStellaPosition = ({ items, currentBurial, burial, currentTab, position }) => {
	const actions = useActions();
	const createArr = useArrayFromLenght(items.length);
	const createInputs = useArrayFromLenght(items.length == 1 ? 3 : 5);
	const noDisabled = burial.stella.noDisabled;
	const alignType = useCallback(() => (createArr <= 1 ? null : position.align), [createArr, position.align]);
	const title = useMemo(() => (items.length == 1 ? 'Расположение стелы' : 'Расположение стел'), [items.length]);
	const classesLength = createInputs.length == 3 ? 'min' : 'max';

	useEffect(() => {
		if (position.status == false || currentTab != 2) actions.changeStellaPopUpState(false);
	}, [currentBurial, currentTab, actions, position.status]);

	useEffect(() => {
		if (burial.stella.positionBoxState) {
			if (items.length == 1) {
				stellaEvents.oneStellaPosition(burial.id, position.items);
			} else {
				const align = alignType();
				if (align !== 'space') {
					const index = align == 'center' ? 1 : align == 'right' ? 2 : 3;
					stellaEvents.twoStelesInParalel(index, burial.id, position.items);
				} else {
					stellaEvents.twoStelesInLine(burial.id, position.items);
				}
			}
		}
	}, [position.items, alignType, burial.id, burial.stella.positionBoxState, items.length]);

	return items ? (
		<PopUp state={burial.stella.positionBoxState} onClick={() => actions.changeStellaPopUpState(false)}>
			<h2 className='mb24 title'>{title}</h2>
			<div className={`popup popup-position stella-position stella-position-${alignType()} mb24`}>
				{items.length > 1 ? (
					<div className='stella-position-variables'>
						<p>Выравнивание:</p>
						<div className='stella-position-variables__items'>
							{noDisabled.parralelAlign == true && <ParralelAlign burial={burial} alignType={alignType} />}
							{noDisabled.lineAlign == true && <LineAlign burial={burial} alignType={alignType} />}
						</div>
					</div>
				) : null}
				<div className={`popup-position__values popup-position__stella__values ${alignType()} ${classesLength}`}>
					{createInputs.map((item, index) => (
						<TextInput
							key={index}
							blockClasses={`popup-position__input`}
							defaultValue={position.items[index]}
							name={`popUpStellaPosition_${index}`}
							id={`popUpStellaPosition_${index}`}
							onChange={value => {
								actions.changeValuesStellaPopUp({
									index: index,
									value: parseInt(value)
								});
							}}
							required={true}
						/>
					))}
				</div>
				<div className={`popup-position__stella ${classesLength}`}>
					<div className={`popup-position__stella__wrapper ${alignType()}`}>
						{createArr.map(item => (
							<div key={item} className='position__stella__item'>
								{item + 1}
							</div>
						))}
					</div>
				</div>
			</div>
		</PopUp>
	) : null;
};

export default PopUpStellaPosition;
