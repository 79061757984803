import { useCallback } from 'react';

export const useDetection = () => {
	const checkDeselect = useCallback((e, callback) => {
		// deselect when clicked on empty area
		const clickedOnEmpty = e.target === e.target.getStage();
		if (clickedOnEmpty) callback(null);
	}, []);

	return {
		checkDeselect
	};
};
