import { findBurial, sendEventToChangeMaterial, changeSelectProductStatus } from './../../step1Slice';

import { createTombstone } from 'utils/helpers/step3_generateTombstone';

const step3Tombstone = () => {
	const changeCurrentTombstone = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.currentTomb = action.payload;
	};

	const changeTombstoneMaterial = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].material = action.payload;
		action.payload.value == 'granite'
			? (burial.tombstone.items[burial.tombstone.currentTomb].stone = 'Габбро Диабаз')
			: (burial.tombstone.items[burial.tombstone.currentTomb].stone = 'Мрамор');
		sendEventToChangeMaterial(burial.id, action.payload.value, 'tombstone_marble_grade_changed', 'tombstone_marble_grade_changed');
	};

	const changeTombstoneMaterialType = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].stone = action.payload;
	};

	const changeTombstoneIndividualSizes = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].individual = action.payload;
	};

	const changeTombstoneArtisticDesignStatus = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].artisticDesign.status = action.payload;
	};

	const changeTombstoneArtisticDesignSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].artisticDesign.selectedProducts = changeSelectProductStatus(
			burial.tombstone.items[burial.tombstone.currentTomb].artisticDesign.selectedProducts,
			action.payload,
			true
		);
	};

	const changeTombstoneDecorationFaskaStatus = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].decorationFaska.status = action.payload;
	};

	const changeTombstoneDecorationFaskaSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items[burial.tombstone.currentTomb].decorationFaska.selectedProducts = changeSelectProductStatus(
			burial.tombstone.items[burial.tombstone.currentTomb].decorationFaska.selectedProducts,
			action.payload,
			true
		);
	};

	const createTombstoneItem = (state, action) => {
		const burial = findBurial(state);
		const { title, value } = burial.material;
		const stone = burial.materialStone;

		burial.tombstone.items = [...burial.tombstone.items, createTombstone(title, value, stone)];
		if (burial.tombstone.items.length !== 0) burial.tombstone.currentTomb = burial.tombstone.items.length - 1;
	};

	const deleteCurrentTombstoneItem = (state, action) => {
		const burial = findBurial(state);
		burial.tombstone.items = burial.tombstone.items.filter((el, index) => burial.tombstone.currentTomb != index);
		if (burial.tombstone.items.length != 0) burial.tombstone.currentTomb = 0;
	};

	return {
		changeCurrentTombstone,
		changeTombstoneMaterial,
		changeTombstoneMaterialType,
		changeTombstoneIndividualSizes,
		changeTombstoneArtisticDesignStatus,
		changeTombstoneArtisticDesignSelectedProducts,
		changeTombstoneDecorationFaskaStatus,
		changeTombstoneDecorationFaskaSelectedProducts,
		createTombstoneItem,
		deleteCurrentTombstoneItem
	};
};

export default step3Tombstone;
