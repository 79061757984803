import React, { useRef, useMemo, useLayoutEffect } from 'react';

import useImage from 'use-image';
import { Image } from 'react-konva';

import { cors } from 'services/constans';
import { OFFSET } from '../constans';
import { getScaledImageDimensions } from './../utils/getScaledImageDimensions';

const CreateImage = ({
	item,
	onSelect,
	onDragEnd,
	handleTransformEnd,
	draggable,
	items,
	changeItems,
	alignItemsBySide,
	stageDimensions
}) => {
	const imageRef = useRef();
	const url = useMemo(() => (item.url.includes('http') && !item.url.includes('blob') ? cors : '') + item.url, [item.url]);
	const [image, loading] = useImage(url, 'anonymous');
	const konvaItem = useMemo(() => {
		if (item.size) delete item.size;
		if (item.image) delete item.image;
		return item;
	}, [item]);

	const fitWidth = fitValue => {
		let width, height;
		width = fitValue;
		height = (imageRef.current.height() / imageRef.current.width()) * fitValue;
		return [width, height];
	};

	const fitHeight = fitValue => {
		let width, height;
		width = (imageRef.current.width() / imageRef.current.height()) * fitValue;
		height = fitValue;
		return [width, height];
	};

	useLayoutEffect(() => {
		if (imageRef.current && loading === 'loaded') {
			const stage = imageRef.current.parent.parent;
			let newItems = [...items];

			if (item?.fitWidth || item?.fitHeight) {
				newItems = newItems.map(el => {
					if (el.id === item.id) {
						let [width, height] = item.fitWidth ? fitWidth(item.fitWidth) : fitHeight(item.fitHeight);
						if (width > stageDimensions.width / (item.side ? 2 : 1) - OFFSET * 2) {
							[width, height] = fitWidth(stageDimensions.width / (item.side ? 2 : 1) - OFFSET * 2);
						}

						return {
							...item,
							x: item.align ? (stageDimensions.width / (item.side ? 2 : 1) - width) / 2 : item.x,
							y: item.verticalAlign ? (stageDimensions.height - height) / 2 : item.y,
							width,
							height,
							wasChanged: true
						};
					}
					return el;
				});
			}

			const limits =
				imageRef.current.width() > stageDimensions.width / (item.side ? 2 : 1) - OFFSET * 2 ||
				imageRef.current.height() > stageDimensions.height - OFFSET * 2;
			if (limits && !(item.alignBySide && item.alignBySide.status && !item?.fitWidth && !item?.fitHeight) && !item.wasChanged) {
				const stageWidth = stageDimensions.width / (item.side ? 2 : 1) - OFFSET * 2;
				const stageHeight = stageDimensions.height - OFFSET * 2;
				const { newWidth, newHeight } = getScaledImageDimensions(
					stageWidth,
					stageHeight,
					item.width ?? imageRef.current.width(),
					item.height ?? imageRef.current.height()
				);

				newItems = items.map(el => {
					if (el.id === item.id) return { ...el, x: el.x || 0, y: el.y || 0, width: newWidth, height: newHeight, wasChanged: true };
					return el;
				});
			}

			if (item.alignBySide && item.alignBySide.status) {
				// TODO
				const stageWidth = stageDimensions.type === 'stele' ? stageDimensions.width / 2 : stageDimensions.width;
				// const stageWidth = stageDimensions.width;
				newItems = alignItemsBySide(stage, item.id, newItems, stageWidth, imageRef.current, item.frame, stageDimensions.height);
			}

			if (item.moveBottom) imageRef.current.moveToBottom();

			if (item?.resizeDisable && !item.wasChanged) {
				newItems = newItems.map(el => {
					if (el.id === item.id) {
						return {
							...item,
							width: imageRef.current.width(),
							height: imageRef.current.height(),
							wasChanged: true
						};
					}
					return el;
				});
			}

			changeItems(newItems);
		}
	}, [
		item?.alignBySide,
		item?.moveBottom,
		item?.resizeDisable,
		item?.wasChanged,
		loading,
		stageDimensions.height,
		stageDimensions.type,
		stageDimensions.width
	]);

	useLayoutEffect(() => {
		if (imageRef.current) {
			imageRef.current.setAttrs({ ...item });
			const doCache = imageRef.current.width() && imageRef.current.height();
			if (doCache) {
				imageRef.current.cache({
					imageSmoothingEnabled: true,
					hitCanvasPixelRatio: true
				});
			}
		}
	}, [konvaItem, url, image]);

	return (
		<Image
			{...konvaItem}
			image={image}
			draggable={draggable}
			ref={imageRef}
			onClick={e => onSelect(e.target.id())}
			onTap={e => onSelect(e.target.id())}
			onDragEnd={onDragEnd}
			onTransformEnd={handleTransformEnd}
			listening={!item.frame ?? true}
			perfectDrawEnabled={true}
		/>
	);
};

export default CreateImage;
