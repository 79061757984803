import { useLayoutEffect } from 'react';

export const useWithCustomPriceReUnit = ({
	onChangeCustomPrice,
	refetchHandlerRef,
	onChangeCustomPriceHandler,
	refetchDataHandler,
	price,
	changeCustomPrice
}) => {
	useLayoutEffect(() => {
		onChangeCustomPrice.current = onChangeCustomPriceHandler;
	}, [onChangeCustomPriceHandler]);

	useLayoutEffect(() => {
		refetchHandlerRef.current = refetchDataHandler;
	}, [refetchDataHandler]);

	useLayoutEffect(() => {
		changeCustomPrice(prev => price);
	}, [price]);

	return null;
};
