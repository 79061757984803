import React, { useCallback } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';

import { useActions } from 'hooks/useActions';

const ChangeClothes = ({ currentTable, type }) => {
	const actions = useActions();
	const currentStateChangeClothes = currentTable?.changeClothes;

	const onChangeToggleHandler = useCallback(() => {
		actions.setTableValue({ type, obj: 'changeClothes', value: !currentStateChangeClothes });
	}, [currentStateChangeClothes, actions, type]);

	return (
		<BlockWithToggle title='Переодеть' openState={currentStateChangeClothes} onChangeToggle={onChangeToggleHandler} disableContent={true} />
	);
};

export default ChangeClothes;
