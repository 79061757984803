import { stellaEvents } from 'utils/events/stella';

const resetCatalog = { status: false, selectedProducts: [] };

export const resetingElements = {
	pedestal: [
		{
			title: 'Фаски',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.decorationFaska;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'pedestal',
				current: currentElement,
				moduleObject: 'decorationFaska',
				value: resetCatalog
			})
		}
	],
	boxes: [
		{
			title: 'Фаски',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.decorationFaska;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'boxes',
				current: currentElement,
				moduleObject: 'decorationFaska',
				value: resetCatalog
			})
		}
	],
	stella: [
		{
			title: 'Форма',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.form;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'stella',
				current: currentElement,
				moduleObject: 'form',
				value: resetCatalog
			}),
			callback: burialId => {
				if (burialId === null || burialId === undefined) return;
				stellaEvents.steleModelChanged(burialId, 1);
			}
		},
		{
			title: 'Рамки',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.frames;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'stella',
				current: currentElement,
				moduleObject: 'frames',
				value: resetCatalog
			})
		},
		{
			title: 'Фаски',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.decorationFaska;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'stella',
				current: currentElement,
				moduleObject: 'decorationFaska',
				value: resetCatalog
			})
		}
	],
	flower: [
		{
			title: 'Фаски',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.decorationFaska;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'flowers',
				current: currentElement,
				moduleObject: 'decorationFaska',
				value: resetCatalog
			})
		}
	],
	tombstone: [
		{
			title: 'Фаски',
			func: 'changeFormElements',
			dependencies: item => {
				const el = item.form.decorationFaska;
				return el.status && el.selectedProducts.length !== 0;
			},
			value: currentElement => ({
				type: 'tombstone',
				current: currentElement,
				moduleObject: 'decorationFaska',
				value: resetCatalog
			})
		}
	]
};
