import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';
import cn from 'classnames';

import './flowerType.scss';

const items = [
	{ type: 'standard', img: 'images/flower/flower_1.svg' },
	{ type: 'separate', img: 'images/flower/flower_2.svg' },
	{ type: 'general', img: 'images/flower/flower_3.svg' }
];

const FlowerType = ({ burial, currentFlower, flowers }) => {
	const actions = useActions();
	const currentFlowerObj = flowers[currentFlower];

	return (
		<BlockWithArrow title='Вид цветника' blockClasses='sidebar__radio__block flower__block' contentClasses='box__value__flowers'>
			{items.map(item => (
				<RadioInput
					key={item.type}
					defaultChecked={currentFlowerObj.selected === item.type}
					onChange={e => {
						actions.changeFlowerType({ name: item.type, type: e.target.dataset.value });
					}}
					name='flowers'
					id={item.type}
					value={item.type}
					classNames={cn('flower__block--img', {
						disable:
							(parseInt(currentFlower) !== 0 && item.type !== 'separate') ||
							(burial.pedestal.items.length === 0 && burial.boxes.items.length !== 0 && item.type === 'general') ||
							(flowers.length >= 2 && item.type !== 'separate')
					})}
					title={item.type}
				>
					<img src={item.img} alt='flower type' />
				</RadioInput>
			))}
		</BlockWithArrow>
	);
};

export default FlowerType;
