// import { defaultFontSizeTransform, fontSizeTransform } from './fontSizeTransform.config';
import { getHexColor } from '../components/UI/canvas/getHexFromImg';
import { A3, A6 } from './sizes';

export const getOwnPictureArgs = item => {};

export const getTombstoneTemplate = ({ stone = 'Габбро Диабаз', width, height, portrait }) => {
	const PORTRAIT_GAP = 100; //mm;
	const PORTRAIT_NAME_GAP = 40;
	// const TOP_GAP = 220; //(portrait ? 220 : 0); //mm
	const THIRD_NAME_S = 40; //mm
	// const THIRD_NAME_S = fontSizeTransform[THIRD_NAME_PT];
	const THIRD_FIRST_GAP = 0; //20; //mm
	const FIRST_NAME_S = 35; //mm
	// const FIRST_NAME_S = fontSizeTransform[FIRST_NAME_PT];
	const FIRST_SECOND_GAP = 0; //15; //mm
	const SECOND_NAME_S = 35; //mm
	// const SECOND_NAME_S = fontSizeTransform[SECOND_NAME_PT];
	const EPITAFIA_S = 17; //mm
	// const EPITAFIA_S = fontSizeTransform[EPITAFIA_PT];
	const DATE_SIZE = 30; //mm день месяц надо 25;
	const DATE_GAP = 25; //mm

	const template = {};
	template.portrait = {
		x: 10,
		y: PORTRAIT_GAP,
		fitHeight: A3.height,
		type: 'image',
		align: 'center'
	};

	template.thirdName = {
		text: 'ФАМИЛИЯ',
		x: 10,
		y: template.portrait.y + A3.height + PORTRAIT_NAME_GAP,
		fontSize: THIRD_NAME_S,
		textAlign: 'center',
		fill: getHexColor(stone).textColor.value,
		type: 'text'
	};

	template.firstName = {
		text: 'ИМЯ',
		x: 10,
		y: template.thirdName.y + THIRD_NAME_S + THIRD_FIRST_GAP,
		fontSize: FIRST_NAME_S,
		textAlign: 'center',
		fill: getHexColor(stone).textColor.value,
		type: 'text'
	};

	template.secondName = {
		text: 'ОТЧЕСТВО',
		x: 10,
		y: template.firstName.y + FIRST_NAME_S + FIRST_SECOND_GAP,
		fontSize: SECOND_NAME_S,
		textAlign: 'center',
		fill: getHexColor(stone).textColor.value,
		type: 'text'
	};

	template.dates = {
		x: 10,
		y: template.secondName.y + SECOND_NAME_S + DATE_GAP,
		fontSize: DATE_SIZE,
		textAlign: 'center',
		fill: getHexColor(stone).textColor.value,
		type: 'text'
	};

	template.engravingEpitafia = {
		x: 10,
		y: height - 100 - 2 * EPITAFIA_S,
		fontSize: EPITAFIA_S,
		textAlign: 'center',
		fill: getHexColor(stone).textColor.value,
		textColor: getHexColor(stone).textColor,
		type: 'text'
	};

	template.creed = {
		x: 30,
		y: 30,
		fitHeight: A6.height,
		type: 'image',
		align: null
	};

	template.engraving = {
		align: 'center',
		verticalAlign: 'center',
		type: 'image'
	};

	template.ownPicture = {
		y: 100,
		x: 100,
		// align: 'center',
		fitHeight: 120,
		type: 'image'
	};

	return template;
};
