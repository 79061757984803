import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';

import PrimaryButton from 'components/UI/button/primary';
import TextInput from 'components/UI/input/text';
import SelectInput from 'components/UI/input/select';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

import { ReactComponent as Basket } from 'images/icons/basket.svg';
import { ReactComponent as Left } from 'images/align/left.svg';
import { ReactComponent as Right } from 'images/align/right.svg';
import { ReactComponent as Center } from 'images/align/center.svg';
import { fontSizeTransform } from 'config/fontSizeTransform.config';
import { fonts } from 'config/fontSizeTransform.config';
import { numericTypes, engravingTextColors } from 'config/canvas.config';
import { generateEpitafia } from '../../../../../utils/forms/generateEpitafia';
import { standaloneSize } from 'utils/standaloneSize';
import { generateDateItem } from '../../../../../utils/forms/generateFioAndDates';
import DateInput from '../../../../UI/input/date';
import InputTypeNumber from 'components/UI/input/text/number';
registerLocale('ru', ru);

const itemsArr = numericTypes;
const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));
const FontOptions = fonts;

const DatesOfLife = ({ template, data, currentTableFioColor }) => {
	const actions = useActions();
	const { font, align, items, numericTypes: typesNumeric } = data;

	const changeData = useCallback(
		(value, include) => actions.setEngravingItemsFioDateTable({ obj: 'datesItems', type: 'fio', include, value }),
		[]
	);

	const changeItemsData = useCallback((actionType = null, value, include, index) => {
		actions.setEngravingItemsFioDateTableItems({ actionType, obj: 'datesItems', type: 'fio', include, value, index });
	}, []);

	const DateElement = ({ el, index }) => {
		return (
			<>
				<div className='row'>
					<div className='flex'>
						<DateInput
							label='Дата рождения'
							id={'Дата рождения'}
							blockClasses='restavrationInput date'
							value={new Date(el.startDate).toISOString().split('T')[0]}
							onChange={(e, value) => {
								changeItemsData(null, value, 'startDate', index);
							}}
						/>
						<DateInput
							label='Дата смерти'
							id={'Дата смерти'}
							blockClasses='restavrationInput date'
							value={new Date(el.endDate).toISOString().split('T')[0]}
							onChange={(e, value) => {
								changeItemsData(null, value, 'endDate', index);
							}}
						/>
					</div>
				</div>
				<div className='row'>
					<InputTypeNumber
						blockClasses='own__form__input'
						label='Введите стоимость'
						defaultValue={el.price}
						name={`own__form__input__${index}`}
						id={`own__form__input__${index}`}
						onChange={price => changeItemsData(null, price, 'price', index)}
						useDelay={false}
					/>
				</div>
				<div className='row'>
					<div className='flex'>
						<h3 style={{ margin: '5px' }}>Высота дата</h3>
						<SelectInput
							title={standaloneSize(el.fontSize)}
							items={options}
							onClick={value => changeItemsData(null, value.value, 'fontSize', index)}
						/>
						<h3 style={{ margin: '5px' }}>Высота год</h3>
						<SelectInput
							title={standaloneSize(el.fontSize2)}
							items={options}
							onClick={value => changeItemsData(null, value.value, 'fontSize2', index)}
						/>
						<button onClick={() => changeItemsData('remove', null, 'fontSize', index)}>
							<Basket />
						</button>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div className='box'>
				<SelectInput title={currentTableFioColor} items={engravingTextColors} onClick={value => changeData(value, 'color')} />
				<div
					className='row'
					style={{
						paddingBottom: '12px',
						borderBottom: '1px solid #c6cbd9'
					}}
				>
					<SelectInput title={font.title} items={FontOptions} onClick={value => changeData(value, 'font')} />
					<div className='align-btns'>
						<button className={`${align == 'left' ? 'active' : ''}`} onClick={e => changeData('left', 'align')}>
							<Left />
						</button>
						<button className={`${align == 'center' ? 'active' : ''}`} onClick={e => changeData('center', 'align')}>
							<Center />
						</button>
						<button className={`${align == 'right' ? 'active' : ''}`} onClick={e => changeData('right', 'align')}>
							<Right />
						</button>
					</div>
				</div>
				{items.map((el, index) => (
					<DateElement el={el} index={index} key={index} />
				))}

				<BlockWithArrow title='Формат цифр:' openState={false} arrowDelete={true} blockClasses='sidebar__radio__block numericTypes'>
					{itemsArr.map((el, index) => (
						<RadioInput
							key={index}
							title={el}
							id={el}
							onChange={e => changeData(el, 'numericTypes')}
							defaultChecked={typesNumeric == el ? true : false}
						/>
					))}
				</BlockWithArrow>
			</div>
			<PrimaryButton
				title='Добавить строку'
				onClick={() => {
					const newTemplate = items.length === 0 ? template : { ...template, dates: { ...template['dates'], y: 0 } };
					changeItemsData('create', generateDateItem(newTemplate, 0));
				}}
				className='btn box__value__add'
			/>
		</>
	);
};

export default DatesOfLife;
