import { useMemo } from 'react';

import { imgObj, textObj } from 'components/Canvas/constans';
import { generatePlateObj } from 'utils/canvas/data/generatePlateObj';
import { generateStellaObj } from 'utils/canvas/data/generateStellaObj';
import { generateTombstoneObj } from 'utils/canvas/data/generateTombstoneObj';
import { transformFontSize } from 'utils/canvas/transformFontSize';
import { defaultFontFamily } from 'config/fontSizeTransform.config';

const useDataCanvas = (burial, canvasData, canvasType, item) => {
	const dependencies = useMemo(() => {
		let scope = [];
		// stele canvas data
		if (canvasType === 'stele') scope = [...scope, ...generateStellaObj(burial, item)];
		// tables canvas data
		if (canvasType === 'plate') scope = [...scope, ...generatePlateObj(item)];
		// tombstone canvas data
		if (canvasType === 'tombstone') scope = [...scope, ...generateTombstoneObj(item)];
		// console.log(scope);
		return scope.filter(item => item.status !== false && item.obj && item.obj.text !== '');
		// return scope;
	}, [
		burial.elements.elems,
		burial.elements.fio,
		burial.elements.epitafia,
		burial.engraving.creed,
		burial.engraving.fio,
		burial.engraving.engraving,
		burial.engraving.ownForm,
		burial.engraving.epitafia,
		burial.engraving.portrait.items,
		item?.fio,
		item?.epitafia,
		item?.portrait,
		item?.elements,
		item?.engraving,
		item?.form?.decoration,
		item?.form?.frames,
		canvasType
	]);

	const data = useMemo(() => {
		let arr = [...canvasData];
		let scope = [];
		const isContains = catType => arr.find(el => el.catType === catType);

		const doAction = (status, obj, catType, type) => {
			const checkType = type === 'image' ? obj?.items?.length !== 0 : obj?.text !== '';
			const check = status && obj && checkType;

			if (check) {
				if (!isContains(catType)) {
					let element;
					switch (type) {
						case 'image':
							element = imgObj(obj, catType);
							break;
						case 'text':
							element = textObj(obj, catType);
							break;
						default:
							element = { ...obj, catType };
							break;
					}
					// const element = type === 'image' ? imgObj(obj, catType) : textObj(obj, catType);
					arr = [...arr, element];
				} else {
					arr = arr.map(el => {
						if (el.catType === catType) {
							if (type === 'image') {
								const newEl = el.img !== obj.img ? imgObj(obj, catType, el.x, el.y) : el;
								el = { ...newEl, url: obj.url ?? obj.img, type: 'image' };
							} else if (type === 'text') {
								el = {
									...el,
									text: obj.text,
									fontFamily: obj.fontFamily ?? defaultFontFamily.value,
									fontSize: transformFontSize(obj.fontSize),
									textAlign: obj.textAlign ?? 'Left',
									fontStyle: obj.textStyle ?? 'normal',
									fill: obj.fill,
									type: 'text'
								};
							} else {
								el = {
									...el,
									startDate: obj.startDate,
									endDate: obj.endDate,
									fontFamily: obj.fontFamily ?? defaultFontFamily.value,
									fontSize: transformFontSize(obj.fontSize),
									fontSize2: transformFontSize(obj.fontSize2),
									textAlign: obj.textAlign ?? 'Left',
									fontStyle: obj.textStyle ?? 'normal',
									fill: obj.fill,
									type: obj.type,
									catType
								};
							}
						}
						return el;
					});
				}
			} else {
				arr = arr.filter(el => el.catType !== catType);
			}
			scope.push(catType);
		};

		dependencies.map(item => {
			const { status, obj, category, type } = item;
			doAction(status, obj, category, type);
		});

		arr = arr.filter(el => scope.some(item => item === el.catType)).sort((a, b) => a.sort - b.sort);

		return arr;
	}, [dependencies]);

	return data;
};

export default useDataCanvas;
