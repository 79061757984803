import React, { useCallback, memo, useMemo, useState } from 'react';

import { complectsAPI } from 'services/Complects';
import { useActions } from 'hooks/useActions';

import TextInput from 'components/UI/input/text';
import Productlist from 'components/UI/list/productsList';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { setComplect } from 'utils/helpers/complects/setComplect';
import { useSelector } from 'react-redux';

const CalatolCollectionProductList = ({ burial, currentBurial, type: material }) => {
	const actions = useActions();
	const categories = useConfiguratorCategories('sets');
	const cat_tax = categories.find(el => el.slug === material)?.id;
	const materials = useSelector(state => state.configurator.present.configuratorSteps.categories.materials);
	const materialType = burial.material.value;

	const stone = useMemo(() => burial.materialStone, [burial.materialStone]);
	const polishing = useMemo(() => burial.polishing, [burial.polishing]);

	const [dimensions, setDimensions] = useState({
		length: '',
		width: '',
		height: ''
	});
	const query = useMemo(() => {
		const material = materials.find(el => el.title === stone);
		const { height, length, width } = dimensions;

		let obj = {
			material: material.id,
			polishing: polishing
		};

		if (height) obj.size_thickness = height;
		if (length) obj.size_height = length;
		if (width) obj.size_width = width;

		return obj;
	}, [stone, polishing, dimensions, materials]);

	const onClickProductHandler = useCallback(
		async item => {
			const values = await setComplect(item, null, materialType);
			actions.changeHistorySelectedProduct(values);
		},
		[materialType]
	);

	return (
		<div className='productList productList__catalog'>
			<div className='exception__item'>
				<div className='sidebar__radio__block--values sidebar__radio__block--values--full-width'>
					<TextInput
						blockClasses='sidebar__radio__block--values__length'
						label='Высота'
						defaultValue={dimensions.length}
						name={`collection_lenght`}
						id={`collection_lenght`}
						required={true}
						onChange={value => setDimensions({ ...dimensions, length: value })}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Ширина'
						defaultValue={dimensions.width}
						name={`collection_width`}
						id={`collection_width`}
						required={true}
						onChange={value => setDimensions({ ...dimensions, width: value })}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<TextInput
						blockClasses='sidebar__radio__block--values__width'
						label='Толщина'
						defaultValue={dimensions.height}
						name={`collection_height`}
						id={`collection_height`}
						required={true}
						onChange={value => setDimensions({ ...dimensions, height: value })}
					/>
					<span>мм</span>
				</div>
			</div>
			<Productlist
				fetchData={complectsAPI.useFetchComplectsByTypeQuery}
				type={material}
				selectedProducts={burial.catalog.selectedProducts}
				currentBurial={currentBurial}
				query={query}
				onClickProduct={onClickProductHandler}
				taxId={cat_tax}
			/>
		</div>
	);
};

export default memo(CalatolCollectionProductList);
