import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import CheckBox from 'components/UI/input/checkbox';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

const AdditionalWorks = () => {
	const actions = useActions();
	const { status, items } = useSelector(state => state.configurator.present.step6.additionalWork);

	const onChangeToggleHandler = useCallback(() => actions.changeAdditionalWorkStatus(!status), [actions, status]);

	const onChangeHandler = useCallback(obj => actions.changeAdditionalWorkItem(obj), [actions]);

	return (
		<BlockWithToggle title='Дополнительные работы' openState={status} onChangeToggle={onChangeToggleHandler}>
			{items.map(el => (
				<CheckBox
					key={el.id}
					title={el.text}
					defaultChecked={el.status}
					name={el.text}
					id={el.text}
					value={el.text}
					addText={true}
					addTextClassName={el.addTextClassName ?? 'sidebar__radio__block--values__volume'}
					addTextValue={el.amount}
					addTextLabel={el.addTextLabel}
					onChange={e => onChangeHandler({ id: el.id, status: !el.status, amount: el.amount, price: el.price })}
					onChangeText={value => onChangeHandler({ id: el.id, status: el.status, amount: parseFloat(value), price: el.price })}
				>
					{el.status && (
						<div className='checkbox__additation__text'>
							<InputTypeNumber
								blockClasses='withCurrency'
								label='Введите стоимость'
								defaultValue={el.price}
								name={`own__form__input__${el.text}`}
								id={`own__form__input__${el.text}`}
								onChange={price => onChangeHandler({ id: el.id, status: el.status, amount: el.amount, price })}
								max={99999}
								useDelay={false}
							/>
						</div>
					)}
				</CheckBox>
			))}
		</BlockWithToggle>
	);
};

export default AdditionalWorks;
