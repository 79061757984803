import { v4 as uuid } from 'uuid';
import { defaultFontSizeTransform } from 'config/fontSizeTransform.config';

export const generateFio = template => {
	const unique_id = uuid();
	const dateId = uuid();
	const nameId = uuid();
	const surnameId = uuid();
	const middlenameId = uuid();

	return {
		id: unique_id,
		name: template.firstName.text,
		surname: template.thirdName.text,
		middleName: template.secondName.text,
		fontStyle: 'Прямой',
		dateOfBorn: '1965-10-10',
		dateOfDeath: '2018-08-15',
		numericFormat: 'Арабские',
		fontSize: defaultFontSizeTransform,
		dateId,
		nameId,
		surnameId,
		middlenameId,
		type: 'text'
	};
};
