import React from 'react';

import ProductlistWithParams from 'components/UI/list/productsListWithParams';

import { productsAPI } from 'services/Products';
import { useActions } from 'hooks/useActions';
import { useNotification } from 'hooks/useNotification';

const ProdolnaProducts = ({ flowers, currentFlower, burial, currentBurial, cat_tax }) => {
	const actions = useActions();
	const currentFlowerObj = flowers[currentFlower];
	const individualSizes = currentFlowerObj.prodolna;
	const selectedProducts = individualSizes.selectedProducts;
	const defaultMaterial = currentFlowerObj.stone;
	const polishing = burial.polishing;

	const { Notification, notificationToggle } = useNotification({
		callback: actions.onChangeHistoryFlowersDimensions,
		type: 'flower',
		currentElement: currentFlowerObj,
		currentIndexElement: currentFlower
	});

	return (
		<>
			<Notification />
			<ProductlistWithParams
				fetchFunction={productsAPI.useFetchProductsQuery}
				selectedProducts={selectedProducts}
				moduleType='flower'
				firstLabel='Высота'
				secondLabel='Длина'
				thirdLabel='Ширина'
				currentBurial={currentBurial}
				title='Деталь продольная'
				blockClasses='sidebar__radio__block sidebar__radio__block__value--individual'
				contentClasses='configurator__light-box__content--list'
				onClickProduct={values => {
					const { thickness: width, length, height } = values.size.values;
					notificationToggle({
						values: [
							{
								type: 'prodolna',
								sizes: { length: width, width: length, height },
								status: individualSizes.status
							},
							values
						]
					});
				}}
				widthDefault={parseInt(individualSizes.sizes.width)}
				lengthDefault={parseInt(individualSizes.sizes.height)}
				heightDefault={parseInt(individualSizes.sizes.length)}
				toggleState={individualSizes.status}
				onChangeLength={(value, state) => {
					notificationToggle({
						values: [
							{
								type: 'prodolna',
								sizes: { width: individualSizes.sizes.width, length: individualSizes.sizes.length, height: value },
								status: individualSizes.status
							}
						],
						state
					});
				}}
				onChangeWidth={(value, state) => {
					notificationToggle({
						values: [
							{
								type: 'prodolna',
								sizes: { width: value, length: individualSizes.sizes.length, height: individualSizes.sizes.height },
								status: individualSizes.status
							}
						],
						state
					});
				}}
				onChangeHeight={(value, state) => {
					notificationToggle({
						values: [
							{
								type: 'prodolna',
								sizes: { width: individualSizes.sizes.width, length: value, height: individualSizes.sizes.height },
								status: individualSizes.status
							}
						],
						state
					});
				}}
				onChangeToggle={e => {
					actions.onChangeHistoryFlowersDimensions(
						{
							type: 'prodolna',
							sizes: { width: individualSizes.sizes.width, length: individualSizes.sizes.length, height: individualSizes.sizes.height },
							status: !individualSizes.status
						},
						individualSizes.status !== true && selectedProducts.length !== 0 ? selectedProducts[0] : null
					);
				}}
				taxId={cat_tax}
				stone={defaultMaterial}
				polishing={polishing}
				firstLabelApi='size_height'
				secondLabelApi='size_length'
				thirdLabelApi='size_thickness'
			/>
		</>
	);
};

export default ProdolnaProducts;
