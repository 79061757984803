import React, { useState, useCallback, useLayoutEffect } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import TextEditor from 'components/UI/popup/textEditor/index';
import SecondButton from 'components/UI/button/second';
import InputTypeNumber from 'components/UI/input/text/number';

import { generateEpitafia } from 'utils/forms/generateEpitafia';
import { useActions } from 'hooks/useActions';

const Epitafia = ({ burial, options, template }) => {
	const actions = useActions();

	const epitafiaState = burial.elements.epitafia.status;
	const epitafiaText = burial.elements.epitafia.text;
	const epitafiaAlign = burial.elements.epitafia.textAlign;
	const epitafiaFontSize = burial.elements.epitafia.fontSize;
	const epitafiaPrice = burial.elements.epitafia.price;

	const [openEpitaphPop, setOpenEpitaphPop] = useState(false);

	const onToggleHandler = useCallback(() => {
		actions.changeEpitafia({
			type: 'epitafia',
			value: { status: !epitafiaState, ...generateEpitafia(), text: '' }
		});
		setOpenEpitaphPop(false);
	}, [epitafiaState]);

	const onEditHandler = useCallback(() => setOpenEpitaphPop(true), []);

	useLayoutEffect(() => {
		if (epitafiaText.length === 0) setOpenEpitaphPop(false);
	}, [epitafiaText]);

	const onBasketClick = useCallback(() => {
		setOpenEpitaphPop(false);
		actions.changeEpitafia({
			type: 'epitafia',
			value: { status: epitafiaState, ...generateEpitafia(null, 0), text: '' }
		});
	}, [epitafiaState]);

	const onChangeTextAreaHandler = useCallback((e, value) => {
		if (value === '') return;
		actions.changeEpitafia({ type: 'epitafia', include: 'text', value });
	}, []);

	const onChangeAlignHandler = useCallback(type => {
		actions.changeEpitafia({ type: 'epitafia', include: 'textAlign', value: type });
	}, []);

	const onChangeFontSize = useCallback(value => {
		actions.changeEpitafia({ type: 'epitafia', include: 'fontSize', value: value.value });
	}, []);

	const onChangePrice = useCallback(price => {
		actions.changeEpitafia({ type: 'epitafia', include: 'price', value: price });
	}, []);

	const addEpitafiaHandler = useCallback(e => {
		setOpenEpitaphPop(true);
		actions.changeEpitafia({
			type: 'epitafia',
			value: { status: true, ...generateEpitafia(template.elementsEpitafia, 0) }
		});
	}, []);

	return (
		<BlockWithToggle title='Эпитафия' openState={epitafiaState} onChangeToggle={onToggleHandler} contentClasses='editDelete'>
			{TextEditor && (
				<TextEditor
					openState={openEpitaphPop}
					onClick={() => setOpenEpitaphPop(false)}
					className='textEditor epitafiaElements'
					title='Редактор эпитафии:'
					defaultValue={epitafiaText}
					onChangeTextArea={onChangeTextAreaHandler}
					libraryState={true}
					libraryBtnTitle='Библиотека эпитафий'
					onClickLibraryProductCard={item => onChangeTextAreaHandler(null, item.text)}
					libraryClassName='mt32 --svg__book-before'
					alignState={true}
					alignType={epitafiaAlign}
					onChangeAlign={onChangeAlignHandler}
					fontSizeState={true}
					fontSizeType='select'
					fontSizeSelect={epitafiaFontSize}
					onChangefontSize={onChangeFontSize}
					fontSizeOptions={options}
					fontFamilyState={false}
					selectedLibraryProducts={[{ id: 0, sku: 'epitafia-0', text: epitafiaText }]}
				/>
			)}

			{epitafiaText !== '' && <ActionBlock title={epitafiaText} onEditClick={onEditHandler} onBasketClick={onBasketClick} />}

			{epitafiaState && epitafiaText !== '' && (
				<InputTypeNumber
					blockClasses='own__form__input'
					label='Введите стоимость'
					defaultValue={epitafiaPrice}
					name={`own__form__input__faska}`}
					id={`own__form__input__faska}`}
					onChange={onChangePrice}
					useDelay={false}
				/>
			)}

			{epitafiaText === '' && openEpitaphPop === false && (
				<SecondButton title='Добавить Эпитафию' onClick={addEpitafiaHandler} classes='second__btn box__value__add' />
			)}
		</BlockWithToggle>
	);
};

export default Epitafia;
