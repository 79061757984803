import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from 'react';
import { Stage, Layer, Rect, Line } from 'react-konva';

import { useDetection } from './hooks/useDetection';
import { useDragging } from './hooks/useDragging';
import { checkIntersectionInArea } from './utils/checkIntersectionInArea';
import useBurialsData from 'hooks/useBurialsData';

import CreateRect from './modules/createRect';
import GenerateGrid from './modules/generateGrid';

import './canvas.scss';
import { Rectangle } from './rectangle';
import useImage from 'use-image';
import { Point, Box } from '@flatten-js/core';
import { useSelector } from 'react-redux';
import Konva from 'konva';
import { dispatchEvent } from '../../../utils/events/dispatchEvent';
import { useActions } from '../../../hooks/useActions';
import { Fence, getLength, isVertical } from './fence';

const initialRectangles = [];

export const checkOutsize = rect => {
	if (!rect.defaultSize) return null;
	const defaultSize = rect.defaultSize;
	return !(
		(Math.round(rect.width) === defaultSize[0] && Math.round(rect.height) === defaultSize[1]) ||
		(Math.round(rect.width) === defaultSize[1] && Math.round(rect.height) === defaultSize[0])
	);
};
const pattern1 = ({ rectWidth, rectHeight, zone, shapes, excludedAreas = [] }) => {
	const rects = [];
	const obstructions = shapes.map(
		shape =>
			new Box(
				shape.attrs.x - shape.attrs.offsetX,
				shape.attrs.y - shape.attrs.offsetY,
				shape.attrs.x + shape.attrs.width - shape.attrs.offsetX,
				shape.attrs.y + shape.attrs.height - shape.attrs.offsetY
			)
	);

	obstructions.push(
		...excludedAreas.areas.map(area => new Box(area.y * 100, area.x * 100, area.y * 100 + area.width / 10, area.x * 100 + area.height / 10))
	);

	const zoneBox = new Box(
		zone.attrs.x - zone.attrs.offsetX,
		zone.attrs.y - zone.attrs.offsetY,
		zone.attrs.x + zone.attrs.width - zone.attrs.offsetX,
		zone.attrs.y + zone.attrs.height - zone.attrs.offsetY
	);

	const error = 0.6;
	for (let y = zoneBox.ymin; y < zoneBox.ymax - rectHeight + error; y += rectHeight) {
		for (let x = zoneBox.xmin; x < zoneBox.xmax - rectWidth + error; x += rectWidth) {
			// if (x > 0 && y > 0 && x < width - rectWidth && y < height - rectHeight) continue;

			const rect = new Box(x + error, y + error, x + rectWidth - error, y + rectHeight - error);
			let intersection = false;
			obstructions.forEach(obstruction => {
				// if (rect.intersect(obstruction)) intersection = true;
				if (obstruction.intersect(rect)) intersection = true;
			});
			if (intersection) continue;

			rects.push({
				x: x,
				y: y,
				width: rectWidth,
				height: rectHeight
			});
		}
	}
	return rects;
};

const CreateCanvasGrid = ({
	stageRef,
	canvasId,
	stageDimensions,
	activeTool,
	activeSize,
	activeMagnetSize,
	activeAutoPattern,
	activeBrushMode,
	activeMaterial
}) => {
	const platesLayerRef = useRef(null);
	const { burial, burials } = useBurialsData();
	const excludedAreas = useSelector(state => state.configurator.present.step1.excludeArea);
	// const pavingEditor = useSelector(state => state.configurator.present.step5.pavingEditor);
	const rectangles = useSelector(state => state.configurator.present.step5.pavingEditor.rectangles);
	const fence = useSelector(state => state.configurator.present.step5.pavingEditor.fence);
	const actions = useActions();
	const { small, big } = { small: 5, big: 30 };
	dispatchEvent('getExcludedSpacePosition');
	// const { width: widthBlock, height: heightBlock } = activeSize;

	const [isPainting, setPainting] = useState({
		status: false,
		coordinates: { from: null, to: null }
	});
	const materials = useSelector(state => state.configurator.present.step5.tile.horizontal);
	const [isDragging, setIsDragging] = useState(false);
	const [selectedId, selectShape] = useState(null);
	const [zoneSize, setZoneSize] = useState(null);
	const [buffer, setBuffer] = useState(null);
	const [cursorBlock, setCursorBlock] = useState(null);
	const [cursorLine, setCursorLine] = useState(null);
	const [width, setWidth] = useState(parseFloat(stageDimensions.width) / 10);
	const [height, setHeight] = useState(parseFloat(stageDimensions.height) / 10);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const [defaultSize, setDefaultSize] = useState([30, 30]);
	if (!window.rectImg) window.rectImg = {};
	const vars = React.useRef({ isDragging: false });
	const keyDownHandler = useCallback(
		e => {
			if (e.metaKey || e.ctrlKey) {
				if (e.code === 'KeyC' && selectedId !== null) {
					setBuffer(JSON.parse(JSON.stringify(rectangles[selectedId])));
				}
				if (e.code === 'KeyV' && buffer !== null) {
					const newArray = [...rectangles, buffer];
					actions.setRectangles(newArray);
					selectShape(newArray.length - 1);
				}
			}
			if (e.code === 'Backspace' || e.code === 'Delete') {
				if (selectedId !== null) {
					actions.setRectangles([...rectangles.slice(0, selectedId), ...rectangles.slice(selectedId + 1)]);
					selectShape(null);
				}
			}
		},
		[selectedId, rectangles, buffer]
	);

	useEffect(() => {
		window.addEventListener('keydown', keyDownHandler);

		return () => {
			window.removeEventListener('keydown', keyDownHandler);
		};
	}, [selectedId, rectangles, buffer]);

	useEffect(() => {
		if (burial.improvement.tileArea.type.includes('Весь')) {
			setZoneSize(null);
			setWidth(burial.areaValues.length / 10);
			setHeight(burial.areaValues.width / 10);
		}
		if (burial.improvement.tileArea.type.includes('30')) {
			setZoneSize(30);
			setWidth(burial.areaValues.length / 10);
			setHeight(burial.areaValues.width / 10);
		}
		if (burial.improvement.tileArea.type.includes('15')) {
			setZoneSize(15);
			setWidth(burial.areaValues.length / 10);
			setHeight(burial.areaValues.width / 10);
		}
		// setZoneSize(burial.improvement.tileArea.type.includes('30') ? 30 : 15);
	}, [burial.improvement.tileArea.type, burial.areaValues, stageDimensions]);

	useEffect(() => {
		setPainting({
			status: false,
			coordinates: { from: null, to: null }
		});
	}, [isDragging]);

	useEffect(() => {
		if (!activeAutoPattern.pattern) {
			// actions.setRectangles([]);
			return;
		}
		actions.setRectangles(
			pattern1({
				rectWidth: activeAutoPattern.width,
				rectHeight: activeAutoPattern.height,
				zone: stageRef.current.find('.zone')[0],
				shapes: stageRef.current.find('.burial'),
				excludedAreas
			})
		);
	}, [activeAutoPattern, zoneSize, excludedAreas]);

	const randomTail = () => Math.random() / 1000;
	const onMouseMoveHandler = useCallback(() => {
		if (!['marker', 'fence'].includes(activeTool)) return;

		const positionX = (stageRef.current.getPointerPosition().x - stageRef.current.x()) / stageRef.current.scaleX();
		const positionY = (stageRef.current.getPointerPosition().y - stageRef.current.y()) / stageRef.current.scaleX();
		setCursorPosition({
			x: Math.round(positionX / activeMagnetSize) * activeMagnetSize + randomTail(),
			y: Math.round(positionY / activeMagnetSize) * activeMagnetSize + randomTail()
		});
	}, [activeTool, activeSize, activeMagnetSize]);

	useEffect(() => {
		if (activeTool !== 'brush') {
			return;
		}
		console.log(JSON.parse(JSON.stringify(rectangles)));
		if (activeBrushMode.mode === 'All') {
			const img = new Image();
			img.onload = () => {
				const newImages = { ...window.rectImg };
				if (!window.rectImg[materials[0].img]) {
					newImages[materials[0].img] = img;
					window.rectImg = newImages;
				}
			};

			img.src = materials[0].img;
		}

		if (activeBrushMode.mode === 'byOne') {
			const materials = {};
			rectangles.forEach(rectangle => {
				if (rectangle.material && !materials[rectangle.material.title]) {
					materials[rectangle.material.title] = rectangle.material;
				}
			});
			// const promises = [];
			const newImages = {};
			Object.keys(materials).forEach(title => {
				const img = new Image();

				const onloadCb = () => {
					newImages[materials[title].img] = img;
					window.rectImg = { ...window.rectImg, ...newImages };
				};
				img.onload = onloadCb;
				img.src = materials[title].img;
			});
		}
	}, [activeTool, materials, activeBrushMode, rectangles]);

	const onMouseClickHandler = useCallback(() => {
		if (activeTool === 'fence' && !vars.current.isDragging) {
			if (!cursorLine) {
				setCursorLine({
					x: 0,
					y: 0,
					points: [cursorPosition.x, cursorPosition.y, cursorPosition.x, cursorPosition.y]
				});
			} else {
				actions.setFence([
					...fence,
					{
						x: 0,
						y: 0,
						points: [cursorLine.points[0], cursorLine.points[1], cursorPosition.x, cursorPosition.y]
					}
				]);
				setCursorLine(null);
			}

			return;
		}

		if (vars.current.isDragging || activeTool !== 'marker') {
			vars.current.isDragging = false;
			return;
		}

		const newArray = [
			...rectangles,
			{
				height: cursorBlock.height,
				width: cursorBlock.width,
				x: cursorPosition.x - activeSize.width / 2,
				y: cursorPosition.y - activeSize.height / 2
			}
		];
		actions.setRectangles(newArray);
		// selectShape(newArray.length - 1);
	}, [rectangles, cursorBlock, cursorPosition, activeTool, activeSize]);

	const scaleBy = 1.02;
	const onWheelHandler = useCallback(e => {
		// stop default scrolling
		e.evt.preventDefault();

		const oldScale = stageRef.current.scaleX();
		const pointer = stageRef.current.getPointerPosition();

		const mousePointTo = {
			x: (pointer.x - stageRef.current.x()) / oldScale,
			y: (pointer.y - stageRef.current.y()) / oldScale
		};
		let direction = e.evt.deltaY > 0 ? 1 : -1;
		if (e.evt.ctrlKey) {
			direction = -direction;
		}

		var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

		stageRef.current.scale({ x: newScale, y: newScale });

		var newPos = {
			x: pointer.x - mousePointTo.x * newScale,
			y: pointer.y - mousePointTo.y * newScale
		};
		stageRef.current.position(newPos);
	}, []);

	useEffect(() => {
		if (activeTool) selectShape(null);
		if (activeTool !== 'fence') setCursorLine(null);
		if (activeTool === 'marker') {
			setCursorBlock({ width: activeSize.width, height: activeSize.height });
		} else {
			setCursorBlock(null);
		}
	}, [activeTool, activeSize]);

	useEffect(() => {
		let count = 0;
		fence.forEach(part => {
			count += isVertical(part.points) ? Math.round(getLength(part.points)) : Math.round(getLength(part.points));
		});
		//TODO убрать после отладки
		console.log('Забор см: ' + count);
		actions.setFenceCount(count);
	}, [fence]);

	useEffect(() => {
		// Расчет количества плиток
		let count = 0;
		let countByMaterial = {};
		let parts = [];
		const groupedParts = [];
		let bigParts = [];
		// Межевание целых и кусочков
		rectangles.forEach(rect => {
			const outsize = checkOutsize(rect);
			if (outsize === false) {
				count++;
				if (!countByMaterial[rect.material.id]) countByMaterial[rect.material.id] = 1;
				else countByMaterial[rect.material.id] += 1;
			} else if (outsize) parts.push(rect);
		});

		//Сортировка по площади
		parts = parts.sort((partA, partB) => {
			return partB.width * partB.height - partA.width * partA.height;
		});

		let materialsIds = [...new Set(parts.map(part => part.material.id))];
		// Делим плитку по материалам
		materialsIds.forEach(mId => {
			const mParts = parts.filter(part => part.material.id === mId);
			mParts.forEach(part => {
				const w = Math.max(Math.round(part.width), Math.round(part.height));
				const h = Math.min(Math.round(part.width), Math.round(part.height));
				const wD = Math.max(part.defaultSize[0], part.defaultSize[1]);
				const hD = Math.min(part.defaultSize[0], part.defaultSize[1]);
				let wCut = w;
				let hCut = h;
				let findPlace = false;
				for (let index = 0; index <= groupedParts.length - 1; index++) {
					const group = groupedParts[index];
					if (group.mId !== mId) continue;
					for (let spaceIndex = 0; spaceIndex <= group.freeSpace.length - 1; spaceIndex++) {
						const space = group.freeSpace[spaceIndex];

						if (space.wD >= w && space.hD >= h) {
							findPlace = true;
							if (space.wD >= h) {
								wCut = Math.min(w, h);
								hCut = Math.max(w, h);
							}
							space.wD -= wCut;

							if (hD - hCut > 0) group.freeSpace.push({ wD: wCut, hd: hD - hCut });
							break;
						} else if (space.wD >= h && space.hD >= w) {
							findPlace = true;
							if (space.wD >= w) {
								wCut = Math.min(w, h);
								hCut = Math.max(w, h);
							}
							space.wD -= hCut;
							if (hD - wCut > 0) group.freeSpace.push({ wD: hCut, hd: hD - wCut });
							break;
						}
					}
					if (findPlace) break;
				}

				if (!findPlace) {
					wCut = Math.max(w, h);
					hCut = Math.min(w, h);
					const newGroup = {
						mId,
						freeSpace: [{ wD: wD - wCut, hD }]
					};
					if (hD - hCut > 0) newGroup.freeSpace.push({ wD: wCut, hD: hD - hCut });
					groupedParts.push(newGroup);
				}
			});
		});
		//TODO убрать после отладки
		count += groupedParts.length;
		groupedParts.forEach(part => {
			if (!countByMaterial[part.mId]) countByMaterial[part.mId] = 1;
			else countByMaterial[part.mId] += 1;
		});
		console.log(countByMaterial);
		console.log('Количество целых плиток ', count);
		actions.setRectCountByMaterial(countByMaterial);
		actions.setRectCount(count);
	}, [rectangles]);

	useEffect(() => {
		const stone = materials[0];
		if (stone.default_plate_size.split('x').length === 2) {
			setDefaultSize(stone.default_plate_size.split('x').map(value => parseInt(value)));
		} else if (stone.default_plate_size.split('х').length === 2) {
			setDefaultSize(stone.default_plate_size.split('х').map(value => parseInt(value)));
		}
	}, [materials]);

	const onSelectFence = useCallback(
		i => {
			if (activeTool === 'eraser') {
				actions.setFence([...fence.slice(0, i), ...fence.slice(i + 1)]);
				return;
			}
		},
		[fence, activeTool]
	);

	const onSelectRect = useCallback(
		i => {
			if (activeTool === 'fence') return;
			if (activeTool === 'eraser') {
				actions.setRectangles([...rectangles.slice(0, i), ...rectangles.slice(i + 1)]);
				selectShape(null);
				return;
			}

			if (activeTool === 'brush') {
				const img = new Image();
				if (activeBrushMode.mode === 'byOne') {
					img.onload = () => {
						const newRectangles = [
							...rectangles.slice(0, i),
							{
								...rectangles[i],
								material: materials[0],
								// outsize: checkOutsize(rectangles[i], defaultSize),
								defaultSize
								// defaultPlateSize: materials.stone[0].default_plate_size
							},
							...rectangles.slice(i + 1)
						];
						const newImages = { ...window.rectImg };
						if (!window.rectImg[materials[0].img]) {
							newImages[materials[0].img] = img;
							window.rectImg = newImages;
						}
						actions.setRectangles(newRectangles);
					};
				}

				if (activeBrushMode.mode === 'All') {
					img.onload = () => {
						const newRectangles = rectangles.map(rect => {
							return {
								...rect,
								material: materials[0],
								// outsize: checkOutsize(rect, defaultSize),
								defaultSize
							};
						});
						const newImages = { ...window.rectImg };
						if (!window.rectImg[materials[0].img]) {
							newImages[materials[0].img] = img;
							window.rectImg = newImages;
						}
						actions.setRectangles(newRectangles);
					};
				}
				img.src = materials[0].img;
				return;
			}
			if (selectedId !== i) selectShape(i);
			else selectShape(null);
		},
		[selectedId, rectangles, materials, activeBrushMode, activeTool]
	);

	return (
		<>
			<Stage
				className='two__sides__canvas'
				style={{ overflow: 'hidden' }}
				id={canvasId}
				width={window.innerWidth - 380 - 24 * 2 - 5}
				height={window.innerHeight - 189 - 24 * 2 - 52}
				onWheel={onWheelHandler}
				onMouseMove={onMouseMoveHandler}
				onMouseUp={onMouseClickHandler}
				onDragStart={() => {
					vars.current.isDragging = true;
				}}
				draggable={true}
				scaleX={2}
				scaleY={2}
				x={zoneSize ? 300 : parseFloat(stageDimensions.height) / 10}
				y={zoneSize ? 100 : parseFloat(stageDimensions.width) / 10}
				ref={stageRef}
			>
				<Layer ref={platesLayerRef}>
					<Rect
						x={-1000}
						y={-1000}
						width={2000}
						height={2000}
						offsetX={0}
						offsetY={0}
						fill={'#FFFFFF'}
						name={'baсkground'}
						listening={false}
					/>

					{!zoneSize && (
						<Rect
							x={0}
							y={0}
							width={parseFloat(stageDimensions.height) / 10}
							height={parseFloat(stageDimensions.width) / 10}
							offsetX={parseFloat(stageDimensions.height) / 20}
							offsetY={parseFloat(stageDimensions.width) / 20}
							fill={'rgba(118,252,116,0.15)'}
							stroke={'rgba(142,255,106,0.73)'}
							strokeWidth={0.5}
							name={'zone'}
						/>
					)}
					{burials.map((grave, i) => (
						<React.Fragment key={i}>
							{zoneSize && (
								<Rect
									key={i}
									x={grave.areaValues.x}
									y={grave.areaValues.y}
									width={grave.areaValues.length / 10}
									height={grave.areaValues.width / 10} // height}
									offsetX={grave.areaValues.length / 20}
									offsetY={grave.areaValues.width / 20}
									fill={'rgba(118,252,116,0.15)'}
									name={'zone'}
								/>
							)}

							{grave.flowers &&
								grave.flowers.items.map((bed, i) => (
									<Rect
										key={i}
										x={bed.position3d.y * 100}
										y={bed.position3d.x * 100}
										width={parseFloat(bed.poperehna.sizes.length) / 10}
										height={(parseFloat(bed.prodolna.sizes.width) + parseFloat(bed.poperehna.sizes.width)) / 10}
										offsetX={parseFloat(bed.poperehna.sizes.length) / 20}
										offsetY={0}
										rotation={-grave.position.angle || 0}
										fill='#00000030'
										name={'burial'}
									/>
								))}
							{grave.pedestal &&
								grave.pedestal.items.map((pedestal, i) => (
									<Rect
										key={i}
										x={pedestal.position.y * 100}
										y={pedestal.position.x * 100}
										width={parseFloat(pedestal.individualSizes.values.width / 10)}
										height={parseFloat(pedestal.individualSizes.values.length / 10)}
										offsetX={pedestal.individualSizes.values.width / 20}
										offsetY={pedestal.individualSizes.values.length / 10}
										rotation={-grave.position.angle || 0}
										fill='#00000060'
										name={'burial'}
									/>
								))}
						</React.Fragment>
					))}
					{excludedAreas.areas.map((area, i) => {
						return (
							<Rect
								key={i}
								x={area.y * 100}
								y={area.x * 100}
								width={parseFloat(area.width / 10)}
								height={parseFloat(area.height / 10)}
								offsetX={0}
								offsetY={0}
								fill='#ffffffaa'
								stroke={'rgba(105,105,105,0.73)'}
								strokeWidth={0.5}
								name={'excludeArea'}
							/>
						);
					})}
					{rectangles.map((rect, i) => {
						return (
							<Rectangle
								key={i}
								shapeProps={rect}
								isSelected={i === selectedId}
								activeMagnetSize={activeMagnetSize}
								draggable={!activeTool}
								// image={image}
								brush={activeTool === 'brush'}
								image={window.rectImg[rect.material?.img]}
								onSelect={() => onSelectRect(i)}
								onChange={newAttrs => {
									const rects = rectangles.slice();
									rects[i] = newAttrs;
									actions.setRectangles(rects);
								}}
							/>
						);
					})}
					{fence.map((fence, i) => {
						return (
							<Fence
								key={i}
								shapeProps={fence}
								activeMagnetSize={activeMagnetSize}
								onSelect={() => {
									onSelectFence(i);
								}}
							/>
						);
					})}
					{cursorBlock && (
						<Rect
							x={cursorPosition.x - activeSize.width / 2}
							y={cursorPosition.y - activeSize.height / 2}
							width={cursorBlock.width}
							height={cursorBlock.height}
							fill={'rgba(118,252,116,0.25)'}
							stroke={'rgba(142,255,106,0.73)'}
							strokeWidth={0.5}
						/>
					)}
					{cursorLine && (
						<Fence
							shapeProps={{
								x: 0,
								y: 0,
								points: [cursorLine.points[0], cursorLine.points[1], cursorPosition.x, cursorPosition.y],
								stroke: 'rgba(142,255,106,0.73)',
								strokeWidth: 2
							}}
							activeMagnetSize={activeMagnetSize}
						/>
					)}
				</Layer>
			</Stage>
		</>
	);
};

export default memo(CreateCanvasGrid);
