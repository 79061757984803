import React, { useCallback } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

const EngravingPaintPicture = ({ burial }) => {
	const actions = useActions();
	const paintPortrait = burial.paintAPortrait;

	const onChangeToggle = useCallback(() => {
		actions.setEngravingValue({
			obj: 'paintAPortrait',
			value: { status: !paintPortrait.status, price: 0 }
		});
	}, [paintPortrait]);

	const onChangePrice = useCallback(
		price => {
			actions.setEngravingValue({
				obj: 'paintAPortrait',
				value: { status: paintPortrait.status, price }
			});
		},
		[paintPortrait]
	);

	return (
		<BlockWithToggle title='Покрасить портрет' openState={paintPortrait.status} onChangeToggle={onChangeToggle}>
			<InputTypeNumber
				label='Введите стоимость'
				name='painting'
				id='painting'
				onChange={onChangePrice}
				defaultValue={paintPortrait.price}
				useDelay={false}
			/>
		</BlockWithToggle>
	);
};

export default EngravingPaintPicture;
