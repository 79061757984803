import { CHANGE_WIDTH, CHANGE_HEIGHT } from 'components/step1/constants';
import { findBurial } from 'slices/step1Slice';

const step4Reducers = () => {
	const addExcludeItem = (state, action) => {
		state.excludeArea.areas.push({
			id: state.excludeArea.areas.length,
			width: 1000,
			height: 1000,
			x: 0,
			y: 0
		});
	};

	const removeExcludeItem = (state, action) => {
		state.excludeArea.areas = state.excludeArea.areas.filter(item => item.id !== action.payload);
		for (let index = 0; index < state.excludeArea.areas.length; index++) {
			state.excludeArea.areas[index].id = index;
		}
	};

	const changeExcludedItemPosition = (state, action) => {
		const area = state.excludeArea.areas.find(item => item.id === action.payload.id);
		if (!area) return;
		if (action.payload.x !== undefined) area.x = action.payload.x;
		if (action.payload.y !== undefined) area.y = action.payload.y;
	};

	const changeExcludedItemValue = (state, action) => {
		const area = state.excludeArea.areas.find(item => item.id === action.payload.id);
		if (action.payload.type === CHANGE_WIDTH) area.width = action.payload.value;
		if (action.payload.type === CHANGE_HEIGHT) area.height = action.payload.value;
	};

	const changeExcludeAreaOpen = (state, action) => {
		state.excludeArea.isOpen = action.payload;
	};

	const changeBurialInstallation = (state, action) => {
		const burial = findBurial(state);
		burial.installation.type = action.payload;
		burial.improvement.tileArea.type = action.payload;
	};

	const changeTileAreaPrice = (state, action) => {
		if (typeof action.payload === 'object') {
			const burial = findBurial(state, action.payload.index);
			burial.improvement.tileArea.price = action.payload.price;
		} else {
			const burial = findBurial(state);
			burial.improvement.tileArea.price = action.payload;
		}
	};

	return {
		addExcludeItem,
		removeExcludeItem,
		changeExcludedItemValue,
		changeExcludeAreaOpen,
		changeBurialInstallation,
		changeTileAreaPrice,
		changeExcludedItemPosition
	};
};

export default step4Reducers;
