import React, { useCallback } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';

import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { productsAPI } from 'services/Products';

const PedestalCatalogForm = ({ burial, template }) => {
	const actions = useActions();
	const categories = useConfiguratorCategories('modules');

	const module_tax = categories.find(el => el.slug === 'elements')?.id;
	const catalogState = burial.elements.elems.status;
	const catalogSelectedProducts = burial.elements.elems.selectedProducts;

	const [refetch, { data, isFetching, isError }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => refetch({ args: { module_tax } }, true), [module_tax]); // the second arg is preferCacheValue

	const onToggleHandler = useCallback(() => {
		actions.changeElementsSelectedProducts({
			module: 'status',
			value: !catalogState
		});
		if (catalogSelectedProducts.length !== 0) {
			actions.changeElementsSelectedProducts({
				module: 'selectedProducts',
				value: catalogSelectedProducts[0]
			});
		}
	}, [catalogSelectedProducts, catalogState, actions]);

	const onClickProduct = useCallback(
		item => {
			const newItem = {
				...item,
				...template.elements,
				id: item.id.toString(),
				width: parseInt(item.size.values.width),
				height: parseInt(item.size.values.height),
				type: 'image'
			};

			actions.changeElementsSelectedProducts({
				module: 'selectedProducts',
				value: newItem
			});
		},
		[actions]
	);

	return (
		<CatalogForm
			formTitle='Бронзовые элементы'
			formState={catalogState}
			onToggle={onToggleHandler}
			onClickProductCard={onClickProduct}
			btnTitle='Каталог элементов'
			selectedProducts={catalogSelectedProducts}
			products={data?.apiResponse ?? []}
			refetch={refetchData}
			hook
			isLoading={isFetching}
			isError={isError}
		/>
	);
};

export default PedestalCatalogForm;
