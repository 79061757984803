import React, { useState } from 'react';
import { Circle, Text, Line } from 'react-konva';

const rectParams = {
	// fill: '#00000010',
	stroke: '#1f1f1f',
	strokeWidth: 1.5
};

export const getLength = points => {
	return Math.max(Math.abs(points[0] - points[2]), Math.abs(points[1] - points[3]));
};

export const isVertical = points => {
	return Math.abs(points[0] - points[2]) < Math.abs(points[1] - points[3]);
};
export const Fence = ({ shapeProps, isSelected, onSelect, onChange, activeMagnetSize, draggable }) => {
	const shapeRef = React.useRef();
	return (
		<React.Fragment>
			<Line onClick={onSelect} onTap={onSelect} ref={shapeRef} {...shapeProps} {...rectParams} />
			<Circle
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				x={shapeProps.points[0]}
				y={shapeProps.points[1]}
				stroke={rectParams.stroke}
				fill={rectParams.stroke}
				radius={0.7}
			/>
			<Circle
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				stroke={rectParams.stroke}
				fill={rectParams.stroke}
				x={shapeProps.points[2]}
				y={shapeProps.points[3]}
				radius={0.7}
			/>
			<>
				{isVertical(shapeProps.points) ? (
					<Text
						x={shapeProps.points[0] - 6}
						y={Math.min(shapeProps.points[1], shapeProps.points[3]) + getLength(shapeProps.points) / 2}
						fontSize={6}
						rotation={-90}
						text={Math.round(getLength(shapeProps.points))}
					/>
				) : (
					<Text
						x={Math.min(shapeProps.points[0], shapeProps.points[2]) + getLength(shapeProps.points) / 2}
						y={shapeProps.points[1] - 6}
						fontSize={6}
						text={Math.round(getLength(shapeProps.points))}
					/>
				)}
			</>
		</React.Fragment>
	);
};
