import React from 'react';

import InputTypeNumber from './text/number';
import InputTypeText from './text/text';

import './text.scss';

const TextInput = props => {
	const { type } = props;

	if (type === 'number') {
		return <InputTypeNumber {...props} />;
	}

	return <InputTypeText {...props} />;
};

export default TextInput;
