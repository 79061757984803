import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';

import ProductListItem from './../card/products';

import { useObserver } from 'hooks/useObserver';
import { convertStringToPrice } from 'utils/convertStringToPrice';
import { useShallowEffect } from '@mantine/hooks';

import './productList.scss';

const Productlist = ({ fetchData, type, selectedProducts, query, onClickProduct, taxId }) => {
	const [totalPages, setTotalPages] = useState(1);
	const [limit] = useState(5);
	const [page, setPage] = useState(1);
	const lastElement = useRef();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const postsLength = useMemo(() => {
		return products.length >= page * limit;
	}, [page, products, limit]);
	const style = {
		display: 'flex',
		height: '1px',
		position: 'relative',
		width: '100%'
	};

	const params = useMemo(() => {
		let obj = {};
		if (taxId) obj = Object.assign({ module_tax: taxId }, obj);
		if (query && typeof query === 'string') {
			obj = Object.assign({ search: query }, obj);
		} else {
			obj = { ...obj, ...query };
		}

		return obj;
	}, [query, taxId]);

	const { data, isError, isFetching } = fetchData(
		{ type, args: { ...params, page: page ?? 1, per_page: limit ?? 4 } },
		{ refetchOnMountOrArgChange: true }
	);

	useShallowEffect(() => {
		setProducts([]);
		setPage(1);
	}, [params, isError]);

	useObserver(
		lastElement,
		page < totalPages,
		loading,
		useCallback(() => {
			if (!loading && postsLength) {
				setLoading(true);
				setPage(page => page + 1);
			}
		}, [postsLength, loading])
	);

	useEffect(() => {
		let value = data?.totalCount ?? 1,
			posts = data?.apiResponse ?? [];

		setTotalPages(parseInt(value));
		setLoading(false);
		setProducts(prev => {
			let uniq_prod = [...prev].filter(item => posts.every(prod => prod?.sku !== item?.sku)) ?? [];
			if (posts.length === 0) uniq_prod = [];
			return uniq_prod.concat(posts);
		});
	}, [data]);

	return (
		<div className='productList'>
			{isFetching && <h2 className='message mb12'>Идет загрузка...</h2>}
			{isError && <h2 className='message mb12'>Что то случилось, попробуйте позже</h2>}
			{!isError && !isFetching && products.length === 0 && <h2 className='message mb12'>Ничего не найдено</h2>}
			{products.length !== 0 &&
				products.map(item => {
					const status = selectedProducts.some(el => item?.sku === el?.sku) ?? false;

					const size =
						item.size?.values &&
						[
							item.size.values?.height || item.size.values?.length || 0,
							item.size.values?.width || item.size.values?.length || 0,
							item.size.values?.thickness || 0
						].join('x');

					const sizesTitle = item.size?.raw && item.size.raw.replaceAll('*', 'x');
					const reservTitle = item.size?.values ? Object.values(item.size?.values).join('x') : '0000x000x00';

					return (
						<ProductListItem
							key={item?.id ?? item?.sku}
							item={item}
							title={item.title}
							sku={item?.sku}
							size={size ?? sizesTitle ?? reservTitle}
							price={convertStringToPrice(item.price.unit)}
							status={status}
							components={item.components}
							onClick={onClickProduct}
						/>
					);
				})}

			{products.length !== 0 && <div ref={lastElement} style={style} />}
		</div>
	);
};

export default Productlist;
