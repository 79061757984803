import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from 'react';

import { Stage, Layer, Rect, Line } from 'react-konva';
import { useDetection } from './hooks/useDetection';
import { useReflect } from './hooks/useReflect';
import { useCanvasAttach } from './hooks/useCanvasAttach';

import CreateCanvasRenderItems from './createCanvasRenderItems';

import './canvas.scss';
import Transformers from './modules/transformers';
import { getHexColor } from './getHexFromImg';

const CreateCanvas = ({ url, changeUrl, canvasId, configurator, openStatus, item, burialId, stageDimensions, canvasData, stone }) => {
	const parentRef = useRef(null);
	const stageRef = useRef(null);
	const layerRef = useRef(null);
	const backgroundRef = useRef(null);
	const [selectedId, selectShape] = useState(null);
	const [proportional, setProportional] = useState(true);
	const [width, setWidth] = React.useState(300);
	const [height, setHeight] = React.useState(300);
	const [scale, setScale] = React.useState(1);
	const selectedItem = useMemo(() => url.find(el => el.id == selectedId), [selectedId, url]);
	const { checkDeselect } = useDetection();
	const { reflectImage } = useReflect();

	const memorizedStageDimensions = useMemo(
		() => ({
			height: stageDimensions.height,
			type: stageDimensions.type,
			width: stageDimensions.width
		}),
		[stageDimensions.height, stageDimensions.type, stageDimensions.width]
	);

	const { canvasType } = useCanvasAttach({ canvasId, stageRef, item, stageDimensions, burialId });

	useEffect(() => selectShape(null), [openStatus, canvasData]);

	const onDrawHandler = useCallback(() => {
		let allTextures = configurator.current.contentWindow.v3d.puzzles.canvasTextures;
		if (allTextures !== undefined) {
			let canvasTex = allTextures[canvasId];
			if (canvasTex !== undefined) canvasTex.needsUpdate = true;
		}
	}, [canvasId, configurator]);

	const reflectHorizontalHandler = useCallback(
		() => changeUrl(reflectImage(stageRef, selectedId, url)),
		[changeUrl, reflectImage, selectedId, url]
	);
	const reflectVerticalHandler = useCallback(
		() => changeUrl(reflectImage(stageRef, selectedId, url, 'scaleY')),
		[changeUrl, reflectImage, selectedId, url]
	);

	const onMouseDownHandler = useCallback(e => checkDeselect(e, selectShape), [checkDeselect]);
	const onTouchStart = useCallback(e => checkDeselect(e, selectShape), [checkDeselect]);
	const proportionalChangeHandler = useCallback(e => setProportional(!proportional), [proportional]);

	useEffect(() => {
		//380 sidebar, 24 left padding, 46 right padding
		const availableWidth = window.innerWidth - 380 - 24 - 46;
		const availableHeight = window.innerHeight - 140 - 24 * 2 - 24;
		const dimensionWidth = parseFloat(stageDimensions.width);
		const dimensionHeight = parseFloat(stageDimensions.height);
		const aspect = dimensionWidth / dimensionHeight;

		const limitWidth = availableWidth / dimensionWidth < availableHeight / dimensionHeight;
		const width = dimensionWidth;
		const height = dimensionHeight;
		const scale = limitWidth ? availableWidth / dimensionWidth : availableHeight / dimensionHeight;
		setScale(scale);
		setWidth(width);
		setHeight(height);
	}, [stageDimensions]);

	useEffect(() => {
		if (!backgroundRef.current) return;
		backgroundRef.current.fill(canvasType === 'plate' ? item.canvasBg : getHexColor(stone).backgroundColor);
	}, [stone, openStatus]);

	return (
		<div ref={parentRef}>
			<Stage
				className={`two__sides__canvas ${selectedId ? 'selected' : ''}`}
				// style={{ '&:after': { background: getHexColor(stone).textColor }, color: getHexColor(stone).textColor }}
				// style={{ width: width * scale + 'px', height: height * scale + 'px' }}
				// style={canvasType === 'plate' ? { background: item.canvasBg ?? '#00000080' } : {}}
				id={canvasId}
				width={width * scale}
				height={height * scale}
				onMouseDown={onMouseDownHandler}
				onTouchStart={onTouchStart}
				ref={stageRef}
				scaleX={scale}
				scaleY={scale}
			>
				{openStatus && (
					<Layer>
						<Rect
							x={0}
							y={0}
							width={width}
							height={height}
							offsetX={0}
							offsetY={0}
							// fillPatternX={0}
							// fillPatternY={0}
							name={'baсkground'}
							ref={backgroundRef}
							// fillPatternScaleX={1}
							// fillPatternScaleY={1}
							listening={false}
						/>
						<Line
							x={0}
							y={0}
							points={[width / (stageDimensions.type === 'stele' ? 4 : 2), 0, width / (stageDimensions.type === 'stele' ? 4 : 2), height]}
							strokeWidth={1}
							dashEnabled={true}
							dash={[10, 5]}
							stroke={'#ababab'}
						/>
						{stageDimensions.type === 'stele' && (
							<Line
								x={width / 2}
								y={0}
								points={[width / 4, 0, width / 4, height]}
								strokeWidth={1}
								dashEnabled={true}
								dash={[10, 5]}
								stroke={'#ababab'}
							/>
						)}
					</Layer>
				)}
				<Layer ref={layerRef} onDraw={onDrawHandler}>
					<CreateCanvasRenderItems
						items={url}
						changeItems={changeUrl}
						stageRef={stageRef}
						selectShape={selectShape}
						canvasType={canvasType}
						alignCenter={canvasType === 'plate'}
						canvasData={canvasData}
						stageDimensions={memorizedStageDimensions}
						canvasInfo={item}
						selectedId={selectedId}
					/>
				</Layer>
				<Transformers
					selectedId={selectedId}
					layer={layerRef}
					stage={stageRef}
					stageDimensions={stageDimensions}
					proportional={proportional}
					data={url}
					changeData={changeUrl}
					parentRef={parentRef}
					selectedItem={selectedItem}
				/>
			</Stage>
			<div className='canvas__container__func'>
				{selectedId && canvasType !== 'plate' && selectedItem?.type === 'image' && (
					<>
						<a className='reflect--horisontal' onClick={reflectHorizontalHandler}></a>
						<a className='reflect--vertical' onClick={reflectVerticalHandler}></a>
						{selectedItem?.alignBySide?.status === true && selectedItem.type === 'image' && (
							<a className={`proportional--transform ${proportional ? 'active' : ''}`} onClick={proportionalChangeHandler}></a>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default memo(CreateCanvas);
