import React, { useState } from 'react';
import { Rect, Transformer, Text } from 'react-konva';
import { checkOutsize } from './createCanvasGrid';

const rectParams = {
	// fill: '#00000010',
	stroke: '#00000050',
	strokeWidth: 0.5,
	// pixelRatio: 10
	fillPatternScaleX: 0.6,
	fillPatternScaleY: 0.6,
	fillPatternX: -1,
	fillPatternY: 0
};
export const Rectangle = ({ brush, image, shapeProps, isSelected, onSelect, onChange, activeMagnetSize, draggable }) => {
	const shapeRef = React.useRef();
	const trRef = React.useRef();
	const [isEditing, setIsEditing] = useState(false);
	React.useEffect(() => {
		if (isSelected) {
			trRef.current.nodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [isSelected]);
	const gridStep = activeMagnetSize; //cm
	React.useEffect(() => {
		// console.log(shapeProps);
	}, [shapeProps]);
	const randomTail = () => Math.random() / 1000;
	return (
		<React.Fragment>
			<Rect
				onClick={onSelect}
				onTap={onSelect}
				ref={shapeRef}
				{...shapeProps}
				{...rectParams}
				stroke={checkOutsize(shapeProps) ? 'rgba(255,0,0,0.66)' : rectParams.stroke}
				draggable={draggable}
				fillPatternImage={brush ? image ?? null : null}
				onDragEnd={e => {
					onChange({
						...shapeProps,
						...rectParams,
						x: Math.round(e.target.x() / gridStep) * gridStep + randomTail(),
						y: Math.round(e.target.y() / gridStep) * gridStep + randomTail()
					});
					setIsEditing(false);
				}}
				onDragStart={() => {
					setIsEditing(true);
				}}
				onTransformStart={() => {
					setIsEditing(true);
				}}
				onTransformEnd={e => {
					const node = shapeRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();
					node.scaleX(1);
					node.scaleY(1);
					onChange({
						...shapeProps,
						...rectParams,
						x: Math.round(node.x() / gridStep) * gridStep + randomTail(),
						y: Math.round(node.y() / gridStep) * gridStep + randomTail(),
						// set minimal value
						width: Math.max(5, Math.round((node.width() * scaleX) / gridStep) * gridStep) + randomTail(),
						height: Math.max(5, Math.round((node.height() * scaleY) / gridStep) * gridStep) + randomTail()
					});
					setIsEditing(false);
				}}
			/>
			{!isEditing && (
				<>
					<Text
						x={shapeProps.x + shapeProps.width / 2 - (shapeProps.width > 9 ? 4 : 2)}
						y={shapeProps.y}
						fontSize={6}
						text={Math.round(shapeProps.width)}
					/>
					<Text x={shapeProps.x} y={shapeProps.y + shapeProps.height} fontSize={6} rotation={-90} text={Math.round(shapeProps.height)} />
				</>
			)}
			{!isEditing && checkOutsize(shapeProps) && (
				<>
					<Text x={shapeProps.x} y={shapeProps.y} fontSize={12} text={'*'} fill={'rgba(255,0,0,0.66)'} />
				</>
			)}
			{isSelected && (
				<Transformer
					ref={trRef}
					rotateEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						// limit resize
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</React.Fragment>
	);
};
