import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { data } from './constans';

export const worksAPI = createApi({
	reducerPath: 'worksAPI',
	tagTypes: ['Works'],
	baseQuery: fetchBaseQuery({
		baseUrl: data.baseUrl,
		prepareHeaders: headers => {
			headers.set('Access-Control-Allow-Origin', '*');
			return headers;
		}
	}),
	endpoints: build => ({
		fetchWorks: build.query({
			query: ({ args }) => ({
				url: '/works',
				params: { ...args }
			}),
			transformResponse(apiResponse, meta) {
				if (typeof apiResponse === 'object' && apiResponse.length !== 0) {
					apiResponse = apiResponse.map(el => ({ ...el, img: el.image }));
				}
				return apiResponse;
			},
			providesTags: result => ['Works']
		})
	})
});
