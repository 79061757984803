import React, { useCallback } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

const items = [1, 2, 3, 4];

const HowManyBoxes = ({ burial }) => {
	const actions = useActions();
	const howManyBoxes = burial.boxes.items.length;

	const changeBoxes = useCallback(index => actions.changeBoxesAmountListen(index), []);

	return (
		<BlockWithArrow
			title='Количество кубов'
			blockClasses='sidebar__radio__block__value'
			contentClasses='sidebar__radio__block__value__items'
		>
			{items.map(item => (
				<RadioInput
					key={item}
					title={item}
					defaultChecked={howManyBoxes == item}
					onChange={() => changeBoxes(item)}
					name='complect__amount'
					id={`complect__amount_${item}`}
				/>
			))}
		</BlockWithArrow>
	);
};

export default HowManyBoxes;
