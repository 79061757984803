import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { Group, Text, Line } from 'react-konva';
import { defaultFontFamily, defaultFontSizeTransform } from 'config/fontSizeTransform.config';
import { defaultTextColor } from 'config/canvas.config';
import { OFFSET } from '../constans';
import { romanize } from '../../../../utils/dateToRoman';

const fontStyleTypes = [
	{ name: 'Прямой', type: 'normal' },
	{ name: 'Под наклоном', type: 'italic' }
];

const CreateDateRome = ({ item, handleTransformEnd, onDragEnd, onSelect, draggable, stageDimensions }) => {
	const { fontSize, fontSize2, textAlign, fontStyle, fontFamily, text, x, y, width, fill } = item;
	const [firstYearWidth1, setFirstYearWidth1] = useState(0);
	const [firstPartWidth, setFirstPartWidth] = useState(0);
	const [firstPartDayWidth, setFirstPartDayWidth] = useState(0);
	const [firstPartMonthWidth, setFirstPartMonthWidth] = useState(0);
	const [firstYearWidth2, setFirstYearWidth2] = useState(0);
	const [separatorWidth, setSeparatorWidth] = useState(0);
	const [secondYearWidth1, setSecondYearWidth1] = useState(0);
	const [secondPartWidth, setSecondPartWidth] = useState(0);
	const [secondPartDayWidth, setSecondPartDayWidth] = useState(0);
	const [secondPartMonthWidth, setSecondPartMonthWidth] = useState(0);
	const [secondYearWidth2, setSecondYearWidth2] = useState(0);

	const [groupX, setGroupX] = useState(0);
	const [height, setHeight] = useState(0);
	const groupRef = useRef(null);

	const fontStyleKonva = useMemo(() => {
		let el = fontStyleTypes.find(el => el.name === fontStyle || el.type === fontStyle);
		return el?.type ?? 'normal';
	}, [fontStyle, fontStyleTypes]);

	const firstPartRef = useCallback(
		node => {
			if (node === null) return;
			setFirstPartDayWidth(node.textWidth);
		},
		[fontSize, fontFamily]
	);

	const firstPartMonthRef = useCallback(
		node => {
			if (node === null) return;
			setFirstPartMonthWidth(node.textWidth);
		},
		[fontSize, fontFamily, item.startDate]
	);

	const firstYearRef1 = useCallback(
		node => {
			if (node === null) return;
			setFirstYearWidth1(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const firstYearRef2 = useCallback(
		node => {
			if (node === null) return;
			setFirstYearWidth2(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const separatorRef2 = useCallback(
		node => {
			if (node === null) return;
			setSeparatorWidth(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const secondPartRef = useCallback(
		node => {
			if (node === null) return;
			setSecondPartDayWidth(node.textWidth);
		},
		[fontSize, fontFamily]
	);

	const secondPartMonthRef = useCallback(
		node => {
			if (node === null) return;
			setSecondPartMonthWidth(node.textWidth);
		},
		[fontSize, fontFamily, item.endDate]
	);

	const secondYearRef1 = useCallback(
		node => {
			if (node === null) return;
			setSecondYearWidth1(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const secondYearRef2 = useCallback(
		node => {
			if (node === null) return;
			setSecondYearWidth2(node.textWidth);
		},
		[fontSize2, fontFamily]
	);

	const zeros = num => {
		return ('00' + num).slice(-2);
	};

	useEffect(() => {
		setFirstPartWidth(Math.max(firstPartDayWidth, firstPartMonthWidth));
	}, [firstPartDayWidth, firstPartMonthWidth]);

	useEffect(() => {
		setSecondPartWidth(Math.max(secondPartMonthWidth, secondPartDayWidth));
	}, [secondPartMonthWidth, secondPartDayWidth]);

	useEffect(() => {
		if (item.index !== 0) return;

		const stageWidth = stageDimensions.type === 'stele' ? stageDimensions.width / 2 - OFFSET * 2 : stageDimensions.width;
		const width =
			firstYearWidth1 + firstPartWidth + firstYearWidth2 + secondYearWidth1 + separatorWidth + secondPartWidth + secondYearWidth2;
		if (textAlign === 'center') {
			setGroupX(stageWidth / 2 - width / 2);
		} else if (textAlign === 'left') {
			setGroupX(0);
		} else {
			setGroupX(stageWidth - width);
		}
	}, [
		fontFamily,
		fontSize,
		fontSize2,
		textAlign,
		stageDimensions,
		firstPartWidth,
		firstYearWidth1,
		firstYearWidth2,
		secondPartWidth,
		secondYearWidth1,
		secondYearWidth2,
		separatorWidth
	]);

	useEffect(() => {
		const height = Math.max(fontSize2, fontSize * 2);
		setHeight(height);
	}, [y, fontSize, fontSize2]);

	const getYear = date => {
		return String(new Date(date).getFullYear()).match(/.{1,2}/g);
	};

	const getDay = date => {
		return zeros(new Date(date).getDate());
	};

	const getMonth = date => {
		return romanize(new Date(date).getMonth());
	};

	return (
		<Group
			ref={groupRef}
			x={groupX}
			y={y}
			onClick={e => onSelect(e.target.parent.id())}
			onTap={e => onSelect(e.target.parent.id())}
			onDragEnd={onDragEnd}
			onTransformEnd={handleTransformEnd}
			draggable={draggable}
			id={item?.id.toString()}
			resizeDisable={true}
		>
			<Text
				ref={firstYearRef1}
				x={0}
				y={-parseFloat(fontSize2) / 2 + height / 2}
				text={getYear(item.startDate)[0]}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={firstPartRef}
				x={firstYearWidth1 + (firstPartWidth - firstPartDayWidth) / 2}
				y={-parseFloat(fontSize) + height / 2}
				text={getDay(item.startDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Line
				x={0}
				y={-1 + height / 2}
				points={[firstYearWidth1 + 1, 0, firstYearWidth1 + firstPartWidth - 1, 0]}
				strokeWidth={2}
				stroke={fill ?? defaultTextColor.value}
			/>
			<Text
				ref={firstPartMonthRef}
				x={firstYearWidth1 + (firstPartWidth - firstPartMonthWidth) / 2}
				y={height / 2}
				text={getMonth(item.startDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={firstYearRef2}
				x={firstYearWidth1 + firstPartWidth}
				y={-parseFloat(fontSize2) / 2 + height / 2}
				text={getYear(item.startDate)[1]}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={separatorRef2}
				x={firstYearWidth1 + firstPartWidth + firstYearWidth2}
				y={-parseFloat(fontSize2) / 4 + height / 2}
				text={' ● '} //●∙∘◦◯
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2) / 2}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={secondYearRef1}
				x={firstYearWidth1 + firstPartWidth + firstYearWidth2 + separatorWidth}
				y={-parseFloat(fontSize2) / 2 + height / 2}
				text={getYear(item.endDate)[0]}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={secondPartRef}
				x={
					firstYearWidth1 +
					firstPartWidth +
					firstYearWidth2 +
					separatorWidth +
					secondYearWidth1 +
					(secondPartWidth - secondPartDayWidth) / 2
				}
				y={-parseFloat(fontSize) + height / 2}
				text={getDay(item.endDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Line
				x={firstYearWidth1 + firstPartWidth + firstYearWidth2 + separatorWidth}
				y={-1 + height / 2}
				points={[secondYearWidth1 + 1, 0, secondYearWidth1 + secondPartWidth - 1, 0]}
				strokeWidth={2}
				stroke={fill ?? defaultTextColor.value}
			/>
			<Text
				ref={secondPartMonthRef}
				x={
					firstYearWidth1 +
					firstPartWidth +
					firstYearWidth2 +
					separatorWidth +
					secondYearWidth2 +
					+(secondPartWidth - secondPartMonthWidth) / 2
				}
				y={height / 2}
				text={getMonth(item.endDate)}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
			<Text
				ref={secondYearRef2}
				x={firstYearWidth1 + firstPartWidth + firstYearWidth2 + secondYearWidth1 + separatorWidth + secondPartWidth}
				y={-parseFloat(fontSize2) / 2 + height / 2}
				text={getYear(item.endDate)[1]}
				fill={fill ?? defaultTextColor.value}
				wrap='word'
				fontSize={parseFloat(fontSize2)}
				fontStyle={fontStyleKonva}
				fontFamily={fontFamily ?? defaultFontFamily}
				perfectDrawEnabled={false}
			/>
		</Group>
	);
};

export default CreateDateRome;
