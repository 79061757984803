import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { data } from './constans';

export const materialsAPI = createApi({
	reducerPath: 'materialsAPI',
	tagTypes: ['Material'],
	baseQuery: fetchBaseQuery({
		baseUrl: data.baseUrl,
		prepareHeaders: headers => {
			headers.set('Access-Control-Allow-Origin', '*');
			return headers;
		}
	}),
	endpoints: build => ({
		fetchMaterial: build.query({
			query: ({ type, args }) => ({
				url: '/materials/',
				params: { ...args }
			}),
			providesTags: result => ['Material']
		})
	})
});
