import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { useActions } from 'hooks/useActions';

import InputTypeNumber from 'components/UI/input/text/number';

const PopUpValuesItem = ({ item, boxesLength, pedestalItemsLength, max, positionItems, value }) => {
	const actions = useActions();
	const { index, id } = item;

	const [inputValue, setInputValue] = useState(value);

	useLayoutEffect(() => setInputValue(value), [value, inputValue]);

	const classes = useMemo(() => {
		const check = pedestalItemsLength == 0 && (index == 0 || boxesLength == index);
		const secondCheck = index == 0 || boxesLength == index;

		return check ? 'novisible' : secondCheck ? 'min' : '';
	}, [pedestalItemsLength, index, boxesLength]);

	const min = useMemo(() => 0, []);

	const onChangeBoxesPosition = useCallback(
		newValue => {
			if (value !== newValue) {
				setInputValue(newValue);
				actions.changeBoxPopUpValuesListen({ index: index, value: parseInt(newValue) });
			}
		},
		[actions, index, value]
	);

	return (
		<InputTypeNumber
			min={min}
			blockClasses={`popup-position__input ${classes}`}
			defaultValue={inputValue}
			name={`popUpBoxesPosition_${id}`}
			id={`popUpBoxesPosition_${id}`}
			onChange={onChangeBoxesPosition}
			required={true}
			type='number'
			useDelay={false}
		/>
	);
};

export default PopUpValuesItem;
