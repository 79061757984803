import React, { useCallback, useMemo } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

const EngravingCoating = ({ burial }) => {
	const actions = useActions();
	const сoatingWithEngravingStatus = burial.сoatingWithEngraving.status;
	const сoatingWithEngravingType = burial.сoatingWithEngraving.type;
	const items = useMemo(() => ['Золотая краска', 'Сусальное золото'], []);

	const onToggleHandler = useCallback(
		() => actions.setEngravingValue({ obj: 'сoatingWithEngraving', include: 'status', value: !сoatingWithEngravingStatus }),
		[actions, сoatingWithEngravingStatus]
	);

	const onChangeHandler = useCallback(
		item => actions.setEngravingValue({ obj: 'сoatingWithEngraving', include: 'type', value: item }),
		[actions]
	);

	return (
		<BlockWithToggle
			title='Покрытие гравировки'
			openState={сoatingWithEngravingStatus}
			onChangeToggle={onToggleHandler}
			blockClasses='sidebar__radio__block'
		>
			{items.map((item, index) => (
				<RadioInput
					key={index}
					title={item}
					defaultChecked={сoatingWithEngravingType == item}
					onChange={() => onChangeHandler(item)}
					name='coating_type'
					id={`coating_type_${item}`}
				/>
			))}
		</BlockWithToggle>
	);
};

export default EngravingCoating;
