export const storeFilters = [
	// no skip
	// 'step1/changeBurialPosition',
	// step 1
	'step1/changeHowManyBurials',
	'step1/changeInstallation',
	'step1/changeBurialSelectedState',
	'step1/changeWorkZoneOpen',
	'step1/changeBurialUnionCount',
	'step1/renderChangeHowManyBurials',
	'step1/changeGraves',
	'step1/changeBurialAreaValues',
	'step1/changeSelectedProductsComplect',
	'step1/selectComponentProduct',
	'step1/changeSelectedProducts',

	// step 2
	'step1/changeCatalogTypeFirst',
	'step1/changeMaterialType',
	'step1/changeMaterialStone',
	'step1/changePolishing',
	'step3/changeCurrentSidebarTab',
	// step 3

	// pedestal
	'step1/changePedestalSelectedProducts',
	'step1/changePedestalIndividualSizes',
	'step1/changePedestalIndividualStatus',
	'step1/removePedestal',
	'step1/changeBoxesAmount',
	'Toggle pedestal sizes toggle/@@redux-scc-combined-action',
	// boxes
	'step1/changeBoxPopUpValues',
	'step1/changeBoxPositionStatus',
	'step1/changeBoxPopUpState',
	// stella
	'step1/addStella',
	'step1/changeTileAreaPrice',
	'step1/changeStellaIndividualSizes',
	'step1/changeStellaSelectedProducts',
	'step1/removeStella',
	// flowers
	'step1/changeFlowerIndividualSizes',
	'step1/changeFlowerSelectedProductsPoperehna',
	// tombstone
	'step1/changeListSelectedProducts',
	'step1/changeListIndividualSizes',

	// requests
	'productsAPI/executeQuery/fulfilled',
	'productsAPI/subscriptions/unsubscribeQueryResult',
	'worksAPI/executeQuery/fulfilled',
	'complectsAPI/executeQuery/fulfilled',
	'worksAPI/subscriptions/unsubscribeQueryResult',
	'worksAPI/executeQuery/pending',
	'productsAPI/executeQuery/pending',
	'productsAPI/queries/removeQueryResult'
];

export const filterActions = action => !action.payload?.historySkip ?? true;
