import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

const useArrayFromLenght = (length, makeObject = false) => {
	const array = useMemo(() => {
		let arr = Array.apply(null, { length: length }).map(Number.call, Number);
		if (makeObject)
			arr = arr.map(el => {
				const unique_id = uuid();
				return { index: el, id: unique_id };
			});
		return arr;
	}, [length, makeObject]);

	return array;
};

export default useArrayFromLenght;
