import { generateDropZone } from '../forms/generateDropZone';
import { generateCatalog } from '../forms/generateCatalog';
import { v4 as uuid } from 'uuid';
import { dimensions } from 'config/defaultDimensions.config';

export const createFlower = ({
	index = null,
	selected = 'standard',
	title = 'Гранит',
	value = 'granite',
	stone = 'Габбро Диабаз',
	poperehnaStatus = true,
	poperehnaSizes,
	prodolnaStatus = true,
	prodolnaSizes,
	position = {
		x: 0,
		y: 0
	},
	flowerId
}) => {
	const unique_id = flowerId ?? uuid();
	const { poperehnaSizes: defaultPoperehnaSizes, prodolnaSizes: defaultProdolnaSizes } = dimensions.flower;
	return {
		id: unique_id,
		indexInGeneral: index,
		selected: selected,
		material: {
			title: title,
			value: value
		},
		stone: stone,
		poperehna: {
			status: poperehnaStatus,
			sizes: poperehnaSizes ?? defaultPoperehnaSizes,
			selectedProducts: []
		},
		prodolna: {
			status: prodolnaStatus,
			sizes: prodolnaSizes ?? defaultProdolnaSizes,
			selectedProducts: []
		},
		form: {
			clienForm: generateDropZone(),
			decorationFaska: generateCatalog(),
			ownFaska: generateDropZone()
		},
		position,
		position3d: { x: 0, y: 0 }
	};
};
