import { preloaderEvents } from 'utils/events/preloader';
import { data as servicesData } from 'services/constans';
import { getInstallation } from './getInstallation';

export const setComplect = async (item, preloaderState, materialType) => {
	if (preloaderState !== 'disable') preloaderEvents.preloaderEnable();
	const promiseItems = Object.entries(item.modules).map(async el => {
		const data = await new Promise(resolve => {
			fetch(servicesData.baseUrl + '/modules/' + el[0])
				.then(res => res.json())
				.then(async res => {
					if (res.terms.includes('stella') && res.size.values) {
						res.installation = await getInstallation(materialType, res.size.values, 1);
					}
					resolve(Array.from(Array(parseInt(el[1])).keys()).map(item => res));
				});
		});
		return data;
	});

	return await Promise.all(promiseItems).then(res => {
		let components = res.flat().map(el => {
			const sizes = Object.values(el.size.values).map(el => parseFloat(el === '' ? 0 : el));
			return { ...el, type: el.terms[0], sizes };
		});
		const stellasLength = components.filter(el => el.terms.includes('stella'));
		if (stellasLength >= 2) {
			components = components.map(el => {
				if (el.terms.includes('stella')) delete el.installation;
				return el;
			});
		}
		const values = { ...item, components };
		return values;
	});
};
