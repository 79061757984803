import { findBurial, changeSelectProductStatus } from './../../step1Slice';

const step3Engraving = () => {
	const setEngravingValue = (state, action) => {
		const burial = findBurial(state);
		const { obj, include, value } = action.payload;
		if (include != null) {
			burial.engraving[obj][include] = value;
		} else {
			burial.engraving[obj] = value;
		}
	};

	const changeSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		const { value, type = 'creed' } = action.payload;

		burial.engraving[type].items = changeSelectProductStatus(burial.engraving[type].items, value, true);
	};

	const changeEpitaliaSelectedProducts = (state, action) => {
		const burial = findBurial(state);
		const { value, obj = 'library', type = 'creed' } = action.payload;
		burial.engraving[type].items[obj].items = changeSelectProductStatus(burial.engraving[type].items[obj].items, value, true);
	};

	const setItemsFioDate = (state, action) => {
		const burial = findBurial(state);
		const { type, obj, include, value } = action.payload;
		if (include != null) {
			burial.engraving[type].items[obj][include] = value;
		} else {
			burial.engraving[type].items[obj] = value;
		}
	};

	const setItemsFioDateItems = (state, action) => {
		const burial = findBurial(state);
		const { actionType, type, obj, index, include, value } = action.payload;
		if (actionType != null) {
			if (actionType == 'create') {
				burial.engraving[type].items[obj].items = burial.engraving[type].items[obj].items.concat(value);
			} else {
				burial.engraving[type].items[obj].items = burial.engraving[type].items[obj].items.filter((el, i) => index != i);
			}
		} else {
			if (include != null) {
				burial.engraving[type].items[obj].items[index][include] = value;
			} else {
				burial.engraving[type].items[obj].items[index] = value;
			}
		}
	};

	return {
		setEngravingValue,
		changeSelectedProducts,
		setItemsFioDate,
		setItemsFioDateItems,
		changeEpitaliaSelectedProducts
	};
};

export default step3Engraving;
