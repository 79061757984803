import { generateBurial } from 'utils/helpers/step1_generateBurial';

const step1Reducers = () => {
	const changeReligion = (state, action) => {
		state.religion = action.payload;
	};

	const changeLoading = (state, action) => {
		state.loading = action.payload;
	};

	const changeInstallation = (state, action) => {
		state.installation = action.payload;
	};

	const changeTotal = (state, action) => {
		if (state.workZone.selected != 'Место захоронения') {
			state.total.totalArea = ((state.workZone.zoneValues.width * state.workZone.zoneValues.height) / 1000000).toFixed(2);
			let burials = 0,
				exclude = 0;
			state.burials.forEach(element => (burials += parseFloat((element.areaValues.length * element.areaValues.width) / 1000000)));
			if (state.excludeArea.isOpen != false)
				state.excludeArea.areas.forEach(element => (exclude += parseFloat((element.height * element.width) / 1000000)));
			state.total.workArea = state.total.totalArea - (burials + exclude);
		} else {
			let value = 0;
			state.burials.forEach(element => (value += parseFloat((element.areaValues.length * element.areaValues.width) / 1000000)));
			state.total.totalArea = value.toFixed(2);
			state.total.workArea = state.total.totalArea - value;
		}
	};

	const changeWorkZone = (state, action) => {
		if (action.payload.type === 'changeZoneValuesWidth') state.workZone.zoneValues.width = action.payload.value;
		if (action.payload.type === 'changeZoneValuesHeight') state.workZone.zoneValues.height = action.payload.value;

		if (action.payload.type === 'changeSelected') state.workZone.selected = action.payload.value;
	};

	const changeWorkZoneOpen = (state, action) => {
		state.workZone.open = action.payload;
	};

	const changeHowManyBurials = (state, action) => {
		state.howManyBurials = action.payload;
		let newBurials = [];
		for (let index = 0; index < action.payload; index++) {
			const element = state.burials[index];
			newBurials.push(!element ? generateBurial(newBurials.length) : element);
		}
		state.burials = newBurials;
		state.linkBurials.value = [];
	};

	const renderChangeHowManyBurials = (state, action) => {
		state.renderBurials = action.payload;
	};

	const toggleLinkBurials = (state, action) => {
		state.linkBurials.isOpen = !state.linkBurials.isOpen;
		state.linkBurials.isOpen == false ? (state.burials = state.linkedBurials) : (state.linkBurials.value = []);
	};

	const changeLinkBurials = (state, action) => {
		state.linkBurials.value = action.payload;
	};

	const changeBurialsAmount = (state, action) => {
		state.burials = [];
		if (state.burials.length > action.payload) state.burials = state.burials.slice(0, action.payload);
		if (state.burials.length < action.payload) {
			const itemsAmountToAdd = action.payload - state.burials.length;
			const newBurials = [];
			for (let i = 0; i < itemsAmountToAdd; i++) {
				newBurials.push(generateBurial(newBurials.length));
			}
			state.burials = [...state.burials, ...newBurials];
		}
		state.linkedBurials = state.burials;
		state.renderLinks = true;
		state.howManyLinks = state.burials.length;
		state.linkBurials.isOpen = false;
	};

	const changeBurialSelectedState = (state, action) => {
		const { id, value } = action.payload;
		const burial = state.burials.find(item => item.id === id);
		burial.selected = value;
	};

	const changeBurialAreaValues = (state, action) => {
		const burial = state.burials.find(item => item.id === action.payload.id);
		if (!burial || !burial.areaValues) return;
		if (action.payload.type === 'CHANGE_HEIGHT') burial.areaValues.width = +action.payload.value;
		if (action.payload.type === 'CHANGE_WIDTH') burial.areaValues.length = +action.payload.value;
		if (action.payload.type === 'CHANGE_X') burial.areaValues.x = +action.payload.value;
		if (action.payload.type === 'CHANGE_Y') burial.areaValues.y = +action.payload.value;
	};

	const changeBurialPosition = (state, action) => {
		const burial = state.burials.find(item => item.id === action.payload.id);

		if (!burial || !burial.position) return;
		burial.position.x = action.payload.x;
		burial.position.y = action.payload.y;
		burial.position.angle = action.payload.angle;

		if (action.payload.pedestal && burial.pedestal.items[0]) {
			burial.pedestal.items[0].position.x = action.payload.pedestal.x;
			burial.pedestal.items[0].position.y = action.payload.pedestal.y;
		}

		if (action.payload.beds && burial.flowers) {
			action.payload.beds.forEach(bed => {
				const flower = burial.flowers.items.find(item => item.id === bed.id);
				if (flower) {
					flower.position3d.x = bed.x;
					flower.position3d.y = bed.y;
				}
			});
		}
	};

	const changeBurialUnionCount = (state, action) => {
		const burial = state.burials.find(item => item.id === action.payload.id);
		burial.unionCount = action.payload.value;
	};

	const changeGraves = (state, action) => {
		if (!state.burials.length) state.burials = [generateBurial()];
	};

	return {
		changeBurialSelectedState,
		changeLoading,
		changeReligion,
		changeInstallation,
		changeTotal,
		changeWorkZone,
		changeWorkZoneOpen,
		changeHowManyBurials,
		renderChangeHowManyBurials,
		toggleLinkBurials,
		changeLinkBurials,
		changeBurialsAmount,
		changeBurialAreaValues,
		changeBurialUnionCount,
		changeGraves,
		changeBurialPosition
	};
};

export default step1Reducers;
