export const generateTombstoneObj = item => {
	const {
		elements: { elems: elemsElements, epitafia: elemsEpitafia },
		engraving: { engraving, epitafia },
		form: { decoration }
	} = item;

	let elementsItems = [{ status: elemsEpitafia.status, obj: elemsEpitafia, category: 'tombstone-elements-epitafia', type: 'text' }];

	const elemsBronsItem = Object.values(elemsElements.selectedProducts).pop();
	if (elemsBronsItem && elemsBronsItem.img) {
		elementsItems = [
			...elementsItems,
			{
				status: elemsElements.status,
				obj: { ...elemsBronsItem, resizeDisable: true },
				category: 'tombstone-elements-elements',
				type: 'image'
			}
		];
	}

	const engravingItems = [
		{ status: epitafia.status, obj: epitafia.items, category: 'tombstone-engraving-epitafia', type: 'text' },
		{
			status: engraving.status,
			obj: Object.values(engraving.items).pop(),
			category: 'tombstone-engraving-elements',
			type: 'image'
		}
	];

	const formDecorationEls = Object.values(decoration.selectedProducts);

	const formItems = [
		{
			status: decoration.status,
			obj: formDecorationEls.length && { ...formDecorationEls.pop(), alignBySide: { status: true, type: 'front' } },
			category: 'tombstone-decoration-elements',
			type: 'image'
		}
	];

	return [...elementsItems, ...engravingItems, ...formItems];
};
