import React, { useCallback } from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

import { useActions } from 'hooks/useActions';

const items = ['Ручная', 'Станочная'];

const EngravingType = ({ burial }) => {
	const actions = useActions();
	const type = burial.type;

	const onChangeTypeHandler = useCallback(item => actions.setEngravingValue({ obj: 'type', value: item }), [actions]);

	return (
		<BlockWithArrow title='Вид гравировки' openState={false} arrowDelete={true}>
			{items.map((item, index) => (
				<RadioInput
					key={index}
					title={item}
					defaultChecked={type == item}
					onChange={() => onChangeTypeHandler(item)}
					name='engraving_type'
					id={`engraving_type_${item}`}
				/>
			))}
		</BlockWithArrow>
	);
};

export default EngravingType;
