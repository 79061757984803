export const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
export const cors = isLocal ? `http://${window.location.hostname}:8080/` : '';

export const data = {
	baseUrl: `https://${window.location.hostname}/wp-json/app/v1`,
	categoriesUrl: `https://${window.location.hostname}/wp-json/wp/v2`,
	isLocal
};

if (isLocal) {
	data.baseUrl = cors + `https://3d.granit-pamyati.ru/wp-json/app/v1`;
	data.categoriesUrl = cors + `https://3d.granit-pamyati.ru/wp-json/wp/v2`;
}
