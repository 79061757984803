import React, { useState } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import SecondButton from 'components/UI/button/second';
import PopUp from 'components/UI/popup/popup';
import FormWithTabs from './FormWithTabs';
import FioTab from './FormWithTabs/fioTab';
import DatesOfLife from './FormWithTabs/datesOfLife';

import { generateFioAndDates } from 'utils/forms/generateFioAndDates';
import { useActions } from 'hooks/useActions';

const FioAndDates = ({ currentTable, type, template }) => {
	const actions = useActions();
	const currentTableFioStatus = currentTable?.fio?.status;
	const currentTableFioItems = currentTable?.fio?.items;

	const [openFIOPop, setOpenFIOPop] = useState(false);

	return (
		<BlockWithToggle
			title='ФИО + даты'
			openState={currentTableFioStatus}
			onChangeToggle={() => {
				actions.setTableValue({
					type,
					obj: 'fio',
					value: { status: !currentTableFioStatus, items: null }
				});
			}}
			blockClasses='sidebar__radio__block classForTablesForm'
			contentClasses='editDelete'
		>
			{currentTableFioItems !== null && (
				<PopUp state={openFIOPop} classes='deceasedDataPlate configurator__light-box' onClick={e => setOpenFIOPop(!openFIOPop)}>
					<FormWithTabs
						children1={<FioTab type='granit' data={currentTableFioItems.fio} currentTableFioColor={currentTableFioItems.fio.color.title} />}
						children2={
							<DatesOfLife
								template={template}
								type='granit'
								data={currentTableFioItems.datesItems}
								currentTableFioColor={currentTableFioItems.datesItems.color.title}
							/>
						}
					/>
				</PopUp>
			)}

			{currentTableFioItems !== null && currentTableFioItems.name !== '' ? (
				<ActionBlock
					title={currentTableFioItems.fio.items[0].value}
					onEditClick={() => setOpenFIOPop(true)}
					onBasketClick={() => {
						setOpenFIOPop(false);
						actions.setTableValue({
							type,
							obj: 'fio',
							value: { status: currentTableFioStatus, items: null }
						});
					}}
				/>
			) : (
				<SecondButton
					title='Добавить ФИО'
					onClick={e => {
						setOpenFIOPop(true);
						actions.setTableValue({
							type,
							obj: 'fio',
							include: 'items',
							value: generateFioAndDates(template)
						});
					}}
					classes='box__value__add'
				/>
			)}
		</BlockWithToggle>
	);
};

export default FioAndDates;
