export const A3 = { width: 297, height: 420 };
export const A4 = { width: 210, height: 297 };
export const A5 = { width: 148, height: 210 };
export const A6 = { width: 105, height: 148 };

export const getFitArgs = item => {
	const args = {};
	if (item.extra?.comment) {
		switch (item.extra.comment) {
			case 'A-3':
			case 'А-3':
				args.fitHeight = A3.height;
				break;
			case 'A-4':
			case 'А-4':
				args.fitHeight = A4.height;
				break;
			case 'A-5':
			case 'А-5':
				args.fitHeight = A5.height;
				break;
			case 'A-6':
			case 'А-6':
				args.fitHeight = A6.height;
				break;
			default:
				return {};
		}
	}
	return args;
};
