import React from 'react';
import { convertPrice } from 'utils/convertPrice';

const BigCard = ({ item, onClick = () => {}, status }) => {
	const { img, title, sku, price } = item;

	return (
		<div className='product__card' onClick={() => onClick({ ...item, img })}>
			{img && (
				<div className='product__card__image'>
					<img src={img} alt={title} />
				</div>
			)}
			<div className='product__card__heading'>
				<h2>{title}</h2>
				<span>Артикул: {sku}</span>
			</div>
			<div className='product__card__details'>
				<h3>{price && price !== 0 ? convertPrice(price) : ''}</h3>
				<button className={`--svg__add_to_cart-after ${status ? 'active' : ''}`}></button>
			</div>
		</div>
	);
};

export default BigCard;
