import React from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import FaskaModule from 'components/UI/bigComponents/FaskaModule';

import { useActions } from 'hooks/useActions';

const Boxes = ({ burial, currentBurial }) => {
	const actions = useActions();
	const currentBox = burial.boxes.currentBox;
	const boxes = burial.boxes.items;

	return (
		<>
			<TabsComponent
				currentEl={currentBox}
				currentBurial={currentBurial}
				AllEl={boxes}
				title='КУБ'
				onClick={e => actions.changeCurrentBox(e.target.dataset.value)}
			/>
			<FaskaModule type='boxes' currentElement={currentBox} />
		</>
	);
};

export default Boxes;
