import { worksAPI } from 'services/Works';
import { useCallback, useMemo } from 'react';
import { useShallowEffect } from '@mantine/hooks';

export const useEngravingEpitafiaPrice = ({ engravingType, changePrice, fontSizeType, fontSize, materialSlug, status }) => {
	const [refetch, { data, isFetching, isError }] = worksAPI.useLazyFetchWorksQuery();

	const refetchData = useCallback(() => {
		const args = {
			work_tax: engravingType === 'Ручная' ? 60 : 61,
			material_tax: materialSlug,
			per_page: 100
		};

		if (engravingType === 'Ручная') {
			const sizeinmm = parseInt((`${fontSize}` || '').replace(/\D+/g, ''));
			args.type = fontSizeType;
			args.size_height = sizeinmm < 20 ? 2 : 5;
		}
		if (!status) return;
		refetch({ args }, true);
	}, [engravingType, materialSlug, fontSize, fontSizeType, status]); // the second arg is preferCacheValue

	const returnData = useMemo(
		() => ({
			data: isFetching ? [] : data || [],
			isLoading: isFetching,
			isError
		}),
		[data, isFetching, isError]
	);

	useShallowEffect(() => refetchData(), [engravingType, fontSize, fontSizeType, materialSlug, status]);

	useShallowEffect(() => {
		if (returnData.data.length && !returnData.isLoading) {
			changePrice(returnData.data?.[0]?.price?.unit || 0);
		}
	}, [returnData]);

	useShallowEffect(() => {
		if (returnData.data.length && !returnData.isLoading) {
			changePrice(returnData.data?.[0]?.price?.unit || 0);
		}
	}, [engravingType, fontSizeType, fontSize, materialSlug, status]);

	return returnData;
};
