export const getScaledImageDimensions = (containerWidth, containerHeight, width, height, wasChanged = false) => {
	if (wasChanged) return { newWidth: width, newHeight: height };

	let widthRatio = containerWidth / width,
		heightRatio = containerHeight / height;

	let bestRatio = Math.min(widthRatio, heightRatio);
	let newWidth = width * bestRatio,
		newHeight = height * bestRatio;

	return { newWidth, newHeight };
};
