import React, { useCallback } from 'react';
import { useActions } from 'hooks/useActions';

import NotificationPortal from './NotificationPortal';

const Notification = ({ heading, text, type, clicked, goToStep }) => {
	const actions = useActions();

	const onClickNoHandler = useCallback(() => actions.changeNotificationStatus(false), [actions]);

	const onClickYesHander = useCallback(() => {
		actions.changeNotificationStatus(false);
		goToStep(clicked);
	}, [clicked, actions, goToStep]);

	return (
		<NotificationPortal
			heading={heading}
			text={text}
			type={type}
			clicked={clicked}
			onClickNoHandler={onClickNoHandler}
			onClickYesHander={onClickYesHander}
		/>
	);
};

export default Notification;
