import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import CatalogPlate from './CatalogPlate';
import Editor from './Editor';

const PlateEditor = () => {
	return (
		<BlockWithArrow title='Раскладка плитки'>
			<CatalogPlate />
			<Editor />
		</BlockWithArrow>
	);
};

export default PlateEditor;
