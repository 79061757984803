import { changeSelectProductStatus, findBurial, sendEventToChangeMaterial } from '../../step1Slice';

import { createFlower } from 'utils/helpers/step3_generateFlower';
import { generateStella } from 'utils/helpers/step3_generateStella';
import { generatePedestal } from 'utils/helpers/step3_generatePedestal';

import { step2Events } from 'utils/events/steps/step2';
import { preloaderEvents } from 'utils/events/preloader';
import { elements, engraving, improvement, installation, tables, boxes, pedestal, stella, tombstone } from 'utils/helpers/config/burial';
import { resetFlowers, resetTombstones, resetTables } from 'utils/reset';

const step2Reducers = () => {
	const changeMaterialType = (state, action) => {
		const burial = findBurial(state);
		const material = {
			title: action.payload.title,
			value: action.payload.value
		};
		burial.material = material;
		burial.polishing = 5;
		action.payload.value == 'granite' ? (burial.materialStone = 'Габбро Диабаз') : (burial.materialStone = 'Мрамор');
		sendEventToChangeMaterial(burial.id, action.payload.value, 'granite_grade_changed', 'marble_grade_changed');

		burial.pedestal.items = burial.pedestal.items.map(el => ({
			...el,
			title: material.title,
			value: material.value,
			stone: burial.materialStone
		}));
		burial.stella.items = burial.stella.items.map(el => ({
			...el,
			title: material.title,
			value: material.value,
			stone: burial.materialStone
		}));
		burial.flowers.items = burial.flowers.items.map(el => ({ ...el, material: material, stone: burial.materialStone }));
	};

	const changeMaterialStone = (state, action) => {
		const burial = findBurial(state);
		burial.materialStone = action.payload;

		burial.pedestal.items = burial.pedestal.items.map(el => ({ ...el, stone: action.payload }));
		burial.stella.items = burial.stella.items.map(el => ({ ...el, stone: action.payload }));
		burial.flowers.items = burial.flowers.items.map(el => ({ ...el, stone: action.payload }));
	};

	const changeCurrentBurial = (state, action) => {
		state.currentBurial = action.payload;
	};

	const changeCatalogTypeFirst = (state, action) => {
		const burial = findBurial(state);
		burial.catalog.type = action.payload;
	};

	const changeSelectedProductsHandler = (state, action) => {
		const isAll = action.payload?.state?.all;
		const burialsArr = isAll ? state.burials : [findBurial(state)];
		[...burialsArr].map(burial => {
			if (isAll) {
				burial.catalog.selectedProducts = [state.defaultComplect?.[burial.material.value]].filter(el => el);
			} else {
				burial.catalog.selectedProducts = changeSelectProductStatus(burial.catalog.selectedProducts, action.payload, true);
			}
		});
	};

	const changeSelectedProductsComplect = changeSelectedProductsHandler;

	const changeSelectedProducts = changeSelectedProductsHandler;

	const changePolishing = (state, action) => {
		const burial = findBurial(state);
		burial.polishing = action.payload;
		step2Events.changePolishing(burial.id, action.payload);
	};

	const resetToDefaultSecondStep = (state, action) => {
		state.burials = state.burials.map(burial => ({
			...burial,
			material: {
				title: 'Гранит',
				value: 'granite'
			},
			polishing: 5,
			materialStone: 'Габбро Диабаз',
			catalog: {
				type: 'standart',
				selectedProducts: []
			}
		}));
	};

	const selectComplectHander = (state, action) => {
		const burials = action.payload.all ? state.burials : [findBurial(state)];
		let data = action.payload.items.map(el => ({ ...el, type: el.terms[0] ?? '' }));
		let flower = null;

		burials.map(burial => {
			if (action.payload.all) {
				data = state.defaultComplect[burial.material.value].components || [];
			}

			const burialType = action.payload?.type || burial.catalog.type;

			if (burialType !== 'flower') {
				if (data.some(el => el.type === 'prodolna' || el.type === 'poperehna')) {
					state.generalIndexFlowers = state.generalIndexFlowers + 1;
					//TODO замапил thickness
					const { width, length, height, thickness } = data.find(el => el.type === 'prodolna').size.values;
					const poperehnaSizes = { ...data.find(el => el.type === 'poperehna').size.values };
					if (!poperehnaSizes.width) poperehnaSizes.width = poperehnaSizes.thickness;
					flower = {
						type: 'flower',
						index: state.generalIndexFlowers,
						selected: 'standard',
						poperehnaStatus: true,
						poperehnaSizes: poperehnaSizes,
						prodolnaStatus: true,
						prodolnaSizes: { length: width ?? thickness, width: length, height },
						position: { x: 0, y: 0 }
					};
				}

				data = data.filter(el => el.type !== 'prodolna' || el.type !== 'poperehna');

				if (flower) data = [...data, flower];
			}

			const { title, value } = burial.material;
			const stone = burial.materialStone;

			// reset 3d
			resetFlowers(burial.flowers.items, burial.id);
			resetTombstones(burial.tombstone.items, burial.id);
			resetTables(burial.tables, burial.id);

			burial.flowers.items = [];
			burial.flowers.currentFlower = 0;
			burial.tombstone = tombstone;
			burial.tables = tables;
			burial.engraving = engraving;
			burial.elements = elements;
			burial.installation = installation;

			const graveInstallationPrice = data.find(item => item.type === 'stella' && item.installation);
			burial.improvement = improvement(graveInstallationPrice?.installation || 0);

			data.map(item => {
				const itemsSize = item.size?.values;
				const height = parseInt(itemsSize?.height ?? 0);
				const width = parseInt(itemsSize?.width ?? 0);
				const length = parseInt(itemsSize?.length ?? itemsSize?.weight ?? itemsSize?.thickness ?? 100);
				let values = { stone: stone, sverlovka: item.sverlovka, width, length, height };

				if (item.type === 'pedestal') {
					burial.pedestal = pedestal([generatePedestal({ title, value, ...values })]);
					burial.boxes = boxes(width);
				}

				if (item.type === 'stella') {
					burial.stella = stella([generateStella({ title, value, ...values })]);
				}

				if (item.type === 'flower' && burialType !== 'noflower') {
					burial.flowers.items = [
						...burial.flowers.items,
						createFlower({
							...item,
							title,
							value,
							stone
						})
					];
				}
			});
		});
		preloaderEvents.preloaderDisable();
	};

	const selectComponent = selectComplectHander;
	const selectComponentProduct = selectComplectHander;

	return {
		changeMaterialType,
		changeMaterialStone,
		changeCurrentBurial,
		changeCatalogTypeFirst,
		changeSelectedProducts,
		changeSelectedProductsComplect,
		changePolishing,
		resetToDefaultSecondStep,
		selectComponent,
		selectComponentProduct
	};
};

export default step2Reducers;
