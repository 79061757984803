import { generateDropZone } from '../forms/generateDropZone';
import { generateCatalog } from '../forms/generateCatalog';
import { dimensions } from 'config/defaultDimensions.config';

export const generateBox = (title = 'Гранит', value = 'granite', stone = 'Габбро Диабаз') => {
	const { height: defaultHeight, width: defaultWidth, depth: defaultDepth } = dimensions.boxes;

	return {
		title: title,
		value: value,
		stone: stone,
		size: {
			height: defaultHeight ?? 100,
			width: defaultWidth ?? 100,
			depth: defaultDepth ?? 100
		},
		sverlovka: 1,
		indexPos: 0,
		form: {
			decorationFaska: generateCatalog(),
			ownFaska: generateDropZone()
		}
	};
};
