import { useCallback } from 'react';

export const useReflect = () => {
	const reflectImage = useCallback((stageRef, selectedId, data, type = 'scaleX') => {
		const item = stageRef.current.getLayers()[0].findOne(`#${selectedId}`);

		if (!item) return data;

		const newData = data.map(img => {
			if (img.id != selectedId) return img;

			const width = img.width ?? item.width();
			const height = img.height ?? item.height();
			const offsetX = type.includes('X') ? (item.offsetX() > 0 ? 0 : width) : item.offsetX();
			const offsetY = type.includes('Y') ? (item.offsetY() > 0 ? 0 : height) : item.offsetY();

			return {
				...img,
				offsetX,
				offsetY,
				[type]: -item[type]()
			};
		});

		return newData;
	}, []);

	return { reflectImage };
};
