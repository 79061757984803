import { useMemo } from 'react';

import { tablesApi } from 'services/Tables';
import { productsAPI } from 'services/Products';

import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';
import { useShallowEffect } from '@mantine/hooks';
import { useActions } from 'hooks/useActions';
import { preloaderEvents } from 'utils/events/preloader';

import { tableForms } from 'config/tablesForms.config';
import { tablesColorVariationsBD } from 'config/tablesColorVariations';

export const useTableSizes = ({
	tableVariation,
	tableType,
	currentTableForm,
	currentTableColorVariation = 'Черно-белая',
	currentTableCaptionStatus = false,
	currentTableSize
}) => {
	const categories = useConfiguratorCategories('modules');
	const cat_tax = categories.find(el => el.slug === tableType)?.id;
	const actions = useActions();

	const [refetchForms, { data: dataForms, isFetching: formsLoading }] = tablesApi.useLazyFetchTablesFormQuery();
	const [refetchSizes, { data: dataSizes, isFetching: sizesLoading }] = tablesApi.useLazyFetchTablesFormIdsQuery();
	const [refetchCurrentSizeData, { data: currentSizeData, isFetching: currentSizeDataLoading }] = productsAPI.useLazyFetchProductsQuery();

	const forms = useMemo(
		() =>
			dataForms
				?.map(form => {
					const exist = tableForms[tableVariation].find(item => item.type === form);
					if (exist) {
						exist.type = exist.confType;
						return exist;
					}
					return null;
				})
				.filter(el => el !== null) ?? [],
		[dataForms]
	);

	const sizes = useMemo(() => [...(dataSizes || [])].sort((a, b) => a.values.width - b.values.width), [dataSizes]);

	useShallowEffect(() => {
		refetchForms({
			post_type: 'modules',
			taxonomy: 'module_tax',
			term_ids: cat_tax.toString(),
			acf_selector: 'form'
		});
	}, []);

	useShallowEffect(() => {
		if (!forms.length) return;

		const params = {
			form: currentTableForm ?? forms?.[0]?.type,
			color: currentTableColorVariation && tablesColorVariationsBD[currentTableColorVariation],
			caption: currentTableCaptionStatus,
			post_type: 'modules',
			taxonomy: 'module_tax',
			term_ids: cat_tax.toString(),
			module_tax: cat_tax.toString(),
			acf_selector: 'size'
		};

		if (currentTableColorVariation === 'hide') delete params.color;
		if (currentTableCaptionStatus === null) delete params.caption;

		refetchSizes(params);
	}, [currentTableColorVariation, forms, currentTableForm, currentTableCaptionStatus]);

	useShallowEffect(() => {
		const params = {
			per_page: 100,
			module_tax: cat_tax.toString(),
			form: currentTableForm ?? forms?.[0]?.type,
			color: currentTableColorVariation && tablesColorVariationsBD[currentTableColorVariation],
			caption: currentTableCaptionStatus,
			size_height: currentTableSize?.values?.height,
			size_width: currentTableSize?.values?.width
		};

		if (currentTableColorVariation === 'hide') delete params.color;
		if (currentTableCaptionStatus === null) delete params.caption;

		refetchCurrentSizeData({
			args: params
		});
	}, [currentTableSize, currentTableColorVariation, currentTableCaptionStatus]);

	useShallowEffect(() => {
		if (formsLoading || sizesLoading || currentSizeDataLoading) {
			preloaderEvents.preloaderEnable();
		} else {
			preloaderEvents.preloaderDisable();
		}
	}, [formsLoading, sizesLoading, currentSizeDataLoading]);

	useShallowEffect(() => {
		if (currentTableSize === null && !sizesLoading && sizes.length !== 0) {
			actions.setTableValue({
				type: tableVariation,
				obj: 'size',
				value: sizes?.[0]
			});
		}
	}, [sizes]);

	return useMemo(
		() => ({ dataSizes: sizes, forms, currentSizeData: currentSizeData?.apiResponse?.[0], formsLoading, sizesLoading }),
		[sizes, forms, currentSizeData, formsLoading, sizesLoading]
	);
};
