import { generateEvent } from '../helpers/generate_event';

export const canvasEvents = {
	attachCanvas(detail) {
		setTimeout(() => {
			generateEvent('app_canvas_attach', {
				detail
			});
		}, 5000);
	}
};
