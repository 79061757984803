import React, { useCallback } from 'react';

import { CHANGE_HEIGHT, CHANGE_WIDTH } from 'components/step1/constants';

import RadioInput from 'components/UI/input/radio';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';

import { ReactComponent as CrossIcon } from 'images/icons/x_16.svg';

const items = [
	{ title: 'Оставляем пустым', type: 'empty' },
	{ title: 'Уже стоит памятник', type: 'occupied' },
	{ title: 'Облагораживаем', type: 'improved' }
];

const BurialItem = ({ title, id, selected, areaValues, unionCount }) => {
	const actions = useActions();
	const onChange = useCallback(
		e => {
			actions.changeHistoryBurialState({ id, value: e.target.dataset.value });
			if (e.target.dataset.value !== 'improved') actions.changeBurialUnionCount({ value: 1, id });
		},
		[id]
	);

	const onChangeHandler = useCallback((value, type) => actions.changeHistoryBurialDimensions({ value, id, type }), [id]);
	const onChangeWidthHandler = useCallback(value => onChangeHandler(value, CHANGE_WIDTH), []);
	const onChangeHeightHandler = useCallback(value => onChangeHandler(value, CHANGE_HEIGHT), []);

	const onChangeUnionCount = useCallback(e => actions.changeBurialUnionCount({ value: parseInt(e.target.name), id }), [id]);

	return (
		<BlockWithArrow title={title.replace(' ', ' №')} blockClasses='sidebar__radio__block'>
			{items.map((item, i) => (
				<RadioInput
					key={i}
					title={item.title}
					defaultChecked={selected === item.type}
					onChange={onChange}
					name={`grave${id}_state`}
					id={`grave${id}_state_${item.type}`}
					value={item.type}
				/>
			))}
			{/* {selected === 'improved' && (
				<div>
					<h3>Количество объединенных захоронений</h3>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '200px', margin: '5px' }}>
						{burialsCount.map((item, index) => (
							<RadioInput
								key={item}
								name={item}
								title={item}
								defaultChecked={item === unionCount}
								id={`grave${id}` + item}
								onChange={onChangeUnionCount}
							/>
						))}
					</div>
				</div>
			)} */}
			{selected !== 'improved' && (
				<div className={`sidebar__radio__block--values`}>
					<InputTypeNumber
						max='5000'
						blockClasses='sidebar__radio__block--values__length'
						label='Ширина'
						defaultValue={areaValues.length}
						name={`burial${id}_dimessions_width`}
						id={`burial${id}_dimessions_width`}
						required={true}
						onChange={onChangeWidthHandler}
						useDelay={false}
					/>
					<span className='grayscale-gray-4 icon-16'>
						<CrossIcon />
					</span>
					<InputTypeNumber
						max='5000'
						blockClasses='sidebar__radio__block--values__width'
						label='Длина'
						defaultValue={areaValues.width}
						name={`burial${id}_dimessions_height`}
						id={`burial${id}_dimessions_height`}
						required={true}
						onChange={onChangeHeightHandler}
						useDelay={false}
					/>
				</div>
			)}
		</BlockWithArrow>
	);
};

export default BurialItem;
