import { textColors } from '../../../config/canvas.config';

export const getHexColor = title => {
	let color;
	let textColor = textColors.black;
	switch (title) {
		case 'Мрамор':
			color = '#EEEEEEFF';
			break;
		case 'Дымовский':
			color = '#654b3e';
			textColor = textColors.gray;
			break;
		case 'Габбро Диабаз':
			color = '#191919';
			textColor = textColors.gray;
			break;
		case 'Покостовский':
			color = '#d2d3d4';
			break;
		case 'Южно Султаевский':
			color = '#e3cdb2';
			break;
		case 'Мансуровский':
			color = '#e6e0da';
			break;
		case 'Амфиболит':
			color = '#3e3f41';
			textColor = textColors.gray;
			break;
		default:
			color = '#FFFFFF';
			textColor = textColors.black;
	}
	return { backgroundColor: color, textColor };
};
