import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { stellaEvents } from 'utils/events/stella';
import useBurialsData from 'hooks/useBurialsData';
import { useActions } from 'hooks/useActions';

import TypeStoneContent from './TypeStone';
import StellaProducts from './productList';
import Processing from 'components/UI/other/processing';
import PrimaryButton from 'components/UI/button/primary';
import Sverlovka from './sverlovka';
import SecondButton from 'components/UI/button/second';
import TabsComponent from 'components/UI/tabs/tabs';
import PositionSettings from './stellaPopUpSettings';
import PopUpStellaPosition from './PopUp';
import { dimensions } from 'config/defaultDimensions.config';

const Pedestal = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial } = useBurialsData();
	const material = burial.material.value;
	const currentSidebarTabStep3 = useSelector(state => state.configurator.present.step3.currentSidebarTab);
	const currentStella = burial.stella.currentStella;
	const stellsLength = burial.stella.items.length;
	const boxesLength = burial.boxes.items.length;
	const stellaCreating = burial.stella.noDisabled.stellaCreating;
	const checkToAddStella = stellsLength < 2 && boxesLength == 0 && stellaCreating == true;
	const defaultStellaDimensions = useSelector(state => {
		const complects = state.configurator.present.step1.defaultComplect;
		return complects[material]?.components.find(el => el.terms.includes('stella')).size.values || dimensions.stella;
	});

	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [actions, currentTab]);
	const onChangeTabHandler = useCallback(e => actions.changeCurrentStellalItem(e.target.dataset.value), [actions]);
	const createStellaHandler = useCallback(
		e =>
			actions.addHistoryStella({
				material,
				values: defaultStellaDimensions,
				stellaItemsLength: stellsLength + 1,
				burialId: burial.id
			}),
		[burial.id, material, stellsLength, defaultStellaDimensions]
	);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Стела</h1>
			<TabsComponent
				currentEl={currentStella}
				currentBurial={currentBurial}
				AllEl={burial.stella.items}
				title='СТ'
				onClick={onChangeTabHandler}
			/>
			{material === 'granite' && <TypeStoneContent burial={burial} currentStella={currentStella} material={material} />}
			<StellaProducts burial={burial} currentBurial={currentBurial} currentStella={currentStella} material={material} />
			{boxesLength === 0 && (
				<>
					<PositionSettings burial={burial} />
					{burial.stella.positionBoxState && (
						<PopUpStellaPosition
							currentBurial={currentBurial}
							burial={burial}
							items={burial.stella.items}
							position={burial.stella.position}
							currentTab={currentSidebarTabStep3}
						/>
					)}
				</>
			)}
			<Sverlovka burial={burial} currentStella={currentStella} />
			<Processing />
			{checkToAddStella && <SecondButton title='Добавить стелу' classes='mb24 box__value__add' onClick={createStellaHandler} />}
			{stellsLength > 1 && (
				<SecondButton
					title='Удалить стелу'
					classes='mb24 box__value__delete step1__exclude__add'
					onClick={() => {
						stellaEvents.deleteStella(0, burial.id);
						actions.removeStella();
					}}
				/>
			)}
			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Pedestal;
