import { defaultFontSizeTransform, defaultFontFamily } from 'config/fontSizeTransform.config';
import { epitafiaTextStyles } from 'config/canvas.config';
import { v4 as uuid } from 'uuid';

export const generateEpitafia = (template, price) => {
	const unique_id = uuid();
	const data = template || {};

	const obj = {
		id: unique_id,
		text: 'Благословляю все, что было,\n Я лучшей жизни не искал.',
		fontFamily: defaultFontFamily.value,
		fontSize: defaultFontSizeTransform,
		textAlign: 'left',
		textStyle: epitafiaTextStyles[0].name,
		type: 'text',
		...data
	};

	if (price) obj.price = price;

	return obj;
};
