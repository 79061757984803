import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	canvasContainer: {
		status: false,
		id: null
	},
	data: {}
};

export const canvasSlice = createSlice({
	name: 'canvas',
	initialState,
	reducers: {
		changeDataCanvas: (state, action) => {
			const { id, burialId, data } = action.payload;
			if (!state.data[burialId]) state.data[burialId] = {};
			state.data[burialId][id] = data;
		},
		toggleCanvasContainer: (state, action) => {
			const { status, id } = action.payload;
			state.canvasContainer.status = status;
			if (id) state.canvasContainer.id = id;
		}
	}
});

export const canvasSliceActions = canvasSlice.actions;

export default canvasSlice.reducer;
