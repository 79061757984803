import React, { useMemo } from 'react';

import EngravingType from './EngravingType';
import EngravingCreed from './EngravingCreed';
import EngravingPortrait from './EngravingPortrait';
import EngravingEpitafia from './EngravingEpitafia';
import EngravingCatalog from './EngravingCatalog';
import EngravingOwnPicture from './EngravingOwnPicture';
import EngravingPaintPicture from './EngravingPaintPicture';
import EngravingCoating from './EngravingCoating';
import EngravingFioAndDates from './EngravingFioAndDates';
import { getStelaTemplate } from '../../../../config/stelaTemplates.config';

const Pedestal = ({ burial: burialObj }) => {
	let burial = burialObj.engraving;
	const template = useMemo(() => {
		const stone = burialObj.stella.items[0].stone ?? burialObj.materialStone;

		return getStelaTemplate({
			stone,
			width: burialObj.stella.items[0].individualSizes.values.width,
			height: burialObj.stella.items[0].individualSizes.values.height,
			portrait: burialObj.engraving.portrait?.items?.length
		});
	}, [burialObj.stella, burialObj.engraving, burialObj.materialStone]);
	return (
		<>
			<EngravingType burial={burial} template={template} />

			<EngravingCreed burial={burial} template={template['creed']} />

			<EngravingFioAndDates burial={burial} template={template} />

			<EngravingPortrait burial={burial} template={template} />

			<EngravingEpitafia burial={burial} template={template} stone={burialObj.materialStone} material={burialObj.material.value} />

			<EngravingCatalog burial={burial} template={template} />

			<EngravingOwnPicture burial={burial} template={template} />

			<EngravingPaintPicture burial={burial} template={template} />
			<EngravingCoating burial={burial} template={template} />
		</>
	);
};

export default Pedestal;
