import React, { useCallback } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';

import { useActions } from 'hooks/useActions';
import { worksAPI } from 'services/Works';

const EngravingCreed = ({ burial, template }) => {
	const actions = useActions();
	const creedStatus = burial.creed.status;
	const creedItems = burial.creed.items;

	const onToggleHandler = useCallback(() => {
		actions.setEngravingValue({ obj: 'creed', include: 'status', value: !creedStatus });
		creedItems.length !== 0 && actions.changeEngravingSelectedProducts({ value: creedItems[0] });
	}, [creedItems, creedStatus, actions]);

	const onClickProductHandler = useCallback(
		item => {
			actions.changeEngravingSelectedProducts({ value: { ...item, ...template } });
		},
		[actions]
	);

	const [refetch, { data, isFetching, isError }] = worksAPI.useLazyFetchWorksQuery();

	const refetchData = useCallback(() => refetch({ args: { work_tax: 36, per_page: 100 } }, true), []); // the second arg is preferCacheValue

	return (
		<CatalogForm
			formTitle='Символ веры'
			formState={creedStatus}
			onToggle={onToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог символов'
			selectedProducts={creedItems}
			hook
			isLoading={isFetching}
			isError={isError}
			products={data ?? []}
			refetch={refetchData}
			parentClasses='exception__block creed'
		/>
	);
};

export default EngravingCreed;
