import React, { useState, useEffect } from 'react';

const BlockWithToggle = ({
	title,
	onChangeToggle = () => {},
	children,
	blockClasses = 'exception__block',
	contentClasses = '',
	openState = false,
	headingClasses = '',
	disableContent = false,
	disableToogle = false
}) => {
	const [IsOpen, setIsOpen] = useState(openState);

	useEffect(() => setIsOpen(openState), [openState]);

	return (
		<div className={`sidebar__radio__block__value heading_off ${blockClasses}`} id='exclude_space_block'>
			<div className={`sidebar__block__header heading_off ${IsOpen ? 'active' : ''}`}>
				<h3 className={headingClasses}>{title}</h3>
				{disableToogle == false && (
					<input
						type='checkbox'
						className='toggle'
						checked={IsOpen}
						onChange={e => {
							onChangeToggle(e);
							setIsOpen(!IsOpen);
						}}
					/>
				)}
			</div>
			{IsOpen && disableContent == false && <div className={`box__value ${contentClasses}`}>{children}</div>}
		</div>
	);
};

export default BlockWithToggle;
