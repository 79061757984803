import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { data } from './constans';

export const categoriesAPI = createApi({
	reducerPath: 'categoriesAPI',
	tagTypes: ['Categories'],
	baseQuery: fetchBaseQuery({
		baseUrl: data.categoriesUrl,
		prepareHeaders: headers => {
			headers.set('Access-Control-Allow-Origin', '*');
			return headers;
		}
	}),
	endpoints: build => ({
		fetchModules: build.query({
			query: () => ({
				url: `/module_tax`,
				params: { per_page: 100 }
			}),
			transformResponse(apiResponse, meta) {
				return apiResponse;
			},
			providesTags: result => ['Categories']
		}),
		fetchMaterials: build.query({
			query: () => ({
				url: data.baseUrl + `/materials`,
				params: { per_page: 100 }
			}),
			transformResponse(apiResponse, meta) {
				return apiResponse;
			},
			providesTags: result => ['Categories']
		}),
		fetchSets: build.query({
			query: () => ({
				url: `/set_tax`,
				params: { per_page: 100 }
			}),
			transformResponse(apiResponse, meta) {
				return apiResponse;
			},
			providesTags: result => ['Categories']
		}),
		fetchWorks: build.query({
			query: () => ({
				url: `/work_tax`,
				params: { per_page: 100 }
			}),
			transformResponse(apiResponse, meta) {
				return apiResponse;
			},
			providesTags: result => ['Categories']
		})
	})
});
