import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';

import { useActions } from 'hooks/useActions';

const PhotoRestoration = ({ currentTable, type }) => {
	const actions = useActions();
	const currentTableRestorationStatus = currentTable?.restoration?.status;
	const currentTableRestorationPrice = currentTable?.restoration?.price;

	const onInputHandler = useCallback(
		value => {
			actions.setTableValue({
				type: type,
				obj: 'restoration',
				include: 'price',
				value: value
			});
		},
		[actions, type]
	);

	const onToggleHandler = useCallback(() => {
		actions.setTableValue({
			type: type,
			obj: 'restoration',
			value: { status: !currentTableRestorationStatus, price: 0 }
		});
	}, [currentTableRestorationStatus, actions, type]);

	return (
		<LoadForm
			formTitle='Реставрация фото'
			formState={currentTableRestorationStatus}
			onToggle={onToggleHandler}
			label='Введите стоимость'
			inputValue={currentTableRestorationPrice}
			onInput={onInputHandler}
			loadForm={false}
		/>
	);
};

export default PhotoRestoration;
