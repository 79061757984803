import React, { useState, useCallback } from 'react';
import './custom_select.scss';

const SelectInput = ({ title = 'Ничего', onClick = () => {}, items = [] }) => {
	const [isOpen, setIsOpen] = useState(false);
	const onClickHandle = useCallback(
		el => {
			setIsOpen(false);
			onClick(el);
		},
		[onClick]
	);

	return (
		<div className={`custom_select ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
			<h2>{title}</h2>
			<div className='custom_select_box'>
				{items.map((el, index) => (
					<div key={index} value={el.value} onClick={onClickHandle.bind(null, el)}>
						{el.title}
					</div>
				))}
			</div>
		</div>
	);
};

export default SelectInput;
