import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	categories: {},
	notification: {
		status: false,
		clicked: 1,
		heading: '',
		text: '',
		type: '',
		willLostSteps: []
	},
	steps: [
		{
			id: 1,
			title: 'Участок',
			isActive: true
		},
		{
			id: 2,
			title: 'Комплект',
			isActive: false
		},
		{
			id: 3,
			title: 'Модули',
			isActive: false
		},
		{
			id: 4,
			title: 'Установка',
			isActive: false
		},
		{
			id: 5,
			title: 'Благоустройство',
			isActive: false
		},
		{
			id: 6,
			title: 'Доставка',
			isActive: false
		}
	]
};

const ConfiguratorStepsSlice = createSlice({
	name: 'configuratorSteps',
	initialState: initialState,
	reducers: {
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		goToStep: (state, action) => {
			const selectedIndex = state.steps.findIndex(step => step.id === action.payload);

			// all steps before selected will be active
			state.steps = state.steps.map(step => {
				if (step.id === action.payload)
					return {
						...step,
						isActive: true
					};
				const iterateIndex = state.steps.findIndex(item => item.id === step.id);
				const activeState = iterateIndex < selectedIndex;
				return {
					...step,
					isActive: activeState
				};
			});
		},
		changeNotification: (state, action) => {
			state.notification = action.payload;
		},
		changeNotificationStatus: (state, action) => {
			state.notification.status = action.payload;
		}
	}
});

// Action creators are generated for each case reducer function
export const { goToStep, changeNotification, changeNotificationStatus, setCategories } = ConfiguratorStepsSlice.actions;

export const configuratorSliceActions = ConfiguratorStepsSlice.actions;

export default ConfiguratorStepsSlice.reducer;
