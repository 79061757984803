import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import BackfillFlower from './BackfillFlower';
import BackfillArea from './BackfillArea';
import Sculptures from './Sculptures';
import ForgedElements from './ForgedElements';
import MetalElements from './MetalElements';
import StoneElements from './StoneElements';

const DecoratedElements = () => {
	return (
		<BlockWithArrow title='Декоративные элементы' blockClasses='' contentClasses='sidebar__content'>
			<StoneElements />
			<MetalElements />
			<ForgedElements />
			<Sculptures />
			<BackfillFlower />
			<BackfillArea />
		</BlockWithArrow>
	);
};

export default DecoratedElements;
