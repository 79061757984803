import React, { useCallback, useLayoutEffect, useRef, useState, memo } from 'react';
import { useDebouncedValue, useShallowEffect } from '@mantine/hooks';

const InputTypeNumber = ({
	min = 0,
	max = 20000,
	blockClasses = '',
	label = '',
	name = '',
	id = '',
	onChange = () => {},
	required = false,
	readOnly = false,
	defaultValue = 0,
	delay = 300,
	useDelay = true
}) => {
	const ref = useRef(onChange);
	const [value, setValue] = useState(defaultValue);
	const [debouncedSearchTerm] = useDebouncedValue(value, delay);
	const getMinMaxValue = useCallback(transformValue => Math.max(+min, Math.min(+max, transformValue)), [max, min]);

	useLayoutEffect(() => {
		ref.current = onChange;
	}, [onChange]);

	useLayoutEffect(() => setValue(parseFloat(defaultValue)), [defaultValue]);

	const handleChange = useCallback(
		e => {
			const newValue = parseFloat(e.target.value) || min;
			if (e.type === 'blur' && defaultValue == newValue) return;
			ref.current(newValue, { value: defaultValue, setValue });
		},
		[min, defaultValue]
	);

	const handleKeyDown = useCallback(e => e.key === 'Enter' && handleChange(e), [handleChange]);
	const onChangeHandler = useCallback(
		e => {
			const newValue = e.target.value === '' ? min : e.target.value;
			if (value == newValue || e.key === 'Enter') return;
			setValue(getMinMaxValue(parseFloat(newValue)));
		},
		[getMinMaxValue, min, value]
	);

	useShallowEffect(() => {
		if (useDelay && typeof debouncedSearchTerm === 'number') ref.current(debouncedSearchTerm);
	}, [debouncedSearchTerm, useDelay]);

	return (
		<div className={`${blockClasses} input-number`}>
			{!blockClasses.includes('own__form__input') && <label>{label}</label>}
			<input
				type='number'
				value={value}
				name={name}
				id={id}
				required={required}
				onChange={onChangeHandler}
				onBlur={!useDelay ? handleChange : null}
				onKeyDown={!useDelay ? handleKeyDown : null}
				readOnly={readOnly}
				data-value={value}
			/>
		</div>
	);
};

export default memo(InputTypeNumber);
