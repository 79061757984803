import React, { useCallback, useRef, memo } from 'react';
import { useSelector } from 'react-redux';

import Steps from 'components/Steps';
import Canvas from 'components/Canvas';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import { collectBurialDataForCanvas } from 'utils/canvas/collectBurialDataForCanvas';

const Configurator = ({ stepIndex }) => {
	const actions = useActions();
	const ref = useRef();
	const burialsData = useBurialsData();
	const canvasContainer = useSelector(state => state.canvas.canvasContainer);
	const items = collectBurialDataForCanvas(burialsData.burial);

	const clickHandle = useCallback(id => {
		actions.togglePopUp(false);
		actions.goToStep(id);
		actions.clearHistory();
	}, []);

	const closeCanvasContainer = useCallback(() => actions.toggleCanvasContainer({ status: false }), []);

	return (
		<div className='configurator'>
			{stepIndex >= 2 &&
				items.map(item => {
					// if you will have problem with performance uncommit this 👇
					// if (!canvasContainer.status || item.id !== canvasContainer.id) return;
					return (
						<Canvas
							openStatus={canvasContainer.status && item.id === canvasContainer.id}
							closeCanvasContainer={closeCanvasContainer}
							configurator={ref}
							burial={burialsData.burial}
							key={item.id}
							item={item}
						/>
					);
				})}
			<Steps clickHandle={clickHandle} stepIndex={stepIndex} />
			<div id='v3d-container' className='v3d-container configurator__box'>
				<iframe ref={ref} title='Configurator' id='iframe' src='verge/place_of_horror.html'></iframe>
			</div>
		</div>
	);
};

export default memo(Configurator);
