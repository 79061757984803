import { data as servicesData } from 'services/constans';

export const getInstallation = async (materialType, { width, height, thickness }, stellasLength, work_tax = 56) => {
	if (!stellasLength || stellasLength >= 2) return 0;

	const params = [
		['per_page', 100],
		['work_tax', work_tax],
		['material_tax', materialType],
		['size_width', width],
		['size_height', height],
		['size_thickness', thickness]
	]
		.map(el => el.join('='))
		.join('&');

	const installationRes = await fetch(servicesData.baseUrl + '/works?' + params).then(res => res.json());
	return parseFloat(installationRes?.[0]?.price?.unit || 0);
};
