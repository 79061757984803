import React, { useState, useLayoutEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Download } from 'images/icons/download.svg';

const FileInput = ({ title = 'Загрузить макет', img, onDrop = () => {} }) => {
	const [files, setFiles] = useState(img);
	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: 'image/*',
		onDrop: acceptedFiles => {
			setFiles(acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })));
			acceptedFiles.map(file => onDrop(file));
		}
	});

	useLayoutEffect(() => {
		setFiles(img);
	}, [img]);

	const images = files
		? files.map(file => (
				<div key={file.name}>
					<img src={file.preview} style={{ width: '200px' }} alt='preview' />
				</div>
		  ))
		: null;
	return (
		<div className={`drag-upload-form`}>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				<p>
					{title}
					<span>
						<Download />
					</span>
				</p>
			</div>
			<div>{images}</div>
		</div>
	);
};

export default FileInput;
