import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import Cart from './Cart/Cart';
import Preload from './Preload';
import History from './History';

import useBurialsData from 'hooks/useBurialsData';

const Header = () => {
	const burialsData = useBurialsData();
	const { width, height } = useSelector(state => state.configurator.present.step1.workZone.zoneValues);

	const handlerMaximizeScreen = useCallback(() => {
		let configurator = document.querySelector('#iframe'),
			fullscreen = configurator?.contentWindow,
			fullscreenBtn = fullscreen?.document.querySelector('#fullscreen_button');
		fullscreenBtn.click();
	}, []);

	return (
		<>
			<Preload />
			<History />
			<header className='header'>
				<div className='header-left'>
					<div className='header-logo --svg__logo'></div>
					<div className='header-user'>
						<div className='header__info header__info--user'>
							<div className='header__info__icon --svg__user'></div>
							<p>
								<b>Константинопольский</b>
							</p>
							<p>Константин Константинович</p>
						</div>
						<div className='header__info header__info--square'>
							<div className='header__info__icon --svg__info'></div>
							<p>
								<b>Участок: </b> {width}х{height}
							</p>
							<p>
								<b>Захоронение: </b> {burialsData.burials.length}
							</p>
						</div>
					</div>
				</div>
				<div className='header-right'>
					<Cart />
					<a id='maximize' className='--svg__maximize' onClick={handlerMaximizeScreen}></a>
				</div>
			</header>
		</>
	);
};

export default memo(Header);
