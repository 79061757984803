import React, { useCallback, useMemo, useState, useLayoutEffect } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';
import SecondButton from 'components/UI/button/second';
import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import TextEditor from 'components/UI/popup/textEditor';
import TabsComponent from 'components/UI/tabs/tabs';
import InputTypeNumber from 'components/UI/input/text/number';

import { useActions } from 'hooks/useActions';
import { useConfiguratorCategories } from 'hooks/useConfiguratorCategories';

import { productsAPI } from 'services/Products';
import { fontSizeTransform } from 'config/fontSizeTransform.config';
import { generateEpitafia } from 'utils/forms/generateEpitafia';

const Tombstone = ({ burial, currentBurial }) => {
	const actions = useActions();
	const categories = useConfiguratorCategories('modules');
	const options = Object.values(fontSizeTransform).map(el => ({ value: el + ' мм', title: el + ' мм' }));
	const module_tax = categories.find(el => el.slug === 'elements')?.id;

	const currentTomb = burial.tombstone.currentTomb;
	const tombItems = burial.tombstone.items;
	const tombstoneElements = useMemo(() => tombItems[currentTomb].elements, [currentTomb, tombItems]);

	const catalogState = tombstoneElements.elems.status;
	const epitafiaState = tombstoneElements.epitafia.status;
	const epitafiaText = tombstoneElements.epitafia.text;
	const epitafiaAlign = tombstoneElements.epitafia.textAlign;
	const epitafiaFontSize = tombstoneElements.epitafia.fontSize;
	const epitafiaPrice = tombstoneElements.epitafia.price;
	const selectedProducts = useMemo(() => tombItems[currentTomb].elements.elems.selectedProducts, [currentTomb, tombItems]);

	const [openEpitaphPop, setOpenEpitaphPop] = useState(false);

	const onToggleHandler = useCallback(() => {
		if (selectedProducts.length !== 0) {
			actions.changeElementsSelectedProducts({
				type: 'tombstone',
				module: 'selectedProducts',
				value: selectedProducts[0],
				historySkip: true
			});
		}
		actions.changeElementsSelectedProducts({
			type: 'tombstone',
			module: 'status',
			value: !catalogState
		});
	}, [catalogState, selectedProducts]);

	const onClickHandler = useCallback(item => {
		const newItem = {
			...item,
			width: parseInt(item.size.values.width),
			height: parseInt(item.size.values.height)
		};

		actions.changeElementsSelectedProducts({
			type: 'tombstone',
			module: 'selectedProducts',
			value: newItem
		});
	}, []);

	const onToggleEpitafia = useCallback(() => {
		setOpenEpitaphPop(false);
		actions.changeEpitafia({
			type: 'epitafia',
			moduleType: 'tombstone',
			currentEl: currentTomb,
			value: { status: !epitafiaState, ...generateEpitafia(), text: '' }
		});
	}, [currentTomb, epitafiaState]);

	const addEpitafiaHandler = useCallback(
		e => {
			setOpenEpitaphPop(true);
			actions.changeEpitafia({
				type: 'epitafia',
				moduleType: 'tombstone',
				currentEl: currentTomb,
				value: { status: true, ...generateEpitafia() }
			});
		},
		[currentTomb]
	);

	const onChangeFontSize = useCallback(
		value => {
			actions.changeEpitafia({
				type: 'epitafia',
				moduleType: 'tombstone',
				currentEl: currentTomb,
				include: 'fontSize',
				value: value.value
			});
		},
		[currentTomb]
	);

	const onChangeAlign = useCallback(
		type =>
			actions.changeEpitafia({ type: 'epitafia', moduleType: 'tombstone', currentEl: currentTomb, include: 'textAlign', value: type }),
		[currentTomb]
	);

	const onChangeTextArea = useCallback(
		(e, value) => actions.changeEpitafia({ type: 'epitafia', moduleType: 'tombstone', currentEl: currentTomb, include: 'text', value }),
		[currentTomb]
	);

	const onChangePrice = useCallback(
		price => actions.changeEpitafia({ type: 'epitafia', moduleType: 'tombstone', currentEl: currentTomb, include: 'price', value: price }),
		[currentTomb]
	);

	const onBasketClick = useCallback(() => {
		setOpenEpitaphPop(false);
		actions.changeEpitafia({
			type: 'epitafia',
			moduleType: 'tombstone',
			currentEl: currentTomb,
			value: { status: epitafiaState, ...generateEpitafia(), text: '' }
		});
	}, [currentTomb, epitafiaState]);

	useLayoutEffect(() => {
		if (epitafiaText.length === 0) setOpenEpitaphPop(false);
	}, [epitafiaText]);

	const [refetch, { data, isFetching, isError }] = productsAPI.useLazyFetchProductsQuery();

	const refetchData = useCallback(() => refetch({ args: { module_tax } }, true), [module_tax]); // the second arg is preferCacheValue

	return (
		<>
			<TabsComponent
				currentEl={currentTomb}
				currentBurial={currentBurial}
				AllEl={burial.tombstone.items}
				title='СТ'
				onClick={e => actions.changeCurrentTombstone(e.target.dataset.value)}
			/>
			<CatalogForm
				formTitle='Бронзовые элементы'
				formState={catalogState}
				onToggle={onToggleHandler}
				onClickProductCard={onClickHandler}
				btnTitle='Каталог элементов'
				selectedProducts={selectedProducts}
				products={data?.apiResponse ?? []}
				refetch={refetchData}
				hook
				isLoading={isFetching}
				isError={isError}
			/>

			<BlockWithToggle title='Эпитафия' openState={epitafiaState} onChangeToggle={onToggleEpitafia} contentClasses='editDelete'>
				{openEpitaphPop && (
					<TextEditor
						openState={openEpitaphPop}
						onClick={() => setOpenEpitaphPop(false)}
						className='textEditor epitafiaElements'
						title='Редактор эпитафии:'
						defaultValue={epitafiaText}
						onChangeTextArea={onChangeTextArea}
						libraryState={true}
						libraryBtnTitle='Библиотека эпитафий'
						libraryClassName='mt32 --svg__book-before'
						alignState={true}
						alignType={epitafiaAlign}
						onChangeAlign={onChangeAlign}
						fontSizeState={true}
						fontSizeType='select'
						fontSizeSelect={epitafiaFontSize}
						onChangefontSize={onChangeFontSize}
						fontSizeOptions={options}
						fontFamilyState={false}
						onClickLibraryProductCard={item => onChangeTextArea(null, item.text)}
						selectedLibraryProducts={[{ id: 0, sku: 'epitafia-0', text: epitafiaText }]}
					/>
				)}

				{epitafiaText !== '' && (
					<ActionBlock title={epitafiaText} onEditClick={() => setOpenEpitaphPop(true)} onBasketClick={onBasketClick} />
				)}

				{epitafiaState && epitafiaText !== '' && (
					<InputTypeNumber
						blockClasses='own__form__input'
						label='Введите стоимость'
						defaultValue={epitafiaPrice}
						name={`own__form__input__faska}`}
						id={`own__form__input__faska}`}
						onChange={onChangePrice}
						useDelay={false}
					/>
				)}

				{epitafiaText === '' && openEpitaphPop === false && (
					<SecondButton title='Добавить Эпитафию' onClick={addEpitafiaHandler} classes='second__btn box__value__add' />
				)}
			</BlockWithToggle>
		</>
	);
};

export default Tombstone;
