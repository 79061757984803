import React, { useCallback } from 'react';

import PrimaryButton from 'components/UI/button/primary';

import { useActions } from 'hooks/useActions';

const BtnGoToNextStep = ({ id }) => {
	const actions = useActions();
	const goToNextStep = useCallback(() => {
		actions.goToStep(2);
		actions.changeCurrentBurial(id);
		actions.clearHistory();
	}, [actions, id]);

	return <PrimaryButton id='continue_button' onClick={goToNextStep} title='Продолжить' />;
};

export default BtnGoToNextStep;
