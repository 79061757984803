import React, { useCallback, useState } from 'react';

import BlockWithToggle from 'components/UI/block/blockWithToggle';
import ActionBlock from 'components/UI/block/actionBlock';
import SecondButton from 'components/UI/button/second';
import PopUp from 'components/UI/popup/popup';
import DatesOfLife from './formWithTabs/datesOfLife';
import FormWithTabs from './formWithTabs';
import FioTab from './formWithTabs/fioTab';

import { generateFioAndDates } from 'utils/forms/generateFioAndDates';
import { useActions } from 'hooks/useActions';

const EngravingFioAndDates = ({ burial, template }) => {
	const actions = useActions();
	const [openFIOPop, setOpenFIOPop] = useState(false);
	const currentFioStatus = burial.fio.status;
	const currentFioItems = burial.fio.items;

	const onToggleHandler = useCallback(() => {
		actions.setEngravingValue({ obj: 'fio', include: 'status', value: !currentFioStatus });
	}, [currentFioStatus]);

	const onBasketClickHandler = useCallback(() => {
		actions.setEngravingValue({ obj: 'fio', include: 'items', value: null });
		setOpenFIOPop(false);
	}, [currentFioStatus]);

	const addFioHandler = useCallback(e => {
		actions.setEngravingValue({ obj: 'fio', include: 'items', value: generateFioAndDates(template) });
		setOpenFIOPop(true);
	}, []);

	return (
		<BlockWithToggle
			title='ФИО + даты'
			openState={currentFioStatus}
			onChangeToggle={onToggleHandler}
			blockClasses='sidebar__radio__block classForTablesForm'
			contentClasses='editDelete'
		>
			{currentFioItems !== null && (
				<>
					<PopUp
						state={openFIOPop}
						classes='engravingTabs deceasedDataPlate configurator__light-box'
						onClick={e => setOpenFIOPop(!openFIOPop)}
					>
						<FormWithTabs
							childrens={[
								{
									title: 'ФИО усопшего',
									content: <FioTab data={currentFioItems.fio} currentTableFioColor={currentFioItems.fio.color.title} />
								},
								{
									title: 'Даты жизни',
									content: (
										<DatesOfLife
											template={template}
											data={currentFioItems.datesItems}
											currentTableFioColor={currentFioItems.datesItems.color.title}
										/>
									)
								}
							]}
						/>
					</PopUp>
				</>
			)}

			{currentFioItems !== null && currentFioItems.fio.items.length !== 0 && currentFioItems.fio.items[0]?.text !== '' ? (
				<ActionBlock
					title={currentFioItems.fio.items[0].text}
					onEditClick={() => setOpenFIOPop(true)}
					onBasketClick={onBasketClickHandler}
				/>
			) : (
				<SecondButton title='Добавить ФИО' onClick={addFioHandler} classes='box__value__add' />
			)}
		</BlockWithToggle>
	);
};

export default EngravingFioAndDates;
