import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

const items = ['Ручная', 'Станочная'];

const EngravingType = ({ type, changeData }) => {
	return (
		<BlockWithArrow title='Вид гравировки' openState={false} arrowDelete={true}>
			{items.map((item, index) => (
				<RadioInput
					key={index}
					title={item}
					defaultChecked={type == item}
					onChange={() => changeData('type', item)}
					name='engraving_type'
					id={`engraving_type_${item}`}
				/>
			))}
		</BlockWithArrow>
	);
};

export default EngravingType;
