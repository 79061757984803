import React from 'react';

import './action.scss';

const ActionButton = ({ title = '', onClick = () => {}, id = '', classes = 'btn --svg__arrow-after' }) => {
	return (
		<button id={id} className={`action_btn ${classes}`} onClick={onClick}>
			{title}
		</button>
	);
};

export default ActionButton;
