import React, { useCallback, useState, memo, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import SecondButton from 'components/UI/button/second';
import PopUp from 'components/UI/popup/popup';
import CreateCanvasGrid from 'components/UI/canvas/createCanvasGrid';

import { ReactComponent as Eraser } from 'images/editor/eraser.svg';
import { ReactComponent as Marker } from 'images/editor/marker.svg';
import { ReactComponent as Brush } from 'images/editor/brush.svg';

import './editor.scss';
import { useEffect } from 'react';
import { ReactComponent as Fence } from 'images/icons/fens.svg';
import { ReactComponent as SaveIco } from 'images/icons/save.svg';
import { ReactComponent as LoadIco } from 'images/icons/load.svg';
import { ReactComponent as ScreenShotIco } from 'images/icons/screenshot.svg';
import { useActions } from 'hooks/useActions';
import useBurialsData from '../../../../hooks/useBurialsData';
import { cors } from '../../../../services/constans';

const tools = [
	{ title: 'marker', icon: <Marker /> },
	{ title: 'brush', icon: <Brush /> },
	{ title: 'eraser', icon: <Eraser /> },
	{ title: 'auto', icon: <div style={{ color: '#9A9AAF' }}>Auto</div> },
	{ title: 'fence', icon: <Fence /> }
];

const blockSize = [
	{ title: '30x30', width: 30, height: 30 },
	{ title: '30x40', width: 40, height: 30 },
	{ title: '30x60', width: 60, height: 30 }
];

const autoPatterns = [
	{ title: 'Нет', pattern: null },
	{ title: '30x30', width: 30, height: 30, pattern: 'pattern1' },
	{ title: '30x60', width: 30, height: 60, pattern: 'pattern1' },
	{ title: '60x30', width: 60, height: 30, pattern: 'pattern1' }
];

const brushModes = [
	{ title: 'По одной', mode: 'byOne' },
	{ title: 'Все', mode: 'All' }
];

const fenceModes = [
	{ title: 'Оградка', mode: 'fence' },
	{ title: 'Калитка', mode: 'gate' }
];

const magnetSize = [
	{ title: '1', value: 1 },
	{ title: '2', value: 2 },
	{ title: '5', value: 5 }
];

// const sliceSizes = [
// 	{ title: '10x10', width: 10, height: 10 },
// 	{ title: '15x15', width: 15, height: 15 },
// 	{ title: '20x20', width: 20, height: 20 },
// 	{ title: '30x30', width: 30, height: 30 },
// 	{ title: '40x40', width: 40, height: 40 },
// 	{ title: '50x50', width: 50, height: 50 }
// ];

const Editor = () => {
	const actions = useActions();
	const [openState, setOpenState] = useState(false);
	const [editorState] = useState(false);
	const zoneValues = useSelector(state => state.configurator.present.step1.workZone.zoneValues);
	const materials = useSelector(state => state.configurator.present.step5.tile.horizontal);
	const fenceProductsLength = useSelector(state => state.configurator.present.step5.fence.selectedProducts.length);
	const paving = useSelector(state => state.configurator.present.step5.pavingEditor);
	const [editorData, setEditorData] = useState([]);
	const stageRef = useRef(null);
	const [activeTool, setActiveTool] = useState(null);
	const [activeMaterial, setActiveMaterial] = useState(materials[0]);
	const [activeSize, setActiveSize] = useState(blockSize[0]);
	// const [activeSliceSize, setActiveSliceSize] = useState(sliceSizes[0]);
	const [activeMagnetSize, setActiveMagnetSize] = useState(magnetSize[2].value);
	const [activeAutoPattern, setActiveAutoPattern] = useState(autoPatterns[0]);
	const [activeBrushMode, setActiveBrushMode] = useState(brushModes[0]);
	const { burial } = useBurialsData();
	// const [activeFenceMode, setActiveFenceMode] = useState(fenceModes[0]);
	const fileInput = useRef();
	const switchMaterial = useCallback(
		img => {
			const newMaterial = materials.filter(el => el.img === img)[0];
			setActiveMaterial(newMaterial);
		},
		[materials]
	);

	const changeActiveTool = useCallback(
		type => {
			const check = activeTool === type;
			setActiveTool(check ? null : type);
		},
		[activeTool]
	);

	const savePaving = useCallback(() => {
		function download(content, fileName, contentType) {
			const a = document.createElement('a');
			const file = new Blob([content], { type: contentType });
			a.href = URL.createObjectURL(file);
			a.download = fileName;
			a.click();
		}
		download(JSON.stringify({ ...paving, areaValues: burial.areaValues }), 'data.json', 'text/plain');
	}, [paving]);

	const makeScreenshot = useCallback(saveToFile => {
		window.pavingScreenshot = stageRef.current.toDataURL({ mimeType: 'image/jpeg', quality: 0.9 });
		window.pavingScreenshotCustomFlag = true;
		if (saveToFile) {
			let arr = window.pavingScreenshot.split(','),
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			const a = document.createElement('a');
			// const file = new Blob([window.pavingScreenshot], { type: 'image/jpeg' });
			const file = new Blob([u8arr], { type: 'image/jpeg' });
			a.href = URL.createObjectURL(file);
			a.download = 'screenshot.jpg';
			a.click();
		}
	}, []);

	const loadPaving = () => {
		fileInput.current.click();
	};

	const fileInputOnchange = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		reader.readAsText(file, 'UTF-8');
		reader.onload = readerEvent => {
			const content = JSON.parse(readerEvent.target.result);
			actions.setRectangles(content.rectangles);
			actions.setFence(content.fence);
		};
	};

	useEffect(() => {
		if (!burial.improvement.plate[0]) {
			actions.addRectangles({ rectangles: [], id: burial.id });
			return;
		}
		if (!burial.improvement.plate[0].json || !burial.improvement.plate[0].json.length) return;

		if (burial.improvement.plate[0].id === window.selectedPavingPlate) return;

		window.selectedPavingPlate = burial.improvement.plate[0].id;
		fetch(cors + burial.improvement.plate[0].json, {
			method: 'GET'
		})
			.then(resp => resp.json())
			.then(json => {
				const width = burial.areaValues.length / 10;
				const height = burial.areaValues.width / 10;
				const shiftedRects = json.rectangles.map(rect => {
					return {
						x: rect.x - json.areaValues.x + burial.areaValues.x,
						y: rect.y - json.areaValues.y + burial.areaValues.y,
						width: rect.width,
						height: rect.height,
						fromLibrary: true,
						burialId: burial.id
					};
				});

				actions.addRectangles({ rectangles: shiftedRects, id: burial.id });
			});
	}, [burial?.improvement?.plate, burial.areaValues]);

	useLayoutEffect(() => {
		if (activeTool === 'fence' && fenceProductsLength === 0) {
			actions.changeFenceData({ status: true, data: [] });
		}
	}, [activeTool, fenceProductsLength]);

	return (
		<>
			{openState && (
				<PopUp
					state={openState}
					onClick={() => {
						if (!window.pavingScreenshotCustomFlag) makeScreenshot();
						setOpenState(false);
					}}
					classes='configurator__light-box plate_editor'
				>
					<div className='plate_editor__toolbar'>
						<div className='plate_editor__toolbar__tools'>
							<div className={`plate_editor__toolbar__item--label`}>Инструменты</div>
							<div className={'plate_editor__toolbar__tools_wrapper'}>
								{tools.map(el => {
									const { title, icon } = el;
									return (
										<div
											key={title}
											className={`plate_editor__toolbar__item ${activeTool === el.title ? 'active' : ''}`}
											onClick={() => changeActiveTool(el.title)}
										>
											{icon}
										</div>
									);
								})}
								<div className={`plate_editor__toolbar__item`} onClick={savePaving}>
									<SaveIco />
								</div>
								<div className={`plate_editor__toolbar__item`} onClick={loadPaving}>
									<LoadIco />
									<input type={'file'} style={{ display: 'none' }} ref={fileInput} onChange={fileInputOnchange} accept='.json' />
								</div>
								<div className={`plate_editor__toolbar__item`} onClick={() => makeScreenshot(true)}>
									<ScreenShotIco />
								</div>
							</div>
						</div>
						{/*<div className='plate_editor__toolbar__tools'>*/}
						{/*	{materials.map(material => (*/}
						{/*		<div*/}
						{/*			key={material.material}*/}
						{/*			className={`plate_editor__toolbar__item plate_editor__toolbar__item--material ${*/}
						{/*				activeMaterial.title === material.title ? 'active' : ''*/}
						{/*			}`}*/}
						{/*			onClick={() => setActiveMaterial(material)}*/}
						{/*		>*/}
						{/*			<img src={material.img} alt='stone type' />*/}
						{/*		</div>*/}
						{/*	))}*/}
						{/*</div>*/}
						{activeTool === 'marker' && (
							<div className='plate_editor__toolbar__tools'>
								<div className={`plate_editor__toolbar__item--label`}>Размер</div>
								<div className={'plate_editor__toolbar__tools_wrapper'}>
									{blockSize.map(size => (
										<div
											key={size.title}
											className={`plate_editor__toolbar__item plate_editor__toolbar__item--size ${
												activeSize.title === size.title ? 'active' : ''
											}`}
											onClick={() => setActiveSize(size)}
										>
											{size.title}
										</div>
									))}
								</div>
							</div>
						)}
						{activeTool === 'auto' && (
							<div className='plate_editor__toolbar__tools'>
								<div className={`plate_editor__toolbar__item--label`}>Паттерн</div>
								<div className={'plate_editor__toolbar__tools_wrapper'}>
									{autoPatterns.map(size => (
										<div
											key={size.title}
											className={`plate_editor__toolbar__item plate_editor__toolbar__item--size ${
												activeAutoPattern.title === size.title ? 'active' : ''
											}`}
											onClick={() => setActiveAutoPattern(size)}
										>
											{size.title}
										</div>
									))}
								</div>
							</div>
						)}
						{activeTool === 'brush' && (
							<div className='plate_editor__toolbar__tools'>
								<div className={`plate_editor__toolbar__item--label`}>Режим</div>
								<div className={'plate_editor__toolbar__tools_wrapper'}>
									{brushModes.map(size => (
										<div
											key={size.title}
											className={`plate_editor__toolbar__item plate_editor__toolbar__item--size ${
												activeBrushMode.title === size.title ? 'active' : ''
											}`}
											onClick={() => setActiveBrushMode(size)}
										>
											{size.title}
										</div>
									))}
								</div>
							</div>
						)}
						{/*{activeTool === 'fence' && (*/}
						{/*	<div className='plate_editor__toolbar__tools'>*/}
						{/*		<div className={`plate_editor__toolbar__item--label`}>Инструмент</div>*/}
						{/*		<div className={'plate_editor__toolbar__tools_wrapper'}>*/}
						{/*			{fenceModes.map(size => (*/}
						{/*				<div*/}
						{/*					key={size.title}*/}
						{/*					className={`plate_editor__toolbar__item plate_editor__toolbar__item--size ${*/}
						{/*						activeFenceMode.title === size.title ? 'active' : ''*/}
						{/*					}`}*/}
						{/*					onClick={() => setActiveFenceMode(size)}*/}
						{/*				>*/}
						{/*					{size.title}*/}
						{/*				</div>*/}
						{/*			))}*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*)}*/}
						<div className='plate_editor__toolbar__tools'>
							<div className={`plate_editor__toolbar__item--label`}>Шаг сетки</div>
							<div className={'plate_editor__toolbar__tools_wrapper'}>
								{magnetSize.map(size => (
									<div
										key={size.title}
										className={`plate_editor__toolbar__item plate_editor__toolbar__item--size2 ${
											activeMagnetSize === size.value ? 'active' : ''
										}`}
										onClick={() => setActiveMagnetSize(size.value)}
									>
										{size.title}
									</div>
								))}
							</div>
						</div>
					</div>
					<div className='plate_editor__content'>
						<div className='plate_editor__content__canvas'>
							<CreateCanvasGrid
								stageRef={stageRef}
								url={editorData}
								changeUrl={setEditorData}
								canvasId='plateEditorCanvas'
								openStatus={editorState}
								stageDimensions={zoneValues}
								activeTool={activeTool}
								activeMaterial={activeMaterial}
								activeSize={activeSize}
								switchMaterial={switchMaterial}
								activeMagnetSize={activeMagnetSize}
								activeAutoPattern={activeAutoPattern}
								activeBrushMode={activeBrushMode}
							/>
						</div>
						<div className='plate_editor__content__data'></div>
					</div>
					<div style={{ position: 'absolute', zIndex: 10, bottom: '3px' }}>* Нестандартный размер</div>
				</PopUp>
			)}
			<SecondButton title='Распределить плитку' onClick={() => setOpenState(true)} classes='box__value__edit' />
		</>
	);
};

export default memo(Editor);
