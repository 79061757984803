import React from 'react';

import BlockWithArrow from 'components/UI/block/blockWithArrow';
import RadioInput from 'components/UI/input/radio';

const types = [
	{ title: 'Гранит', value: 'granite' },
	{ title: 'Мрамор', value: 'marble' }
];

const TypeStoneType = ({ onChangeHandler, defaultMaterial, title }) => {
	return (
		<BlockWithArrow title={title}>
			{types.map(material => (
				<RadioInput
					key={material.title}
					title={material.title}
					defaultChecked={defaultMaterial === material.title}
					onChange={onChangeHandler}
					name='Material'
					id={material.title}
					value={material.value}
				/>
			))}
		</BlockWithArrow>
	);
};

export default TypeStoneType;
