import React from 'react';
import ReactDOM from 'react-dom';
import styles from './notification.module.scss';

const NotificationPortal = ({ heading, text, type, onClickNoHandler, onClickYesHander, list }) => {
	return ReactDOM.createPortal(
		<div className={styles.notification}>
			<div className={styles.notification__container}>
				<div className={styles.notification__header}>
					<h2>{heading}</h2>
					<p>{text}</p>
					{list && <strong>{list.join(', ')}</strong>}
				</div>
				<div className={styles.notification__footer}>
					{(type === 'alert' || type === 'changing') && (
						<>
							<button onClick={onClickNoHandler}>Нет</button>
							<button onClick={onClickYesHander}>Да</button>
						</>
					)}
					{type === 'warning' && <button onClick={onClickNoHandler}>Хорошо</button>}
				</div>
			</div>
		</div>,
		document.body
	);
};

export default NotificationPortal;
