export const dispatchEvent = function (event_name, value = {}, decomp = false) {
	// console.log('%creact_event: ' + event_name, 'background: lightgreen;', typeof value === 'function' ? 'callback function' : value);
	return new Promise(resolve => {
		window.dispatchEvent(
			new CustomEvent(event_name, {
				detail: decomp ? { ...value } : { value, _cb: resolve }
			})
		);
	});
};
