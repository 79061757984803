import { convertStringToPrice } from 'utils/convertStringToPrice';

export const convertPrice = price => {
	if (typeof price == 'object') {
		const priceValue = price?.unit ?? price?.cubic_metre ?? price?.square_metre ?? price?.running_metre;
		return convertStringToPrice(priceValue);
	} else if (typeof price === 'string' && price !== '') {
		return price;
	} else {
		return convertStringToPrice(0);
	}
};
