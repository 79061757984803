import React from 'react';
import styles from './preloader.module.scss';

const Preloader = () => {
	return (
		<div className={styles.preloader}>
			<div className={`${styles.preloader__logo} --svg__logo`}></div>
			<div className={styles.lds__ellipsis}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default Preloader;
