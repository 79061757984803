import React, { useCallback, useMemo } from 'react';

import TabsComponent from 'components/UI/tabs/tabs';
import LoadForm from 'components/UI/form/loadForm';
import CatalogForm from 'components/UI/form/catalogForm';
import FaskaModule from 'components/UI/bigComponents/FaskaModule';

import { stellaEvents } from 'utils/events/stella';
import { useActions } from 'hooks/useActions';
import { worksAPI } from 'services/Works';
import Frames from './Frames';
import Decoration from './Decoration';

const Stella = ({ burial, currentBurial }) => {
	const actions = useActions();

	const currentStella = burial.stella.currentStella;

	const stellaHeight = useMemo(
		() => burial.stella.items[burial.stella.currentStella].individualSizes.values.height,
		[burial.stella.currentStella]
	);

	const getValueItem = useCallback(
		(moduleObject, moduleType) => burial.stella.items[currentStella].form[moduleObject][moduleType],
		[burial.stella.items, currentStella]
	);

	const formStatus = getValueItem('form', 'status');
	const selectedProductsForm = getValueItem('form', 'selectedProducts');

	const clientFormStatus = getValueItem('clienForm', 'status');
	const clientFormValue = getValueItem('clienForm', 'price');
	const clientFormImages = getValueItem('clienForm', 'img');

	const framesSelectedProducts = getValueItem('frames', 'selectedProducts');
	const decorationSelectedProducts = getValueItem('frames', 'selectedProducts');

	const resetFormHandler = useCallback(() => stellaEvents.steleModelChanged(burial.id, 1), [burial.id]);

	const [refetch, { data, isFetching, isError }] = worksAPI.useLazyFetchWorksQuery();

	const refetchData = useCallback(
		() => refetch({ args: { work_tax: 23, size_height: stellaHeight, per_page: 100, material_tax: burial.material.value } }, true),
		[stellaHeight, burial.material.value]
	); // the second arg is preferCacheValue

	return (
		<>
			<TabsComponent
				currentEl={currentStella}
				currentBurial={currentBurial}
				AllEl={burial.stella.items}
				title='СТ'
				onClick={e => actions.changeCurrentStellalItem(e.target.dataset.value)}
			/>
			<CatalogForm
				formTitle='Форма'
				formState={formStatus}
				onToggle={() => {
					actions.onToggleFaskaForm([
						{
							type: 'stella',
							current: currentStella,
							moduleObject: 'form',
							historySkip: true,
							value: { status: !formStatus, selectedProducts: [] }
						},
						{
							type: 'stella',
							current: currentStella,
							moduleObject: 'clienForm',
							historySkip: true,
							value: { status: false, price: 0, img: [] }
						}
					]);
					if (formStatus) resetFormHandler();
				}}
				onClickProductCard={item => {
					const include = selectedProductsForm.some(el => el.sku === item.sku);
					!include ? stellaEvents.steleModelChanged(burial.id, item.extra?.id ?? 1) : resetFormHandler();

					actions.changeFormElements({
						type: 'stella',
						current: currentStella,
						moduleObject: 'form',
						moduleType: 'selectedProducts',
						value: include ? [] : [item]
					});
				}}
				btnTitle='Каталог форм'
				products={data ?? []}
				refetch={refetchData}
				hook
				isLoading={isFetching}
				isError={isError}
				selectedProducts={selectedProductsForm}
			/>
			<LoadForm
				formTitle='Форма клиента'
				formState={clientFormStatus}
				onToggle={() => {
					actions.onToggleFaskaForm([
						{
							type: 'stella',
							current: currentStella,
							moduleObject: 'clienForm',
							historySkip: true,
							value: { status: !clientFormStatus, price: 0, img: [] }
						},
						{
							type: 'stella',
							current: currentStella,
							moduleObject: 'form',
							historySkip: true,
							value: { status: false, selectedProducts: [] }
						}
					]);
				}}
				label='Введите стоимость'
				inputValue={clientFormValue}
				onInput={value => {
					actions.changeFormElements({
						type: 'stella',
						current: currentStella,
						moduleObject: 'clienForm',
						moduleType: 'price',
						value: value
					});
				}}
				images={clientFormImages}
				onDrop={files => {
					actions.changeFormElements({
						type: 'stella',
						current: currentStella,
						moduleObject: 'clienForm',
						moduleType: 'img',
						value: files
					});
				}}
			/>
			<Frames
				currentStella={currentStella}
				framesStatus={getValueItem('frames', 'status')}
				selectedProductsFrames={getValueItem('frames', 'selectedProducts')}
				stellaHeight={stellaHeight}
				price={framesSelectedProducts?.[0]?.price?.unit ?? 0}
			/>
			<Decoration
				currentStella={currentStella}
				decorationStatus={getValueItem('decoration', 'status')}
				decorationSides={getValueItem('decoration', 'side')}
				decorationProductsSelected={getValueItem('decoration', 'selectedProducts')}
				stellaHeight={stellaHeight}
				price={decorationSelectedProducts?.[0]?.price?.unit ?? 0}
				burialMaterial={burial.material.value}
			/>
			<FaskaModule type='stella' currentElement={currentStella} />
		</>
	);
};

export default Stella;
