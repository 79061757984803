import React, { useCallback } from 'react';

import LoadForm from 'components/UI/form/loadForm';
import TabsComponent from 'components/UI/tabs/tabs';
import FaskaModule from 'components/UI/bigComponents/FaskaModule';

import { useActions } from 'hooks/useActions';

const Flower = ({ burial, currentBurial }) => {
	const actions = useActions();

	const currentFlower = burial.flowers.currentFlower;
	const flowers = burial.flowers.items;

	const getValueItem = useCallback(
		(moduleObject, moduleType) => flowers[currentFlower].form[moduleObject][moduleType],
		[currentFlower, flowers]
	);

	const clientFormStatus = getValueItem('clienForm', 'status');
	const clientFormValue = getValueItem('clienForm', 'price');
	const clientFormImages = getValueItem('clienForm', 'img');

	return (
		<>
			<TabsComponent
				currentEl={currentFlower}
				currentBurial={currentBurial}
				AllEl={flowers}
				title='ЦВ'
				onClick={e => actions.changecurrentFlower(e.target.dataset.value)}
			/>
			<LoadForm
				formTitle='Форма клиента'
				formState={clientFormStatus}
				onToggle={() => {
					actions.changeFormElements({
						type: 'flowers',
						current: currentFlower,
						moduleObject: 'clienForm',
						value: { status: !clientFormStatus, price: 0, img: [] }
					});
				}}
				label='Введите стоимость'
				inputValue={clientFormValue}
				onInput={value => {
					actions.changeFormElements({
						type: 'flowers',
						current: currentFlower,
						moduleObject: 'clienForm',
						moduleType: 'price',
						value: value
					});
				}}
				images={clientFormImages}
				onDrop={files => {
					actions.changeFormElements({
						type: 'flowers',
						current: currentFlower,
						moduleObject: 'clienForm',
						moduleType: 'img',
						value: files
					});
				}}
			/>
			<FaskaModule type='flowers' currentElement={currentFlower} />
		</>
	);
};

export default Flower;
