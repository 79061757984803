import React, { memo, useMemo, useState, useCallback } from 'react';
import cn from 'classnames';

import CartList from './CartList/CartList';

import { useClickOutside } from '@mantine/hooks';
import { useCart } from 'hooks/useCart';
import useBurialsData from 'hooks/useBurialsData';

import { ReactComponent as ArrowUp } from 'images/icons/up.svg';

const Cart = () => {
	const [openDetails, setOpenDetails] = useState(false);
	const [dropdown, setDropdown] = useState(null);
	const [control, setControl] = useState(null);
	const burialsData = useBurialsData();
	useClickOutside(() => setOpenDetails(false), null, [control, dropdown]);

	const cart = useCart(burialsData.burials);
	const cartTotal = useMemo(() => cart.totalCost.toFixed(0), [cart]);

	const onClickHandler = useCallback(() => {
		if (cartTotal === '0') return;
		setOpenDetails(!openDetails);
	}, [openDetails, cartTotal]);

	return (
		<>
			<div
				ref={setControl}
				onClick={onClickHandler}
				className={cn('header__info header__info--cart', {
					active: openDetails
				})}
			>
				<div className='header__info__content'>
					<div className='header__info__icon --svg__cart'></div>
					<p>Стоимость:</p>
					<p>{cartTotal} руб.</p>
				</div>
				<div
					className={cn('header__info--cart__arrow', {
						hidden: cartTotal === '0'
					})}
				>
					<ArrowUp />
				</div>
			</div>
			{openDetails && (
				<div ref={setDropdown}>
					<CartList cart={cart} />
				</div>
			)}
		</>
	);
};

export default memo(Cart);
