import React, { useMemo, useState } from 'react';
import useGetParametrs from './../../../../API/getParametrs';

import PopUp from '../popup';
import TextareaInput from './../../input/textarea';
import PrimaryButton from '../../button/primary';
import SelectInput from '../../input/select';
import RadioInput from './../../input/radio';
import AdvancedPopUp from './../advanced/popup';

import './textEditor.scss';

import { ReactComponent as Left } from 'images/align/left.svg';
import { ReactComponent as Right } from 'images/align/right.svg';
import { ReactComponent as Center } from 'images/align/center.svg';
import { tablesTextColors, epitafiaTextStyles } from 'config/canvas.config';
import { fontSizeOptions as options } from 'config/fontSizeTransform.config';
import { standaloneSize } from 'utils/standaloneSize';

const TextEditor = ({
	openState,
	onClick = () => {},
	className = '',
	title = 'Редактор эпитафии:',
	defaultValue = '',
	onChangeTextArea = () => {},
	libraryState = false,
	onChangeLibraryState = () => {},
	libraryBtnTitle = 'Библиотека',
	libraryOnClick = () => {},
	onClickLibraryProductCard = () => {},
	selectedLibraryProducts = [],
	libraryClassName = '--svg__book-before',
	alignState = false,
	alignVariant = 'btn',
	alignType = 'center',
	onChangeAlign = () => {},
	fontSizeState = false,
	fontSizeType = 'input',
	fontSizeSelect = '',
	fontSizeOptions = options ?? [],
	onChangefontSize = () => {},
	fontFamilyState = false,
	fontFamylyOption = [],
	fontFamilySelect = '',
	onChangeFontFamily = () => {},
	colorState = false,
	colorTitle = '',
	colors = tablesTextColors,
	onSelectColorHandler = () => {}
}) => {
	const [libraryPopUp, setLibraryPopUp] = useState(false);
	const getParamsHandler = useGetParametrs();
	const fontFamilyTitle = useMemo(() => {
		const title = fontFamylyOption.find(el => el.title === fontFamilySelect || el.value === fontFamilySelect);
		return title?.title ?? '';
	}, [fontFamilySelect, fontFamylyOption]);

	return (
		<>
			<PopUp state={openState} onClick={onClick} classes={`configurator__light-box ${className}`}>
				<h3>{title}</h3>
				<div className='box'>
					<div className='tools row'>
						{fontFamilyState ? <SelectInput title={fontFamilyTitle} items={fontFamylyOption} onClick={onChangeFontFamily} /> : null}
						{fontSizeState ? (
							fontSizeType == 'input' ? (
								<div className='row sidebar__radio__block'>
									<h4>Размер шрифта:</h4>
									{fontSizeOptions.map((el, index) => (
										<RadioInput
											key={index}
											title={el.value}
											id={el.value}
											name={el.value}
											onChange={onChangefontSize}
											defaultChecked={el.value == fontSizeSelect}
										/>
									))}
								</div>
							) : (
								<div className='fontSizeSelect'>
									<SelectInput title={standaloneSize(fontSizeSelect)} items={fontSizeOptions} onClick={onChangefontSize} />
								</div>
							)
						) : null}
						{colorState && <SelectInput title={colorTitle} items={colors} onClick={onSelectColorHandler} />}
						{alignState && alignVariant === 'btn' && (
							<div className='align-btns'>
								<button className={`${alignType == 'left' ? 'active' : ''}`} onClick={e => onChangeAlign('left')}>
									<Left />
								</button>
								<button className={`${alignType == 'center' ? 'active' : ''}`} onClick={e => onChangeAlign('center')}>
									<Center />
								</button>
								<button className={`${alignType == 'right' ? 'active' : ''}`} onClick={e => onChangeAlign('right')}>
									<Right />
								</button>
							</div>
						)}
						{alignState && alignVariant === 'radio' && (
							<div className='align-btns'>
								{epitafiaTextStyles.map((el, index) => (
									<RadioInput
										key={index}
										title={el.title}
										id={el.name}
										name={el.name}
										onChange={onChangeAlign.bind(null, el)}
										defaultChecked={el.name == alignType}
										classNames='textareaRadio sidebar__radio__block'
									/>
								))}
							</div>
						)}
					</div>
					<TextareaInput blockClasses={`textarea textEditor_align ${alignType}`} value={defaultValue} onChange={onChangeTextArea} />
					{libraryState == true ? (
						<PrimaryButton
							title={libraryBtnTitle}
							onClick={e => {
								setLibraryPopUp(true);
								libraryOnClick();
							}}
							className={`btn ${libraryClassName}`}
						/>
					) : null}
				</div>
			</PopUp>
			{libraryPopUp == true ? (
				<AdvancedPopUp
					openState={libraryPopUp}
					changeState={state => {
						setLibraryPopUp(state);
						onChangeLibraryState(state);
					}}
					products={() => getParamsHandler.getModuleProducts('epitafies')}
					onClick={onClickLibraryProductCard}
					selectedProducts={selectedLibraryProducts}
					type={'epitafia'}
					sortState={true}
					sortTypes={[
						{
							sortType: 'az',
							sort: 'text',
							classes: '--svg__sorting-az'
						},
						{
							sortType: 'za',
							sort: 'text',
							classes: '--svg__sorting-za'
						}
					]}
					filterParametr='text'
				/>
			) : null}
		</>
	);
};

export default TextEditor;
