import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import step1Reducers from './graves/step1';
import step2Reducers from './graves/step2';
import step4Reducers from './graves/step4';
import step5Reducers from './graves/step5';

import step3Tables from './graves/step3/tables';
import step3Engraving from './graves/step3/engraving';
import step3Stella from './graves/step3/stella';
import step3Boxes from './graves/step3/boxes';
import step3Pedestal from './graves/step3/pedestal';
import step3Flower from './graves/step3/flower';
import step3Tombstone from './graves/step3/tombstone';
import step3Elements from './graves/step3/elements';

import { data } from 'services/constans';

import { dispatchEvent } from 'utils/events/dispatchEvent';
import { setComplect } from 'utils/helpers/complects/setComplect';
import { asyncWrapper } from 'utils/asyncWrapper';
import { getInstallation } from 'utils/helpers/complects/getInstallation';

const step1Functions = step1Reducers();
const step2Functions = step2Reducers();
const step4Functions = step4Reducers();
const step5Functions = step5Reducers();

const tablesFunctions = step3Tables();
const engravingFunctions = step3Engraving();
const stellaFunctions = step3Stella();
const boxesFunctions = step3Boxes();
const pedestalFunctions = step3Pedestal();
const flowerFunctions = step3Flower();
const tombstoneFunctions = step3Tombstone();
const elementsFunctions = step3Elements();

const params = {
	set_tax: 9,
	module_tax: 9,
	page: 1,
	per_page: 1,
	polishing: 5
};

export const fetchFirstComplectForBurial = createAsyncThunk('step1/fetchFirstComplectForBurial', async (_, thunkAPI) => {
	const graniteres = await axios.get(data.baseUrl + '/sets', {
		params: { ...params, material: 3389, include: 3354 }
	});

	const marbleRes = await axios.get(data.baseUrl + '/sets', {
		params: { ...params, material: 3420, include: 3358 }
	});

	const granite = await setComplect(graniteres.data[0], 'disable', 'granite');
	const marble = await setComplect(marbleRes.data[0], 'disable', 'marble');

	return { granite, marble };
});

export const resetBurialsInstallations = createAsyncThunk('step1/resetBurialsInstallations', async (_, thunkAPI) => {
	const burials = thunkAPI.getState().step1.burials;
	return await asyncWrapper(() => {
		return Promise.all(
			burials
				.map(async burial => {
					if (burial.selected !== 'improved') return null;
					const stellaDimensions = burial.stella.items[0].individualSizes;
					const material = burial.material.value;

					const installationPrice = await getInstallation(
						material,
						{
							width: stellaDimensions.values.width,
							thickness: stellaDimensions.values.length,
							height: stellaDimensions.values.height
						},
						burial.stella.items.length
					);

					return { id: burial.id, price: installationPrice };
				})
				.filter(el => el)
		);
	});
});

export const findBurial = (state, index) => {
	return state.burials.find(item => item.id === state.burials[index || state.currentBurial].id);
};

export const changeSelectProductStatus = (selected, item, noChange = false) => {
	const isSelected = selected.some(el => el?.sku == item?.sku);

	return isSelected == false ? (noChange == true ? (selected = [item]) : selected.concat(item)) : selected.filter(el => el.sku != item.sku);
};

export const sendEventToChangeMaterial = (id, type, firstName, secondName) => {
	if (type == 'granite') {
		return dispatchEvent(firstName, {
			material: 'gabbo_diabez',
			id: id
		});
	} else {
		return dispatchEvent(secondName, {
			material: 'marble',
			id: id
		});
	}
};

export const initialState = {
	loading: true,
	installation: 'Установка памятника',
	religion: 'Православное',
	workZone: {
		selected: 'Место захоронения',
		open: false,
		zoneValues: { width: '2400', height: '2400' }
	},
	renderBurials: 'false',
	howManyBurials: 1,
	linkBurials: { isOpen: false, value: [] },
	burials: [],
	generalIndexFlowers: 0,
	currentBurial: 0,
	renderLinks: false,
	howManyLinks: 0,
	excludeArea: { isOpen: false, areas: [] },
	total: { totalArea: 0, workArea: 0, excluded: [] },
	defaultComplect: { granite: null, marble: null }
};

export const step1Slice = createSlice({
	name: 'step1',
	initialState,
	reducers: {
		// step 1
		changeLoading: step1Functions.changeLoading,
		changeReligion: step1Functions.changeReligion,
		changeInstallation: step1Functions.changeInstallation,
		changeTotal: step1Functions.changeTotal,
		changeWorkZone: step1Functions.changeWorkZone,
		changeWorkZoneOpen: step1Functions.changeWorkZoneOpen,
		changeHowManyBurials: step1Functions.changeHowManyBurials,
		renderChangeHowManyBurials: step1Functions.renderChangeHowManyBurials,
		toggleLinkBurials: step1Functions.toggleLinkBurials,
		changeLinkBurials: step1Functions.changeLinkBurials,
		changeBurialSelectedState: step1Functions.changeBurialSelectedState,
		changeBurialAreaValues: step1Functions.changeBurialAreaValues,
		changeBurialUnionCount: step1Functions.changeBurialUnionCount,
		changeGraves: step1Functions.changeGraves,
		changeBurialPosition: step1Functions.changeBurialPosition,

		// step 2
		changeMaterialType: step2Functions.changeMaterialType,
		changeMaterialStone: step2Functions.changeMaterialStone,
		changeCurrentBurial: step2Functions.changeCurrentBurial,
		changeCatalogTypeFirst: step2Functions.changeCatalogTypeFirst,
		changeSelectedProducts: step2Functions.changeSelectedProducts,
		changeSelectedProductsComplect: step2Functions.changeSelectedProductsComplect,
		changePolishing: step2Functions.changePolishing,
		resetToDefaultSecondStep: step2Functions.resetToDefaultSecondStep,
		selectComponent: step2Functions.selectComponent,
		selectComponentProduct: step2Functions.selectComponentProduct,

		// step 3
		// pedestal
		addPedestal: pedestalFunctions.addPedestal,
		removePedestal: pedestalFunctions.removePedestal,
		changeCurrentPedestalItem: pedestalFunctions.changeCurrentPedestalItem,
		// changePedestalType: pedestalFunctions.changePedestalType,
		changePedestalStone: pedestalFunctions.changePedestalStone,
		changePedestalSverlovka: pedestalFunctions.changePedestalSverlovka,
		changePedestalSelectedProducts: pedestalFunctions.changePedestalSelectedProducts,
		changePedestalIndividualStatus: pedestalFunctions.changePedestalIndividualStatus,
		changePedestalIndividualSizes: pedestalFunctions.changePedestalIndividualSizes,

		// boxes
		changeCurrentBox: boxesFunctions.changeCurrentBox,
		addBox: boxesFunctions.addBox,
		removeBox: boxesFunctions.removeBox,
		changeBoxStone: boxesFunctions.changeBoxStone,
		changeBoxesAmount: boxesFunctions.changeBoxesAmount,
		changeBoxesAmountListen: boxesFunctions.changeBoxesAmount,
		changeBoxSverlovka: boxesFunctions.changeBoxSverlovka,
		changeBoxDepth: boxesFunctions.changeBoxDepth,
		changeBoxWidth: boxesFunctions.changeBoxWidth,
		changeBoxHeight: boxesFunctions.changeBoxHeight,
		changeBoxPositionStatus: boxesFunctions.changeBoxPositionStatus,
		changeIndexPos: boxesFunctions.changeIndexPos,
		changeBoxPopUpState: boxesFunctions.changeBoxPopUpState,
		changeBoxPopUpValues: boxesFunctions.changeBoxPopUpValues,
		changeBoxPopUpValuesListen: boxesFunctions.changeBoxPopUpValues,
		changeBoxPopAllPosition: boxesFunctions.changeBoxPopAllPosition,
		changeBoxPopUpAllValues: boxesFunctions.changeBoxPopUpAllValues,

		// stella
		addStella: stellaFunctions.addStella,
		removeStella: stellaFunctions.removeStella,
		changeCurrentStellalItem: stellaFunctions.changeCurrentStellalItem,
		changeStellaPopUpState: stellaFunctions.changeStellaPopUpState,
		changeStellaPositionStatus: stellaFunctions.changeStellaPositionStatus,
		changeStellaPositionAlign: stellaFunctions.changeStellaPositionAlign,
		changeValuesStellaPopUp: stellaFunctions.changeValuesStellaPopUp,
		changeValuesStellaPopUpAll: stellaFunctions.changeValuesStellaPopUpAll,
		changeValuesStellaPopUpAllFromEvent: stellaFunctions.changeValuesStellaPopUpAllFromEvent,
		changeDisableToCreateStella: stellaFunctions.changeDisableToCreateStella,
		changeDisabledAlignInStellaPopUp: stellaFunctions.changeDisabledAlignInStellaPopUp,
		changeMaxValuesPopUpStella: stellaFunctions.changeMaxValuesPopUpStella,
		changeStellaType: stellaFunctions.changeStellaType,
		changeStellaSelectedProducts: stellaFunctions.changeStellaSelectedProducts,
		changeStellaIndividualSizes: stellaFunctions.changeStellaIndividualSizes,
		changeStellaIndividualStatus: stellaFunctions.changeStellaIndividualStatus,
		changeStellaStone: stellaFunctions.changeStellaStone,
		changeStellaSverlovka: stellaFunctions.changeStellaSverlovka,

		// flower
		addFlower: flowerFunctions.addFlower,
		removeFlower: flowerFunctions.removeFlower,
		changeFlowerSizesStatus: flowerFunctions.changeFlowerSizesStatus,
		changeFlowerIndividualSizes: flowerFunctions.changeFlowerIndividualSizes,
		changeFlowerType: flowerFunctions.changeFlowerType,
		changeSelectedFlowerVariant: flowerFunctions.changeSelectedFlowerVariant,
		changeFlowerSelectedProductsPoperehna: flowerFunctions.changeFlowerSelectedProductsPoperehna,
		changeFlowerSelectedProductsProdolna: flowerFunctions.changeFlowerSelectedProductsProdolna,
		changeFlowerPolishing: flowerFunctions.changeFlowerPolishing,
		changeFlowerOwnState: flowerFunctions.changeFlowerOwnState,
		changeFlowerOwnPrice: flowerFunctions.changeFlowerOwnPrice,
		changeFlowerOwnImg: flowerFunctions.changeFlowerOwnImg,
		changeFlowerMaterial: flowerFunctions.changeFlowerMaterial,
		changeFlowerStone: flowerFunctions.changeFlowerStone,
		changecurrentFlower: flowerFunctions.changecurrentFlower,

		// tombstone
		changeCurrentTombstone: tombstoneFunctions.changeCurrentTombstone,
		changeTombstoneMaterial: tombstoneFunctions.changeTombstoneMaterial,
		changeTombstoneMaterialType: tombstoneFunctions.changeTombstoneMaterialType,
		changeTombstoneIndividualSizes: tombstoneFunctions.changeTombstoneIndividualSizes,
		changeTombstoneArtisticDesignStatus: tombstoneFunctions.changeTombstoneArtisticDesignStatus,
		changeTombstoneArtisticDesignSelectedProducts: tombstoneFunctions.changeTombstoneArtisticDesignSelectedProducts,
		changeTombstoneDecorationFaskaStatus: tombstoneFunctions.changeTombstoneDecorationFaskaStatus,
		changeTombstoneDecorationFaskaSelectedProducts: tombstoneFunctions.changeTombstoneDecorationFaskaSelectedProducts,
		createTombstoneItem: tombstoneFunctions.createTombstoneItem,
		deleteCurrentTombstoneItem: tombstoneFunctions.deleteCurrentTombstoneItem,

		// tables
		changeCurrentTab: tablesFunctions.changeCurrentTab,
		generateTable: tablesFunctions.generateTable,
		setTableValue: tablesFunctions.setTableValue,
		changeCurrentTableItem: tablesFunctions.changeCurrentTableItem,
		setItemsFioDateTable: tablesFunctions.setItemsFioDateTable,
		setItemsFioDateTableItems: tablesFunctions.setItemsFioDateTableItems,
		setItemsGranitTablePortrait: tablesFunctions.setItemsGranitTablePortrait,
		changePortraitData: tablesFunctions.changePortraitData,

		// engraving
		setEngravingValue: engravingFunctions.setEngravingValue,
		changeEngravingSelectedProducts: engravingFunctions.changeSelectedProducts,
		setEngravingItemsFioDateTable: engravingFunctions.setItemsFioDate,
		setEngravingItemsFioDateTableItems: engravingFunctions.setItemsFioDateItems,
		changeEpitaliaSelectedProducts: engravingFunctions.changeEpitaliaSelectedProducts,

		// elements
		changeElementsSelectedProducts: elementsFunctions.changeElementsSelectedProducts,
		changeEpitafia: elementsFunctions.changeEpitafia,
		setElementsValue: elementsFunctions.setElementsValue,

		// step 4
		addExcludeItem: step4Functions.addExcludeItem,
		removeExcludeItem: step4Functions.removeExcludeItem,
		changeExcludedItemPosition: step4Functions.changeExcludedItemPosition,
		changeExcludedItemValue: step4Functions.changeExcludedItemValue,
		changeExcludeAreaOpen: step4Functions.changeExcludeAreaOpen,
		changeBurialInstallation: step4Functions.changeBurialInstallation,
		changeTileAreaPrice: step4Functions.changeTileAreaPrice,

		// step 5
		changeTileArea: step5Functions.changeTileArea,
		changeCladding: step5Functions.changeCladding,
		changePlateProducts: step5Functions.changePlateProducts,

		// USE IT FOR CHANGE FORM ELEMENTS
		changeFormElements: (state, action) => {
			const burial = findBurial(state);
			const { type, current, moduleObject, moduleType, value } = action.payload;
			if (moduleType) {
				burial[type].items[current].form[moduleObject][moduleType] = value;
			} else {
				burial[type].items[current].form[moduleObject] = value;
			}
		},
		// USE JUST IT FOR CHANGE SIZES
		changeListIndividualSizes: (state, action) => {
			const burial = findBurial(state);
			if (action.payload.type != null && action.payload.current != null)
				burial[action.payload.type].items[parseInt(action.payload.current)].individualSizes = action.payload.individual;
		},
		// USE JUST IT FOR CHANGE SELECTED PRODUCTS
		changeListSelectedProducts: (state, action) => {
			const burial = findBurial(state);
			if (action.payload.type != null && action.payload.current != null)
				burial[action.payload.type].items[parseInt(action.payload.current)].selectedProducts = changeSelectProductStatus(
					burial[action.payload.type].items[parseInt(action.payload.current)].selectedProducts,
					action.payload.values,
					action.payload.noChange ?? false
				);
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed
		builder.addCase(fetchFirstComplectForBurial.fulfilled, (state, action) => {
			state.defaultComplect = action.payload || { granite: null, marble: null };
		});
		builder.addCase(resetBurialsInstallations.fulfilled, (state, action) => {
			const newPrices = action.payload;
			state.burials = state.burials.map(burial => {
				const newBurialInstallation = newPrices.find(el => el.id === burial.id);
				if (newBurialInstallation) burial.improvement.tileArea.price = newBurialInstallation.price;
				return burial;
			});
		});
		builder.addCase(resetBurialsInstallations.rejected, (state, action) => {
			state.burials = state.burials.map(burial => {
				burial.improvement.tileArea.price = 0;
				return burial;
			});
		});
	}
});

export const { changeHowManyBurials, changeGraves } = step1Slice.actions;

export const step1SliceActions = { ...step1Slice.actions, fetchFirstComplectForBurial, resetBurialsInstallations };

export default step1Slice.reducer;
