import { generateDropZone } from '../forms/generateDropZone';
import { generateCatalog } from '../forms/generateCatalog';
import { generateCatalogWithCanvas } from '../forms/generateCatalogWithCanvas';
import { generateEngravingForTombstone } from './engraving/generateEngravingForTombstone';
import { v4 as uuid } from 'uuid';
import { dimensions } from 'config/defaultDimensions.config';
import { defaultFontSizeTransform } from 'config/fontSizeTransform.config';

export const createTombstone = (title = 'Гранит', value = 'granite', stone = 'Габбро Диабаз') => {
	const unique_id = uuid();
	const { width: defaultWidth, length: defaultLength, height: defaultHeight } = dimensions.tombstone;
	return {
		id: unique_id,
		material: {
			title: title,
			value: value
		},
		stone: stone,
		selectedProducts: [],
		individualSizes: {
			status: true,
			values: {
				width: defaultWidth ?? 600,
				length: defaultLength ?? 1000,
				height: defaultHeight ?? 100
			}
		},
		form: {
			clienForm: generateDropZone(),
			decoration: generateCatalogWithCanvas(false, false),
			decorationFaska: generateCatalog(),
			ownFaska: generateDropZone()
		},
		engraving: generateEngravingForTombstone(),
		elements: {
			elems: {
				status: false,
				selectedProducts: []
			},
			epitafia: {
				status: false,
				text: '',
				fontSize: defaultFontSizeTransform,
				textAlign: 'center'
			}
		}
	};
};
