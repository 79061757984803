import React, { useState, useCallback, useEffect } from 'react';

import PrimaryButton from 'components/UI/button/primary';
import SidebarElementTab from 'components/UI/tabs/sidebarElementTab';
import Pedestal from './pedestal';
import Boxes from './boxes';
import Flower from './flower';
import Stella from './Stella';
import Tombstone from './tombstone';

import { useActions } from 'hooks/useActions';
import useBurialsData from 'hooks/useBurialsData';

import { dispatchEvent } from 'utils/events/dispatchEvent';

import { ReactComponent as PedestalIcon } from 'images/sidebar/Pedestal.svg';
import { ReactComponent as BoxesIcon } from 'images/sidebar/Boxes.svg';
import { ReactComponent as StellaIcon } from 'images/sidebar/Stella.svg';
import { ReactComponent as FlowerIcon } from 'images/sidebar/Flower.svg';
import { ReactComponent as TombstoneIcon } from 'images/sidebar/Tombstone.svg';

const Form = ({ currentTab }) => {
	const actions = useActions();
	const { burial, currentBurial, burials } = useBurialsData();

	const goToNextStep = useCallback(() => actions.changeCurrentSidebarTab(currentTab + 1), [currentTab, actions]);
	const getLenghtByType = useCallback(type => burial[type].items.length, [burial]);
	const formTabs = [
		{ name: 'Тумба', icon: <PedestalIcon />, count: getLenghtByType('pedestal'), target: 'pedestal' },
		{ name: 'Куб', icon: <BoxesIcon />, count: getLenghtByType('boxes'), target: 'pedestal' },
		{ name: 'Стела', icon: <StellaIcon />, count: getLenghtByType('stella'), target: 'stella' },
		{ name: 'Цветник', icon: <FlowerIcon />, count: getLenghtByType('flowers'), target: 'flower' },
		{ name: 'Надгроб.', icon: <TombstoneIcon />, count: getLenghtByType('tombstone'), target: 'tombstone' }
	];

	const firstActiveTab = formTabs.find(el => el.count !== 0);

	const [activeTab, setActiveTab] = useState(firstActiveTab?.name);

	useEffect(() => {
		dispatchEvent(`setCameraTarget`, { target: formTabs.filter(tab => tab.name === activeTab)[0]?.target, currentBurial });
	}, [activeTab, currentBurial]);

	return (
		<>
			<h2 className='heading-h2'>{burial.title}</h2>
			<h1 className='heading-h1'>Формовка</h1>
			<SidebarElementTab activeTab={activeTab} changeTab={setActiveTab} burials={burials} currentBurial={currentBurial} items={formTabs} />

			{activeTab == 'Тумба' && <Pedestal burial={burial} currentBurial={currentBurial} />}
			{activeTab == 'Куб' && <Boxes burial={burial} currentBurial={currentBurial} />}
			{activeTab == 'Стела' && <Stella burial={burial} currentBurial={currentBurial} />}
			{activeTab == 'Цветник' && <Flower burial={burial} currentBurial={currentBurial} />}
			{activeTab == 'Надгроб.' && <Tombstone burial={burial} currentBurial={currentBurial} />}

			<PrimaryButton title='Продолжить' onClick={goToNextStep} id='continue_button' />
		</>
	);
};

export default Form;
