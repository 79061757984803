import { createFlower } from './step3_generateFlower';
import { generateStella } from './step3_generateStella';
import { dimensions } from 'config/defaultDimensions.config';
import { elements, engraving, improvement, installation, tables } from './config/burial';
import { tablesTabs } from './tables/step3_createTables';

export const generateBurial = (
	existingLength = 0,
	selected = 'improved',
	width = 1800,
	length = 1200,
	title = `Захоронение ${existingLength + 1}`,
	id = existingLength,
	status = 'normal'
) => {
	const { length: defaultLength, width: defaultWidth } = dimensions.burial;

	return {
		title: title,
		selected: selected.toLowerCase(),
		id: id,
		areaValues: {
			length: defaultLength ?? parseInt(width),
			width: defaultWidth ?? parseInt(length)
		},
		unionCount: 1,
		status: status,
		material: {
			title: 'Гранит',
			value: 'granite'
		},
		polishing: 5,
		materialStone: 'Габбро Диабаз',
		catalog: {
			type: 'standart',
			selectedProducts: []
		},
		pedestal: {
			currentPedestal: 0,
			items: []
		},
		boxes: {
			currentBox: null,
			positionBoxState: false,
			position: {
				status: false,
				max: 0,
				items: [0, 0, 0, 0, 0]
			},
			items: []
		},
		stella: {
			currentStella: 0,
			positionBoxState: false,
			maxValues: {
				first: 1000,
				second: 1000,
				merged: 1000
			},
			noDisabled: {
				stellaCreating: true,
				lineAlign: true,
				parralelAlign: true
			},
			position: {
				status: false,
				align: 'center',
				items: [0, 0, 0, 0, 0]
			},
			items: [generateStella({})]
		},
		flowers: {
			currentFlower: 0,
			items: [createFlower({ index: id, selected: 'standard' })]
		},
		tombstone: {
			currentTomb: 0,
			items: []
		},
		tables: tables,
		tablesCurrentTab: tablesTabs[0].name,
		engraving: engraving,
		elements: elements,
		installation: installation,
		improvement: improvement(),
		position: { x: 0, y: 0 }
	};
};
