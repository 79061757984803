import React, { useCallback, useLayoutEffect, useMemo } from 'react';

import RadioButtonsList from 'components/UI/bigComponents/RadioButtonsList';
import BlockWithArrow from 'components/UI/block/blockWithArrow';
import PrimaryButton from 'components/UI/button/primary';
import InputTypeNumber from 'components/UI/input/text/number';

import cn from 'classnames';
import { useActions } from 'hooks/useActions';

import { tablesInstalationTypes, tablesFastingTypes } from 'config/tablesInstalationTypes';
import { worksAPI } from 'services/Works';

const Instalation = ({ currentTable, tableType }) => {
	const actions = useActions();

	const { data: fasteningPrices } = worksAPI.useFetchWorksQuery(
		{ args: { work_tax: 50, per_page: 100 } },
		{ refetchOnMountOrArgChange: true }
	);

	const { data: montazPrices } = worksAPI.useFetchWorksQuery(
		{ args: { work_tax: 52, per_page: 100 } },
		{ refetchOnMountOrArgChange: true }
	);

	const currentMontaz = currentTable?.montaz.type;
	const currentMontazPrice = currentTable?.montaz.price;
	const onChangeCustomPrice = useCallback(
		(price, skip = false) => {
			actions.setTableValue({ type: tableType, obj: 'montaz', include: 'price', value: price, historySkip: skip });
		},
		[tableType]
	);
	const onChangeMontazType = useCallback(
		item => actions.setTableValue({ type: tableType, obj: 'montaz', include: 'type', value: item.title }),
		[tableType]
	);

	const currentFasteningType = currentTable?.fastening.type;
	const currentFastingData = useMemo(() => {
		return tablesInstalationTypes.find(el => el.title === currentMontaz)?.fastening ?? [];
	}, [currentMontaz]);
	const onChangeHowManyInstallationType = useCallback(
		el => actions.setTableValue({ type: tableType, obj: 'fastening', include: 'type', value: el.type }),
		[tableType]
	);

	const currentFasteningAnount = currentTable?.fastening.amount;
	const onChangeHowManyInstallation = useCallback(
		el => actions.setTableValue({ type: tableType, obj: 'fastening', include: 'amount', value: +el }),
		[tableType]
	);

	useLayoutEffect(() => {
		if (!montazPrices || !Array.isArray(montazPrices)) return;
		const montazTypeObj = tablesInstalationTypes.find(el => el.title === currentMontaz);
		const price = montazPrices.find(el => el?.extra?.comment === tableType && el?.extra?.characteristic === montazTypeObj.name);
		actions.setTableValue({ type: tableType, obj: 'montaz', include: 'price', value: price?.price?.unit ?? 0, historySkip: true });
	}, [montazPrices, currentMontaz]);

	useLayoutEffect(() => {
		if (!fasteningPrices || !Array.isArray(fasteningPrices)) return;
		let price = 0;
		if (currentFastingData.length !== 0) {
			const backType = tablesFastingTypes.find(el => el.type === currentFasteningType);
			const priceByOne = fasteningPrices.find(el => el?.extra?.characteristic === backType.backName);
			price = parseFloat(priceByOne?.price?.unit ?? 0) * parseFloat(currentFasteningAnount);
		} else {
			onChangeCustomPrice(0, true);
		}
		actions.setTableValue({ type: tableType, obj: 'fastening', include: 'price', value: price, historySkip: true });
	}, [fasteningPrices, currentFasteningAnount, currentFasteningType, currentFastingData, onChangeCustomPrice]);

	return (
		<>
			{tableType !== 'granit' && (
				<RadioButtonsList methods={tablesInstalationTypes} onChange={onChangeMontazType} currentInstallation={currentMontaz}>
					{currentFastingData.length === 0 && (
						<InputTypeNumber
							blockClasses='own__form__input'
							label='Введите стоимость'
							defaultValue={currentMontazPrice}
							name={`own__form__input__montaz}`}
							id={`own__form__input__montaz}`}
							onChange={price => onChangeCustomPrice(price)}
							useDelay={false}
						/>
					)}
				</RadioButtonsList>
			)}
			{currentFastingData.length !== 0 && (
				<>
					<BlockWithArrow title='Тип креплений' blockClasses='sidebar__radio__block classForTablesForm'>
						{tablesFastingTypes.map((el, index) => (
							<PrimaryButton
								key={index}
								title={el.name}
								onClick={onChangeHowManyInstallationType.bind(null, el)}
								className={cn('btn', {
									isActiveSize: currentFasteningType === el.type
								})}
							/>
						))}
					</BlockWithArrow>
					<BlockWithArrow title='Количество креплений' blockClasses='sidebar__radio__block classForTablesForm'>
						{currentFastingData.map((el, index) => (
							<PrimaryButton
								key={index}
								title={el}
								onClick={onChangeHowManyInstallation.bind(null, el)}
								className={cn('btn', {
									isActiveSize: currentFasteningAnount === el
								})}
							/>
						))}
					</BlockWithArrow>
				</>
			)}
		</>
	);
};

export default Instalation;
