import React, { useCallback } from 'react';

import CatalogForm from 'components/UI/form/catalogForm';

import { useActions } from 'hooks/useActions';
import { worksAPI } from 'services/Works';
import { getFitArgs } from '../../../../config/sizes';

const EngravingCatalog = ({ burial, template }) => {
	const actions = useActions();
	const engravingStatus = burial.engraving.status;
	const engravingItems = burial.engraving.items;

	const onToggleHandler = useCallback(() => {
		actions.setEngravingValue({ obj: 'engraving', include: 'status', value: !engravingStatus });
		if (engravingItems.length !== 0) actions.changeEngravingSelectedProducts({ type: 'engraving', value: engravingItems[0] });
	}, [actions, engravingItems, engravingStatus]);

	const onClickProductHandler = useCallback(
		item => {
			actions.changeEngravingSelectedProducts({
				type: 'engraving',
				value: { ...item, ...getFitArgs(item), ...template.engraving }
			});
		},
		[actions]
	);

	const [refetch, { data, isFetching, isError }] = worksAPI.useLazyFetchWorksQuery();

	const refetchData = useCallback(() => refetch({ args: { work_tax: 24, per_page: 100 } }, true), []); // the second arg is preferCacheValue

	return (
		<CatalogForm
			formTitle='Гравировка'
			formState={engravingStatus}
			onToggle={onToggleHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог гравировки'
			selectedProducts={engravingItems}
			hook
			isLoading={isFetching}
			isError={isError}
			products={data ?? []}
			refetch={refetchData}
			parentClasses='exception__block engraving'
		/>
	);
};

export default EngravingCatalog;
