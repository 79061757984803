import { dispatchEvent } from 'utils/events/dispatchEvent';

export const preloaderEvents = {
	preloaderEnable() {
		dispatchEvent(
			'setpreloader',
			{
				state: true,
				value: true
			},
			true
		);
	},
	preloaderDisable() {
		dispatchEvent(
			'setpreloader',
			{
				state: false,
				value: false
			},
			true
		);
	}
};
