import React, { useState } from 'react';
import './popup.scss';

const FormWithTabs = ({
	titleFirst = 'ФИО усопшего',
	titleSecond = 'Даты жизни',
	children1,
	children2,
	state = false,
	classNames = 'tabs'
}) => {
	const [active, setActive] = useState('tab1');
	return (
		<div className={`${classNames} fio_tabs ${state ? 'active' : ''}`}>
			<div className='tab'>
				<input type='radio' id='tab1' name='tab-group' checked={active == 'tab1' ? true : false} onChange={() => setActive('tab1')} />
				<label htmlFor='tab1' className='tab-title'>
					<h3>{titleFirst}</h3>
				</label>
				<div className='tab-content'>{children1}</div>
			</div>
			<div className='tab'>
				<input type='radio' id='tab2' name='tab-group' checked={active == 'tab2' ? true : false} onChange={() => setActive('tab2')} />
				<label htmlFor='tab2' className='tab-title'>
					<h3>{titleSecond}</h3>
				</label>
				<div className='tab-content'>{children2}</div>
			</div>
		</div>
	);
};

export default FormWithTabs;
