import React, { useCallback } from 'react';

import { withCustomPrice } from 'components/UI/HOC/withCustomPrice';
import CatalogForm from 'components/UI/form/catalogForm';

import { productsAPI } from 'services/Products';

import { useWithCustomPriceReUnit } from 'components/UI/HOC/hooks/useWithCustomPriceReUnit';
import { useActions } from 'hooks/useActions';

const BackgroundCatalog = ({
	currentTable,
	type,
	refetchHandlerRef,
	onChangeCustomPrice,
	refetchData,
	isFetching,
	isError,
	changedData,
	customPrice,
	changeCustomPrice,
	children
}) => {
	const actions = useActions();
	const currentTableBackgroundStatus = currentTable?.background?.status;
	const currentTableBackgroundItems = currentTable?.background?.items;

	const onClickProductHandler = useCallback(
		item => {
			const include = currentTableBackgroundItems.some(el => el.sku === item.sku);
			const price = item.price?.unit ?? customPrice;
			actions.setTableValue({
				type,
				obj: 'background',
				include: 'items',
				value: include ? [] : [{ ...item, price: { unit: price } }]
			});
		},
		[type, currentTableBackgroundItems]
	);

	const onChangeToggleHandler = useCallback(() => {
		actions.setTableValue({
			type,
			obj: 'background',
			value: { status: !currentTableBackgroundStatus, items: [] }
		});
	}, [currentTableBackgroundStatus, type]);

	const onBasketClickHandler = useCallback(() => {
		actions.setTableValue({
			type,
			obj: 'background',
			value: { status: currentTableBackgroundStatus, items: [] }
		});
	}, [currentTableBackgroundStatus, type]);

	const refetchDataHandler = useCallback(refetch => refetch({ args: { module_tax: 49, per_page: 100 } }, true), []);

	const onChangeCustomPriceHandler = useCallback(
		price => {
			if (currentTableBackgroundItems.length === 0 || price === undefined) return;
			actions.setTableValue({
				type,
				obj: 'background',
				include: 'items',
				value: [{ ...currentTableBackgroundItems[0], price: { unit: parseFloat(price) } }]
			});
		},
		[currentTableBackgroundItems]
	);

	useWithCustomPriceReUnit({
		onChangeCustomPrice,
		refetchHandlerRef,
		onChangeCustomPriceHandler,
		refetchDataHandler,
		price: currentTableBackgroundItems?.[0]?.price?.unit ?? 0,
		changeCustomPrice
	});

	return (
		<CatalogForm
			formTitle='Фон на табличке'
			formState={currentTableBackgroundStatus}
			onToggle={onChangeToggleHandler}
			onBasketClick={onBasketClickHandler}
			onClickProductCard={onClickProductHandler}
			btnTitle='Каталог фонов'
			products={changedData.items}
			hook
			isLoading={isFetching}
			isError={isError}
			refetch={refetchData}
			selectedProducts={currentTableBackgroundItems}
		>
			{children}
		</CatalogForm>
	);
};

const withCustomPriceComponent = withCustomPrice(BackgroundCatalog, productsAPI.useLazyFetchProductsQuery);
export default withCustomPriceComponent;
